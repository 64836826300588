// Styles
import { Container, Section, Breadcrumb, Select } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import { useEffect, useState } from "react";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import PlanCard from "../../../components/PlanCard";
import Button from "../../../components/Button";
import SectionLandings from "../../../components/SectionLandings";
import PlanForm from "../../../components/LandingsForms/PlanForm";
import CoberturasForm from "../../../components/LandingsForms/CoberturasForm";
import ValorCuotaForm from "../../../components/LandingsForms/ValorCuotaForm";
// Icons
import {
  FiDollarSign,
  FiShield,
  FiClipboard,
  FiStar,
  FiList,
  FiGlobe,
  FiMonitor,
  FiArrowLeft,
  FiBarChart2,
} from "react-icons/fi";
import loadingBicicleta from "../../../assets/iconos/bicicleta.gif";
// Hooks
import { useCrearPlan } from "./hooks/useCrearPlan";
// Constants
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import InformacionForm from "../../../components/LandingsForms/InformacionForm";
import LandingsForm from "../../../components/LandingsForms/LandingsForm";
import NuevoLimiteForm from "../../../components/LandingsForms/NuevoLimiteForm";
import apiPrudenciaBici from "../../../services/apiPrudencia/PrudenciaBici.js";
import apiPrudenciaMonopatin from "../../../services/apiPrudencia/PrudenciaMonopatin.js";
import NewWrapper from "../../../components/NewWrapper/index.js";
import NewInner from "../../../components/NewInner/index.js";
import Layout from "../../../components/Layout/index.js";
import Subtitle from "../../../components/Subtitle/index.jsx";
import Divider from "../../../components/Divider/index.js";
import NewTitlePageSection from "../../../components/NewTitlePageSection/index.js";
import NewButton from "../../../components/NewButton/index.jsx";
import BackButton from "../../../components/BackButton/index.js";

const LandingsCrearPlan = () => {
  const {
    data,
    detallesForm,
    beneficiosForm,
    planForm,
    actions,
    buttonText,
    setCoberturaIdPrudencia,
    coberturaIdPrudencia,
  } = useCrearPlan();
  const {
    productos,
    coberturas,
    companias,
    companiaLogo,
    tieneValorCuota,
    nuevaCompaniaProducto,
  } = data;
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = planForm;
  const {
    deleteItem,
    editArrayList,
    handleChangeOperador,
    handleChangeCobertura,
    handleValorCuota,
  } = actions;

  //cobertura api prudencia
  const [coberturasApi, setCoberturasApi] = useState([]);
  const [compañiaSeleccionada, setCompañiaSelecciona] = useState();
  const [productoSeleccionado, setProductoSeleccionado] = useState();

  useEffect(() => {
    const fetchData = async () => {
      console.log(productoSeleccionado?.label);
      setCoberturasApi([]);
      if (productoSeleccionado?.label === "Monopatin") {
        console.log("entro apiMonopatin");
        try {
          console.log("entro en el try");
          const response = await apiPrudenciaMonopatin("1870", "1", 250000);
          setCoberturasApi(response.Cotizaciones);
          console.log(response);
        } catch (error) {
          console.error("Error al llamar a apiPrudenciaMonopatin:", error);
        }
      } else if (productoSeleccionado) {
        // Aseguramos que no sea null antes de llamar a la API
        try {
          const response = await apiPrudenciaBici("1870", "1", 250000);
          setCoberturasApi(response.Cotizaciones);
          console.log(response);
        } catch (error) {
          console.error("Error al llamar a apiPrudenciaBici:", error);
        }
      }
    };

    if (productoSeleccionado) {
      fetchData(); // Llama a la función cuando cambia productoSeleccionado
    }
  }, [productoSeleccionado]);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setCoberturaIdPrudencia(selectedId); // Guardar el ID seleccionado en el estado
  };

  return (
    <>
      {/* <Sidebar active="landings" /> */}
      <Layout>
          <NewWrapper>
            <NewInner>
            <Container>
                <Col desktop={12}>
                <BackButton url={"/Landings"} text={"Landings"}/>
                </Col>

                <Col desktop={12}>
                  <NewTitlePageSection
                    title="Landings"
                    description={"Creá un nuevo plan"}
                  />
                </Col>

                <Col desktop={12}>
                  <Divider />
                </Col>

                <Col desktop={12}>
                  <Subtitle
                    icon={<FiGlobe />}
                    title={`Landing`}
                    description={`Elegí a que landing va a pertenecer el plan`}
                    disableDivider={true}
                  />
                </Col>
                <Col desktop={12}>
                  <LandingsForm
                    form={{
                      inputValues,
                      handleChangeExternal,
                      error: formErrors.landing,
                    }}
                  />
                </Col>

                {/* <SectionLandings
                  columns={12}
                  icon={<FiGlobe />}
                  title="Landing"
                  description="Elegí a que landing va a pertenecer el plan"
                  divider={true}
                >

                </SectionLandings> */}

                {/* <SectionLandings
                columns={12}
                icon={<FiClipboard />}
                title="Plan"
                description="Conocé y modificá algunos aspectos generales del plan"
                divider={true}
              >
              </SectionLandings> */}

                <Col desktop={12}>
                  <Divider />
                </Col>

                <Col desktop={12}>
                  <Subtitle
                    icon={<FiClipboard />}
                    title={`Plan`}
                    description={`Conocé y modificá algunos aspectos generales del plan`}
                    disableDivider={true}
                  />
                </Col>
                <Col desktop={12}>
                  <PlanForm
                    form={planForm}
                    data={{
                      productos,
                      companias,
                      setCompañiaSelecciona,
                      setProductoSeleccionado,
                    }}
                  />
                </Col>

                {/* DESDE ACA  */}

                <Col desktop={12}>
                  <Divider />
                </Col>

                <Col desktop={12}>
                  <Subtitle
                    icon={<FiShield />}
                    title={`Coberturas`}
                    description={`Cambiá el estado de las coberturas del plan`}
                    disableDivider={true}
                  />
                </Col>
                <Col desktop={12}>
                  <CoberturasForm
                    coberturas={coberturas}
                    form={{
                      error: formErrors.coberturas,
                      handleChangeCobertura,
                    }}
                  />
                </Col>

                {/* <SectionLandings
                columns={12}
                icon={<FiShield />}
                title="Coberturas"
                description="Elegí las coberturas del plan"
                divider={true}
              > 

              </SectionLandings> */}

                <Col desktop={12}>
                  <Divider />
                </Col>

                {compañiaSeleccionada?.label === "Prudencia" &&
                productoSeleccionado ? (
                  <div style={{ width: "1100%" }}>
                    <h3>Elegi una cobertura</h3>
                    {coberturasApi && coberturasApi.length > 0 ? (
                      <Select onChange={handleSelectChange}>
                        <option>SELECCIONE</option>
                        {coberturasApi.map((cobertura, index) => (
                          <option key={index} value={cobertura.CoberturaID}>
                            {cobertura.CoberturaNombre}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          src={loadingBicicleta}
                          alt="Cargando"
                        />
                        <p>Cargando...</p>
                      </div>
                    )}
                  </div>
                ) : inputValues.landing !== "Seguro Motos" ? (
                  <></>
                ) : // <SectionLandings
                //   columns={12}
                //   icon={<FiDollarSign />}
                //   title="Valor de la cuota"
                //   description="Modificá el cálculo para el valor de la cuota del plan"
                //   divider={true}
                // >
                //   <ValorCuotaForm
                //     valorCuota={{ tieneValorCuota, handleValorCuota }}
                //     form={{ ...planForm, handleChangeOperador }}
                //   />
                // </SectionLandings>
                null}

<Grid colGap={21} rowGap={10} narrow={false}>


                <Col desktop={6}>
                  <Grid colGap={10} rowGap={10} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiList />}
                        title={`Detalles`}
                        description={`Completá los detalles del plan`}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={12}>
                      <Section>
                        <InformacionForm
                          form={planForm}
                          informacionForm={detallesForm}
                          actions={{ deleteItem, editArrayList }}
                          type="detalles"
                          icon={<FiList />}
                        />
                        </Section>
                    </Col>
                  </Grid>
                </Col>

                <Col desktop={6}>
                  <Grid colGap={10} rowGap={10} narrow={false}>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiStar />}
                        title={`Beneficios`}
                        description={`Completá los beneficios del plan`}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={12}>
                    <Section>

                        <InformacionForm
                          form={planForm}
                          informacionForm={beneficiosForm}
                          actions={{ deleteItem, editArrayList }}
                          type="beneficios"
                          icon={<FiStar />}
                        />
                        </Section>
                    </Col>
                  </Grid>
                </Col>
                </Grid>

                {nuevaCompaniaProducto ? (
                  <>
                  <Subtitle
                    columns={12}
                    icon={<FiBarChart2 />}
                    title="Límite"
                    description={`Establecé el valor límite máximo para mostrar el plan en ${inputValues.landing}`}
                    divider={true}
                  />
                    <NuevoLimiteForm
                      form={planForm}
                      data={{ companiaLogo, productos }}
                    />
                  </>
                ) : null}

                {inputValues.compania && inputValues.landing ? (
                  <>
                  <Divider />
                  <Subtitle
                    columns={12}
                    icon={<FiMonitor />}
                    title="Previsualización"
                    description={`Así se vería el plan en la landing de ${inputValues.landing}`}
                  />
                    <PlanCard
                      data={{
                        ...inputValues,
                        coberturas: coberturas,
                        logo: companiaLogo?.logo,
                        nombre_compania: companiaLogo?.nombre,
                      }}
                      dropdown={true}
                    />
                    </>
                ) : null}

                <Col desktop={12}>
                  <hr />
                  <Section>
                    <div className="botones-container">
                      <NewButton
                        backgroundColor={true}
                        onClick={handleValidation}
                      >
                        {buttonText}
                      </NewButton>
                      <Link to="/Landings">
                        <NewButton
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                        >
                          Volver a lista de planes
                        </NewButton>
                      </Link>
                    </div>
                  </Section>
                </Col>

              </Container>

            </NewInner>
          </NewWrapper>
      </Layout>
    </>
  );
};

export default LandingsCrearPlan;
