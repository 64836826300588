import Inner from "../../../components/Inner"
import Wrapper from "../../../components/Wrapper"
import Sidebar from "../../../components/Sidebar"
import Operacion from "../../../components/Operacion"
import { Container } from "./styled"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import NewTitle from "../../../components/NewTitle"
import NuevaSolicitudForm from "../../../components/SolicitudEmisionForm/NuevaSolicitudForm"
import AnulacionForm from "../../../components/SolicitudEmisionForm/AnulacionForm"
import EndosoSolicitudForm from "../../../components/SolicitudEmisionForm/EndosoSolicitudForm"
import NewWrapper from "../../../components/NewWrapper"
import NewInner from "../../../components/NewInner"
import Layout from "../../../components/Layout"

const CargarSolicitudEmision = () => {

    const location = useLocation();
    const id_operacion = new URLSearchParams(location.search).get("operacion");
    const tipo = new URLSearchParams(location.search).get("tipoSolicitud");

    return (
        <Container>
            {/* <Sidebar /> */}
            <Layout>
                <NewWrapper>
                    <NewInner>
                        <NewTitle margin={true}>Solicitud de Seguro</NewTitle>
                        {!id_operacion ?
                            <Operacion />
                        :
                            <>
                            {tipo == 1 && <NuevaSolicitudForm />}
                            {tipo == 2 && <AnulacionForm />}
                            {tipo == 3 && <EndosoSolicitudForm />}
                            </>
                        }
                    </NewInner>
                </NewWrapper>
            </Layout>
        </Container>
    )
}

export default CargarSolicitudEmision