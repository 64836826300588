import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const CardInfoWrapper = styled.div`
    max-width: 780px;
    margin-top: 70px!important;
    margin: 0 auto;

    .cardsField{
      height:100%;
      display:flex;
      flex-direction:column;
      justify-content: space-between;
    }
    .cardsWrapper{
      display: flex;
      align-items: center;
      height: 50px;
    }
`;

export const Error = styled.span`
   
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
`;

export const Icon = styled.img`
  height: 42px;
  width:auto;
  margin-right: 17px;
`;

export const AclaracionInput = styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.subtitle};
    margin-top: 10px;
    display: inline-block;
    gap: 20px;
    align-items: center;
    line-height: 1.5;

    a {
      font-size: 12px;
      color: ${({ theme }) => theme.subtitle};
    }
`;