import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowLeft } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import { useForm } from "../../../hooks/useForm";
import { bajaServicioForm, usuarioForm } from "../../../utils/formInitialValues";
import { anulacionCalidadProductoReglas, datosUsuariosReglas, nuevaAnulacionCalidadReglas } from "../../../utils/formRules";
import { useUsuarios } from "../../../hooks/useUsuarios";
import NewButton from "../../../components/NewButton";
import UsuarioForm from "../../../components/UsuariosForms/UsuarioForm";
import Divider from "../../../components/Divider";
import BackButton from "../../../components/BackButton";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import Success from "../../../components/Success";
import NuevaAnulacionForm from "../../../components/Forms/Calidad/NuevaAnulacionForm";
import useControlCalidad from "../ControlCalidad/hooks/useControlCalidad";
import axiosRequest from "../../../utils/axiosConfig";
import { useLoading } from "../../../hooks/useLoading";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useSocket } from "../../../context/SocketProvider";
import sendFiles from "../../../utils/uploadFile";

const CrearAnulacionCalidad = () => {
  const socket = useSocket()
  const location = useLocation();
  const [mes, setMes] = useState(
    location.state?.mes ? location.state.mes : new Date().getMonth()
  );
  const [anio, setAnio] = useState(
    location.state?.anio ? location.state.anio : new Date().getFullYear()
  );
  const { data } = useControlCalidad(null, null, mes, anio);
  const { motivos, companias, productos, operadoresIds } = data;
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, nuevaAnulacionCalidadReglas, bajaServicioForm);
  const [success, setSuccess] = useState(false)
  const { loading, setLoading } = useLoading()
  const [operadorSeleccionado, setOperadorSeleccionado] = useState(null);
  const [camposProducto, setCamposProducto] = useState([])
  // Form Producto
  const [productoFormInitialValues, setProductoFormInitialValues] = useState({})
  const formProducto = useForm(null, anulacionCalidadProductoReglas, productoFormInitialValues, { campos: camposProducto, producto: inputValues.producto_asegurado })

  const handleValidationForms = (e) => {
    handleValidation(e)
    formProducto.handleValidation(e)
  }

  const getCampos = async () => {
    setCamposProducto([])
    try {
      const campos = await axiosRequest.get(`/control_calidad/campos/producto/${inputValues.producto_asegurado}`)
      const initialValues = {}
      if(campos.data.length > 0){
        campos.data.forEach(campo => initialValues[campo.name_campo] = '')
      }
      setCamposProducto(campos.data)
      setProductoFormInitialValues(initialValues)
    } catch (error) {
      console.log(error)
    }
  }

  console.log(formProducto.inputValues)

  useEffect(() => {
    if(inputValues.producto_asegurado){
      getCampos()
    }
  }, [inputValues.producto_asegurado])

  async function handleSubmit() {
    try {
      setLoading(true)
      const productoData = {...formProducto.inputValues}
      const filtrarArchivos = Object.entries(formProducto.inputValues).filter(
        ([key, value]) => key.includes("archivo") && value !== ""
      );
      if(filtrarArchivos.length > 0){
        await Promise.all(
          filtrarArchivos.map(async ([key, value]) => {
            const filePath = await sendFiles(value);
            productoData[key] = filePath
          })
        );
      }
      const data = await axiosRequest.post('/control_calidad/anulaciones/crear', { anulacion: {...inputValues, id_operador_calidad: operadorSeleccionado.value }, producto: productoData, campos: camposProducto })
      if(data.status === 200){
          const notificacion = {
            url_pagina: `/ControlCalidad/Anulacion/${data.data.id_anulacion_calidad}`,
            nombre_icono: "calidad",
            nombre_pagina: "Control Calidad",
            notificacion: `Nueva anulación (calidad) asignada - ID: ${data.data.id_anulacion_calidad} - Fecha de ingreso: ${dayjs(data.data.fecha_ingreso).format("DD/MM/YYYY")} - Asegurado: ${data.data.nombre_asegurado} ${data.data.apellido_asegurado} - DNI: ${data.data.dni_asegurado}`,
            time: dayjs(new Date()).format("HH:mm"),
            id_usuario: data.data.id_usuario_operador_calidad,
          }

        socket.emit('notificacion-crm-solicitud-creada', notificacion)
        await axiosRequest.post('/notificacion/crear', notificacion)
        setLoading(false)
        setSuccess(true)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div>
      <div>
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                <div>
                  <BackButton url={"/ControlCalidad"} text={"Control Calidad"}/>
                  <NewTitlePageSection
                    title={`Nueva Anulación`}
                    description={"Cargá los datos para crear una nueva anulación"}
                  ></NewTitlePageSection>
                </div>
                <Divider />
                {!success ?
                <>
                <NuevaAnulacionForm form={{inputValues, handleChange, handleChangeExternal, formErrors, handleValidation}}formProducto={formProducto} data={{ motivos, companias, productos, operadoresIds, operadorSeleccionado, setOperadorSeleccionado, camposProducto }}  />
                <Divider />
                <div className="buttons-container">
                  <NewButton
                    backgroundColor={true}
                    onClick={(e) => handleValidationForms(e)}
                    disabled={loading}
                  >
                    {loading ? "Creando anulación..." : "Crear Anulación"}
                  </NewButton>
                  <Link to="/ControlCalidad">
                    <NewButton borderColor={true} textColor={true}>
                      <FiArrowLeft /> Volver a Control Calidad
                    </NewButton>
                  </Link>
                </div>
                </>
                :
                <Success title="¡Anulación creada!" description={`La anulación fue creada correctamente`} link={"/ControlCalidad"} page="Control Calidad" />
                }
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default CrearAnulacionCalidad;
