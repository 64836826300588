import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";
import { FiCheck, FiX } from "react-icons/fi";
import { BsCheckLg } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { VscCircleFilled } from "react-icons/vsc";
import Button from "../Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import Switch from "../Switch";
import NewButton from "../NewButton";

export default function PlanCard({
  data,
  single,
  active,
  onDrop,
  onDragOver,
  handleDraggingPlan
}) {

  const [openDetails, setOpenDetails] = useState(false)

  return (
    <Container
      mejorPrecio={data?.mejorPrecio}
      recomendado={data?.recomendado}
      seguro={data.nombre_compania}
      active={active}
      draggable={true}
      onDragStart={() => handleDraggingPlan(data)}
      onDrop={(e) => onDrop(e, data)}
      onDragOver={onDragOver}
    >
      {/* Dropdown */}
      <button
        className={"dropdown-abierto"}
        onClick={data?.clickFunction}
      >
        {data?.mejorPrecio && (
          <div className="mejor-precio shimmerPrecio">
            <p>
              <span>
                <FaMoneyBillWave></FaMoneyBillWave>
              </span>
              Mejor precio
            </p>
          </div>
        )}
        {data?.recomendado && (
          <div className="recomendado shimmerRecomendado">
            <p>
              <span>
                <AiFillLike></AiFillLike>
              </span>
              Recomendado
            </p>
          </div>
        )}
        {/* Logo and Plan Container */}
        <div className="logo-plan-container">
          <img alt="Logo Seguro" src={data.logo}></img>
          <p>{data.plan}</p>
        </div>
        {!data.plan_api || (data.plan_api && data.precio) || data?.cotizado ? (
          <>
            {/* Checks Container */}
            <div className="checks-container opacidad03">
              {/* Map para cada check */}
              {data.coberturas.map((cobertura) => {
                return (
                  <span
                    id={cobertura.cobertura_info?.principal}
                    className="check"
                  >
                    <p>{cobertura.nombre}</p>
                    {cobertura.activo ? (
                      <FiCheck color="green"/>
                    ) : (
                      <FiX color="red"/>
                    )}
                  </span>
                );
              })}
            </div>
            {/* Botones Container */}
            <div className="botones-container opacidad03">
              {!single ?
              <Link to={`/Landings/Plan/${data.id}`}><NewButton backgroundColor={true}>Editar plan</NewButton></Link>
              : 
              null
              }
              <button id="ver-mas-boton" onClick={() => setOpenDetails(!openDetails)}>
                {openDetails ? "Cerrar cobertura" : "Ver cobertura"}
              </button>
            </div>
          </>
        ) : (
          <div id="buscando-container" className="opacidad03">
            <div id="animacion-loading">
            </div>
          </div>
        )}
      </button>
      {/* Contenido */}
      <Collapse isOpened={openDetails}>
        <div className="children-container">
          {/* Datos Container */}
          <div className="datos-container">
            {/* Plan Container */}
            <div className="plan-container">
              <p>{data.nombre_compania}</p>
              <p>-</p>
              <p>{data.plan}</p>
            </div>
            {/* Detalles Beneficios Container */}
            <div className="detalles-beneficios-container">
              <div
                className="detalles-container"
                id={data.beneficios == "" && "centrado"}
              >
                <h4>
                  <span>
                    <BsCheckLg />
                  </span>
                  Detalles de cobertura
                </h4>
                {data.detalles?.length &&
                  data.detalles.map((detalle) => {
                    let det = null
                    if(typeof(detalle) === "string"){
                      det = detalle
                    } else {
                      det = detalle.detalle
                    }
                    return (
                      <div className="detalle">
                        <span>
                          <VscCircleFilled />
                        </span>
                        <p>{det}</p>
                      </div>
                    );
                  })}
              </div>
              {/* Beneficios Container */}
              <div
                className="beneficios-container"
                id={data.beneficios == "" && "none-beneficios"}
              >
                <h4>
                  <span>
                    <BsCheckLg />
                  </span>
                  Beneficios de la cobertura
                </h4>
                {data.beneficios?.length &&
                  data.beneficios.map((beneficio) => {
                    let ben = null
                    if(typeof(beneficio) === "string"){
                      ben = beneficio
                    } else {
                      ben = beneficio.beneficio
                    }
                    return (
                      <div className="beneficio">
                        <span>
                          <VscCircleFilled />
                        </span>
                        <p>{ben}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Vigencia Container */}
          <div className="vigencia-container">
            <p>Vigencia Anual - {data.precio === 0 ? "Consultar precio" : "Contratar"}</p>
            <Link>
              {data.precio === 0 ? "Consultar" : "Contratar"}
            </Link>
          </div>
        </div>
      </Collapse>
    </Container>
  );
}
