import { FiCalendar } from "react-icons/fi";
import { Col, Grid } from "../Grid";
import Subtitle from "../Subtitle";
import NewInputSelect from "../NewInputSelect";
import { anios, meses } from "../../constants/data";
import NewCalendar from "../NewCalendar";
import Fichajes from "../Fichajes";

const CalendarioFichajes = ({ data }) => {
  const { dia, mes, anio, calendario, handleDiaFichaje,fichajes, loadingFichajes, id_rol, usuarios, usuario, setMes, setAnio } = data  
  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={8}>
        <Subtitle
          title={`Calendario de ${
            usuarios.find((user) => user.value == usuario)?.label
          }`}
          image={true}
          description="Tus fichajes a lo largo del mes"
          icon={<FiCalendar />}
          disableDivider={true}
        />
      </Col>
      <Col desktop={2}>
        <NewInputSelect
          name={"mes"}
          onChange={(e) => setMes(e.target.value)}
          placeholder={"Mes"}
          options={meses}
          value={mes}
        />
      </Col>
      <Col desktop={2}>
        <NewInputSelect
          name="anio"
          placeholder="2024"
          options={anios}
          onChange={(e) => setAnio(e.target.value)}
          value={anio}
        />
      </Col>

      <Col desktop={6}>
        <NewCalendar data={{ dia, mes, anio, calendario, handleDiaFichaje }} />
      </Col>
      <Col desktop={6}>
        <Fichajes data={{ dia, fichajes, loadingFichajes, id_rol }} />
      </Col>
    </Grid>
  );
};

export default CalendarioFichajes;
