import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Select = styled.select`
width: 50%;
border-radius: 5px;
padding:10px;
font-size:12px;
color: gray;


option{
color:gray;
padding: 20px;
}

img{
width: 500px;
height: 500px;
}


` 

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 20px 0 20px 0;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
  }

  .loading-container {
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .empty-state {
    color: ${({ theme }) => theme.subtitle};
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.border};
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    box-sizing: border-box;
    border-radius: 10px;
  }
`;

export const Cobertura = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  color: ${({ theme }) => theme.subtitle};

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const CoberturasContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;

export const Section = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: rgb(120, 120, 120);
    list-style-type: none;
  }

  li {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      display: flex;
      font-size: 20px;
      cursor: pointer;
      color: ${leerColor(colores.rojo)}
    }
  }

  .agregar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-agregar-container {
    div {
        width: 100%;
    }
    display: flex;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
  }

  .input-agregado {
    display: flex;
    align-items: center;
  }

  .botones-container {
    display: flex;
    gap: 10px;
  }

  .lista-titulo {
    color: ${({ theme }) => theme.subtitle};
    margin: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .plan-info {
    display: flex;
      flex-direction: column;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 8px;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 100%;
      justify-content: space-between;

    h3 {
      margin: 0;
        font-weight: 400;
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle};
        line-height: 1.5;
    }

    span {
      font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;
    }
  }

  .switch-calculo {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${leerColor(colores.grisEstados)};
  }

  .section-title {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 6px;
      color: ${leerColor(colores.azul)};
      display: flex;
      align-items: center;

      .icon {
        color: ${leerColor(colores.celeste)};
        display: flex;
      }
    }
    p {
      margin: 0;
    }
  }
`;

export const ActivePlan = styled.div`
  color: ${leerColor(colores.verde)};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  .plan-inactivo {
    color: ${leerColor(colores.grisEstados)};
  }

  .container_switch {
    display: flex;
    align-items: center;
  }

  .blob {
    background: ${leerColor(colores.verde)};
    border-radius: 50%;
    margin: 10px;
    height: 10px;
    width: 10px;

    box-shadow: 0 0 0 0 ${leerColor(colores.verde)};
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const CalculoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;

    img {
        width: 100px;
    }
    .plan-cotizacion {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;

        hr {
            margin: 0;
        }
    }

    .plan-boton {
        padding-left: 20px;
    }

    .plan-operador {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: 10px 10px;
        border-radius: 6px;
        box-sizing: border-box;
        //background-color: #f1f9ff;
        background-color: #f7f7f7;
        color: ${leerColor(colores.negro)};
        height: 35px;
    }

    .plan-valor-producto {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        white-space: nowrap;
        color: ${leerColor(colores.celeste)};
        height: 35px;
        background-color: #F2F5FF;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .plan-parentesis {
        color: ${leerColor(colores.grisEstados)};
    }

    .plan-calculo {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
    }

    .plan-calculo-ejemplo {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .plan-calculo-ejemplo strong {
        //color: ${leerColor(colores.celeste)};
    }

    .plan-ejemplo {
        width: 100%;
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        box-sizing: border-box;
    }

    .plan-input-container {
        width: 100%;
    }
`;

export const Breadcrumb = styled.span`
  font-size: 14px;
  color: #A3A3A3;
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    display: flex;
  }

  a {
    color: ${leerColor(colores.celeste)};
    text-decoration: none;
    font-weight: 600;
  }
`;