import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { limitesPremiosReglas } from "../../../../utils/formRules";
import { useLoading } from "../../../../hooks/useLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import Divider from "../../../Divider";
import { useState } from "react";
import Success from "../../../Success";
import { FiUser } from "react-icons/fi";
import SpinnerLoading from "../../../SpinnerLoading";

const ModalTotalPorcentajes = ({ modal, mes, anio, refreshVendedores }) => {
  const { isModalOpen, handleModal } = modal;
  const [formInitialState, setFormInitialState] = useState({});
  const [porcentajeTotalRules, setPorcentajeTotalRules] = useState({})
  const [vendedores, setVendedores] = useState([]);
  const [porcentajesModificados, setPorcentajesModificados] = useState([])

  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, () => {}, formInitialState);
  const formatter = new Intl.NumberFormat("es-ES", {});

  function validate (state, vendedores) {
    let validaciones = {}
    vendedores.data.forEach(item => {
        const validation = {
            regla: state[item.nombre.toLowerCase().replaceAll(" ", "_")] === '',
            mensaje: 'El porcentaje es requerido.'
        }
        validaciones[item.nombre.toLowerCase().replaceAll(" ", "_")] = [validation]
    })
    return validaciones
  }         

  useEffect(() => {
    const getPorcentajes = async () => {
      if (mes !== null && anio) {
        try {
          setLoading(true)
          const vendedores = await axiosRequest.get(
            `/premios/porcentajes_total/${mes}/${anio}`
          );
          let init = {}
          vendedores.data.forEach(item => {
            init[item.nombre.toLowerCase().replaceAll(" ", "_")] = item.porcentaje
          })
          const validaciones = validate(inputValues, vendedores)
          setPorcentajeTotalRules(validaciones)
          setFormInitialState(init)
          setVendedores(vendedores.data);
          setLoading(false)
        } catch (error) {
          console.log(error);
        }
      }
    };
    getPorcentajes();
  }, [mes, anio]);

  const { loading, setLoading, loadingText, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Actualizando...");
    const data = Object.entries(inputValues)
    const vendedoresPorcentajes = vendedores.map(vendedor => {
        const porcentaje = data.find(porcen => porcen[0] === vendedor.nombre.toLowerCase().replaceAll(" ", "_"))
        return {
            id_vendedor: vendedor.numero, 
            porcentaje: porcentaje[1]
        }
    })
    try {
      const update = await axiosRequest.put(`/premios/porcentajes_total/${mes}/${anio}`, vendedoresPorcentajes);
      // const historial = {
      //   mensaje: `${user.nombre_operador} actualizó el porcentaje de premios que puede cobrar `,
      //   dia: new Date(),
      //   id_vendedor: null,
      //   id_premio: premio.id_vendedor_premio,
      //   id_usuario: user.id_operador,
      //   id_tipo_modificacion: 1
      // }  
      // await axiosRequest.post(
      //   `/premios/historial_modificaciones`,
      //   historial
      // );
      if (update.status === 200) {
        refreshVendedores()
        setLoading(false);
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setSuccess(false);
    handleModal();
  };

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Porcentajes Total</NewTitle>
            <p>
              Actualizá los porcentajes para el total de cada vendedor
            </p>
          </div>
          <Divider />
          {!success ? (
            <>
              <div className="data-container">
                {!loading ?
                  <div className="vendedores-container">
                    {vendedores.map((vendedor) => {
                      return (
                      <div className="vendedor-container">
                        <div>
                        <span className={"asegurado"}>
                            <span className={"userIcon"}>
                              <FiUser />
                            </span>
                            <div className={"aseguradoInfo"}>
                              <h4>{vendedor.nombre}</h4>
                              <span className={"aseguradoAdditionalInfo"}>
                                {vendedor.nombre_tipo_vendedor ? vendedor.nombre_tipo_vendedor : '-'}
                              </span>
                            </div>
                          </span>
                        </div>
                        <div>
                        <NewInputBasic
                            type="number"
                            name={vendedor.nombre.toLowerCase().replaceAll(" ", "_")}
                            placeholder="100"
                            onChange={handleChange}
                            value={inputValues[vendedor.nombre.toLowerCase().replaceAll(" ", "_")]}
                          />
                          {formErrors[vendedor.nombre.toLowerCase().replaceAll(" ", "_")] && (
                            <ErrorInput>
                              {formErrors[vendedor.nombre.toLowerCase().replaceAll(" ", "_")]}
                            </ErrorInput>
                          )}
                        </div>
                      </div>
                      )
                    })}
                  </div>
                : 
                <SpinnerLoading text={"Cargando vendedores..."}/>}
              </div>
              <Divider />
              <div className={"actionButtonsCont"}>
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={handleModal}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton
                    backgroundColor={true}
                    onClick={handleValidation}
                    disabled={loadingText}
                  >
                    {loadingText ? loadingText : "Actualizar"}
                  </NewButton>
                </div>
              </div>
            </>
          ) : (
            <Success
              title="¡Porcentajes actualizados!"
              description="Los porcentajes del total para vendedores fueron actualizados correctamente"
              handleModal={handleCloseModal}
            />
          )}
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalTotalPorcentajes;

/*
                <Grid colGap={21} rowGap={21} narrow={true}>
                  {vendedores.map((vendedor) => {
                    return (
                      <>
                        <Col desktop={9}>
                          <span className={"asegurado"}>
                            <span className={"userIcon"}>
                              <FiUser />
                            </span>
                            <div className={"aseguradoInfo"}>
                              <h4>{vendedor.nombre}</h4>
                              <span className={"aseguradoAdditionalInfo"}>
                                {vendedor.nombre_tipo_vendedor ? vendedor.nombre_tipo_vendedor : '-'}
                              </span>
                            </div>
                          </span>
                        </Col>
                        <Col desktop={3}>
                          <NewInputBasic
                            type="number"
                            name={vendedor.nombre.toLowerCase().replaceAll(" ", "_")}
                            placeholder="100"
                            onChange={handleChange}
                            value={inputValues[vendedor.nombre.toLowerCase().replaceAll(" ", "_")]}
                          />
                          {formErrors[vendedor.nombre.toLowerCase().replaceAll(" ", "_")] && (
                            <ErrorInput>
                              {formErrors[vendedor.nombre.toLowerCase().replaceAll(" ", "_")]}
                            </ErrorInput>
                          )}
                        </Col>
                        <Col desktop={12}>
                          <Divider />
                        </Col>
                      </>
                    );
                  })}
                </Grid>
*/