import { FiUser } from "react-icons/fi";
import { Col, Grid } from "../../../../../components/Grid";
import Subtitle from "../../../../../components/Subtitle";
import CardExcepcion from "../../../../../components/CardExcepcion";

const PreviewExcepcion = ({ form }) => {
  const { inputValues } = form;

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <Subtitle
          title="Excepción"
          description="Revisá los datos cargados antes de publicar la excepción"
          icon={<FiUser />}
          disableDivider={true}
        />
      </Col>
      {inputValues.usuario && inputValues.tipo && inputValues.periodo ? (
        <Col desktop={4}>
          <CardExcepcion
            excepcion={{
              id_usuario_excepcion: inputValues.usuario,
              tipo_excepcion: inputValues.tipo,
              periodo_excepcion: inputValues.periodo,
              periodo_dia_excepcion: inputValues.dia ? inputValues.dia : null,
              periodo_fecha_excepcion: inputValues.fecha
                ? inputValues.fecha
                : null,
              periodo_fecha_desde_excepcion: inputValues.fecha_desde
                ? inputValues.fecha_desde
                : null,
              periodo_fecha_hasta_excepcion: inputValues.fecha_hasta
                ? inputValues.fecha_hasta
                : null,
              hora_excepcion: inputValues.hora,
              hora_desde_excepcion: inputValues.hora_desde
                ? inputValues.hora_desde
                : null,
              hora_hasta_excepcion: inputValues.hora_hasta
                ? inputValues.hora_hasta
                : null,
              goce_sueldo: inputValues.check_goce_sueldo ? 1 : 0,
              motivo_sin_goce_sueldo: inputValues.motivo_sin_goce_sueldo
                ? inputValues.motivo_sin_goce_sueldo
                : null,
              motivo_acuerdo: inputValues.motivo_acuerdo
                ? inputValues.motivo_acuerdo
                : null,
              tipo_goce_sueldo: inputValues.tipo_goce_sueldo
                ? inputValues.tipo_goce_sueldo
                : null,
              tipo_goce_sueldo_otros: inputValues.tipo_goce_sueldo_otros
                ? inputValues.tipo_goce_sueldo_otros
                : null,
              estado_excepcion: 1,
            }}
          />
        </Col>
      ) : null}
    </Grid>
  );
};

export default PreviewExcepcion;
