import { useEffect, useState, useMemo } from "react"; // Importamos hooks de React para manejar estados y efectos
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"; // Hooks para obtener parámetros y ubicación en la ruta
import { useLoading } from "../../../../hooks/useLoading"; // Hook personalizado para manejar estados de carga
import axiosRequest from "../../../../utils/axiosConfig"; // Configuración de Axios para realizar peticiones HTTP

export default function useAnulacionCalidad() {
    // Obtiene los parámetros de la URL (id de anulación)
    const anulacionId = useParams();
    
    // Obtiene el estado de la navegación
    const location = useLocation();
    const previousUrl = location.state?.previousUrl; // Guarda la URL previa si está disponible

    // Estados para almacenar los datos de anulación y producto
    const [anulacion, setAnulacion] = useState(location?.state?.anulacion ?? []); // Usa el estado de navegación si existe
    const [producto, setProducto] = useState(location?.state?.producto ?? []);

    // Hook de carga para indicar si la data se está obteniendo
    const loadingSiniestro = useLoading();

    // Efecto que se ejecuta cuando cambia el `anulacionId`
    useEffect(() => {
        const getSiniestro = async () => {
            // Si no hay datos en el estado de la navegación y hay un id de anulación, se realiza la petición
            if (!location.state && anulacionId) {
                try {
                    loadingSiniestro.setLoading(true); // Indica que la carga ha iniciado
                    const response = await axiosRequest.get(`/control_calidad/anulacion/${anulacionId.id_anulacion_calidad}`);
                    setAnulacion(response.data.anulacion); // Guarda los datos de anulación
                    setProducto(response.data.producto); // Guarda los productos asociados
                } catch (error) {
                    console.error("Error al obtener datos de anulación:", error); // Log de error en caso de fallo en la petición
                } finally {
                    loadingSiniestro.setLoading(false); // Asegura que el loading se desactive incluso si hay error
                }
            }
        };
        getSiniestro();
    }, [anulacionId]); // Se ejecuta cada vez que cambia `anulacionId`

    // Filtra los productos que contienen archivos adjuntos (ejemplo: imágenes, documentos)
    const productosConArchivo = useMemo(() => 
        producto.filter(item => item.item_producto_asegurado.startsWith("archivo")),
        [producto]
    );

    // Filtra los productos que no contienen archivos adjuntos
    const productosSinArchivo = useMemo(() => 
        producto.filter(item => !item.item_producto_asegurado.startsWith("archivo")),
        [producto]
    );

    return {
        data: {
            anulacion, // Datos de la anulación
            producto, // Productos asociados a la anulación
            productosConArchivo, // Productos que contienen archivos
            productosSinArchivo, // Productos sin archivos
            loadingSiniestro, // Estado de carga
            previousUrl // URL previa en la navegación
        }
    };
}

