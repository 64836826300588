import {
  FiCheck,
  FiCoffee,
  FiLogIn,
  FiLogOut,
  FiThumbsUp,
} from "react-icons/fi";
import { Col, Grid } from "../../../../../Grid";
import dayjs from "dayjs";
import ErrorInput from "../../../../../ErrorInput";

const FichajesRecurrentesTab = ({ data, fichajes, acciones, formError }) => {
  const { fichajeSeleccionado, rolesComerciales, id_rol, usuarioComercial } = data;
  const { ingreso, inicio_almuerzo, fin_almuerzo, salida } = fichajes;
  const { seleccionarFichaje } = acciones;

  return (
    <div>
      <Grid colGap={21} rowGap={10} narrow={true}>
        {/* Fichaje de ingreso disponible para todos los roles */}
        <Col desktop={12}>
          <div
            className={`fichaje ${
              fichajeSeleccionado === "Ingreso" && !ingreso ? "selected" : ""
            }  ${ingreso ? "fichado" : ""}`}
            onClick={() => seleccionarFichaje("Ingreso")}
          >
            <div className="fichaje-data">
              <span className="icon">
                <FiLogIn />
              </span>
              <div className="fichaje-info">
                <h4>Ingreso</h4>
                {ingreso ? (
                  <span>
                    Realizado a las{" "}
                    {ingreso.dia_fichaje_esperado
                      ? dayjs(ingreso.dia_fichaje_esperado).format("HH:mm")
                      : dayjs(ingreso.dia_fichaje).format("HH:mm")}
                    hs
                  </span>
                ) : null}
              </div>
            </div>
            {ingreso ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-realizado">
                  <FiCheck />
                </span>
                <span>Fichaje realizado</span>
              </div>
            ) : (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-disponible">
                  <FiThumbsUp />
                </span>
                <span className="fichaje-disponible">Fichaje disponible</span>
              </div>
            )}
          </div>
        </Col>
        {/* Fichajes de inicio y fin de almuerzo que no están disponibles para roles comerciales */}
        {!rolesComerciales.includes(id_rol) ? (
          <>
            <Col desktop={12}>
              <div
                className={`fichaje ${!ingreso ? "disabled" : ""} ${
                  fichajeSeleccionado === "Inicio almuerzo" && ingreso
                    ? "selected"
                    : ""
                } ${inicio_almuerzo ? "fichado" : ""}`}
                onClick={() => seleccionarFichaje("Inicio almuerzo")}
              >
                <div className="fichaje-data">
                  <span className="icon">
                    <FiCoffee />
                  </span>
                  <div className="fichaje-info">
                    <h4>Inicio de almuerzo</h4>
                    {inicio_almuerzo ? (
                      <span>
                        Realizado a las{" "}
                        {dayjs(inicio_almuerzo.dia_fichaje).format("HH:mm")}hs
                      </span>
                    ) : null}
                  </div>
                </div>
                {inicio_almuerzo ? (
                  <div className="fichaje-realizado">
                    <span className="icon-fichaje-realizado">
                      <FiCheck />
                    </span>
                    <span>Fichaje realizado</span>
                  </div>
                ) : null}
                {!inicio_almuerzo && ingreso ? (
                  <div className="fichaje-realizado">
                    <span className="icon-fichaje-disponible">
                      <FiThumbsUp />
                    </span>
                    <span className="fichaje-disponible">
                      Fichaje disponible
                    </span>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col desktop={12}>
              <div
                className={`fichaje ${!inicio_almuerzo ? "disabled" : ""} ${
                  fichajeSeleccionado === "Fin almuerzo" && inicio_almuerzo
                    ? "selected"
                    : ""
                }  ${fin_almuerzo ? "fichado" : ""}`}
                onClick={() => seleccionarFichaje("Fin almuerzo")}
              >
                <div className="fichaje-data">
                  <span className="icon">
                    <FiCoffee />
                  </span>
                  <div className="fichaje-info">
                    <h4>Fin de almuerzo</h4>
                    {fin_almuerzo ? (
                      <span>
                        Realizado a las{" "}
                        {dayjs(fin_almuerzo.dia_fichaje).format("HH:mm")}hs
                      </span>
                    ) : null}
                  </div>
                </div>
                {fin_almuerzo ? (
                  <div className="fichaje-realizado">
                    <span className="icon-fichaje-realizado">
                      <FiCheck />
                    </span>
                    <span>Fichaje realizado</span>
                  </div>
                ) : null}
                {!fin_almuerzo && inicio_almuerzo ? (
                  <div className="fichaje-realizado">
                    <span className="icon-fichaje-disponible">
                      <FiThumbsUp />
                    </span>
                    <span className="fichaje-disponible">
                      Fichaje disponible
                    </span>
                  </div>
                ) : null}
              </div>
            </Col>
          </>
        ) : null}

        <Col desktop={12}>
          <div
            className={`fichaje ${
              (!fin_almuerzo && !rolesComerciales.includes(usuarioComercial)) ||
              (usuarioComercial && !ingreso)
                ? "disabled"
                : ""
            } 
            ${
                (fichajeSeleccionado === "Fin jornada" &&
                  fin_almuerzo &&
                  !usuarioComercial) ||
                (fichajeSeleccionado === "Fin jornada" &&
                  ingreso &&
                  usuarioComercial)
                  ? "selected"
                  : ""
              }  
              ${salida ? "fichado" : ""}`}                    
            onClick={() => seleccionarFichaje("Fin jornada")}
          >
            <div className="fichaje-data">
              <span className="icon">
                <FiLogOut />
              </span>
              <div className="fichaje-info">
                <h4>Salida</h4>
                {salida ? (
                  <span>
                    Realizado a las{" "}
                    {salida.dia_fichaje_esperado
                      ? dayjs(salida.dia_fichaje_esperado).format("HH:mm")
                      : dayjs(salida.dia_fichaje).format("HH:mm")}
                    hs
                  </span>
                ) : null}
              </div>
            </div>
            {salida ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-realizado">
                  <FiCheck />
                </span>
                <span>Fichaje realizado</span>
              </div>
            ) : null}

            {fin_almuerzo && !salida ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-disponible">
                  <FiThumbsUp />
                </span>
                <span className="fichaje-disponible">Fichaje disponible</span>
              </div>
            ) : null}
          </div>
        </Col>

        {/* Fichaje de salida disponible para todos los roles */}
        <Col desktop={12}>
          {formError ? (
            <ErrorInput>
              Es necesario seleccionar un tipo de fichaje.
            </ErrorInput>
          ) : null}
        </Col>
      </Grid>
    </div>
  );
};

export default FichajesRecurrentesTab;
