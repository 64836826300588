import Modal from "../../../Modal";
import { ModalHistorialContainer } from "../../../InfoBoard/styled"
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import SpinnerLoading from "../../../SpinnerLoading";
import { ContainerBotones, Aclaracion, RechazoContainer, TableRechazos } from "./styled";
// Icons
import { ImWarning } from "react-icons/im";
import { useEffect, useState } from "react";
import { Table } from "../../../Table/styled";
import NewButton from "../../../NewButton";
import { FiBarChart, FiCheck, FiCheckCircle, FiUploadCloud, FiXCircle } from "react-icons/fi";
import NewInputSelect from "../../../NewInputSelect";
import NewInputBasic from "../../../NewInputBasic"
import NewTitle from "../../../NewTitle"
import Divider from "../../../Divider";
import NewTable from "../../../NewTable";
import { useTable } from "../../../../hooks/useTable";
import Success from "../../../Success"
import ErrorInput from "../../../ErrorInput";
import dayjs from 'dayjs';
import { FiCheckSquare } from "react-icons/fi";
import { FiXOctagon } from "react-icons/fi";
import { FiAlertTriangle } from "react-icons/fi";
import { subirRechazoManualFullReglas } from "../../../../utils/formRules";
import { subirRechazoManualFullForm } from "../../../../utils/formInitialValues";
import { useForm } from "../../../../hooks/useForm";
import Subtitle from "../../../Subtitle";
import { RechazosCard } from "../../../RechazoCard";

const ModalCargarRechazos2 = ({ isModalOpen, setIsModalOpen, inputValuesUpdate, formErrorsUpdate, handleValidationUpdate, handleChangeUpdate, sections, handleCheck, tableCheck, modo, loading, loadingText, selection, sendData, success, closeModal, userInfo, operadoresBack, cargaManualCompleta, companias, ramos, id_compania, mediosPago, initialDataCargaCompleta, resultadoCargaRechazos }) => {
  
  const estadosOptions = [
      { label: 'notificado', key:1, value:11},
      { label: 'resuelto positivo', key:2, value:5},
      { label: 'negativo derivado', key:3, value:6},
      { label: 'negativo cerrado', key:5, value:13},
      { label: 'promesa de pago', key:4, value:12}
    ];

    const [operadores, setOperadores] = useState(operadoresBack);
    const [operadorAsignado, setOperadorAsignado] = useState(null);


    const formManualFull = useForm(
      handleSubmitRechazoManualFull,
      subirRechazoManualFullReglas,
      subirRechazoManualFullForm
    );


    async function handleSubmitRechazoManualFull (){
      try {
        console.error('SENDING',formManualFull.inputValues)
        sendData([formManualFull.inputValues], operadorAsignado)

      } catch (e ){
        console.error(e)
      }
    }

    // userRol = 8 operador cobranzas, 1 Super, 21 enacargado de cobranzas

    useEffect(()=>{
      if(userInfo){
        if( userInfo.id_rol=== 8) {
          // si es operador de cobranzas solo muestra sus datos
          setOperadores([{
            label: userInfo.nombre_operador,
            key: userInfo.id_usuario,
            value: userInfo.id_usuario
          }])
        } 
      }
    },[])

    useEffect(()=>{
      if( cargaManualCompleta && initialDataCargaCompleta ){
        formManualFull.handleChangeExternal('importe',initialDataCargaCompleta.importe)
        formManualFull.handleChangeExternal('poliza',initialDataCargaCompleta.poliza)
        formManualFull.handleChangeExternal('id_compania',initialDataCargaCompleta.id_compania)
      }
    },[cargaManualCompleta])


    useEffect(()=>{
      if ( formManualFull.inputValues.ramo ) {
        const seccion = ramos.find( r => r.label === formManualFull.inputValues.ramo )
        formManualFull.handleChangeExternal('id_seccion',parseInt(seccion.key))
      }
    },[formManualFull.inputValues.ramo])

    return (
        <div>
            <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <RechazoContainer>
                <div className="header-container">
                  {
                    modo === 'update' ?
                    <NewTitle>Actualizar rechazos</NewTitle> :
                    <NewTitle>Cargar rechazos</NewTitle>
                  }
                  
                  <p>Selecciona los rechazos que deseas gestionar </p>
                </div>
                <Divider />
                {!success ?
                <>
                <div className="data-container">
                  { loading ?
                    <Grid>
                      <Col desktop={12}>
                          <div className='loading-container'>
                              <SpinnerLoading text={loadingText} />
                          </div>
                      </Col>
                    </Grid>
                    : 
                      <>

                      {
                          modo === 'update' &&
                          <Grid>
                            <Col desktop={4}>
                              <NewInputSelect
                                  type={"text"}
                                  name={"idNuevoEstado"}
                                  labelName={"Nuevo Estado"}
                                  id={"idNuevoEstado"}
                                  options={estadosOptions}
                                  placeholder={"Nuevo Estado"}
                                  onChange={handleChangeUpdate}
                              />
                              {formErrorsUpdate.idNuevoEstado ? (
                                <ErrorInput>{formErrorsUpdate.idNuevoEstado}</ErrorInput>
                              ) : null}
                            </Col>
                            <Col desktop={4}>
                              <NewInputSelect
                                  type={"text"}
                                  name={"medioContacto"}
                                  labelName={"Medio Contacto"}
                                  id={"medioContacto"}
                                  options={[
                                    { label: 'WhatsApp', key:1, value:'whatsapp'},
                                    { label: 'Teléfono', key:2, value:'telefono'},
                                    { label: 'Email', key:3, value:'email'}
                                  ]}
                                  placeholder={"Medio de contacto"}
                                  onChange={handleChangeUpdate}
                              />
                              {formErrorsUpdate.medioContacto ? (
                                <ErrorInput>{formErrorsUpdate.medioContacto}</ErrorInput>
                              ) : null}

                            </Col>
                            {
                              inputValuesUpdate && inputValuesUpdate.medioContacto === 'email' && inputValuesUpdate.idNuevoEstado == 11 &&
                              <Col desktop={4}>
                                <p>Se notificaran los rechazos por sistema</p>
                              </Col>
                            }
                          </Grid>
                        }
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        
                            {
                              sections && selection.length > 0 &&
                              <>
                                { modo !== 'update'  && 
                                <Col desktop={3}>
                                  <NewInputSelect
                                    name={"idOperador"}
                                    labelName={"Asginador Operador"}
                                    id={"idOperador"}
                                    options={operadores}
                                    placeholder={"Sin Asignar"}                                    
                                    onChange={(e)=> setOperadorAsignado(e.target.value)}
                                  />
                                </Col>
                                }
                                <Col desktop={12}>
                                  <SeccionDetalleFinalCarga seccion={sections} checkeable selection={selection}  handleCheck={handleCheck} tableCheck={tableCheck} />
                                </Col>
                              </>

                            }
                      </Grid> 
                      </>

                  }
                </div>
                {
                  cargaManualCompleta && selection.length === 0 &&
                  <>
                    <div className="data-container">
                    <Grid>
                      <Col desktop={12}>
                          <div>
                              No encontramos datos asociados a la póliza, puede ingresarlos manualmente.
                          </div>
                      </Col>
                      <Col desktop={3}>
                        <NewInputBasic
                          type={"text"}
                          name={"poliza"}
                          labelName={"Póliza"}
                          placeholder={"1234"}
                          id={"poliza"}
                          onChange={formManualFull.handleChange}
                          value={formManualFull.inputValues.poliza}
                          required
                          disabled
                        />
                        {formManualFull.formErrors.poliza && (
                          <ErrorInput>{formManualFull.formErrors.poliza}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={3}>
                        <NewInputBasic
                          type={"text"}
                          name={"importe"}
                          labelName={"Importe"}
                          placeholder={"1234"}
                          id={"importe"}
                          onChange={formManualFull.handleChange}
                          value={formManualFull.inputValues.importe}
                          required
                          disabled
                        />
                        {formManualFull.formErrors.importe && (
                          <ErrorInput>{formManualFull.formErrors.importe}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={3}>
                          <NewInputSelect
                            name={"id_compania"}
                            labelName={"Compañia"}
                            id={"id_compania"}
                            options={companias}
                            placeholder={"Compañia"}
                            // onChange={formManualFull.handleChange}
                            value={formManualFull.inputValues.id_compania}
                            disabled
                          />
                          {formManualFull.formErrors.id_compania && (
                            <ErrorInput>{formManualFull.formErrors.id_compania}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputSelect
                            type={"text"}
                            name={"ramo"}
                            labelName={"Sección"}
                            id={"ramo"}
                            options={ramos}
                            placeholder={"Sección"}
                            onChange={formManualFull.handleChange}
                          />
                          {formManualFull.formErrors.ramo && (
                            <ErrorInput>{formManualFull.formErrors.ramo}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputSelect
                            type={"text"}
                            name={"medio_pago"}
                            labelName={"Medio de Pago"}
                            id={"medio_pago"}
                            options={[...mediosPago,{label:'EFECTIVO', key:0, value:null}]}
                            placeholder={"Medio de Pago"}
                            onChange={formManualFull.handleChange}
                          />
                          {formManualFull.formErrors.medio_pago && (
                            <ErrorInput>{formManualFull.formErrors.medio_pago}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={"dni"}
                            labelName={"DNI"}
                            placeholder={"33444555"}
                            id={"dni"}
                            onChange={formManualFull.handleChange}
                            value={formManualFull.inputValues.dni}
                            required
                          />
                          {formManualFull.formErrors.dni && (
                            <ErrorInput>{formManualFull.formErrors.dni}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={"nombre"}
                            labelName={"Nombre"}
                            placeholder={"Nompbre Completo"}
                            id={"nombre"}
                            onChange={formManualFull.handleChange}
                            value={formManualFull.inputValues.nombre}
                            required
                          />
                          {formManualFull.formErrors.nombre && (
                            <ErrorInput>{formManualFull.formErrors.nombre}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={"telefono"}
                            labelName={"Teléfono"}
                            placeholder={"1122222333"}
                            id={"telefono"}
                            onChange={formManualFull.handleChange}
                            value={formManualFull.inputValues.telefono}
                            required
                          />
                          {formManualFull.formErrors.telefono && (
                            <ErrorInput>{formManualFull.formErrors.telefono}</ErrorInput>
                          )}
                        </Col>
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={"mail"}
                            labelName={"Correo Electrónico"}
                            placeholder={"mail@asegurado.com"}
                            id={"mail"}
                            onChange={formManualFull.handleChange}
                            value={formManualFull.inputValues.mail}
                            required
                          />
                          {formManualFull.formErrors.mail && (
                            <ErrorInput>{formManualFull.formErrors.mail}</ErrorInput>
                          )}
                        </Col>
                        
                    </Grid>
                    </div>
                  </>
                }

                  <Divider />

                  <div className={"actionButtonsCont"}>
                    <div className={"buttonsCont"}>
                      <NewButton
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        icon={false}
                        onClick={closeModal}
                      >
                        Cancelar
                      </NewButton>
                        {
                          modo === 'update' &&
                          <>
                          {
                          (selection.filter(r => r.checked)).length > 0 &&
                          <NewButton backgroundColor={true} onClick={handleValidationUpdate} disabled={loading}>
                            <FiUploadCloud /> {inputValuesUpdate && inputValuesUpdate.medioContacto === 'email' && inputValuesUpdate.idNuevoEstado == 11 && 'Notificar y '} Actualizar {(selection.filter(r => r.checked)).length} registros seleccionados
                          </NewButton>
                          }
                          </>
                        }
                        {
                          (selection.filter(r => r.checked)).length > 0 && modo === 'create' &&
                          <NewButton backgroundColor={true} onClick={() =>sendData(selection,operadorAsignado)} disabled={loading}>
                            <FiUploadCloud /> Procesar {(selection.filter(r => r.checked)).length} registros seleccionados
                          </NewButton>
                        }
                        {
                          cargaManualCompleta && selection.length === 0 &&
                          <NewButton backgroundColor={true} onClick={formManualFull.handleValidation} disabled={loading}>
                            <FiUploadCloud /> Ingresar pólizas con datos manuales
                          </NewButton>
                        }
                    </div>
                  </div>
                </>
                : 
                  <>
                  {Object.keys(resultadoCargaRechazos).length > 0 ?
                  <div className="data-container">
                    {resultadoCargaRechazos?.cargados?.nuevos_rechazos?.data.length > 0 ?
                    <>
                    <Subtitle
                      icon={<FiCheckCircle />}
                      title={`Ingresados Correctamente`}
                      description={`Lista de rechazos cargados correctamente`}
                      disableDivider={true}
                    />
                    <Grid colGap={21} rowGap={21} narrow={false}>
                      {resultadoCargaRechazos.cargados.nuevos_rechazos.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                    </Grid>
                    <Divider />
                    </>
                    : null}

                    {resultadoCargaRechazos?.sin_cargar?.rechazos_pendientes?.data.length > 0 || resultadoCargaRechazos?.sin_cargar?.rechazos_no_web?.data.length > 0 || resultadoCargaRechazos?.sin_cargar?.poliza_sin_datos?.data.length > 0 || resultadoCargaRechazos?.sin_cargar?.rechazo_plazo_menor?.data.length > 0 || resultadoCargaRechazos?.sin_cargar?.rechazos_no_resueltos?.data.length > 0 ?
                    <>
                    <Subtitle
                      icon={<FiXCircle />}
                      title={`No Ingresados`}
                      description={`Lista de rechazos que no pudieron cargarse junto a sus motivos`}
                      disableDivider={true}
                    />
                    {resultadoCargaRechazos?.sin_cargar?.rechazos_pendientes?.data.length > 0 ?
                      <>
                        <h3>{resultadoCargaRechazos?.sin_cargar.rechazos_pendientes.descripcion}</h3>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                      {resultadoCargaRechazos.sin_cargar.rechazos_pendientes.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                      </Grid>
                      </>
                    : null}
                    {resultadoCargaRechazos?.sin_cargar?.rechazos_no_web?.data.length > 0 ?
                      <>
                        <h3>{resultadoCargaRechazos?.sin_cargar.rechazos_no_web.descripcion}</h3>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                      {resultadoCargaRechazos.sin_cargar.rechazos_no_web.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                      </Grid>
                      </>
                    : null}
                    {resultadoCargaRechazos?.sin_cargar?.poliza_sin_datos?.data.length > 0 ?
                    <>
                    <h3>{resultadoCargaRechazos.sin_cargar.poliza_sin_datos.descripcion}</h3>
                    <Grid colGap={21} rowGap={60} narrow={false}>
                      {resultadoCargaRechazos.sin_cargar.poliza_sin_datos.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                    </Grid>
                    </>
                    : null}
                    {resultadoCargaRechazos?.sin_cargar?.rechazo_plazo_menor?.data.length > 0 ?
                      <>
                        <h3>{resultadoCargaRechazos?.sin_cargar.rechazo_plazo_menor.descripcion}</h3>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                      {resultadoCargaRechazos.sin_cargar.rechazo_plazo_menor.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                    </Grid>
                      </>
                    : null}
                    {resultadoCargaRechazos?.sin_cargar?.rechazos_no_resueltos?.data.length > 0 ?
                      <>
                        <h3>{resultadoCargaRechazos?.sin_cargar.rechazos_no_resueltos.descripcion}</h3>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                      {resultadoCargaRechazos.sin_cargar.rechazos_no_resueltos.data.map(rechazo => {
                        return (
                          <RechazosCard rechazo={rechazo} />
                        )
                      })}
                    </Grid>
                      </>
                    : null}
                    </>
                    : null}
                  </div>
                  : null}
                  </>
                  // <Success title="Operación exitosa" description="La operación se realizó correctamente" handleModal={closeModal} disableMargin={true} />
                }
                </RechazoContainer>
            </Modal>
        </div>
    )
}

export default ModalCargarRechazos2


const TablaResumen = function ({data, structure, indexOn, titleGroup, checkeable, handleCheck, selection, tableCheck }) {
    const [allChecked, setAllChecked] = useState(false);
    const [tableData, setTableData] = useState([])
    const { order, handleTableOrder } = useTable();

    const labels = [
      {
        name: "poliza",
        header: "Poliza",
        component: (item) => {
          return (
            <td  width="80px" className="repetida">{ item.repetida && <span>múltiples ramos</span> }{item.poliza}</td>
          )
        }
      },
      {
        name: "nombre",
        header: "Nombre",
        component: (item) => {
          return (
            <td width="150px"><strong>{item.nombre}</strong></td>
          )
        }
      },
      {
        name: "mail",
        header: "Email",
        component: (item) => {
          return (
            <td width="200px">{item.mail}</td>
          )
        }
      },
      {
        name: "importe",
        header: "Importe",
        component: (item) => {
          return (
            <td width="120px">{formatearComoCurrency(item.importe)}</td>
          )
        }
      },
      {
        name: "importe_nuevo",
        header: "I. Nuevo",
        component: (item) => {
          return (
            <td width="120px">{formatearComoCurrency(item.importe_nuevo)}</td>
          )
        }
      },
      {
        name: "importe_anterior",
        header: "I. Anterior",
        component: (item) => {
          return (
            <td width="120px">{formatearComoCurrency(item.importe_anterior)}</td>
          )
        }
      },
      {
        name: "estado_anterior",
        header: "Estado Anterior",
        component: (item) => {
          return (
            <td width="60px"><span className={item.estado_anterior.toLowerCase()}>{item.estado_anterior}</span></td>
          )
        }
      },
      {
        name: "medio_pago",
        header: "Medio de Pago",
      },
      {
        name: "ramo",
        header: "Ramo",
      },
      {
        name: "createdAt",
        header: "Fecha de Ingreso",
        component: (item) => {
          return (
            <td width="120px">{ dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</td>
          )
        }
      },
      {
        name: "updatedAt",
        header: "Fecha de Actualización",
        component: (item) => {
          return (
            <td width="120px">{ dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
          )
        }
      }
    ];
  
    useEffect(()=>{
      let all = true
      for (const item of selection ) {
        if( ! item.checked ){
          all = false
        }
      }
      setAllChecked(all);
    },[selection,data])

    function formatearComoCurrency(argentinaNumber) {
      // Formatea el número como moneda argentina
      const formatoCurrency = new Intl.NumberFormat('es-AR', {
          style: 'currency',
          currency: 'ARS',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      });
      // Devuelve el número formateado
      return "AR" +formatoCurrency.format(argentinaNumber);
  }

    useEffect(() => {
      const columnsTable = () => {
        const headers = structure.map((item) => {
          const headerData = labels.find((label) => item === label.name);
          let size = null;
          if (item === "numero") {
            size = "20px";
          } else if (item === "nombre") {
            size = "100px";
          } else {
            size = "80px";
          }
  
          let data = {
            header: labels.find((label) => item === label.name)?.header ?? item,
            name: item,
            size: size,
          };
  
          if (headerData?.component) {
            data.component = headerData.component;
          }
  
          return data;
        });

        headers.unshift({
          header: () => {
            return (
              <td width="30px">
                <input type="checkbox" disabled={!checkeable || data.length===1} id={`index`} name="subtable" onClick={(e) => tableCheck(e, data)} checked={allChecked} />  
              </td>
            )
          },
          component: (item) => {
            console.error('ITEEMMMMM', item)
              return (
                <td width='30px'>
                    <input type="checkbox"  disabled={!checkeable || isNaN(item.importe) || item.importe === 0} id={`index`} name="selection" checked={item.checked} onClick={()=> handleCheck(item.id_selection)}/>
                </td>
              )
          }
        },);
        setTableData(headers)
      };
      columnsTable()
    }, [allChecked, selection])

    return (
        <TableRechazos>
          {tableData ?
            <NewTable 
              columns={tableData}
              data={data}
              orderParams={order}
              order={handleTableOrder}
            />
          : null}
        </TableRechazos>
    )
  }
  
  const SeccionDetalleFinalCarga = ({seccion, notDetail, checkeable, selection, handleCheck, tableCheck}) => {
    const subtablas = [];
    let warning = null;

    for (const subseccion in seccion) {
      let structure = ["poliza", "nombre","mail", "detalle"];

      switch (subseccion) {
        // estructuras para update aptos
        case 'rechazos_nuevos_existentes':
        case 'rechazos_to_update_pendientes':
        case 'rechazos_to_update_notificados':
        case 'rechazos_validos_renovados_cerrados_upload':
        case 'rechazos_to_update_promesas': structure = ["poliza", "ramo", "nombre","mail",, "importe", "estado_anterior", "createdAt"]; break;
        case 'rechazos_validos_renovados_upload':  structure = ["poliza", "nombre","mail", "importe_anterior","importe_nuevo", "importe", "estado_anterior", "createdAt"]; break;
        // estructura para update invalido    
        case 'rechazos_no_validos_arrastre': structure = ["poliza", "nombre","mail", "estado_anterior", "createdAt", "updatedAt"]; break;
        case 'rechazos_cerrados_update': structure = ["poliza", "nombre","mail", "estado_anterior", "createdAt"]; break;
        case 'rechazos_no_validos_update': structure = ["poliza", "nombre","mail", "detalle"]; break;

        
        // estructura para altas validas
        case 'rechazos_validos_upload': structure = ["poliza", "ramo", "nombre","mail", "importe", "medio_pago", "telefono" ]; break;
        // estructura para altas no validas
        case 'rechazos_sin_datos': structure = ["poliza", "nombre","mail", "detalle"] ; break;
        case 'rechazos_no_validos_upload': structure = ["poliza", "ramo", "nombre","mail", "estado_anterior", "createdAt"] ; break;
      }

        if (seccion[subseccion].data && seccion[subseccion].data.length > 0){
          subtablas.push(
          <>
            <div className='title_section'>
              <span className={`icon_section ${seccion[subseccion].checkeable? 'valid ' :'invalid '} ${seccion[subseccion].warning?'warning':''}`}> {seccion[subseccion].checkeable? seccion[subseccion].warning ?<FiAlertTriangle/> : <FiCheckSquare /> : <FiXOctagon/> } </span> 
              <h4>{seccion[subseccion].descripcion}</h4>
            </div>
            {
              notDetail ?
              null :
              <TablaResumen 
              data={seccion[subseccion].data}
              structure={ structure }
              indexOn
              checkeable={checkeable && seccion[subseccion].checkeable}
              key={subseccion}
              handleCheck={handleCheck}
              tableCheck={tableCheck}
              selection={selection}
            />
            }
          </>
          )
       }
    }
    return subtablas;
  }
