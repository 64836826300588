import InputBasic from "../../InputBasic";
import Button from "../../Button";
import IconButton from "../../IconButton";
import ErrorInput from "../../ErrorInput";
import { FiTrash2 } from "react-icons/fi";
import NewInputBasic from "../../NewInputBasic";
import NewButton from "../../NewButton";
import { Container } from "./styled";

const InformacionForm = ({ form, informacionForm, actions, type, icon }) => {

  const { inputValues, formErrors } = form
  const { editArrayList, deleteItem } = actions

  return (
    <Container>
      <div className="agregar-container">
        <div className="input-agregar-container">
          <NewInputBasic
            type="text"
            placeholder={type === "detalles" ? "Detalle" : "Beneficio"}
            name={type === "detalles" ? "detalle" : "beneficio"}
            labelName={type === "detalles" ? "Nuevo detalle" : "Nuevo beneficio"}
            value={type === "detalles" ? informacionForm.inputValues.detalle : informacionForm.inputValues.beneficio}
            onChange={informacionForm.handleChange}
          />
          <NewButton
            backgroundColor={true}
            onClick={informacionForm.handleValidation}
          >
            Agregar
          </NewButton>
        </div>
      </div>
      <hr />
      {inputValues[type].length > 0 ? (
        <>
          <h4 className="lista-titulo">Lista de detalles del plan</h4>
          <ul>
            {inputValues[type].map((item, index) => {
              console.log(item);
              return (
                <li>
                  <div className="input-agregar-container input-agregado">
                    <NewInputBasic
                      type="text"
                      value={item}
                      name={item}
                      onChange={(e) => editArrayList(e, index, type)}
                    />
                    <IconButton
                      icon={<FiTrash2 />}
                      action={() => deleteItem(index, type)}
                      tooltipText="Borrar item"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <span className="empty-state">
          <span className="icon">
            {icon}
          </span>
          El plan no tiene {type}
        </span>
      )}
      {formErrors[type] && <ErrorInput>{formErrors[type]}</ErrorInput>}
    </Container>
  );
};

export default InformacionForm;
