import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { limitesPremiosReglas } from "../../../../utils/formRules";
import { useLoading } from "../../../../hooks/useLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import Divider from "../../../Divider";
import { useState } from "react";
import Success from "../../../Success";
import { FiBox, FiPercent, FiUser } from "react-icons/fi";
import SpinnerLoading from "../../../SpinnerLoading";
import CardSinResultados from "../../../CardSinResultados";
import Subtitle from "../../../Subtitle";
import NewInputSelect from "../../../NewInputSelect";
import { meses, anios } from "../../../../constants/data";
import { useTabs } from "../../../../hooks/useTabs";
import NewTabs from "../../../NewTabs";
import InputCheckbox from "../../../InputCheckbox";

const ModalTotalPorcentajesComisiones = ({ modal, refreshData }) => {
  const { isModalOpen, handleModal } = modal;
  const [formInitialState, setFormInitialState] = useState({});
  const [formInitialStateTeletrabajo, setFormInitialStateTeletrabajo] = useState({});
  const [porcentajeTotalRules, setPorcentajeTotalRules] = useState({});
  const [vendedores, setVendedores] = useState([]);
  const [porcentajesModificados, setPorcentajesModificados] = useState([]);
  const [mes, setMes] = useState(new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const { tabSelected, handleTab } = useTabs();
  const [vendedoresTeletrabajo, setVendedoresTeletrabajo] = useState([])

  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, () => {}, formInitialState);
  const teletrabajoForm = useForm(handleSubmitTeletrabajo, () => {}, formInitialStateTeletrabajo);

  const formatter = new Intl.NumberFormat("es-ES", {});

  function validate(state, vendedores) {
    let validaciones = {};
    vendedores.data.forEach((item) => {
      const validation = {
        regla: state[item.nombre.toLowerCase().replaceAll(" ", "_")] === "",
        mensaje: "El porcentaje es requerido.",
      };
      validaciones[item.nombre.toLowerCase().replaceAll(" ", "_")] = [
        validation,
      ];
    });
    return validaciones;
  }

  const getTeletrabajo = async () => {
    if (mes !== null && anio) {
      try {
        setLoading(true);
        const vendedores = await axiosRequest.get(
          `/comisiones_teletrabajo/${mes}/${anio}`
        );
        console.log(vendedores.data)
        let init = {};
        vendedores.data.forEach((item) => {
          init[item.nombre.toLowerCase().replaceAll(" ", "_")] =
            item.activo;
        });
        setVendedoresTeletrabajo(vendedores.data)
        setFormInitialStateTeletrabajo(init);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getPorcentajes = async () => {
    if (mes !== null && anio) {
      try {
        setLoading(true);
        const vendedores = await axiosRequest.get(
          `/comisiones_porcentaje_total/${mes}/${anio}`
        );
        let init = {};
        vendedores.data.forEach((item) => {
          init[item.nombre.toLowerCase().replaceAll(" ", "_")] =
            item.porcentaje;
        });
        const validaciones = validate(inputValues, vendedores);
        setPorcentajeTotalRules(validaciones);
        setFormInitialState(init);
        setVendedores(vendedores.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // useEffect(() => {

  //   getPorcentajes();
  // }, [mes, anio]);

  const { loading, setLoading, loadingText, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Actualizando...");
    const data = Object.entries(inputValues);
    const vendedoresPorcentajes = vendedores.map((vendedor) => {
      const porcentaje = data.find(
        (porcen) =>
          porcen[0] === vendedor.nombre.toLowerCase().replaceAll(" ", "_")
      );
      return {
        id_vendedor: vendedor.numero,
        porcentaje: porcentaje[1],
      };
    });
    try {
      const update = await axiosRequest.put(
        `/comisiones_porcentaje_total/${mes}/${anio}`,
        vendedoresPorcentajes
      );
      if (update.status === 200) {
        // refreshData()
        setLoading(false);
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function handleSubmitTeletrabajo() {
    setLoading(true);
    changeLoadingText("Actualizando...");
    const data = Object.entries(teletrabajoForm.inputValues);
    const venTeletrabajo = vendedoresTeletrabajo.map((vendedor) => {
      const teletrabajo = data.find(
        (ven) =>
          ven[0] === vendedor.nombre.toLowerCase().replaceAll(" ", "_")
      );
      return {
        id_vendedor: vendedor.id_vendedor ? vendedor.id_vendedor : vendedor.numero,
        activo: teletrabajo[1],
      };
    });

    try {
      const update = await axiosRequest.put(
        `/comisiones_teletrabajo/${mes}/${anio}`,
        venTeletrabajo
      );
      if (update.status === 200) {
        // refreshData()
        setLoading(false);
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setSuccess(false);
    handleModal();
  };

  const TABS = [
    {
      name: "Porcentajes",
    },
    {
      name: "Teletrabajo",
    },
  ];

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Gestionar Comisiones</NewTitle>
            <p>Actualizá porcentajes y teletrabajo de los vendedores</p>
          </div>
          <Divider />
          {!success ? (
            <>
              <div className="data-container">
                <NewTabs
                  tabs={TABS}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
                />
              </div>
              {tabSelected === 0 ? (
                <>
                  <div className="data-container">
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={4}>
                        <NewInputSelect
                          labelName="Mes"
                          name="mes"
                          placeholder="Mes"
                          onChange={(e) => setMes(e.target.value)}
                          value={mes}
                          options={meses}
                        />
                        {formErrors.mes && (
                          <ErrorInput>{formErrors.mes}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={4}>
                        <NewInputSelect
                          labelName="Año"
                          name="anio"
                          placeholder="Año"
                          onChange={(e) => setAnio(e.target.value)}
                          value={anio}
                          options={anios}
                        />
                        {formErrors.anio && (
                          <ErrorInput>{formErrors.anio}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={4} alignBottom={true}>
                        <NewButton
                          backgroundColor={true}
                          onClick={getPorcentajes}
                        >
                          Buscar
                        </NewButton>
                      </Col>
                    </Grid>
                    <Subtitle
                      icon={<FiBox />}
                      title={"Vendedores"}
                      description={
                        "Lista de todos los vendedores disponibles y sus porcentajes"
                      }
                      disableDivider={true}
                    />
                    {!loading ? (
                      <div className="vendedores-container">
                        {vendedores.length > 0 ? (
                          vendedores.map((vendedor) => {
                            return (
                              <div className="vendedor-container">
                                <div>
                                  <span className={"asegurado"}>
                                    <span className={"userIcon"}>
                                      <FiUser />
                                    </span>
                                    <div className={"aseguradoInfo"}>
                                      <h4>{vendedor.nombre}</h4>
                                      <span
                                        className={"aseguradoAdditionalInfo"}
                                      >
                                        {vendedor.nombre_tipo_vendedor
                                          ? vendedor.nombre_tipo_vendedor
                                          : "-"}
                                      </span>
                                    </div>
                                  </span>
                                </div>
                                <div>
                                  <NewInputBasic
                                    type="number"
                                    name={vendedor.nombre
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}
                                    placeholder="100"
                                    onChange={handleChange}
                                    value={
                                      inputValues[
                                        vendedor.nombre
                                          .toLowerCase()
                                          .replaceAll(" ", "_")
                                      ]
                                    }
                                  />
                                  {formErrors[
                                    vendedor.nombre
                                      .toLowerCase()
                                      .replaceAll(" ", "_")
                                  ] && (
                                    <ErrorInput>
                                      {
                                        formErrors[
                                          vendedor.nombre
                                            .toLowerCase()
                                            .replaceAll(" ", "_")
                                        ]
                                      }
                                    </ErrorInput>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <CardSinResultados
                            icon={<FiPercent />}
                            title="Sin resultados"
                            description={
                              "No hay porcentajes cargados para el mes y año seleccionados"
                            }
                          />
                        )}
                      </div>
                    ) : (
                      <SpinnerLoading text={"Cargando vendedores..."} />
                    )}
                  </div>
                  <Divider />
                  <div className={"actionButtonsCont"}>
                    <div className="buttonsCont">
                      <NewButton
                        backgroundColor={false}
                        textColor={true}
                        borderColor={true}
                        onClick={handleModal}
                      >
                        Cancelar
                      </NewButton>
                      <NewButton
                        backgroundColor={true}
                        onClick={handleValidation}
                        disabled={loadingText}
                      >
                        {loadingText ? loadingText : "Actualizar"}
                      </NewButton>
                    </div>
                  </div>
                </>
              ) : null}

              {tabSelected === 1 ? (
                <>
                  <div className="data-container">
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={4}>
                        <NewInputSelect
                          labelName="Mes"
                          name="mes"
                          placeholder="Mes"
                          onChange={(e) => setMes(e.target.value)}
                          value={mes}
                          options={meses}
                        />
                        {formErrors.mes && (
                          <ErrorInput>{formErrors.mes}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={4}>
                        <NewInputSelect
                          labelName="Año"
                          name="anio"
                          placeholder="Año"
                          onChange={(e) => setAnio(e.target.value)}
                          value={anio}
                          options={anios}
                        />
                        {formErrors.anio && (
                          <ErrorInput>{formErrors.anio}</ErrorInput>
                        )}
                      </Col>
                      <Col desktop={4} alignBottom={true}>
                        <NewButton
                          backgroundColor={true}
                          onClick={getTeletrabajo}
                        >
                          Buscar
                        </NewButton>
                      </Col>
                    </Grid>
                    <Subtitle
                      icon={<FiBox />}
                      title={"Vendedores"}
                      description={
                        "Habilitar columna teletrabajo para vendedores"
                      }
                      disableDivider={true}
                    />
                    {!loading ? (
                      <div className="vendedores-container">
                        {vendedoresTeletrabajo.length > 0 ? (
                          vendedoresTeletrabajo.map((vendedor) => {
                            return (
                              <div className="vendedor-container">
                                <div>
                                  <span className={"asegurado"}>
                                    <span className={"userIcon"}>
                                      <FiUser />
                                    </span>
                                    <div className={"aseguradoInfo"}>
                                      <h4>{vendedor.nombre}</h4>
                                      <span
                                        className={"aseguradoAdditionalInfo"}
                                      >
                                        {vendedor.nombre_tipo_vendedor
                                          ? vendedor.nombre_tipo_vendedor
                                          : "-"}
                                      </span>
                                    </div>
                                  </span>
                                </div>
                                <div>
                                  <InputCheckbox 
                                    name={vendedor.nombre
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}
                                    onChange={teletrabajoForm.handleChangeCheckbox}
                                    checked={teletrabajoForm.inputValues[
                                        vendedor.nombre
                                          .toLowerCase()
                                          .replaceAll(" ", "_")
                                      ]}
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <CardSinResultados
                            icon={<FiPercent />}
                            title="Sin resultados"
                            description={
                              "No hay porcentajes cargados para el mes y año seleccionados"
                            }
                          />
                        )}
                      </div>
                    ) : (
                      <SpinnerLoading text={"Cargando vendedores..."} />
                    )}
                  </div>
                  <Divider />
                  <div className={"actionButtonsCont"}>
                    <div className="buttonsCont">
                      <NewButton
                        backgroundColor={false}
                        textColor={true}
                        borderColor={true}
                        onClick={handleModal}
                      >
                        Cancelar
                      </NewButton>
                      <NewButton
                        backgroundColor={true}
                        onClick={teletrabajoForm.handleValidation}
                        disabled={loadingText}
                      >
                        {loadingText ? loadingText : "Actualizar"}
                      </NewButton>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <Success
              title="¡Datos actualizados!"
              description="Los datos para los vendedores fueron actualizados correctamente"
              handleModal={handleCloseModal}
            />
          )}
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalTotalPorcentajesComisiones;

