import React, { useEffect, useState } from "react";
import Subtitle from "../../../Subtitle";
import {
  FiAlertCircle,
  FiArrowDown,
  FiArrowUp,
  FiArrowUpRight,
  FiBarChart,
  FiStar,
  FiTarget,
  FiUser,
} from "react-icons/fi";
import { meses } from "../../../../constants/data";
import { Col, Grid } from "../../../Grid";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerLoading from "../../../SpinnerLoading";
import Subtitulo from "../../../Subtitulo";
import NewButton from "../../../NewButton";
import PieChart from "../../../PieChart";
import axios from "axios";
import axiosRequest from "../../../../utils/axiosConfig";
import CardNumber from "../../../CardNumber";
import CardSinResultados from "../../../CardSinResultados";
import Divider from "../../../Divider";
import { useTheme } from "../../../../context/ThemeContext";

const VendedorDetalleTab = ({ detalle }) => {
  const {
    mes,
    anio,
    vendedor,
    premios,
    vendedorNombre,
    loading,
    objetivos,
    objetivosProductos,
    objetivosCanales,
    estadisticasObjetivosProductos,
    objetivosModal,
    data,
    location,
    previousUrl,
  } = detalle;
  const formatter = new Intl.NumberFormat("es-ES", {});
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;
  const [formaPago, setFormaPago] = useState({});
  const [diasHabiles, setDiasHabiles] = useState(null);
  const [premiosMes, setPremiosMes] = useState(
    location.state?.data ? location.state.data : []
  );
  const [grupo, setGrupo] = useState([]);
  const [grupoData, setGrupoData] = useState(null);
  const [limites, setLimites] = useState(null);
  const [porcentajeObjetivoOperaciones, setPorcentajeObjetivoOperaciones] =
    useState(null);
  const isComercial =
    userRol === 10 || userRol === 18 || userRol === 22 || userRol === 24;
  const { theme } = useTheme();
  console.log(vendedor);
  const VENDEDORES_CALIDAD = ["341", "361", "362"];

  const optionsPieChart = {
    plugins: {
      title: {
        display: true,
        color: theme === "dark" ? "#ffffff" : "#666666",
        text: `Operaciones emitidas con medios de pago`,
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          color: theme === "dark" ? "#ffffff" : "#666666",
        },
      },
    },
  };

  const getLimites = async () => {
    try {
      const limites = await axiosRequest.get(`/premios/limites`);
      const porcentaje_objetivo_operaciones = await axiosRequest.get(
        `/premios/porcentaje_objetivo_operaciones`
      );
      setLimites(limites.data);
      setPorcentajeObjetivoOperaciones(porcentaje_objetivo_operaciones.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLimites();
  }, []);

  useEffect(() => {
    const getVendedor = async () => {
      if (vendedor) {
        try {
          setGrupo([]);
          const grupo = await axiosRequest.get(
            `/premios/grupo/${vendedor.id}/${parseInt(mes) + 1}/${anio}`
          );
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}/${anio}`
          );
          if (grupo.data) {
            const grupoData = grupos.data.find(
              (item) => item.id_vendedores_grupo == grupo.data.id_grupo
            );
            setGrupoData(grupoData);
            setGrupo(grupo.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes, anio]);

  useEffect(() => {
    const getVendedor = async () => {
      //if (!location.state) {
      try {
        const data = await axiosRequest.get(
          `/premios/historial/vendedor/${vendedor.id}/${mes}/${anio}`
        );
        setPremiosMes(data.data);
      } catch (error) {
        console.log(error);
      }
      // }
    };
    getVendedor();
  }, [mes, anio]);

  useEffect(() => {
    const getFormaPago = async () => {
      try {
        const formaPago = await axiosRequest.get(
          `/premios/vendedor_forma_pago/${mes}/${vendedor.id}/${anio}`
        );
        const dataPie = {
          labels: formaPago.data.forma_pago.map((item) => {
            const porcentaje = (
              (item.operaciones * 100) /
              data?.operaciones
            ).toFixed(2);
            return `${item.forma_pago} (${porcentaje}%)`;
          }),
          datasets: [
            {
              label: "Operaciones emitidas: ",
              data: formaPago.data.forma_pago.map((item) => item.operaciones),
              backgroundColor: [
                "rgb(26, 86, 255)",
                "rgb(116, 255, 227)",
                "rgb(125, 188, 255)",
              ],
              hoverOffset: 4,
            },
          ],
        };
        setFormaPago(dataPie);
      } catch (error) {
        console.log(error);
      }
    };
    getFormaPago();
  }, [userRol, mes, data, anio]);

  useEffect(() => {
    const getDiasHabiles = async () => {
      try {
        setDiasHabiles(null);
        const month =
          parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
        console.log(month);
        const feriados = await axios.get(
          `https://api.argentinadatos.com/v1/feriados/${anio}`
        );
        const feriadosMes = feriados.data.filter((item) => {
          const day = new Date(
            anio,
            parseInt(mes),
            parseInt(item.fecha.split("-")[2])
          ).getDay();
          if (
            item.fecha.split("-")[1].includes(month) &&
            day != 0 &&
            day != 6
          ) {
            return item;
          }
        });

        let diasHabiles = 0;
        let diasHabilesTranscurridos = 0;

        const diasMes = new Date(anio, parseInt(mes) + 1, 0).getDate();
        const feriadosDias =
          feriadosMes.length > 0
            ? feriadosMes.map((item) => item.fecha.split("-")[1])
            : [];
        for (let i = 1; i <= diasMes; i++) {
          let day = new Date(anio, parseInt(mes), i).getDay();
          let today = new Date().getDate();
          if (day != 0 && day != 6) {
            diasHabiles++;

            if (today >= i && !feriadosDias.includes(i.toString())) {
              diasHabilesTranscurridos++;
            }
          }
        }

        const actualMonth = new Date().getMonth();

        if (actualMonth != mes) {
          diasHabilesTranscurridos = diasHabiles;
        }
        diasHabiles -= feriadosMes.length;
        setDiasHabiles({ diasHabiles, diasHabilesTranscurridos });
      } catch (error) {
        console.log(error);
      }
    };
    getDiasHabiles();
  }, [mes]);

  const porcentajeOperaciones = Math.round(
    (100 * data?.operaciones) / objetivos?.operaciones
  );

  return (
    <>
      <Divider />
      <Subtitle
        icon={<FiStar />}
        title={`Premios del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLowerCase()}`}
        description={
          "Valores obtenidos para todos los premios. Hacé click en cada uno para conocer más detalles."
        }
        disableDivider={true}
      />
      <div className="aclaraciones-premios-container">
        {limites ? (
          <div className="tope-premios">
            <p>
              El tope de la suma de premios es{" "}
              <strong>
                $
                {VENDEDORES_CALIDAD.includes(vendedor.id)
                  ? formatter.format(limites?.limite_general_calidad)
                  : formatter.format(limites?.limite_general)}
              </strong>
              . El tope de la suma de premios para quienes cumplan sus objetivos
              en operaciones y prima es{" "}
              <strong>
                $
                {VENDEDORES_CALIDAD.includes(vendedor.id)
                  ? formatter.format(limites?.limite_objetivos_calidad)
                  : formatter.format(limites?.limite_objetivos)}
              </strong>
              .
            </p>
          </div>
        ) : null}
        {porcentajeObjetivoOperaciones && parseInt(mes) >= 10 ? (
          <Grid colGap={10} rowGap={21} narrow={false}>
            <Col desktop={6}>
              <div className="tope-premios">
                <p>
                  <strong>No se cobran premios</strong> si no se llega al{" "}
                  <strong>
                    {
                      porcentajeObjetivoOperaciones.operaciones_porcentaje_objetivo
                    }
                    % del objetivo de operaciones.
                  </strong>
                </p>
              </div>
            </Col>
            <Col desktop={6}>
              <div className="tope-premios">
                <p>
                  <strong>No se cobran comisiones</strong> si no se hace un{" "}
                  <strong>mínimo</strong> de <strong>40 operaciones.</strong>
                </p>
              </div>
            </Col>
          </Grid>
        ) : null}
        <Grid colGap={10} rowGap={10} narrow={false}>
          <Col desktop={12}>
            <div className="tope-premios">
              <p>
                Se cobraran comisiones a partir de llegar al 85% de prima y al
                85% de objetivos. Entre 40 a 60 operaciones, se cobra el 50% de
                las comisiones. Válido para Vendedores desde 3 meses en GyS.
              </p>
            </div>
          </Col>
          <Col desktop={12}>
            <div className="tope-premios">
              <p>
                <strong>
                  No se cobran premios si no se llega al 100% de la prima.
                </strong>
              </p>
            </div>
          </Col>
        </Grid>
      </div>

      {premiosMes?.premios?.length > 0 ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {premiosMes.premios.map((premio) => {
            if (premio !== "vendedor" && premio !== "id_vendedor") {
              return (
                <Col desktop={3}>
                  <Link
                    to={{
                      pathname: `/Premio/Vendedor/${vendedor.id}/${premio.id_premio}`,
                      state: {
                        previousUrl: previousUrl,
                        premio: {
                          nombre: premio.nombre,
                          valor: formatter.format(premio.premio),
                        },
                        data: premios.find(
                          (prem) => prem.id_vendedor_premio == premio.id_premio
                        ),
                        mes: mes,
                        anio: anio,
                        vendedor: vendedorNombre,
                      },
                    }}
                  >
                    <CardNumber
                      title={premio.nombre}
                      value={`${formatter.format(premio.premio)}`}
                      background={premio === "total"}
                    />
                  </Link>
                </Col>
              );
            }
          })}
          {data?.totalPrima && objetivos?.prima ? (
            <Col desktop={3}>
              <div className={`premio-container total`}>
                <span>Total</span>
                {data?.totalPrima < objetivos?.prima ? (
                  <h2>
                    {userRol !== 10 && userRol !== 18 && userRol !== 22
                      ? `${formatter.format(premiosMes.total)}`
                      : `0` && userRol !== 24
                      ? `${formatter.format(premiosMes.total)}`
                      : `0`}
                  </h2>
                ) : (
                  <h2>{`${formatter.format(premiosMes.total)}`}</h2>
                )}
              </div>
            </Col>
          ) : null}
        </Grid>
      ) : null}
      {loading &&
      ((porcentajeOperaciones >=
        parseInt(
          porcentajeObjetivoOperaciones?.operaciones_porcentaje_objetivo
        ) &&
        isComercial) ||
        !isComercial ||
        parseInt(mes) < 10) ? (
        <SpinnerLoading />
      ) : null}
      {!loading && premiosMes.premios?.length === 0 ? (
        <CardSinResultados
          icon={<FiStar />}
          title={"No hay premios"}
          description={"¡Los premios de este mes se publicarán proximamente!"}
        />
      ) : null}

      {grupo?.integrantes?.length > 0 ? (
        <>
          <Divider />
          <Subtitle
            icon={<FiUser />}
            title={"Grupo"}
            description={"Prima de los integrantes del grupo"}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            {grupo.integrantes.length > 0 ? (
              <>
                {grupo.integrantes.map((item, index) => {
                  console.log(item);
                  return (
                    <Col desktop={3}>
                      <div
                        className={`vendedor-container ${
                          item.id_vendedor != vendedor.id && "no-seleccionado"
                        }`}
                      >
                        <h2>#{index + 1}</h2>
                        <span className="vendedor-nombre">{item.nombre}</span>
                        <span>${formatter.format(Math.round(item.prima))}</span>
                      </div>
                    </Col>
                  );
                })}
                {userRol !== 10 &&
                userRol !== 18 &&
                userRol !== 22 &&
                userRol !== 24 &&
                grupoData ? (
                  <Col desktop={12}>
                    <Link
                      to={{
                        pathname: `/Premios/Vendedores/Grupos/${grupo.id_grupo}`,
                        state: grupoData,
                        previousUrl: previousUrl,
                      }}
                    >
                      <NewButton backgroundColor={true}>
                        Ver grupo <FiArrowUpRight />
                      </NewButton>
                    </Link>
                  </Col>
                ) : null}
              </>
            ) : (
              <SpinnerLoading />
            )}
          </Grid>
        </>
      ) : null}

      <Divider />
      <Subtitle
        icon={<FiTarget />}
        title={`Objetivos del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLowerCase()}`}
        description={"Operaciones y prima a realizar"}
        disableDivider={true}
      />
      {objetivos && (
        <Grid colGap={21} rowGap={21} narrow={false}>
          <Col desktop={3}>
            <CardNumber
              title="Objetivo operaciones"
              value={objetivos.operaciones}
            />
          </Col>
          <Col desktop={3}>
            <CardNumber
              title="Objetivo prima"
              value={`$${formatter.format(objetivos.prima)}`}
            />
          </Col>
          {userRol !== 10 &&
          userRol !== 18 &&
          userRol !== 22 &&
          userRol !== 24 &&
          mes === new Date().getMonth() &&
          anio === new Date().getFullYear() ? (
            <Col desktop={12}>
              <NewButton
                backgroundColor={true}
                onClick={() => objetivosModal.setIsModalOpen(true)}
              >
                Actualizar objetivos
              </NewButton>
            </Col>
          ) : null}
        </Grid>
      )}
      {!objetivos && loading && <SpinnerLoading />}
      {!objetivos && !loading && (
        <CardSinResultados
          icon={<FiTarget />}
          title={"No hay objetivos cargados"}
          description={`No se encontraron objetivos establecidos para el mes de
            ${meses.find((m) => m.value == mes)?.label.toLowerCase()}`}
        />
      )}

      <Divider />

      {objetivosProductos.length > 0 ? (
        <>
          <Subtitle
            icon={<FiTarget />}
            title={`Objetivos del mes de ${meses
              .find((m) => m.value == mes)
              ?.label.toLowerCase()} por productos`}
            description={"Lista de objetivos de operaciones por producto"}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            {objetivosProductos.map((obj) => {
              return (
                <Col desktop={3}>
                  <CardNumber
                    title={`Objetivo ${obj.descripcion}`}
                    value={obj.operaciones}
                  />
                </Col>
              );
            })}
          </Grid>
        </>
      ) : null}

      {objetivosCanales.length > 0 ? (
        <>
          <Subtitle
            icon={<FiTarget />}
            title={`Objetivos del mes de ${meses
              .find((m) => m.value == mes)
              ?.label.toLowerCase()} por canales`}
            description={"Lista de objetivos de operaciones por canales"}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            {objetivosCanales.map((obj) => {
              return (
                <Col desktop={3}>
                  <CardNumber
                    title={`Objetivo ${obj.descripcion}`}
                    value={obj.operaciones}
                  />
                </Col>
              );
            })}
          </Grid>
        </>
      ) : null}

      {/* {estadisticasObjetivosProductos.length > 0 ?
        <>
          <Divider />
          <Subtitle
            icon={<FiBarChart />}
            title={`Estadísticas de objetivos productos en el mes de ${meses
              .find((m) => m.value == mes)
              ?.label.toLocaleLowerCase()}`}
            description={`Datos sobre las operaciones por productos según objetivos`}
            disableDivider={true}
          />  
          <Grid colGap={21} rowGap={21} narrow={false}>
          {estadisticasObjetivosProductos.map((estadistica, index) => {
            const checkIcon = estadistica?.superado && index % 2 == 0
            let icon = null
            if(checkIcon){
              icon = estadistica.superado === "positivo" ? <FiArrowUp /> : <FiArrowDown />
            }
            return (
              <Col desktop={3}>
                <CardNumber
                  title={estadistica.producto}
                  value={estadistica.operaciones}
                  icon={icon}
                />
              </Col>
            )
          })}    
          </Grid>
        </>
      : null} */}

      <Divider />
      <Subtitle
        icon={<FiBarChart />}
        title={`Estadísticas del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLocaleLowerCase()}`}
        description={`Datos sobre el desempeño de ${vendedorNombre}`}
        disableDivider={true}
      />
      {data && diasHabiles ? (
        <>
          {data.operaciones !== 0 ? (
            <>
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={3}>
                  <CardNumber
                    title="Operaciones realizadas"
                    value={data?.operaciones}
                  />
                </Col>
                {objetivos?.operaciones ? (
                  <Col desktop={3}>
                    <CardNumber
                      title="Porcentaje operaciones"
                      value={`${Math.round(
                        (100 * data?.operaciones) / objetivos.operaciones
                      )}%`}
                    />
                  </Col>
                ) : null}
                <Col desktop={3}>
                  <CardNumber
                    title="Prima obtenida"
                    value={`$${formatter.format(Math.round(data?.totalPrima))}`}
                  />
                </Col>
                {objetivos?.prima ? (
                  <Col desktop={3}>
                    <CardNumber
                      title="Porcentaje prima"
                      value={`${Math.round(
                        (100 * data?.totalPrima) / objetivos.prima
                      )}%`}
                    />
                  </Col>
                ) : null}
                <Col desktop={3}>
                  <CardNumber
                    title="Dias hábiles"
                    value={diasHabiles.diasHabiles}
                  />
                </Col>
                <Col desktop={3}>
                  <CardNumber
                    title="Dias hábiles transcurridos"
                    value={diasHabiles.diasHabilesTranscurridos}
                  />
                </Col>
              </Grid>
              {Object.keys(formaPago)?.length > 0 ? (
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <div className={`total-container pie`}>
                      <PieChart
                        data={formaPago}
                        optionsData={optionsPieChart}
                      />
                    </div>
                  </Col>
                </Grid>
              ) : null}
            </>
          ) : (
            <CardSinResultados
              icon={<FiBarChart />}
              title={"No hay estadísticas"}
              description={`No existen estadísticas para el mes seleccionado`}
            />
          )}
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default VendedorDetalleTab;
