import { MenuDiv, StyledBurger, StyledMenu } from "./styled";
import Clientes from "../../assets/iconos/clientes.svg";
import Estadisticas from "../../assets/iconos/estadisticas.svg";
import Consultas from "../../assets/iconos/consultas.svg";
import Rechazo from "../../assets/iconos/rechazos.svg";
import React, { useState, useRef, useEffect, useContext } from "react";
import axiosRequest from '../../utils/axiosConfig'
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { rolesCampañas, rolesCapacitaciones, rolesLandings, rolesEstadisticasProductoCanal, rolesObjetivos, rolesFedPat, rolesFichajes, rolesSiniestros, rolesConsultas, rolesStorageApi, rolesEstadisticas, rolesClientes, rolesEmisiones, rolesUsuarios, rolesRechazos, rolesHistorialSolicitudes, rolesSolicitudEmision, rolesSynergys, rolesColaboradores, rolesPremios, rolesEstadisticasCobranza } from "../../constants/roles";
import { AiOutlineUser } from 'react-icons/ai'
import { FiTarget, FiFile, FiBarChart2, FiUsers, FiXSquare, FiStar, FiFolder, FiCalendar, FiLink, FiLogOut, FiDollarSign, FiAlertTriangle, FiClipboard, FiInfo, FiShield } from 'react-icons/fi'

const NavMenu = ({open, user, active }) => {

    const node = useRef();
    //const [open, setOpen] = useState(false);
    // History - React Router
    const history = useHistory(); 
    const [username, setUsername] = useState('')
    const authContext = useContext(AuthContext);
    const userRole = JSON.parse(localStorage.getItem('userInfo'))
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    console.log(userRole)

    // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
    useEffect(() => {
      if(!authContext.isAuthenticated()){
        history.push("/");
      }
    }, [])

    // Obtener nombre de usuario
    useEffect(() => {
      if(userRole){
        const getUsername = async () => {
          const username = await axiosRequest.get(`/usuario_operador/${userRole.id_usuario}`)
          setUsername(username.data.nombre_operador)
        }
        getUsername()
      }
    }, [userRole])

    // Remueve el token del local storage
    const logout = () =>{
      localStorage.removeItem("userInfo");
      localStorage.removeItem("expiresIn");
      removeCookie('token')
    }

    const menuLinks = [
      {
        'path': '/Fichajes',
        'class': 'fichajes',
        'active': 'fichajes',
        'icon': <FiCalendar />,
        'name': 'Fichajes',
        'allowedRoles': rolesFichajes,
        'allowedUsuarios': [],
        'show': true
      }, 
      {
        'path': '/SolicitudEmision',
        'class': 'solicitudEmision',
        'active': 'solicitudEmision',
        'icon': <FiFile />,
        'name': 'Solicitudes de Emisión',
        'allowedRoles': rolesSolicitudEmision,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Emisiones',
        'class': 'emisiones',
        'active': 'emisiones',
        'icon': <FiFile />,
        'name': 'Emisiones',
        'allowedRoles': rolesEmisiones,
        'allowedUsuarios': [],
        'show': true
      }, 
      {
        'path': '/HistorialSolicitudes',
        'class': 'historialSolicitudes',
        'active': 'historialSolicitudes',
        'icon': <FiFile />,
        'name': 'Historial Solicitudes',
        'allowedRoles': rolesHistorialSolicitudes,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/EstadisticasProductoCanal',
        'class': 'estadisticas-producto-canal',
        'active': 'estadisticas-producto-canal',
        'icon': <FiBarChart2 />,
        'name': 'Est. Producto - Canal',
        'allowedRoles': rolesEstadisticasProductoCanal,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/EstadisticasCobranzas',
        'class': 'estadisticas-cobranzas',
        'active': 'estadisticas-cobranzas',
        'icon': <FiBarChart2 />,
        'name': 'Estadísticas Cobranzas',
        'allowedRoles': [1, 8, 4, 23],
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Whatsapp',
        'class': 'whatsapp',
        'active': 'whatsapp',
        'icon': Estadisticas,
        'name': 'WhatsApp',
        'allowedRoles': rolesEstadisticasProductoCanal,
        'show': false
      },
      {
        'path': '/ObjetivosLeads',
        'class': 'objetivos',
        'active': 'objetivos',
        'icon': <FiTarget />,
        'name': 'Objetivos Leads',
        'allowedRoles': rolesObjetivos,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/ObjetivosCobranzas',
        'class': 'objetivos-cobranzas',
        'active': 'objetivos-cobranzas',
        'icon': <FiTarget />,
        'name': 'Objetivos de Cobranzas',
        'allowedRoles': [1, 8, 23],
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/ObjetivosPorVendedor',
        'class': 'objetivos-por-vendedor',
        'active': 'objetivos-por-vendedor',
        'icon': <FiTarget />,
        'name': 'Objetivos Por Vendedor',
        'allowedRoles': [1, 4, 13, 14],
        'allowedUsuarios': [1, 4, 13, 14],
        'show': false
      },
      {
        'path': '/Rechazos2',
        'class': 'rechazos',
        'active': 'rechazos',
        'icon': <FiXSquare />,
        'name': 'Cobranzas',
        'allowedRoles': rolesRechazos,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Colaboradores',
        'class': 'colaboradores',
        'active': 'colaboradores',
        'icon': <FiXSquare />,
        'name': 'Colaboradores',
        'allowedRoles': rolesColaboradores,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Usuarios',
        'class': 'usuarios',
        'active': 'usuarios',
        'icon': <FiUsers />,
        'name': 'Usuarios',
        'allowedRoles': rolesUsuarios,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Clientes',
        'class': 'clientes',
        'active': 'clientes',
        'icon': Clientes,
        'name': 'Clientes',
        'allowedRoles': rolesClientes,
        'allowedUsuarios': [],
        'show': false
      }, 
      {
        'path': '/Estadisticas',
        'class': 'estadisticas',
        'active': 'estadisticas',
        'icon': Estadisticas,
        'name': 'Estadisticas',
        'allowedRoles': rolesEstadisticas,
        'allowedUsuarios': [],
        'show': false
      }, 
      {
        'path': '/StorageAPi',
        'class': 'storageapi',
        'active': 'storageapi',
        'icon': <FiFolder />,
        'name': 'StorageApi',
        'allowedRoles': rolesStorageApi,
        'allowedUsuarios': [],
        'show': false
      }, 
      {
        'path': '/Consultas',
        'class': 'consultas',
        'active': 'consultas',
        'icon': Consultas,
        'name': 'Consultas',
        'allowedRoles': rolesConsultas,
        'allowedUsuarios': [],
        'show': false
      }, 
      {
        'path': '/Siniestros',
        'class': 'siniestros',
        'active': 'siniestros',
        'icon': <FiAlertTriangle />,
        'name': 'Siniestros',
        'allowedRoles': rolesSiniestros,
        'allowedUsuarios': userRole?.siniestros_habilitado_usuario ? userRole.id_usuario : '',
        'show': true
      },
      {
        'path': '/FedPat',
        'class': 'fedpat',
        'active': 'fedpat',
        'icon': <FiShield />,
        'name': 'FedPat',
        'allowedRoles': rolesFedPat,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/Campanias',
        'class': 'campanias',
        'active': 'campanias',
        'icon': <FiClipboard />,
        'name': 'Campañas',
        'allowedRoles': rolesCampañas,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/Cobranzas',
        'class': 'Cobranzas',
        'active': 'Cobranzas',
        'icon': Rechazo,
        'name': 'Cobranzas',
        'allowedRoles': [1, 2, 4, 8, 15, 17, 23],
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/Capacitaciones',
        'class': 'capacitaciones',
        'active': 'capacitaciones',
        'icon': <FiInfo />,
        'name': 'Capacitaciones',
        'allowedRoles': rolesCapacitaciones,
        'allowedUsuarios': [],
        'show': false
      },
 
      {
        'path': '/Landings',
        'class': 'landings',
        'active': 'landings',
        'icon': <FiLink />,
        'name': 'Landings',
        'allowedRoles': rolesLandings,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Autogestion',
        'class': 'autogestion',
        'active': 'autogestion',
        'icon': <FiDollarSign />,
        'name': 'Autogestión',
        'allowedRoles': [1, 4, 7, 13, 14],
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': `https://synergy-kappa.vercel.app?userId=${btoa(JSON.parse(localStorage.getItem('userInfo')).id_usuario)}`,
        'icon': <FiStar />,
        'name': 'SinerGyS',
        'allowedUsuarios': [],
        'allowedRoles': rolesSynergys,
        'show': false,
        'external': true
      },
      {
        'path': '/PremiosVendedores',
        'class': 'premios-vendedores',
        'active': 'premios-vendedores',
        'icon': <FiStar />,
        'name': 'Premios Vendedores',
        'allowedUsuarios': [],
        'allowedRoles': [1, 4, 7, 13, 17, 16],
        'show': false
      },
      {
        'path': '/Premios',
        'class': 'premios',
        'active': 'premios',
        'icon': <FiStar />,
        'name': 'Premios',
        'allowedUsuarios': [],
        'allowedRoles': [1, 4, 7, 13, 17, 16],
        'show': true
      },
      {
        'path': `/Premio/Vendedor/${userRole.id_vendedor_novedades}`,
        'class': 'premios',
        'active': 'premios',
        'icon': <FiStar />,
        'name': 'Premios',
        'allowedUsuarios': [],
        'allowedRoles': [10,18],
        'show': true
      },
    ]

    return(
        <MenuDiv ref={node} active={active}>
        <StyledMenu open={open}>
        <h2>¡Hola {username}!</h2>
        <nav>
          <ul>
            {userRole && menuLinks.map((item, index) => {
              if((item.allowedRoles.includes(userRole.id_rol) || item?.allowedUsuarios === userRole.id_usuario) && item.show){
                return (
                  <li key={index}>
                    {!item.external ? <Link to={{ pathname: item.path }} className={item.class} active={item.active}>
                      <figure>
                        {typeof(item.icon) === 'string' ? 
                          <img src={item.icon} alt={item.name} />
                        : 
                          <span className="icon">{item.icon}</span>
                        }
                      </figure>
                      <span>{item.name}</span>
                    </Link> :
                    <a href={ item.path } className={item.class} active={item.active}>
                    <figure>
                      {typeof(item.icon) === 'string' ? 
                        <img src={item.icon} alt={item.name} />
                      : 
                        <span className="icon">{item.icon}</span>
                      }
                    </figure>
                    <span>{item.name}</span>
                  </a>
                    }

                  </li>
                )
              }
            })}
            <li>
              <a href="/" onClick={logout}>
                <figure>
                  {/*<img src={CerrarSesion} alt={"Cerrar Sesion"} />*/}
                  <span className="icon"><FiLogOut /></span>
                </figure>
                <span>Cerrar Sesi&oacute;n</span>
              </a>
            </li>
          </ul>
        </nav>
      </StyledMenu>
      </MenuDiv>
    )
}

export default NavMenu
