import styled from "styled-components";
import { leerColor, colores } from "../../constants";

export const Container = styled.div`
  .premio-container {
    background-color: ${({ theme }) => theme.background};

    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
    transition: all 0.5s;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  .title-card {
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle};
    font-weight: 400;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .value-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .icon-card {
      padding: 10px;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.little_icon};
      display: flex;
      align-items: center;
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.border};
      transition: all 0.5s;
    }
  }

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    color: ${({ theme }) => theme.title};
  }

  .link {
    color: ${leerColor(colores.grisEstados)};
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.2s;
    cursor: pointer;
  }

  .link:hover {
    color: ${leerColor(colores.azulRebranding)};
  }

  a :hover {
    border: 1px solid ${leerColor(colores.azulRebranding)};
    transition: 0.3s;
  }

  .total {
    border: 1px solid ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    background-color: ${leerColor(colores.negro)};

    span,
    h2 {
      color: ${leerColor(colores.blanco)};
    }
  }
`;
