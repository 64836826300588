import dayjs from "dayjs"
import { FICHAJES_ICONS, TIPOS_EXCEPCIONES_ICONS } from "../../constants/icons"
import SpinnerLoading from "../SpinnerLoading"
import CardSinResultados from "../CardSinResultados"
import { FiCalendar } from "react-icons/fi"

const Fichajes = ({ data }) => {
  const { dia, fichajes, loadingFichajes, id_rol } = data
  const rolesPermitidosHoraRealFichaje = [1, 4, 16]
  
  return (
    <div className='fichajes-container'>
    <div className='fichajes-header'>
      <p>Fichajes del día</p>
      <h4 className="subtitulo-historial">
        {dayjs(dia).format("dddd D MMMM, YYYY")}
      </h4>
    </div>
    {fichajes?.excepcion?.length > 0 && fichajes?.excepcion.map(excepcion => {
        return (
          <div
          className={`excepcion-container-fichajes`}
        >
          {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion && excepcion.periodo_excepcion ?
          <>
            <span className="icon">
                {TIPOS_EXCEPCIONES_ICONS[excepcion.tipo_excepcion.toLowerCase()]}
            </span>
            <span>
                {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion ? <><strong>{excepcion.tipo_excepcion} {excepcion.tipo_excepcion === "Acuerdo" ? `(${excepcion.motivo_acuerdo})` : ''}</strong></> : null}
                    
                {excepcion.periodo_excepcion === "Desde / Hasta" && excepcion.periodo_fecha_desde_excepcion && excepcion.periodo_fecha_hasta_excepcion ?  <> desde el <strong>{dayjs(excepcion.periodo_fecha_desde_excepcion).format("DD/MM/YYYY")}</strong> hasta el <strong>{dayjs(excepcion.periodo_fecha_hasta_excepcion).format("DD/MM/YYYY")}</strong></> : null}

                {excepcion.periodo_excepcion === "Fecha" && excepcion.periodo_fecha_excepcion ? <> el día <strong>{dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")}</strong></> : null}

                {excepcion.hora_desde_excepcion && excepcion.hora_hasta_excepcion ? <> desde las <strong>{dayjs(excepcion.hora_desde_excepcion).format("HH:mm")}hs</strong> hasta las <strong>{dayjs(excepcion.hora_hasta_excepcion).format("HH:mm")}hs</strong></> : null}
            </span>
            </>
            :
            <span>Completá todos los datos necesarios para ver la excepción acá</span>
            }
        </div>
        )
      })}
    <div className='fichajes-contenido'>
      {fichajes?.fichajes?.length > 0 && fichajes?.fichajes.map(item => {
        return (
          <div className='fichaje'>
            <div className='nombre-fichaje'>
            <span className='icon'>
              {FICHAJES_ICONS[item.descripcion_fichaje.toLowerCase().replaceAll(" ", "_")]}
            </span>
            <span>
            <><strong>{item.descripcion_fichaje}</strong></>
            </span>
            </div>
            <span>
            <>fichado a las {item.dia_fichaje_esperado ? dayjs(item.dia_fichaje_esperado).format("HH:mm") : dayjs(item.dia_fichaje).format("HH:mm")}hs {rolesPermitidosHoraRealFichaje.includes(id_rol) ? `(${dayjs(item.dia_fichaje).format("HH:mm")}hs)` : null}</>
            </span>
            {/* {rolesPermitidosHoraRealFichaje.includes(id_rol) ? <p>Hora del fichaje real: {dayjs(item.dia_fichaje).format("HH:mm")}</p> : null} */}
          </div>
        )
      })}

      {loadingFichajes.loading && fichajes?.fichajes?.length === 0 ? <SpinnerLoading /> : null}
      {!loadingFichajes.loading && fichajes?.fichajes?.length === 0 && fichajes?.excepcion?.length === 0 ? <CardSinResultados icon={<FiCalendar />} title="No hay fichajes" description="No encontramos ningún fichaje para el día seleccionado" disableBorder={true} /> : null}
    </div>
  </div>
  )
}

export default Fichajes