import { Grid, Col } from "../../Grid";
import Button from "../../Button";
import InputBasic from "../../InputBasic";
import { useForm } from "../../../hooks/useForm";
import { useEffect, useState } from "react";
import ErrorInput from "../../ErrorInput";
import axiosRequest from "../../../utils/axiosConfig";
import useButtonMessage from "../../../hooks/useButtonMessage";
import { cotizadorLimitesReglas } from "../../../utils/formRules";
import SpinnerLoading from "../../SpinnerLoading";
import { Container, LimiteCard } from "./styled";
import Subtitulo from "../../Subtitulo";
import { formatNumber } from "../../../utils/formatter";
import Divider from "../../Divider";
import NewInputBasic from "../../NewInputBasic";
import NewButton from "../../NewButton";
import Success from "../../Success";

const CotizadorForm = ({ productos }) => {
  const [initialValues, setInitialValues] = useState({});
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    cotizadorLimitesReglas,
    initialValues
  );
  const formatter = new Intl.NumberFormat("es-ES", {});
  const [success, setSuccess] = useState(false)

  const { buttonText, handleButtonText } =
    useButtonMessage("Actualizar valores");

  useEffect(() => {
    const initialValues = productos.reduce((total, item) => {
      return {
        ...total,
        [item.descripcion.toLowerCase().replace(" ", "_")]:
          item.limite_cotizador,
      };
    }, {});
    setInitialValues(initialValues);
  }, [productos]);

  async function handleSubmit() {
    handleButtonText("Actualizando...");
    let data = [];
    try {
      productos.forEach((producto) => {
        if (
          inputValues[producto.descripcion.toLowerCase().replace(" ", "_")] !==
          producto.limite_cotizador
        ) {
          data.push({
            id_landing_producto: producto.id_landing_producto,
            limite_cotizador:
              inputValues[producto.descripcion.toLowerCase().replace(" ", "_")],
          });
        }
      });
      const actualizar = await axiosRequest.put(
        "/limite_cotizador_producto",
        data
      );
      if (actualizar) {
        setSuccess(true)
        handleButtonText("¡Valores actualizados correctamente!");
        setTimeout(() => {
          handleButtonText("Actualizar valores");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {!success ?
      <Grid colGap={21} rowGap={40} narrow={false}>
        {productos.length > 0 ? (
          <>
            {productos.map((producto) => {
              return (
                <Col desktop={3}>
                  <LimiteCard>
                    <div>
                      <span>{producto.descripcion}</span>
                      <h3>$ {formatter.format(producto.limite_cotizador)}</h3>
                    </div>
                    <Divider />
                    <NewInputBasic
                      type={"number"}
                      placeholder={"Teléfono"}
                      name={producto.descripcion
                        .toLowerCase()
                        .replace(" ", "_")}
                      value={
                        inputValues[
                          producto.descripcion.toLowerCase().replace(" ", "_")
                        ]
                      }
                      onChange={handleChange}
                    />
                    {formErrors[
                      inputValues[
                        producto.descripcion.toLowerCase().replace(" ", "_")
                      ]
                    ] && (
                      <ErrorInput>
                        {
                          formErrors[
                            inputValues[
                              producto.descripcion
                                .toLowerCase()
                                .replace(" ", "_")
                            ]
                          ]
                        }
                      </ErrorInput>
                    )}
                  </LimiteCard>
                </Col>
              );
            })}
            <Col desktop={12}>
            <Divider />
            </Col>
            <Col desktop={12}>
              <NewButton backgroundColor={true} onClick={handleValidation}>
                {buttonText}
              </NewButton>
            </Col>
          </>
        ) : (
          <Col desktop={12}>
            <SpinnerLoading text="Cargando valores del cotizador" />
          </Col>
        )}
      </Grid>
      : 
      <Success title="¡Valores actualizados!" description="Los límites del cotizador se actualizaron correctamente" link={"/Landings"} page="Landings" />
      }
    </Container>
  );
};

export default CotizadorForm;
