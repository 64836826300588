import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.a`
    position: relative;

    .acciones-container {
      width: 250px;
      max-height: 400px;
      position: absolute;
      padding: 10px;
      top: 60px;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border-radius: 6px;
      z-index: 199;
      box-sizing: border-box;
      font-family: "DM Sans", sans-serif;
      background-color: ${({ theme }) => theme.background};
      display: flex;
      border: 1px solid ${({ theme }) => theme.border};
      transition: all 0.5s;
    }

    .accion {
        font-weight: 400;
        color: ${({ theme }) => theme.title};
        cursor: pointer;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        span {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        :hover {
            background-color: ${({ theme }) => theme.hover_background};
        }
    }
`;
