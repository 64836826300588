import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const EmisionData = styled.div`

  .seccion-container {
      display: flex;
      flex-direction: column;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 8px;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 100%;
      justify-content: space-between;
      span {
        text-transform: capitalize;
      }
      a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  
    span {
        font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;
    }
    h2 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        color: ${({ theme }) => theme.subtitle};
        line-height: 1.5;
    }
    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }
    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

  /*
  position: relative;
  h6 {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    //font-family: "Raleway";
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: ${leerColor(colores.grisEstados)};
    margin: 10px 0;
    margin-bottom: 6px;
    //text-transform: capitalize;
  }
  .linkML {
    //font-family: "Raleway";
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    margin: 10px 0;
    margin-bottom: 6px;
    display: inline-block;
  }
  svg {
    margin: 10px 0;
    margin-bottom: 6px;
  }
  .extraCont {
    display: flex;

    svg {
      width: 20px;
      margin-right: 10px;
    }
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
  }
  div .buttonsCont a:not(:first-child) {
    margin-left: 25px;
  }
  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;
  }
  .dropDown {
    width: 110px;
    border-radius: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 105%;
    right: -3%;
    z-index: 99;
    padding: 20px;
    background-color: ${leerColor(colores.blanco)};
    -webkit-box-shadow: 12px 12px 30px 0px rgba(177, 178, 180, 0.2);
    box-shadow: 12px 12px 30px 0px rgba(177, 178, 180, 0.2);
    color: ${leerColor(colores.grisModales)};

    a {
      margin: 0 !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: white;
      bottom: -10px;
      left: 50%;
      z-index: 99;
      -webkit-transform: translate(-50%, 0) rotate(45deg);
      -ms-transform: translate(-50%, 0) rotate(45deg);
      transform: translate(-50%, 0) rotate(45deg);
    }

    a:hover {
      color: ${leerColor(colores.celeste)};
      font-weight: 700;
    }
  }
  .dropDown a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 12px;
    text-align: left;
  }
  .dropDown a:last-of-type {
    padding-bottom: 0;
  }
  .dropUp {
    display: none;
  }
  ${(props) =>
    props.apiDisabled === true &&
    css`
      .emisionAPI {
        display: none;
      }
      .dropDown a:nth-child(2) {
        padding-bottom: 0;
      }
    `}

  a:visited {
    color: ${leerColor(colores.azulRebranding)};
  }

  .icon {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 10px;
  }

  .icon span {
    display: flex;
    align-items: center
  }

  .icon:hover {
    opacity: 0.7;
  }
  */
`;
