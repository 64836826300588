import { useEffect, useState } from "react";
import { useTabs } from "../../../../../hooks/useTabs";
import { useForm } from "../../../../../hooks/useForm";
import { fichajePorUsuarioReglas, fichajePropioReglas } from "../../../../../utils/formRules";
import { fichajePorUsuarioForm, fichajePropioForm } from "../../../../../utils/formInitialValues";
import dayjs from "dayjs";
import axiosRequest from "../../../../../utils/axiosConfig";
import { useLoading } from "../../../../../hooks/useLoading";

export default function useModalFichajes({ modal, fichajes, refreshFichajes, usuariosData }) {
    // Modal
    const { handleModal } = modal;

    // Estados
    const [fichajeSeleccionado, setFichajeSeleccionado] = useState(null)
    const [success, setSuccess] = useState(false)
    const [fichajesUsuario, setFichajesUsuario] = useState([])

    // Custom Hooks
    const { tabSelected, handleTab } = useTabs()
    const loadingFichajesUsuario = useLoading()
    const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation } = useForm(handleFichajePorUsuario, fichajePorUsuarioReglas, fichajePorUsuarioForm)
    const fichajeForm = useForm(handleFichaje, fichajePropioReglas, fichajePropioForm)

    // Usuarios y roles
    const id_usuario = JSON.parse(localStorage.getItem("userInfo"))?.id_usuario;
    const id_rol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol
    const rolesPermitidosExcepciones = [1, 16]
    const rolesComerciales = [10, 22, 24]
    const usuarioComercial = rolesComerciales.includes(id_rol) ? id_rol : usuariosData.find(item => item.id_usuario == inputValues?.usuario)?.id_rol
  
    // Fichajes del usuario logueado
    const ingreso = fichajes.find(item => item.descripcion_fichaje === "Ingreso")
    const inicio_almuerzo = fichajes.find(item => item.descripcion_fichaje === "Inicio almuerzo")
    const fin_almuerzo = fichajes.find(item => item.descripcion_fichaje === "Fin almuerzo")
    const salida = fichajes.find(item => item.descripcion_fichaje === "Fin jornada")
    const salida_especial = fichajes.find(item => item.descripcion_fichaje === "Salida especial")
    const reingreso_especial = fichajes.find(item => item.descripcion_fichaje === "Reingreso especial")
  
    // Fichajes del usuario seleccionado en la tab "Fichajes por Usuario"
    const ingreso_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario?.find(item => item.descripcion_fichaje === "Ingreso")
    const inicio_almuerzo_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Inicio almuerzo")
    const fin_almuerzo_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Fin almuerzo")
    const salida_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Fin jornada")
    const salida_especial_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Salida especial")
    const reingreso_especial_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Reingreso especial")
    
    let tabs = [
      {
        name: "Recurrentes",
      },
      {
        name: "Especiales",
      }
    ];
  
    if(rolesPermitidosExcepciones.find(id => id === id_rol)){
      tabs.push({ name: "Por usuario" })
    }

    // Index de cada tab dentro del modal
    const FICHAJES_RECURRENTES_TAB = 0
    const FICHAJES_ESPECIALES_TAB = 1
    const FICHAJES_POR_USUARIO_TAB = 2
  
    // Reiniciar estado del modal de fichajes
    const resetModal = () => {
      setSuccess(false)
      setFichajeSeleccionado(null)
      handleModal()
    }
  
    // Selección de fichaje para actualizar el estado del formulario dependiendo de la tab seleccionada
    const seleccionarFichaje = (fichaje) => {
      const FICHAJE_POR_USUARIO_TAB = 2
      if(tabSelected === FICHAJE_POR_USUARIO_TAB){
          handleChangeExternal('fichaje', fichaje)
      } else {
          fichajeForm.handleChangeExternal('fichaje', fichaje)
      }
      setFichajeSeleccionado(fichaje)
    }
  
    // Postear en base de datos fichaje realizado por usuario
    async function handleFichajePorUsuario(){
      const rol_usuario = usuariosData.find(item => item.id_usuario == inputValues.usuario)?.id_rol    
      const formattedDate = new Date(`${dayjs(inputValues.fecha).format("YYYY-MM-DD")} ${inputValues.hora}`)
  
      try {
          const dataFichaje = {
              id_usuario_fichaje: inputValues.usuario,
              descripcion_fichaje: fichajeSeleccionado,
              dia_fichaje: formattedDate,
              fichajes_dia_actual: fichajesUsuario,
              rol_usuario: rol_usuario
          }
          await axiosRequest.post(`/fichaje`, dataFichaje)
          getFichajesUsuarios()
          refreshFichajes()
          setFichajeSeleccionado(null)
          setSuccess(true)
      } catch (error) {
          console.log(error)
      }
          
    }
  
    // Postear en base de datos fichaje realizado por el usuario logueado
    async function handleFichaje() {
      try {
          const dataFichaje = {
              id_usuario_fichaje: id_usuario,
              descripcion_fichaje: fichajeSeleccionado,
              dia_fichaje: new Date(),
              fichajes_dia_actual: fichajes,
              rol_usuario: id_rol
          }
          await axiosRequest.post(`/fichaje`, dataFichaje)
          refreshFichajes()
          setFichajeSeleccionado(null)
          setSuccess(true)
      } catch (error) {
          console.log(error)
      }
    }
  
    useEffect(() => {
      setFichajeSeleccionado(null)
    }, [tabSelected])
  
    // Obtener de la base de datos los fichajes del usuario logueado
    const getFichajesUsuarios = async () => {
      if(inputValues.usuario && inputValues.fecha){
          loadingFichajesUsuario.setLoading(true)
          try {
              const fichajes = await axiosRequest.get(`/fichajes_usuario/${inputValues.usuario}/${inputValues.fecha}`)
              setFichajesUsuario(fichajes.data)
              loadingFichajesUsuario.setLoading(false)
          } catch (error) {
              console.log(error)
          }
      }
  }
  
  // Obtener fichajes del usuario cuando se modifica el usuario seleccionado o la fecha
    useEffect(() => {
      getFichajesUsuarios()
    }, [inputValues.usuario, inputValues.fecha])

    return {
        data: {
            tabs,
            tabSelected,
            success,
            fichajeSeleccionado,
            rolesComerciales,
            usuarioComercial,
            id_rol
        }, 
        fichajeForm,
        fichajeUsuarioForm: {
            inputValues, handleChange, handleChangeExternal, formErrors, handleValidation
        },
        acciones: {
            resetModal,
            seleccionarFichaje,
            handleTab
        },
        fichajes_usuario_actual: {
            ingreso,
            inicio_almuerzo,
            fin_almuerzo,
            salida,
            salida_especial,
            reingreso_especial
        },
        fichajes_por_usuario: {
            ingreso_fichaje_usuario,
            inicio_almuerzo_fichaje_usuario,
            fin_almuerzo_fichaje_usuario,
            salida_fichaje_usuario,
            salida_especial_fichaje_usuario,
            reingreso_especial_fichaje_usuario
        },
        tabs_modal: {
          FICHAJES_RECURRENTES_TAB,
          FICHAJES_ESPECIALES_TAB,
          FICHAJES_POR_USUARIO_TAB
        }
    }
}

