import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const TipoNotificacionContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 0 20px 20px 20px;

    .tipo-notificacion {
        font-size: 12px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.notification_tab_background};
        box-sizing: border-box;
        padding: 10px 16px;
        color: ${({ theme }) => theme.notification_tab_color};
        cursor: pointer;
        font-weight: 500;
        transition: all 0.5s;
    }

    .selected {
        color: ${({ theme }) => theme.notification_selected_tab_color};
        background-color: ${({ theme }) => theme.notification_selected_tab_background};
        transition: all 0.5s;
    }
`