import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiEdit2, FiCheck, FiX, FiAlertCircle } from 'react-icons/fi';

const EditableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 100%;
`;

const EditableInput = styled.input`
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #2684ff;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const EditableActions = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 4px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.confirm ? '#4CAF50' : '#f44336'};
  
  &:hover { opacity: 0.8; }
`;

const EditIcon = styled(FiEdit2)`
  cursor: pointer;
  opacity: 0.6;
  &:hover { opacity: 1; }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  background: #ff4444;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 10px;
    width: 8px;
    height: 8px;
    background: #ff4444;
    transform: rotate(45deg);
  }
`;

const ErrorIcon = styled(FiAlertCircle)`
  color: #ff4444;
  margin-right: 4px;
`;

const validateField = (field, value) => {
  switch (field) {
    case 'mail':
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return {
        isValid: emailRegex.test(value.trim()),
        value: value.trim().toLowerCase(),
        error: 'Formato de correo inválido'
      };

    case 'telefono':
      const phoneRegex = /^[0-9]{10,12}$/;
      const cleanPhone = value.replace(/\D/g, '');
      return {
        isValid: phoneRegex.test(cleanPhone),
        value: cleanPhone,
        error: 'El teléfono debe contener entre 10 y 12 dígitos'
      };

    case 'importe':
      const cleanImporte = value.replace(',', '.').replace(/[^\d.]/g, '');
      const importeRegex = /^\d+(\.\d{0,2})?$/;
      return {
        isValid: importeRegex.test(cleanImporte),
        value: cleanImporte,
        error: 'Formato de importe inválido'
      };

    default:
      return { isValid: true, value };
  }
};

const validateInputChange = (field, value) => {
  switch (field) {
    case 'mail':
      // validaciones de mail
      return /^[a-zA-Z0-9._%+-@]*$/.test(value);

    case 'telefono':
      // validaciones de telefono
      return /^\d*$/.test(value);

    case 'importe':
      // validaciones de importe
      return /^\d*\.?\d*$/.test(value);

    default:
      return true;
  }
};

const getFieldHelp = (field) => {
  switch (field) {
    case 'mail':
      return 'Ingrese un correo válido (ejemplo@dominio.com)';
    case 'telefono':
      return 'Ingrese entre 10 y 12 dígitos numéricos';
    case 'importe':
      return 'Ingrese un número con hasta 2 decimales';
    default:
      return '';
  }
};

const EditableField = ({ 
  label, 
  value, 
  field, 
  onSave, 
  canEdit = true 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [error, setError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (isEditing) {
      const validation = validateField(field, tempValue);
      setError(validation.isValid ? '' : validation.error);
    }
  }, [tempValue, field, isEditing]);

  if (!canEdit) return <span>{label}</span>;

  const handleStartEdit = () => {
    setIsEditing(true);
    setTempValue(value);
    setError('');
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (validateInputChange(field, newValue)) {
      setTempValue(newValue);
    }
  };

  const handleInputFocus = () => {
    if (error) setShowTooltip(true);
  };

  const handleInputBlur = () => {
    setShowTooltip(false);
  };

  const handleSave = async () => {
    const validation = validateField(field, tempValue);
    if (!validation.isValid) {
      setError(validation.error);
      setShowTooltip(true);
      return;
    }

    if (validation.value === value) {
      setIsEditing(false);
      return;
    }

    try {
      await onSave(field, validation.value);
      setIsEditing(false);
      setError('');
    } catch (error) {
      console.error('Error al guardar:', error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setTempValue(value);
    setError('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !error) handleSave();
    if (e.key === 'Escape') handleCancel();
  };

  return (
    <EditableWrapper>
      {isEditing ? (
        <>
          <EditableInput
            value={tempValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder={getFieldHelp(field)}
            autoFocus
          />
          {showTooltip && error && (
            <Tooltip>
              <ErrorIcon size={12} />
              {error}
            </Tooltip>
          )}
          <EditableActions>
            <ActionButton 
              confirm 
              onClick={handleSave}
              disabled={!!error}
            >
              <FiCheck size={14} />
            </ActionButton>
            <ActionButton onClick={handleCancel}>
              <FiX size={14} />
            </ActionButton>
          </EditableActions>
        </>
      ) : (
        <>
          <span>{label}</span>
          <EditIcon 
            size={14} 
            onClick={handleStartEdit}
          />
        </>
      )}
    </EditableWrapper>
  );
};

export default EditableField;