import IconButton from "../components/IconButton";
import editIcon from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import edit from "../assets/iconos/edit.svg";
import see from "../assets/iconos/see.svg";
import dayjs from "dayjs";
import ActionsButton from "../components/ActionsButtonRefactor";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { VscLock } from "react-icons/vsc";
import { DEFAULT_AVATAR, meses } from "../constants/data";
import {
  FiAlertTriangle,
  FiArrowRight,
  FiCheck,
  FiEdit,
  FiEye,
  FiFile,
  FiStar,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { companias } from "../constants/siniestros";
import { FiUser } from "react-icons/fi";
import { LOGOS_COMPANIAS } from "../constants/logos-companias";
import { HiSparkles } from "react-icons/hi";
import NewButton from "../components/NewButton";
import { RiWhatsappLine } from "react-icons/ri";
import { FiMail } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
// *** Siniestros *** //
export const siniestrosTable = (openEditModal, user, location) => {
  return [
    {
      header: "Número",
      name: "num_siniestro",
      component: (item) => {
        return (
          <td width="60px">
            {item.num_siniestro ? item.num_siniestro : "-----"}
          </td>
        );
      },
      toRender: "cover",
      size: "20px",
    },
    {
      header: "Fecha Carga",
      name: "fecha_carga_siniestro",
      component: (item) => {
        return <td width="100px">{item.fecha_carga_siniestro}</td>;
      },
      size: "40px",
    },
    {
      header: "Póliza",
      name: "poliza",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado",
      size: "120px",
      itemClassName: true,
    },
    {
      header: "Tipo",
      name: "tipo_siniestro",
      size: "140px",
    },
    {
      header: "Asegurado",
      name: "asegurado",
      component: (item) => {
        if (item.asegurado) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                {/* <span className={"userIcon"}>
                  <FiUser />
                </span> */}
                <div className={"aseguradoInfo"}>
                  <span>{item.asegurado}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni_siniestro}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "160px",
    },
    {
      header: "Ramo",
      name: "ramo",
      size: "120px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.compania}
                className={item.compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
    /*
    {
      header: "Prioridad",
      name: "prioridad",
      size: "0.8fr",
      itemClassName: true,
    },
    */
    {
      header: "Acciones",
      component: (item) => {
        return user.id_rol === 18 || user.siniestros_habilitado_usuario ? (
          <td width="8%">
            <div className="actionsContainer">
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={<FiEye color="#1a56ff" />}
                tooltipText="Ver Siniestro"
                action={() => openEditModal(item)}
              />
            </div>
          </td>
        ) : (
          <td width="8%">
            <div className="actionsContainer">
              {/*
            <NewButton backgroundColor={false} borderColor={true} textColor={true} onClick={() => openEditModal(item)}>
              Ver <FiArrowRight />
            </NewButton>
            */}
              <Link
                to={{
                  pathname: `/Siniestro/${item.id}`,
                  state: {
                    siniestro: item,
                    previousUrl: `${location.pathname}${location.search}`,
                  },
                }}
              >
                <NewButton
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                >
                  Ver <FiArrowRight />
                </NewButton>
              </Link>
              {/*
            <IconButton
              backgroundColor={false}
              borderColor={false}
              textColor={true}
              icon={<FiEdit color="#1a56ff" />}
              tooltipText="Editar Siniestro"
              action={() => openEditModal(item)}
            />
            <IconButton
              backgroundColor={false}
              borderColor={false}
              textColor={true}
              icon={<FiTrash color="#1a56ff" />}
              tooltipText="Borrar Siniestro"
              action={() => borrar(item, openDeleteModal)}
            />
            */}
            </div>
          </td>
        );
      },
      toRender: "_id",
      size: "30px",
    },
  ];
};

// *** Solicitud Emisión *** //
export const solicitudEmisionTable = (
  historialModal,
  descargarSolicitud,
  seleccionarSolicitud,
  solicitudes,
  activeButton,
  handleToggle,
  user
) => {
  let headers = [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      size: "40px",
    },

    /*
        {
            header: "Op.",
            name: "operacion",
            size: "40px"
        }, 
        */
    {
      header: "F. Ingreso",
      name: "f_ingreso",
      component: (item) => {
        return (
          <td className="operador" width={"80px"}>
            <div className={"aseguradoInfo"}>
              <span>{dayjs(item.f_ingreso).format("DD/MM/YYYY")}</span>
              <span className={"aseguradoAdditionalInfo"}>
                {dayjs(item.f_ingreso).format("HH:mm")} hs
              </span>
            </div>
          </td>
        );
      },
      size: "80px",
    },
    {
      header: "Estado",
      component: (item) => {
        const classNameEstado =
          item.operador === "Sin Asignar"
            ? `${item.estado.toLowerCase().replace(" ", "-")}-sin-asignar`
            : item.estado.toLowerCase().replaceAll(" ", "-");
        return (
          <td className="estado" width="200px">
            <div className="estado-container">
              <span className={classNameEstado}>
                {item.estado}{" "}
                {item.id_estado_previo === 3 && (
                  <span className="estado_previo_rechazado">
                    <FiAlertTriangle />
                  </span>
                )}
              </span>
              {item.prioridad == 1 ? (
                <span className="prioridad">
                  Prioridad <HiSparkles />
                </span>
              ) : null}
            </div>
          </td>
        );
      },
      name: "estado",
      size: "50px",
      itemClassName: true,
    },
    {
      header: "Vendedor",
      name: "vendedor",
      component: (item) => {
        return (
          <td className="operador" width={"160px"}>
            <span className={"vendedor_operador"}>
              <img
                className="avatar_small"
                src={
                  item.avatar_vendedor ? item.avatar_vendedor : DEFAULT_AVATAR
                }
                alt="Avatar del usuario"
              />
              <div className={"aseguradoInfo"}>
                <span>{item.vendedor}</span>
              </div>
            </span>
          </td>
        );
      },
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Operador",
      component: (item) => {
        return (
          <td className="operador" width={"160px"}>
            <span className={"vendedor_operador"}>
              {item.operador !== "Sin Asignar" ? (
                <img
                  className="avatar_small"
                  src={
                    item.avatar_operador ? item.avatar_operador : DEFAULT_AVATAR
                  }
                  alt="Avatar del usuario"
                />
              ) : null}
              <div className={"aseguradoInfo"}>
                <span>
                  {item.operador === "Sin Asignar" ? "-" : item.operador}
                </span>
              </div>
            </span>
          </td>
        );
      },
      name: "operador",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Cliente",
      name: "cliente",
      component: (item) => {
        if (item.cliente) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                {/* <span className={"userIcon"}>
                  <FiUser />
                </span> */}
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Producto",
      component: (item) => {
        if (item.producto) {
          return (
            <td width="100px" className="producto">
              {item.producto}
            </td>
          );
        }

        return <td>-</td>;
      },
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "140px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.compania}
                className={item.compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
    {
      header: "",
      component: (item, index) => {
        const itemsActionButton = [
          {
            text: "Ver",
            type: "link",
            path: `/Emision/${item.id}`,
          },
          {
            text: "Historial",
            type: "action",
            action: () => historialModal(item),
          },
          /*
          {
            text: "Descargar",
            type: "action",
            action: () => descargarSolicitud(item.id),
          },
          */
        ];

        return (
          <td width="5%">
            <ActionsButton
              items={itemsActionButton}
              active={activeButton}
              handleToggle={handleToggle}
              index={index}
            />
          </td>
        );
      },
      size: "50px",
    },
  ];

  if (
    user.id_rol === 1 ||
    user.id_rol === 6 ||
    user.id_rol === 13 ||
    user.id_rol === 7 ||
    user.id_rol === 14
  ) {
    headers.unshift({
      header: "",
      component: (item) => {
        const checked = solicitudes.find(
          (solicitud) => solicitud.id === item.id
        );
        return (
          <td width={"30px"}>
            <input
              type="checkbox"
              onClick={(e) => seleccionarSolicitud(e, item)}
              checked={checked}
            />
          </td>
        );
      },
    });
  }

  return headers;
};

// *** Emisiones *** //
export const emisionesTable = (
  historialModal,
  descargarSolicitud,
  activeButton,
  handleToggle
) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Op.",
      name: "operacion",
      size: "70px",
    },
    {
      header: "F. Emisión",
      name: "f_emision",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Vendedor",
      name: "vendedor",
      component: (item) => {
        return (
          <td className="operador" width={"160px"}>
            <span className={"vendedor_operador"}>
              <img
                className="avatar_small"
                src={
                  item.avatar_vendedor ? item.avatar_vendedor : DEFAULT_AVATAR
                }
                alt="Avatar del usuario"
              />
              <div className={"aseguradoInfo"}>
                <span>{item.vendedor}</span>
              </div>
            </span>
          </td>
        );
      },
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Operador",
      component: (item) => {
        return (
          <td className="operador" width={"160px"}>
            <span className={"vendedor_operador"}>
              {item.operador !== "Sin Asignar" ? (
                <img
                  className="avatar_small"
                  src={
                    item.avatar_operador ? item.avatar_operador : DEFAULT_AVATAR
                  }
                  alt="Avatar del usuario"
                />
              ) : null}
              <div className={"aseguradoInfo"}>
                <span>
                  {item.operador === "Sin Asignar" ? "-" : item.operador}
                </span>
              </div>
            </span>
          </td>
        );
      },
      name: "operador",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Cliente",
      component: (item) => {
        if (item.cliente) {
          return (
            <td className="operador" width="200px">
              <span className={"asegurado"}>
                {/* <span className={"userIcon"}>
                  <FiUser />
                </span> */}
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td className="operador"></td>;
      },
      name: "cliente",
      size: "100px",
    },
    {
      header: "Producto",
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "120px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        const compania = LOGOS_COMPANIAS.find(
          (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
        );
        return (
          <td width={"120px"} className="compania">
            <img
              src={compania?.url}
              alt={item.compania}
              className={item.compania.toLowerCase().replaceAll(" ", "_")}
            />
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "",
      component: (item, index) => {
        const itemsActionButton = [
          {
            text: "Ver",
            type: "link",
            path: `/Emision/${item.id}`,
          },
          {
            text: "Historial",
            type: "action",
            action: () => historialModal(item),
          },
          /*
          {
            text: "Descargar",
            type: "action",
            action: () => descargarSolicitud(item.id),
          },
          */
        ];

        return (
          <td width="5%">
            <ActionsButton
              items={itemsActionButton}
              index={index}
              active={activeButton}
              handleToggle={handleToggle}
            />
          </td>
        );
      },
      size: "50px",
    },
  ];
};

// *** Historial de Solicitudes *** //
export const historialSolicitudesTable = (historialModal) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      size: "40px",
    },
    {
      header: "Op.",
      name: "operacion",
      size: "60px",
    },
    {
      header: "F. Ingreso",
      name: "f_ingreso",
      size: "100px",
    },
    {
      header: "Estado",
      component: (item) => {
        const classNameEstado =
          item.operador === "Sin Asignar"
            ? `${item.estado.toLowerCase().replace(" ", "-")}-sin-asignar`
            : item.estado.toLowerCase();
        return (
          <td className="estado" width="200px">
            <div className="estado-container">
              <span className={classNameEstado}>
                {item.estado}{" "}
                {item.id_estado_previo === 3 && (
                  <span className="estado_previo_rechazado">
                    <FiAlertTriangle />
                  </span>
                )}
              </span>
              {item.prioridad == 1 ? (
                <span className="prioridad">
                  Prioridad <HiSparkles />
                </span>
              ) : null}
            </div>
          </td>
        );
      },
      name: "estado",
      size: "50px",
      itemClassName: true,
    },
    {
      header: "Operador",
      component: (item) => {
        return (
          <td className="operador" width={"180px"}>
            <span className={"vendedor_operador"}>
              {item.operador !== "Sin Asignar" ? (
                <img
                  className="avatar_small"
                  src={
                    item.avatar_operador ? item.avatar_operador : DEFAULT_AVATAR
                  }
                  alt="Avatar del usuario"
                />
              ) : null}
              <div className={"aseguradoInfo"}>
                <span>
                  {item.operador === "Sin Asignar" ? "-" : item.operador}
                </span>
              </div>
            </span>
          </td>
        );
      },
      name: "operador",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Cliente",
      name: "cliente",
      component: (item) => {
        return (
          <td className="cliente" width="200px">
            <span className={"asegurado"}>
              <div className={"aseguradoInfo"}>
                <span>{item.cliente}</span>
                <span className={"aseguradoAdditionalInfo"}>
                  DNI: {item.dni}
                </span>
              </div>
            </span>
          </td>
        );
      },
      size: "100px",
    },

    {
      header: "Producto",
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "120px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        const compania = LOGOS_COMPANIAS.find(
          (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
        );
        return (
          <td width={"120px"} className="compania">
            <img
              src={compania?.url}
              alt={item.compania}
              className={item.compania.toLowerCase().replaceAll(" ", "_")}
            />
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "Acciones",
      component: (item) => {
        if (item.estado === "Rechazado") {
          return (
            <td width="5%">
              <div className="actionsContainer">
                <IconButton
                  backgroundColor={false}
                  borderColor={false}
                  textColor={true}
                  icon={<FiEye color="#1a56ff" />}
                  tooltipText="Ver Historial"
                  action={() => historialModal(item)}
                />
                <Link
                  to={{
                    //pathname: `/EditarSolicitudEmision/${item.id}`,
                    /*
                    pathname: `/EditarSolicitudEmision/${item.id}`,
                    state: { data: item.id },
                    search: `?tipoSolicitud=${item.id_tipo_solicitud}`,
                    */
                    pathname: `/Emision/${item.id}`,
                  }}
                >
                  <IconButton
                    backgroundColor={false}
                    borderColor={false}
                    textColor={true}
                    icon={<FiFile color="#1a56ff" />}
                    tooltipText="Ver Solicitud"
                  />
                </Link>
              </div>
            </td>
          );
        } else {
          return (
            <td width="5%">
              <div className="actionsContainer">
                <IconButton
                  backgroundColor={false}
                  borderColor={false}
                  textColor={true}
                  icon={<FiEye color="#1a56ff" />}
                  tooltipText="Ver Historial"
                  action={() => historialModal(item)}
                />
                <Link
                  to={{
                    /*
                    pathname: `/EditarSolicitudEmision/${item.id}`,
                    state: { data: item.id },
                    search: `?tipoSolicitud=${item.id_tipo_solicitud}`,
                    */
                    pathname: `/Emision/${item.id}`,
                  }}
                >
                  <IconButton
                    backgroundColor={false}
                    borderColor={false}
                    textColor={true}
                    icon={<FiFile color="#1a56ff" />}
                    tooltipText="Ver Solicitud"
                  />
                </Link>
              </div>
            </td>
          );
        }
      },
      name: "acciones",
      size: "50px",
    },
  ];
};

// *** Estadísticas Producto Canal *** //
export const productoCanalTable = (mesAnterior) => {
  return [
    {
      header: "Canal",
      component: (item) => {
        return (
          <td>
            <span>{item.canal}</span>
          </td>
        );
      },
      name: "canal",
      toRender: "cover",
      size: "2fr",
    },
    {
      header: `No Gestionados ${mesAnterior}`,
      component: (item) => {
        return (
          <td>
            <span>
              {
                item[
                  `no_gestionados_${
                    mesAnterior !== "" && mesAnterior.toLowerCase()
                  }`
                ]
              }
            </span>
          </td>
        );
      },
      name: `no_gestionados_${mesAnterior !== "" && mesAnterior.toLowerCase()}`,
      size: "1.6fr",
    },
    {
      header: "Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.leads}</span>
          </td>
        );
      },
      name: "leads",
      size: "1fr",
    },
    {
      header: "Proyectado Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.proyectado_leads}</span>
          </td>
        );
      },
      name: "proyectado_leads",
      size: "1.4fr",
      itemClassName: true,
    },
    {
      header: "Operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.operaciones}</span>
          </td>
        );
      },
      name: "operaciones",
      size: "1.4fr",
    },
    {
      header: "Proyectado Operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.proyectado_operaciones}</span>
          </td>
        );
      },
      name: "proyectado_operaciones",
      size: "1.4fr",
    },
    {
      header: "Emisiones",
      component: (item) => {
        return (
          <td>
            <span>{item.emisiones}</span>
          </td>
        );
      },
      name: "emisiones",
      size: "1.2fr",
    },
    {
      header: "Cierre",
      component: (item) => {
        return (
          <td>
            <span>{item.cierre}</span>
          </td>
        );
      },
      name: "cierre",
      size: "1fr",
    },
    {
      header: "Obj. Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.objetivos_leads}</span>
          </td>
        );
      },
      name: "objetivos_leads",
      size: "1.2fr",
    },
    {
      header: "Obj. Op.",
      component: (item) => {
        return (
          <td>
            <span>{item.objetivos_operaciones}</span>
          </td>
        );
      },
      name: "objetivos_operaciones",
      size: "1fr",
    },
    {
      header: "Conversión",
      component: (item) => {
        return (
          <td>
            <span>{item.conversion}</span>
          </td>
        );
      },
      name: "conversion",
      size: "1fr",
    },
  ];
};

// *** Usuarios *** //
export const usuariosTable = (editarUsuario, location) => {
  return [
    {
      header: "Id",
      name: "id_operador",
      toRender: "cover",
      component: (item) => {
        return (
          <td width="100px">
            <span>{item.id_operador}</span>
          </td>
        );
      },
      size: "100px",
      itemClassName: true,
    },

    {
      header: `Nombre`,
      name: "nombre_operador",
      component: (item) => {
        if (item.nombre_operador) {
          return (
            <td className="cliente" width="450px">
              <span className={"asegurado"}>
                <img
                  className="avatar"
                  src={
                    item.avatar_usuario ? item.avatar_usuario : DEFAULT_AVATAR
                  }
                  alt="Avatar del usuario"
                />
                <div className={"aseguradoInfo"}>
                  <span>
                    {item.nombre_operador} {item.apellido_operador}
                  </span>
                  <span className={"aseguradoAdditionalInfo"}>
                    {item.usuario_usuario}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      /*
      component: (item) => {
        return (
          <td>
            <span>
              {item.nombre_operador} {item.apellido_operador}
            </span>
          </td>
        );
      },
      */
      size: "1.5fr",
      itemClassName: true,
    },
    /*
    {
      header: "Usuario",
      name: "usuario_usuario",
      component: (item) => {
        return (
          <td>
            <span>{item.usuario_usuario}</span>
          </td>
        );
      },
      size: "2fr",
      itemClassName: true,
    },
    */
    {
      header: "Rol",
      name: "titulo_rol",
      component: (item) => {
        return (
          <td width="350px">
            <span>{item.titulo_rol}</span>
          </td>
        );
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Estado",
      name: "estado_usuario",
      component: (item) => {
        return (
          <td width="250px">
            <span className={item.estado_usuario ? "activo" : "inactivo"}>
              {item.estado_usuario ? "Activo" : "Inactivo"}
            </span>
          </td>
        );
      },
      itemClassName: true,
      size: "1fr",
    },
    {
      header: "Acciones",
      component: (item) => {
        console.log(item);
        return (
          <td width="8%">
            <div className="actionsContainer">
              <Link
                to={{
                  pathname: `/Usuarios/Editar/${item.id_operador}`,
                  state: {
                    previousUrl: `${location.pathname}${location.search}`,
                  },
                }}
              >
                <NewButton
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  onClick={() => editarUsuario(item.id_operador)}
                >
                  Ver <FiArrowRight />
                </NewButton>
              </Link>
              {/*}
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={edit}
                action={() => editarUsuario(item.id_operador)}
                tooltipText="Editar Usuario"
              />
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={<VscLock />}
                action={() => nuevaClave(item)}
                tooltipText="Generar clave"
              />
              */}
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "80px",
    },
  ];
};

// *** Autogestion *** //
export const autogestionTable = (location) => {
  return [
    {
      header: "Nro. Op.",
      name: "numero",
      toRender: "cover",
      component: (item) => {
        return (
          <td width="80px">
            <span>{item.numero}</span>
          </td>
        );
      },
      size: "0.8fr",
      itemClassName: true,
    },

    {
      header: "Nombre",
      name: "nombre",
      component: (item) => {
        if (item.cliente.nombre) {
          return (
            <td className="cliente" width="250px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.cliente.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Producto",
      name: "producto",
      component: (item) => {
        return (
          <td width="180px">
            <span>
              {item.servicio.producto.charAt(0).toUpperCase() +
                item.servicio.producto.slice(1).replaceAll("_", " ")}
            </span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Fecha",
      name: "fecha",
      component: (item) => {
        console.log(item);
        return (
          <td width="100px">
            <span>
              {item.fecha} {item.hora}hs
            </span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Teléfono",
      name: "telefono",
      component: (item) => {
        return (
          <td width="120px">
            <span>{item.cliente.telefono}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Email",
      name: "email",
      component: (item) => {
        return (
          <td width="250px">
            <span>{item.cliente.email}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Acciones",
      component: (item) => {
        return (
          <td>
            <div className="actionsContainer">
              <Link
                to={{
                  pathname: `/Autogestion/${item.id_operacion}`,
                  state: {
                    contratacion: item,
                    previousUrl: `${location.pathname}${location.search}`,
                  },
                }}
              >
                <NewButton
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                >
                  Ver <FiArrowRight />
                </NewButton>
              </Link>

              {/*
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={see}
                action={() => abrirModalPago(item)}
                tooltipText="Ver contratación"
              />
              */}
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "70px",
    },
  ];
};

// *** Objetivos Leads *** //
export const objetivosLeadsTable = (editarObjetivoLead, borrarObjetivoLead) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      component: (item) => {
        return (
          <td>
            <span>{item.id}</span>
          </td>
        );
      },
      size: "0.8fr",
      itemClassName: true,
    },
    {
      header: `Año`,
      name: "anio",
      component: (item) => {
        return (
          <td>
            <span>{item.anio}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Mes",
      name: "mes",
      component: (item) => {
        const monthName = meses.find(
          (mes) => parseInt(mes.value) + 1 === item.mes
        );
        return (
          <td>
            <span>{monthName?.label}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Canal",
      name: "canal",
      component: (item) => {
        return (
          <td>
            <span>{item.canal}</span>
          </td>
        );
      },
      size: "1.4fr",
      itemClassName: true,
    },
    {
      header: "Producto",
      name: "producto",
      component: (item) => {
        return (
          <td>
            <span>{item.producto}</span>
          </td>
        );
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Leads",
      name: "leads",
      component: (item) => {
        return (
          <td>
            <span>{item.leads}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Operaciones",
      name: "operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.operaciones}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Acciones",
      component: (item) => {
        return (
          <td>
            <div className="actionsContainer">
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={edit}
                action={() => editarObjetivoLead.openEditModal(item)}
                tooltipText="Editar objetivo"
              />
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={deleteIcon}
                action={() => borrarObjetivoLead.openDeleteModal(item)}
                tooltipText="Borrar objetivo"
              />
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "70px",
    },
  ];
};

// *** Emisiones *** //
export const premiosVendedoresTable = () => {
  return [
    {
      header: "Vendedor",
      name: "vendedor",
      toRender: "cover",
      size: "200px",
    },
    {
      header: "Cant. Op.",
      name: "cantidaddeoperaciones",
      size: "110px",
    },
    {
      header: "Competencias",
      name: "competencias",
      size: "120px",
    },
    {
      header: "Op.",
      name: "operaciones",
      size: "80px",
    },
    {
      header: "Grande",
      name: "grande",
      size: "100px",
    },
    {
      header: "Camión",
      name: "camion",
      size: "100px",
    },
    {
      header: "Cross / Ref. Auto",
      name: "referidoscrosselingautos",
      size: "120px",
    },
    {
      header: "Cross / Ref. Moto",
      name: "referidoscrossellingmotos",
      size: "120px",
    },
    {
      header: "Hogar",
      name: "hogar",
      size: "100px",
    },
    {
      header: "General Grupal",
      name: "generalgrupal",
      size: "100px",
    },
    {
      header: "Puntos",
      name: "sistemadepuntos",
      size: "100px",
    },
    {
      header: "Mes pasado",
      name: "superarmespasado",
      size: "120px",
    },
    {
      header: "Tarjeta crédito",
      name: "tarjetacredito",
      size: "120px",
    },
    {
      header: "Breakfast",
      name: "breakfast",
      size: "120px",
    },
    {
      header: "Acciones",
      component: (vendedor) => {
        return (
          <Link
            to={{
              pathname: `/Premio/Vendedor/${vendedor.id_vendedor}`,
              state: { nombre: vendedor.vendedor, data: vendedor },
            }}
          >
            <NewButton
              backgroundColor={false}
              textColor={true}
              borderColor={true}
            >
              Ver <FiArrowRight />
            </NewButton>
          </Link>
        );
      },
      size: "120px",
    },
  ];
};

// *** Emisiones *** //
export const objetivosVendedores = (location) => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Vendedor",
      name: "nombre",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>Vendedor</span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Mes",
      name: "mes",
      component: (item) => {
        console.log(item.mes);
        console.log(meses);
        const month = meses.find(
          (mes) => parseInt(mes.value) === parseInt(item.mes) - 1
        )?.label;
        return <td width="100px">{month}</td>;
      },
      size: "100px",
    },
    {
      header: "Operaciones",
      name: "operaciones",
      size: "100px",
    },
    {
      header: "Prima",
      name: "prima",
      component: (item) => {
        return (
          <td width="100px">${formatter.format(Math.round(item.prima))}</td>
        );
      },
      size: "100px",
    },
    {
      header: "Grupo",
      name: "grupo",
      size: "100px",
    },
    {
      header: "Acciones",
      name: "acciones",
      component: (item) => {
        return (
          <td width="100px">
            {" "}
            <Link
              to={{
                pathname: `/Premio/Vendedor/${item.id_vendedor}`,
                state: { nombre: item.nombre, previousUrl: `${location.pathname}${location.search}` },
              }}
            >
              <NewButton
                backgroundColor={false}
                textColor={true}
                borderColor={true}
              >
                Ver <FiArrowRight />
              </NewButton>
            </Link>
          </td>
        );
      },
      size: "100px",
    },
  ];
};

// *** Usuarios *** //
export const operacionesVendedorTable = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Operación",
      name: "numero",
      size: "50px",
    },
    {
      header: "Cliente",
      name: "nombre",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>Cliente</span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Prima Bruta",
      name: "prima_bruta",
      component: (item) => {
        return (
          <td width="100px">
            ${formatter.format(Math.round(item.prima_bruta))}
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "Prima Neta",
      name: "prima_neta",
      component: (item) => {
        return (
          <td width="100px">
            ${formatter.format(Math.round(item.prima_neta))}
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "Forma de pago",
      name: "forma_pago",
      size: "100px",
    },
    {
      header: "Est. Novedades",
      name: "estado",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado_ac",
      size: "100px",
    },
    {
      header: "Poliza",
      name: "poliza",
      size: "100px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.compania}
                className={item.compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
  ];
};

// *** Rechazos *** //
export const rechazosTable = (
  seleccionarRechazo,
  rechazosSeleccionados,
  seleccionarTodos,
  activeButton,
  handleToggle,
  handleHistorial
) => {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return [
    {
      header: () => {
        return (
          <input
            type="checkbox"
            onClick={seleccionarTodos}
            //checked={checked}
          />
        );
      },
      component: (item) => {
        const checked = rechazosSeleccionados.find(
          (rechazo) => rechazo.id === item.id
        );
        return (
          <td width={"30px"}>
            <input
              type="checkbox"
              onClick={(e) => seleccionarRechazo(e, item)}
              checked={checked}
              // solo se pueden seleccionar rechazos pendientes o notificados
              disabled={!(item.id_estado === 8 || item.id_estado === 11)}
            />
          </td>
        );
      },
    },
    {
      header: "Id",
      name: "id",
      component: (item) => {
        return <td width="60px">{item.id}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Ingreso",
      name: "createdAt",
      component: (item) => {
        return <td width="100px">{item.createdAt}</td>;
      },
      size: "50px",
    },
    {
      header: "Estado",
      name: "estado",
      component: (item) => {
        return (
          <td width="120px">
            <span
              className={item.Rejects_estado.estado
                .toLowerCase()
                .replaceAll(" ", "-")}
            >
              {item.arrastre === 1 && (
                <span className="arrastre">
                  {" "}
                  <FiArrowLeft /> ARRASTRE{" "}
                </span>
              )}
              {item.Rejects_estado.estado}
            </span>
          </td>
        );
      },
      size: "120px",
      itemClassName: true,
    },
    {
      header: "V. Notificado",
      name: "veces_notificado",
      component: (item) => {
        return (
          <td width="180px">
            <span className={"veces_notificado"}>
              {item.veces_notificado +
                item.veces_notificado_whatsapp +
                item.veces_notificado_telefono}
              {item.veces_notificado > 0 && <FiMail />}
              {item.veces_notificado_whatsapp > 0 && <RiWhatsappLine />}
              {item.veces_notificado_telefono > 0 && <FiPhone />}
            </span>
          </td>
        );
      },
      size: "120px",
      itemClassName: true,
    },
    {
      header: "Últ. Modificación",
      name: "updatedAt",
      component: (item) => {
        return <td width="150px">{item.updatedAt}</td>;
      },
      size: "50px",
    },
    {
      header: "Asegurado",
      name: "asegurado",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="170px">
              <span className={"asegurado"}>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }
        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Póliza",
      name: "poliza",
      component: (item) => {
        return <td width="100px">{item.poliza}</td>;
      },
      size: "70px",
    },
    {
      header: "Teléfono",
      name: "telefono",
      component: (item) => {
        if (item.telefono) {
          const speeches = {
            0: `¡Hola ${item.nombre}! Nos comunicamos desde SeguroWeb. Queremos decirte que la última cuota de tu póliza ${item.poliza} de la Compañía ${item.compania}, está impaga y se encuentra sin cobertura. El importe es de $${item.importe}. Sabemos que estar protegido es muy importante para vos. Para poder regularizar tu seguro responde este mensaje. Estamos para ayudarte.`,
            1: `¡Hola ${
              item.nombre
            }! Queremos recordarte que la última cuota del mes de ${
              meses[parseInt(item.createdAt.split("/")[1]) - 1]
            } de tu póliza ${item.poliza} de la Compañía ${
              item.compania
            } ¡Está impaga y se encuentra sin cobertura! El importe es de $${
              item.importe
            } ¡Es muy importante que abones para que tu bien esté asegurado! Para poder regularizar tu seguro respondé este mensaje. Estamos para ayudarte.`,
            2: `¡Hola ${item.nombre}! Te recordamos que tu póliza ${item.poliza} continúa manteniendo un saldo pendiente de pago y se encuentra sin cobertura. Te sugerimos abonarlo para evitar la cancelación de tu póliza y así recuperes la protección. Queremos ayudarte a que te sea fácil abonarlo. Sí tenés alguna dificultad ¿Querés que te llamemos?`,
          };
          const text = `&text=${speeches[item.veces_notificado_whatsapp]}`;
          return (
            <td width="180px">
              <a
                id="boton-wpp"
                href={`https://api.whatsapp.com/send?phone=${item.telefono}${text}`}
                target="_blank"
                rel="noreferrer"
                className="whatsapp-telefono"
              >
                <RiWhatsappLine />
                {item.telefono}
              </a>
            </td>
          );
        }
      },
      size: "70px",
    },
    {
      header: "Ramo",
      name: "ramo",
      component: (item) => {
        if (item.ramo) return <td width="150px">{item.ramo}</td>;
      },
      size: "70px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.compania}
                className={item.compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }
        return <td>-</td>;
      },
      size: "120px",
    },
    {
      header: "",
      component: (item, index) => {
        const itemsActionButton = [
          {
            text: "Ver",
            type: "link",
            path: `/Rechazo/${item.id}`,
          },
          {
            text: "Historial",
            type: "action",
            action: () => handleHistorial(item.id),
          },
        ];
        return (
          <td width="5%">
            <ActionsButton
              items={itemsActionButton}
              active={activeButton}
              handleToggle={handleToggle}
              index={index}
            />
          </td>
        );
      },
      size: "50px",
    },
  ];
};

// *** Rechazos *** //
export const cotizadorLandingsTable = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Id",
      name: "id_producto",
      component: (item) => {
        return <td width="150px">{item.id_producto}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Producto",
      name: "descripcion",
      component: (item) => {
        return <td width="300px">{item.descripcion}</td>;
      },
    },
    {
      header: "Límite",
      name: "limite_cotizador",
      component: (item) => {
        console.log(item);
        return (
          <td width="300px">${formatter.format(item.limite_cotizador)}</td>
        );
      },
      itemClassName: true,
    },
  ];
};

// *** Rechazos *** //
export const limitesLandingsTable = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Id",
      name: "id_producto",
      component: (item) => {
        return <td width="60px">{item.id_producto}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.nombre) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.nombre.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.nombre}
                className={item.nombre.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }
        return <td>-</td>;
      },
      size: "120px",
    },
    {
      header: "Producto",
      name: "descripcion",
      component: (item) => {
        return <td width="120px">{item.descripcion}</td>;
      },
    },
    {
      header: "Límite",
      name: "limite",
      component: (item) => {
        return <td width="120px">${formatter.format(item.limite)}</td>;
      },
      size: "120px",
      itemClassName: true,
    },
  ];
};

// *** Rechazos *** //
export const nuevasPolizasTable = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "#",
      name: "#",
      component: (item) => {
        return <td width="60px">{item["#"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Asoc",
      name: "Asoc",
      component: (item) => {
        return <td width="100px">{item["Asoc"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Operación",
      name: "Operación",
      component: (item) => {
        return <td width="100px">{item["Operación"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Póliza",
      name: "Póliza",
      component: (item) => {
        return <td width="100px">{item["Póliza"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Prima",
      name: "Prima",
      component: (item) => {
        return <td width="150px">AR$ {formatter.format(item["Prima"])}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Sección",
      name: "Sección",
      component: (item) => {
        return <td width="200px">{item["Sección"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Tipo endoso",
      name: "Tipo endoso",
      component: (item) => {
        return <td width="200px">{item["Tipo endoso"]}</td>;
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Anulada",
      name: "Anulada",
      component: (item) => {
        return (
          <td width="80px">
            {item["Anulada"] === "N" ? (
              <FiX color="#ff2121" size="20px"></FiX>
            ) : (
              <FiCheck color="#3d9f54" size="20px"></FiCheck>
            )}
          </td>
        );
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Vigente",
      name: "Vigente",
      component: (item) => {
        return (
          <td width="80px">
            {item["Vigente"] === 0 ? (
              <FiX color="#ff2121" size="20px"></FiX>
            ) : (
              <FiCheck color="#3d9f54" size="20px"></FiCheck>
            )}
          </td>
        );
      },
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Compañia",
      name: "Compañia",
      component: (item) => {
        if (item["Compañia"]) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) =>
              logo.nombre.toLowerCase() === item["Compañia"].toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item["Compañia"]}
                className={item["Compañia"].toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }
        return <td>-</td>;
      },
      size: "120px",
    },
  ];
};

// *** Comisiones *** //
export const comisionesVendedorTable = (tipoVendedor, teletrabajoActivo) => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  let columns = null

  if(tipoVendedor === 3) { 
    columns = [
      {
        header: "Vendedor",
        name: "vendedor",
        component: (item) => {
          return <td className="spaced" width={"500px"}>{item.vendedor}</td>;
        },
        toRender: "cover",
      },
      {
        header: "Prima",
        name: "prima",
        component: (item) => {
          return <td className="spaced" width={"500px"}>{item.prima}</td>;
        },
        toRender: "cover",
        description: 'Prima realizada en el mes'
      },
      {
        header: "Anulaciones",
        name: "anulaciones",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.anulaciones}</td>;
        },
        toRender: "cover",
        description: 'Total anulaciones del mes'
      },
      {
        header: "% Tentativo",
        name: "tentativo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.tentativo}</td>;
        },
        toRender: "cover",
        description: '8.5% Tentativo de Anulaciones'
      },
      {
        header: "%",
        name: "porcentaje",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.porcentaje}</td>;
        },
        toRender: "cover",
        description: '% de la prima bruta (excluyendo los canales cross, ref, anu, ret y rec y productos salud y mascotas'
      },
      {
        header: "Comisiones",
        name: "comisiones",
        component: (item) => {
          return <td className="spaced total_comisiones" width={"200px"}>{item.comisiones}</td>;
        },
        toRender: "cover",
        description: 'Resultado de sueldo bruto mas premios e Incentivo'
      }
    ];
  } else {
    columns = [
      {
        header: "Vendedor",
        name: "vendedor",
        component: (item) => {
          return <td className="spaced" width={"500px"}>{item.vendedor}</td>;
        },
        toRender: "cover",
      },
      {
        header: "Prima Bruta",
        name: "prima_bruta",
        component: (item) => {
          return <td className="spaced" width={"500px"}>{item.prima_bruta}</td>;
        },
        toRender: "cover",
        description: 'Prima realizada en el mes excluyendo canales Cross, Ref, Anu, Ret y Rec y producto Salud y Mascotas'
      },
      {
        header: "Anulaciones",
        name: "anulaciones",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.anulaciones}</td>;
        },
        toRender: "cover",
        description: 'Total anulaciones del mes'
      },
      {
        header: "% Tentativo",
        name: "tentativo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.tentativo}</td>;
        },
        toRender: "cover",
        description: '8.5% Tentativo de Anulaciones'
      },
      {
        header: "%",
        name: "porcentaje",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.porcentaje * 100}</td>;
        },
        toRender: "cover",
        description: '% de la prima bruta (excluyendo los canales cross, ref, anu, ret y rec y productos salud y mascotas'
      },
      {
        header: "Sueldo Bruto",
        name: "sueldo_bruto",
        component: (item) => {
          return <td className="spaced" width={"400px"}>{item.sueldo_bruto}</td>;
        },
        toRender: "cover",
        description: 'Producto entre Prima Bruta y % de comisiones, menos tentativo de anulaciones'
      },
      {
        header: "Premio Cross Prima",
        name: "prima_cross",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_cross}</td>;
        },
        toRender: "cover",
        description: '7% de la prima Cross'
      },
      {
        header: "Premio Cross Total",
        name: "total_cross",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_cross}</td>;
        },
        toRender: "cover",
        description: '7% de la prima Cross'
      },
      {
        header: "Premio Cross Operaciones",
        name: "operaciones_cross",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.operaciones_cross}</td>;
        },
        toRender: "cover",
        description: '+10 ops Cross, $200 c/u'
      },
      {
        header: "Premio Cross Operaciones Total",
        name: "total_operaciones_cross",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_operaciones_cross ?? 0}</td>;
        },
        toRender: "cover",
        description: '+10 ops Cross, $200 c/u'
      },
      {
        header: "Premio Ref Prima",
        name: "prima_ref",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_ref}</td>;
        },
        toRender: "cover",
        description: '7% de la prima Referidos'
  
      },
      {
        header: "Premio Ref Total",
        name: "total_ref",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_ref}</td>;
        },
        toRender: "cover",
        description: '7% de la prima Referidos'
      },
      {
        header: "Retiro Prima",
        name: "prima_retiro",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_salud ?? 0}</td>;
        },
        toRender: "cover",
        description: '7% de la prima de Retiro, excluyendo canales Cross y Ref'
      },
      {
        header: "Retiro Total",
        name: "total_retiro",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_salud ?? 0}</td>;
        },
        toRender: "cover",
        description: '7% de la prima de Retiro, excluyendo canales Cross y Ref'
      },
      {
        header: "Mascotas Prima",
        name: "prima_mascotas",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_mascotas}</td>;
        },
        toRender: "cover",
        description: '9% de la prima de Mascotas, excluyendo canales Cross y Ref'
      },
      {
        header: "Mascotas Total",
        name: "total_mascotas",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_mascotas}</td>;
        },
        toRender: "cover",
        description: '9% de la prima de Mascotas, excluyendo canales Cross y Ref'
      },
      {
        header: "Anu, Ret y Rec Prima",
        name: "prima_anu_ret_rec",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_anu_ret_rec}</td>;
        },
        toRender: "cover",
        description: '1% de la prima de Anulaciones, Retencion y Recupero, excluyendo Salud y Mascotas'
      },
      {
        header: "Anu, Ret y Rec Total",
        name: "total_anu_ret_rec",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_anu_ret_rec}</td>;
        },
        toRender: "cover",
        description: '1% de la prima de Anulaciones, Retencion y Recupero, excluyendo Salud y Mascotas'
      },
      {
        header: "Incentivo",
        name: "incentivo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.incentivo}</td>;
        },
        toRender: "cover",
        description: '+1% de comision si cumple 80 datos verdes con  25 ventas'
      },
      {
        header: "Incentivo 2 Prima",
        name: "prima_incentivo_dos",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_incentivo_dos}</td>;
        },
        toRender: "cover",
        description: 'Prima emitida desde 3M a 3,9M: 1.5% | 4M a 4,9M: 2% | 5M a 5,9M: 2.5% | 6M a 6,9M: 2.6% | 7M a 7,9M: 2.7% | 8M+: 2.8%'
      },
      {
        header: "Incentivo 2 Total",
        name: "total_incentivo_dos",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_incentivo_dos}</td>;
        },
        toRender: "cover",
        description: 'Prima emitida desde 3M a 3,9M: 1.5% | 4M a 4,9M: 2% | 5M a 5,9M: 2.5% | 6M a 6,9M: 2.6% | 7M a 7,9M: 2.7% | 8M+: 2.8%'
      },
      {
        header: "Premio Fijo Operaciones",
        name: "operaciones_premio_fijo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.operaciones_premio_fijo}</td>;
        },
        toRender: "cover",
        description: 'Mes 1: +20op $30.000 | Mes 2: +25op $35.000 | Mes 3: +30op $40.000 | Mes 4: +35op $25.000'
      },
      {
        header: "Premio Fijo Total",
        name: "total_premio_fijo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_premio_fijo}</td>;
        },
        toRender: "cover",
        description: 'Mes 1: +20op $30.000 | Mes 2: +25op $35.000 | Mes 3: +30op $40.000 | Mes 4: +35op $25.000'
      },
      {
        header: "Comisiones",
        name: "comisiones",
        component: (item) => {
          return <td className="spaced total_comisiones" width={"200px"}>{item.comisiones}</td>;
        },
        toRender: "cover",
        description: 'Resultado de sueldo bruto mas premios e Incentivo'
      },

    ];
  }

  if(teletrabajoActivo){
    columns.push(
      {
        header: "Teletrabajo Prima",
        name: "prima_teletrabajo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.prima_teletrabajo}</td>;
        },
        toRender: "cover",
        description: '1% de la suma del sueldo bruto, la prima de cross, ref, salud, mascotas, anu, ret y rec'
      },
      {
        header: "Teletrabajo Total",
        name: "total_teletrabajo",
        component: (item) => {
          return <td className="spaced" width={"200px"}>{item.total_teletrabajo}</td>;
        },
        toRender: "cover",
        description: '1% de la suma del sueldo bruto, la prima de cross, ref, salud, mascotas, anu, ret y rec'
      },
    )
  }

  return columns
};

// *** Comisiones *** //
export const operacionesComisionesVendedorTable = (mes, anio) => {
  const formatter = new Intl.NumberFormat("es-ES", {});
  const diaActual = dayjs(new Date()).format("DD/MM/YYYY")
  const primerDia = `01/${mes}/${anio}`
  const diasMes = new Date(anio, parseInt(mes) + 1, 0).getDate();
  const ultimoDia = `${diasMes}/${mes}/${anio}`
  const mesNombre = meses.find(month => month.value == mes)?.label
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  
  const columns = [
    {
      header: "Producto",
      name: "nombre_producto",
      component: (item) => {
        return <td width={"300px"}>{item.nombre_producto}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Tipo",
      name: "nombre_canal",
      component: (item) => {
        return <td width={"300px"}>{item.nombre_canal}</td>;
      },
      toRender: "cover",
    },
    {
      header: `Totales Cantidad - ${mesNombre}`,
      name: "operaciones",
      component: (item) => {
        return <td width={"400px"}>{item.operaciones}</td>;
      },
      toRender: "cover",
    },
    {
      header: `Totales Prima - ${mesNombre}`,
      name: "prima",
      component: (item) => {
        return <td width={"300px"}>{formatter.format(item.prima)}</td>;
      },
      toRender: "cover",
    }
  ];

  if(mes == currentMonth && anio == currentYear){
    columns.push(
      {
        header: `Cantidad - ${diaActual}`,
        name: "operaciones_dia",
        component: (item) => {
          return <td width={"300px"}>{item.operaciones_dia ?? 0}</td>;
        },
        toRender: "cover",
      },
      {
        header: `Prima - ${diaActual}`,
        name: "prima_dia",
        component: (item) => {
          return <td width={"300px"}>{item.prima_dia ? formatter.format(item.prima_dia) : 0}</td>;
        },
        toRender: "cover",
      },
    )
  }

  return columns
};

// *** Comisiones *** //
export const anulacionesComisionesVendedorTable = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Operación",
      name: "idOpe",
      component: (item) => {
        return <td width={"200px"}>{item.idOpe}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Fecha",
      name: "fecha",
      component: (item) => {
        return <td width={"250px"}>{dayjs(item.fecha).format("DD/MM/YYYY")}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Nombre",
      name: "nombre",
      component: (item) => {
        return <td width={"800px"}>{item.nombre}</td>;
      },
      toRender: "cover",
    },
    {
      header: "DNI",
      name: "dni",
      component: (item) => {
        return <td width={"200px"}>{item.dni}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Sección",
      name: "seccion",
      component: (item) => {
        return <td width={"200px"}>{item.seccion}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Póliza",
      name: "poliza",
      component: (item) => {
        return <td width={"400px"}>{item.poliza}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Forma de Pago",
      name: "forma_pago",
      component: (item) => {
        return <td width={"600px"}>{item.forma_pago}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Días Vigente",
      name: "dias_vigencia",
      component: (item) => {
        return <td width={"400px"}>{item.dias_vigencia}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Prima",
      name: "prima",
      component: (item) => {
        return <td width={"400px"}>{item.prima !== "-" && item.prima != 0 ? formatter.format(item.prima) : "-"}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px" className="compania">
              <img
                src={compania?.url}
                alt={item.compania}
                className={item.compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
  ];
};

// *** Control Calidad Anulaciones *** //
export const controlCalidadAnulacionesTable = (seleccionarAnulacion, anulacionesSeleccionadas, location) => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "",
      component: (item) => {
        const checked = anulacionesSeleccionadas.find((anulacion) => anulacion.anulacion.id_anulacion_calidad === item.id_anulacion_calidad);
        return (
          <td width={"50px"}>
            <input
              type="checkbox"
              onClick={(e) => seleccionarAnulacion(e, item)}
              checked={checked}
            />
          </td>
        );
      },
    },
    {
      header: "ID",
      name: "id_anulacion_calidad",
      component: (item) => {
        return <td width={"80px"}>{item.anulacion.id_anulacion_calidad}</td>;
      },
      toRender: "cover",
    },
    {
      header: "F. Ingreso",
      name: "fecha_ingreso",
      component: (item) => {
        return (
          <td className="operador" width={"200px"}>
            <div className={"aseguradoInfo"}>
              <span>{dayjs(item.anulacion.fecha_ingreso).format("DD/MM/YYYY")}</span>
              <span className={"aseguradoAdditionalInfo"}>
                {dayjs(item.anulacion.fecha_ingreso).format("HH:mm")} hs
              </span>
            </div>
          </td>
        );
      },
    },
    {
      header: "Estado",
      component: (item) => {
        const classNameEstado =
          item.anulacion.operador === "Sin Asignar"
            ? `${item.anulacion.estado.toLowerCase().replace(" ", "-")}-sin-asignar`
            : item.anulacion.estado.toLowerCase().replaceAll(" ", "-");
        return (
          <td className="estado" width="200px">
            <div className="estado-container">
              <span className={classNameEstado}>
                {item.anulacion.estado}{" "}
              </span>
            </div>
          </td>
        );
      },
      name: "estado",
      size: "50px",
      itemClassName: true,
    },
    {
      header: "Operador",
      name: "nombre_operador",
      component: (item) => {
        return (
          <td className="operador" width={"250px"}>
            <span className={"vendedor_operador"}>
              <div className={"aseguradoInfo"}>
              {/* <img
                className="avatar_small"
                src={
                  DEFAULT_AVATAR
                }
                alt="Avatar del usuario"
              /> */}
                <span>
                  {item.anulacion.nombre_operador} {item.anulacion.apellido_operador}
                </span>
              </div>
            </span>
          </td>
        );
      },
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Asegurado",
      name: "nombre_asegurado",
      component: (item) => {
        if (item.anulacion.nombre_asegurado) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                {/* <span className={"userIcon"}>
                  <FiUser />
                </span> */}
                <div className={"aseguradoInfo"}>
                  <span>{item.anulacion.nombre_asegurado} {item.anulacion.apellido_asegurado}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.anulacion.dni_asegurado}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Producto",
      name: "nombre_producto",
      component: (item) => {
        return <td width={"200px"}>{item.anulacion.nombre_producto}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Operacion",
      name: "id_operacion_novedades",
      component: (item) => {
        return <td width={"200px"}>{item.anulacion.id_operacion_novedades}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Motivo",
      name: "nombre_motivo_anulacion",
      component: (item) => {
        return <td width={"200px"}>{item.anulacion.nombre_motivo_anulacion}</td>;
      },
      toRender: "cover",
    },
    {
      header: "Subido hace",
      component: (item) => {
        const date1 = dayjs(item.anulacion.fecha_ingreso)
        const date2 = dayjs(new Date())
        let difference = Math.round(date2.diff(date1, 'hours', true))
        let prioridadClase = ''
        if(difference === 0){
            prioridadClase = 'prioridad_baja'
            const differenceMinutos = Math.round(date2.diff(date1, 'minutes', true))
            difference = `${differenceMinutos} ${difference === 1 ? 'minuto' : 'minutos'}`
        } else {
          if(difference < 12){
            prioridadClase = 'prioridad_baja'
            difference = `${difference} ${difference === 1 ? 'hora' : 'horas'}`
          } else if(difference >= 12 && difference < 24){
            prioridadClase = 'prioridad_media'
            difference = `${difference} horas`
          } else if(difference >= 24){
            prioridadClase = 'prioridad_alta'
            const differenceDias = Math.round(date2.diff(date1, 'days', true))
            difference = `${differenceDias} ${differenceDias === 1 ? 'día' : 'días'}`
          }
        }
        return <td width={"200px"}><span className="subido_hace_container"><div className={`subido_hace ${prioridadClase}`}></div> {difference}</span></td>;
      },
      toRender: "cover",
    },
    {
      header: "Compañia",
      name: "nombre_compania",
      component: (item) => {
        if (item.anulacion.nombre_compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.anulacion.nombre_compania.toLowerCase()
          );
          return (
            <td width="160px" className="compania">
              <img
                src={compania?.url}
                alt={item.anulacion.nombre_compania}
                className={item.anulacion.nombre_compania.toLowerCase().replaceAll(" ", "_")}
              />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
    {
      header: "Acciones",
      component: (item) => {
        return (
          <td width="8%">
            <div className="actionsContainer">
              <Link
                to={{
                  pathname: `/ControlCalidad/Anulacion/${item.anulacion.id_anulacion_calidad}`,
                  state: {
                    previousUrl: `${location.pathname}${location.search}`,
                    anulacion: item.anulacion,
                    producto: item.producto
                  },
                }}
              >
                <NewButton
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                >
                  Ver <FiArrowRight />
                </NewButton>
              </Link>
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "80px",
    },
  ];
};
