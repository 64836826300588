import { DivApp, EmisionData, ModalContainerEmision } from "../../styled.js";
import {
  Grid,
  Col
} from "../../../components/Index/index.js";
import Sidebar from "../../../components/Sidebar/index.js";
import { useHistory } from "react-router-dom";
import SpinnerLoading from "../../../components/SpinnerLoading/index.js";
import Estado from "../../../components/Estado/index.js";
import { Container, ContainerEstados, ContainerFile, ContainerHistorial, EmptyDocumentacion, ModificacionHistorial, Prioridad } from "./styled.js";
import {
  FiUser,
  FiArrowLeft,
  FiX,
  FiZap,
  FiXOctagon,
  FiMail,
} from "react-icons/fi";
import NewButton from "../../../components/NewButton/index.jsx";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewTitle from "../../../components/NewTitle/index.jsx";
import { useEffect } from "react";
import useRechazoV2 from "../../../hooks/useRechazosV2.js";
import EmisionSeccion from "../../../components/EmisionSeccion";
import { useTabs } from "../../../hooks/useTabs.js";
import ModalGestionarRechazo from "../../../components/Modals/Rechazos/ModalGestionarRechazos/index.js";
import ModalAsignarRechazo from "../../../components/Modals/Rechazos/ModalAsignarRechazos/index.js";
import useModal from "../../../hooks/useModal.js";
import { getEstados, asignOperador,getOperadores, updateRejectField } from "../../../services/Rechazos.js";
import { useState } from "react";
import dayjs from "dayjs";
import { meses } from "../../../constants/data.js";
import Divider from "../../../components/Divider/index.js";
import HeaderTitleSection from "../../../components/HeaderTitleSection/index.js";
import MediosContactoSeccion from "../../../components/MediosContactoSeccion/index.js";
import ModalNotificar from "../../../components/Modals/Rechazos/ModalNotificar/index.js";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";

import NewWrapper from "../../../components/NewWrapper/index.js";
import NewInner from "../../../components/NewInner/index.js";
import Layout from "../../../components/Layout/index.js";
import BackButton from "../../../components/BackButton/index.js";
import NewTitlePageSection from "../../../components/NewTitlePageSection/index.js";
import EditableField from '../../../components/EditableField';

const validateField = (field, value) => {
  switch (field) {
    case 'mail':
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return {
        isValid: emailRegex.test(value.trim()),
        value: value.trim().toLowerCase(),
        error: 'Formato de correo inválido'
      };

    case 'telefono':
      const phoneRegex = /^[0-9]{10,12}$/;
      const cleanPhone = value.replace(/\D/g, '');
      return {
        isValid: phoneRegex.test(cleanPhone),
        value: cleanPhone,
        error: 'El teléfono debe contener entre 10 y 12 dígitos'
      };

    case 'importe':
      const cleanImporte = value.replace(',', '.').replace(/[^\d.]/g, '');
      const importeRegex = /^\d+(\.\d{0,2})?$/;
      return {
        isValid: importeRegex.test(cleanImporte),
        value: cleanImporte,
        error: 'Formato de importe inválido'
      };

    default:
      return { isValid: true, value };
  }
};

const RechazoV2 = () => {
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const { tabSelected, tabName, handleTab } = useTabs();
  // Hook de router
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  console.error('user',userInfo)
  //const { data, loading, pagination } = useEmisiones(params, order)
  const { rechazo, historial, loading, sendOneMail, getHistorial, getRechazo, success, handleSuccess, selectOperadores } = useRechazoV2()
  const [estados, setEstados] = useState([])
  const modal = useModal()
  const modalNotificar = useModal()
  const modalAsignar = useModal()
  const [operadoresBack, setOperadoresBack] = useState([])

  const speeches = {
    0: `¡Hola ${rechazo?.nombre}! Nos comunicamos desde SeguroWeb. Queremos decirte que la última cuota de tu póliza ${rechazo?.poliza} de la Compañía ${rechazo?.compania}, está impaga y se encuentra sin cobertura. El importe es de $${rechazo?.importe}. Sabemos que estar protegido es muy importante para vos. Para poder regularizar tu seguro responde este mensaje. Estamos para ayudarte.`,
    1: `¡Hola ${rechazo?.nombre}! Queremos recordarte que la última cuota del mes de ${meses[parseInt(rechazo?.createdAt.split('/')[1])-1]} de tu póliza ${rechazo?.poliza} de la Compañía ${rechazo?.compania} ¡Está impaga y se encuentra sin cobertura! El importe es de $${rechazo?.importe} ¡Es muy importante que abones para que tu bien esté asegurado! Para poder regularizar tu seguro respondé este mensaje. Estamos para ayudarte.`,
    2: `¡Hola ${rechazo?.nombre}! Te recordamos que tu póliza ${rechazo?.poliza} continúa manteniendo un saldo pendiente de pago y se encuentra sin cobertura. Te sugerimos abonarlo para evitar la cancelación de tu póliza y así recuperes la protección. Queremos ayudarte a que te sea fácil abonarlo. Sí tenés alguna dificultad ¿Querés que te llamemos?`
  }
  const textWhatsApp = `&text=${speeches[rechazo?.veces_notificado_whatsapp]}`

  useEffect(() => {
    const init = async () => {
      try {
        const getEstadosRechazos = async () => {
          try {
            const estados = await getEstados()
            setEstados(estados)
          } catch (error) {
            console.error('Error al obtener estados:', error)
          }
        }
        await getEstadosRechazos()
        
        const ops = await getOperadores();
        if (ops) {
          setOperadoresBack(ops)
        }
      } catch (error) {
        console.error('Error en inicialización:', error)
      }
    }
    init()
  }, [])

  const userLabels = {
    nombre: "Nombre",
    dni: "DNI",
  }

  // Roles que pueden editar
  const rolesEdicion = [5, 23, 4, 1];
  const estadosQueEditan = [4, 8, 7, 9, 10, 11, 12] //todos excepto: cerrado negativo, negativo derivado o cerrado positivo
  const estadosQueGestionan = [12, 11, 8] //promesa, notificado, pendiente
  // Se toma en cuenta rolesEdicion y estadosQueEditan para verificar si el usuario puede modificar
  const puedeEditar = rolesEdicion.includes(parseInt(userInfo?.id_rol)) && 
                      estadosQueEditan.includes(rechazo?.id_estado);

  const [editingField, setEditingField] = useState(null);
  const [tempValue, setTempValue] = useState('');

  const handleStartEdit = (field, value) => {
    setEditingField(field);
    setTempValue(value);
  };

  const handleSave = async (field) => {
    try {
      if (!rechazo?.id) {
        console.error('No hay ID de rechazo disponible');
        return;
      }

      const validation = validateField(field, tempValue);
      if (!validation.isValid) {
        alert(validation.error);
        return;
      }

      // Si el valor es igual al actual, cerrar la edición sin hacer cambios (osea sin guardar historial)
      if (validation.value === rechazo[field]) {
        setEditingField(null);
        setTempValue('');
        return;
      }

      const response = await updateRejectField(
        rechazo.id,
        field,
        validation.value,
        userInfo?.id_usuario
      );

      if (response.type === 'success') {
        await getRechazo();
        setEditingField(null);
        setTempValue('');
      } else {
        console.error('Error al guardar:', response.msg);
      }
    } catch (error) {
      console.error('Error al guardar:', error);
    }
  };

  const handleCancel = () => {
    setEditingField(null);
    setTempValue('');
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') handleSave(field);
    if (e.key === 'Escape') handleCancel();
  };

  const renderEditableField = (label, value, field) => {
    return (
      <EditableField
        label={label}
        value={value}
        field={field}
        canEdit={puedeEditar}
        onSave={async (field, value) => {
          try {
            const response = await updateRejectField(
              rechazo.id,
              field,
              value,
              userInfo?.id_usuario
            );

            if (response.type === 'success') {
              // Actualiza tanto el rechazo como el historial
              await Promise.all([
                getRechazo(),
                getHistorial()
              ]);
            } else {
              console.error('Error al guardar:', response.msg);
            }
          } catch (error) {
            console.error('Error al guardar:', error);
          }
        }}
      />
    );
  };

  const contactoLabels = {
    mail: renderEditableField("Correo", rechazo?.mail, "mail"),
    telefono: renderEditableField("Teléfono", rechazo?.telefono, "telefono")
  };

  const rechazoLabels = {
    id: "Número",
    createdAt: "Fecha de Ingreso",
    updatedAt: "Ú. modificación", 
    compania: "Compañía",
    poliza: "Póliza",
    seccion: "Ramo",
    medio_pago: "Medio de Pago",
    importe: renderEditableField("Importe", rechazo?.importe, "importe")
  };

  const handleBack = () => history.goBack();

  let tabs = [
    {
      name: "Rechazo",
    },
    {
      name: "Historial",
    }
  ];

  const handleNotificarModal = () => {
    modalNotificar.setIsModalOpen(true)
    sendOneMail()
  }

  const formatTitle = () => {
    if (!rechazo) return 'Cargando...';
    return `Rechazo - ${rechazo.seccion || 'Sin sección'} - ${rechazo.nombre || 'Sin nombre'}`;
  };

  return (
    <>
      <ModalAsignarRechazo modal={modalAsignar} rechazo={rechazo} getHistorial={getHistorial} getRechazo={getRechazo} selectOperadores={selectOperadores} userInfo={userInfo} asignOperador={asignOperador} />
      <ModalGestionarRechazo modal={modal} estados={estados} rechazo={rechazo} getHistorial={getHistorial} getRechazo={getRechazo} />
      <ModalNotificar modal={modalNotificar} loading={loading} success={success} handleSuccess={handleSuccess} />
      
      <Layout>
      <NewWrapper>
        <NewInner>
        {/* <ModalContainerEmision> */}
        <Container>
          {!loading ? (
            <>
            <div>
              <BackButton url="/Rechazos2" text="Cobranzas" />
            {rechazo?.veces_notificado + rechazo?.veces_notificado_telefono + rechazo?.veces_notificado_whatsapp > 0 &&
                <Col desktop={12}>
                  <Prioridad>Veces Notificado: { rechazo?.veces_notificado + rechazo?.veces_notificado_telefono + rechazo?.veces_notificado_whatsapp }</Prioridad>
                </Col>
            }
             {/* <Col desktop={12}>
                  <ContainerEstados>
                    <div>
                    <span className="header-estado">
                      Rechazo: {rechazo?.id}
                    </span>
                    <span>
                      ・ Estado del Rechazo:{" "}
                      <Estado
                        estado={rechazo?.Rejects_estado.estado}
                        operador={null}
                        previo={null}
                      />
                    </span>
                    {rechazo?.arrastre === 1 &&
                        <span className={"arrastre"}>
                          <span className="text_arrastre"> ARRASTRE </span>
                        </span>
                    }
                    </div>
                    {
                      rechazo?.id_operador_asignado 
                      &&
                      <div className={`asignacion ${rechazo?.id_operador_asignado === userInfo.id_usuario ? 'to_me':null}`}>
                      {
                        rechazo?.id_operador_asignado === userInfo.id_usuario ?
                        <span>Asignado a mi: {userInfo.nombre_operador} </span>
                        :
                        <span>Asignado a {operadoresBack?.filter(o => parseInt(o.key) == parseInt(rechazo?.id_operador_asignado))[0]?.label}</span>
                      }
                    </div>}
                  </ContainerEstados>
                </Col> */}
                <Col desktop={12}>
                <NewTitlePageSection
                      title={formatTitle()}
                      description={`Número de rechazo: ${rechazo?.id || ''}`}
                    ></NewTitlePageSection>
                </Col>
              </div>
              <div>
              <HeaderTitleSection compania={rechazo?.compania} estado={rechazo?.Rejects_estado.estado} arrastre={rechazo?.arrastre === 1} asignado={operadoresBack?.filter(o => parseInt(o.key) == parseInt(rechazo?.id_operador_asignado))[0]?.label} />

              </div>
             <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {rechazo && tabSelected === 0 ?
                <>
                  <EmisionSeccion
                    title="Datos del Cliente"
                    data={rechazo}
                    label={userLabels}
                    icon={<FiUser />}
                  />
                  <Divider />
                  <MediosContactoSeccion
                    title="Datos de contacto"
                    data={rechazo}
                    label={contactoLabels}
                    icon={<FiMail />}
                    speech={textWhatsApp}
                  />
                  <Divider />
                  <EmisionSeccion
                    title="Datos del Rechazo"
                    data={rechazo}
                    label={rechazoLabels}
                    icon={<FiXOctagon />}
                  />
                </>
              : null}

              {tabSelected === 1 ?
                <ContainerHistorial>
                {historial.length > 0 && historial.map((item, index) => {
                  /*
                  const mesModificacion = dayjs(item.createdAt).format("M");
                  const mesModificacionAnterior =
                  index > 0 ? dayjs(historial[index - 1].createdAt).format("M") : "";
                  */

                  const diaModificacion = dayjs(item.createdAt).format("DD");
                  const diaModificacionAnterior =
                    index > 0 ? dayjs(historial[index - 1].createdAt).format("DD") : "";

                  return (
                    <>
                    {/*
                    {mesModificacion !== mesModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {
                        meses.find(
                          (mes) => mes.value === parseInt(mesModificacion - 1)
                        )?.label
                      }
                    </h4>
                  ) : null}
                   */}
                  {diaModificacion != diaModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {dayjs(item.createdAt).format("dddd D MMMM, YYYY")}
                    </h4>
                  ) : null}
                    <ModificacionHistorial>
                    <div className="icono">
                      <span>
                        {item.mailgun_status === "opened" && item.id_user === 66 ? <HiOutlineMailOpen color="#3d9f54" /> : null}
                        {item.mailgun_status === "failed" && item.id_user === 66 ? <FiX color="red" /> : null}
                        {!item.mailgun_status && item.id_user ? <FiUser /> : null}
                      </span>
                    </div>
                    <div className="modificacion">
                      <div className="modificacion-titulo">
                        {item.mailgun_status ? 
                          <span className="notificacion-sin-comentario">{item.comentario}</span>
                        :
                        <span>
                          <strong>{item.nombre_usuario} </strong> 
                          modificó el estado a <strong className={`estado ${item.estado.toLowerCase().replaceAll(" ", "-")}`}> {item.estado}</strong>. 
                          {item.via_contacto ? <> Comunicación con el asegurado mediante <strong className="whatsapp">{item.via_contacto.toLowerCase().replaceAll("_", " ")}</strong>. </> : null} 
                          
                          {item.veces_notificado ? <> El asegurado fue notificado {item.veces_notificado > 1 ? `${item.veces_notificado} veces.`  : `${item.veces_notificado} vez.`} </> : null}
                        </span>
                        }

                      </div>
                      {item.comentario && !item.mailgun_status ?
                      <div className="calculo-container">
                        <span>{item.comentario}</span>
                      </div>
                      : null}
                      <div>
                        <span className="modificacion-hora">
                          Modificación realizada a las{" "}
                          {dayjs(item.createdAt).format("HH:mm")}hs
                        </span>
                      </div>
                    </div>
                  </ModificacionHistorial>
                  {index + 1 !== historial.length ? <Divider /> : null}
                  </>
                  )
                })}
              </ContainerHistorial>
              : null}

              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <EmisionData apiDisabled={false}>
                    <div className={"actionButtonsCont"}>
                    <NewButton
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        direction={"before"}
                        onClick={handleBack}
                      >
                      <FiArrowLeft /> Volver
                    </NewButton>

                    <div className={"buttonsCont"}>
                    {
                        ( estadosQueGestionan.includes(rechazo?.id_estado))
                        &&
                        <>
                        <NewButton
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                          icon={false}
                          onClick={handleNotificarModal}
                        >
                          <FiZap /> Notificar
                        </NewButton>
                        <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={modal.handleModal}
                        >
                          <FiZap /> Gestionar rechazo
                        </NewButton>
                        <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={modalAsignar.handleModal}
                        >
                          <FiUserCheck /> Asignar
                        </NewButton>
                        </>
                        
                      }
                      </div>
                    </div>
                  </EmisionData>
                </Col>
              </Grid>
            </>
          ) : (
            <SpinnerLoading text="Cargando rechazo..." />
          )}
        </Container>
      {/* </ModalContainerEmision> */}
        </NewInner>
      </NewWrapper>
      </Layout>


    </>
  );
};

export default RechazoV2;