import { useState } from "react";
import useModal from "./useModal";
import dayjs from "dayjs";
import axiosRequest from "../utils/axiosConfig";
import { useLoading } from "./useLoading";
import { useSocket } from "../context/SocketProvider";

export const useAsignacionControlCalidad = (refreshData) => {

  const socket = useSocket()
  const [anulacionesSeleccionadas, setAnulacionesSeleccionadas] = useState([])
  const [anulacionesAsignadas, setAnulacionesAsignadas] = useState(false)
  const [operadorId, setOperadorId] = useState(null)
  const { isModalOpen, handleModal } = useModal()
  const { loading, setLoading } = useLoading()

  const handleChangeOperador = (value) => {
    setOperadorId(value)
  }

  const seleccionarAnulacion = (event, item) => {
    const { checked } = event.target
    if(checked){
        setAnulacionesSeleccionadas(prev => [...prev, item])
    } else {
        setAnulacionesSeleccionadas(prev => prev.filter(anulacion => anulacion.id_anulacion_calidad !== item.anulacion.id_anulacion_calidad))
    }
  }

  const removerAnulacion = (id) => {
    setAnulacionesSeleccionadas(prev => prev.filter(solicitud => solicitud.id !== id))
  }

  const asignarAnulacion = async (exitoModal) => {
      setLoading(true)

      const operadores = await axiosRequest.get('/operadores')

      const assing = anulacionesSeleccionadas.map(async (item) => {
        await axiosRequest.put(`/control_calidad/anulaciones/asignar/${item.anulacion.id_anulacion_calidad}`, { id_operador_calidad: parseInt(operadorId.value) })

        const operador = operadores.data.find(operador => operador.id_operador === parseInt(operadorId.value))
        const notificacion = {
          url_pagina: `/ControlCalidad`,
          nombre_icono: "calidad",
          nombre_pagina: "Control Calidad",
          notificacion: `Te asignaron una anulación: ID: ${item.anulacion.id_anulacion_calidad} - Fecha de ingreso: ${dayjs(item.anulacion.fecha_ingreso).format("DD/MM/YYYY")} - Asegurado: ${item.anulacion.nombre_asegurado} ${item.anulacion.apellido_asegurado} - DNI: ${item.anulacion.dni_asegurado} - Compañia: ${item.anulacion.nombre_compania}`,
          time: dayjs(new Date()).format("hh:mm"),
          id_usuario: operador.id_usuario,
        }
        socket.emit('notificacion-crm-emision', notificacion)
        await axiosRequest.post('/notificacion/crear', notificacion)
      })

      Promise.all(assing).then(() => {
        handleModal()
        exitoModal.setIsModalOpen(true)
        exitoModal.handleModalMessage("¡Anulaciones asignadas!")
        setAnulacionesSeleccionadas([])
        setLoading(false)
        setAnulacionesAsignadas(true)
        setOperadorId(null)
        
        refreshData()

        setTimeout(() => {
            exitoModal.setIsModalOpen(false)
            setAnulacionesAsignadas(false)
        }, 2000);
      });
  }

  return {
    modal: {
        isModalOpen,
        handleModal,
    },
    seleccionarAnulacion,
    anulacionesSeleccionadas,
    removerAnulacion,
    asignarAnulacion,
    handleChangeOperador,
    loading,
    anulacionesAsignadas,
    operadorId
  };
}