import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Boton = styled.a`
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  min-height: ${(props) => props.tamaño == "chico" && "37px"};
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 300px;
  font-family: 'DM Sans', sans-serif;

  margin-right: 8px;

  background-color: ${(props) =>
    props['data-backgroundcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)};
  border: 1px solid
    ${(props) => (props['data-bordercolor'] ? leerColor(colores.celeste) : "none")};
  color: ${(props) =>
    props['data-textcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)};
  text-decoration: none;
  pointer-events: ${(props) => (props['data-disabled'] ? "none" : "")};
  font-size: ${(props) => props.tamaño == "chico" && "12px"} !important;

  ${(props) =>
    props['data-disabled'] === true && props['data-backgroundcolor'] &&
    css`
      & {
        background-color: ${leerColor(colores.gris)};
      }
    `}

    ${(props) =>
    props.icon &&
    props.direction === "before" &&
    css`
      &::before {
        content: url(${(props) => props.icon});
        padding-right: 10px;
      }
    `}


  ${(props) =>
    props.icon &&
    props.direction === "after" &&
    css`
      &::after {
        content: url(${(props) => props.icon});
        padding-left: 10px;
      }
    `}
    
  
    ${(props) =>
    props['data-disabled'] === true && props.icon && !props['data-backgroundcolor'] &&
    css`
      & {
        color: ${leerColor(colores.gris)};

        &::before {
          content: url(${(props) => props.icon})
        }

        &::after {
          content: ''
        }
      }
    `}

  :hover {
    opacity: 0.9;
  }




    // Ícono
    span {
      margin: 4px 8px 0px 0px;
      font-size: ${(props) => (props.tamaño == "chico" ? "15px" : "16px")};
    }

`;
