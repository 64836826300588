import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";
import { NACIONALIDADES, PROFESIONES, ESTADO_CIVIL } from "../constants/lasegunda";
import orderAlphabetically from "../utils/alphabeticalOrder";

// --------------- PÁGINA SOLICITUD DE EMISIÓN --------------- //

// Get solicitud
export const getAnulaciones = async (filterParams, orderParams, page, userParams) => {
  const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
  const anulacionesData = await axiosRequest.get(`/control_calidad/anulaciones?${pageFilter}${filterParams}&${orderParams}&${userParams}`)
  return anulacionesData.data
}

// Get solicitud
export const getMotivosAnulaciones = async () => {
  const motivos = await axiosRequest.get(`/control_calidad/motivos`);
  const motivosList = formatSelectData(
      motivos.data,
      "id_motivo_anulacion",
      "nombre_motivo_anulacion",
      "id_motivo_anulacion"
    );
  return motivosList
}

// Get solicitud
export const getProductosCalidad = async () => {
  const productos = await axiosRequest.get(`/control_calidad/productos`);
  const productosList = formatSelectData(
      productos.data,
      "numero",
      "descripcion",
      "numero"
    );
  return productosList
}
