import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/*
@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Bold.woff2') format('woff2'),
      url('/fonts/Raleway-Bold.woff') format('woff');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-SemiBold.woff2') format('woff2'),
      url('/fonts/Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-Regular.woff2') format('woff2'),
      url('/fonts/Raleway-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
*/

html {
  font-family: 'DM Sans', sans-serif;
  background-color: ${({ theme }) => theme.background};
  transition: all 0.5s;
}

body {
  font-family: 'DM Sans', sans-serif;
}

* {

  a, button {
     
    font-weight: 600;
    font-size: 14px;
    cursor: pointer !important; 
    transition: 0.2s all;
  }

}


`;

export default GlobalStyle;
