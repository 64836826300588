// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import ModalHistorial from "../../../components/Modals/SolicitudEmision/ModalHistorial";
import TitlePageSection from "../../../components/TitlePageSection";
import NotificacionPagina from "../../../components/NotificacionPagina";
// Custom Hooks
import useHistorialSolicitudes from "../../../hooks/useHistorialSolicitudes";
import useFiltersNew from "../../../hooks/useFiltersNew";
import useHistorialNew from "../../../hooks/useHistorialNew";
import { useTable } from "../../../hooks/useTable";
import { useNotificacionesPage } from "../../../hooks/useNotificaciones";
// Filters
import { filtersHistorialSolicitudes } from "../../../utils/filtersPages";
import { filterHistorialSolicitudesInitialValues } from "../../../utils/filtersPages";
// Table
import { historialSolicitudesTable } from "../../../utils/tableData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// Icons
import { FiFilter, FiPlus, FiX } from "react-icons/fi";
import NewButton from "../../../components/NewButton";
import NewFiltersSection from "../../../components/NewFiltersSection";
import NewTable from "../../../components/NewTable";
import { useState } from "react";
import { Container } from "./styled";
import { useEffect } from "react";
import CardStats from "../../../components/CardStats";
import Divider from "../../../components/Divider";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";

const HistorialSolicitudesRefactor = () => {
  const [filtersApplied, setFiltersApplied] = useState([]);

  // Modales
  const {
    historial,
    isModalOpen,
    handleModal,
    handleModalHistorial,
    solicitudData,
  } = useHistorialNew();

  const { inputValues, params, handleSearch, handleChange, handleChangeExternal, clearSearch } =
    useFiltersNew(filterHistorialSolicitudesInitialValues);

  const { order, handleTableOrder } = useTable();

  const { data, loading, refreshSolicitudesEmision, pagination } =
    useHistorialSolicitudes(params, order);
  const filters = filtersHistorialSolicitudes(data);

  const tableData = historialSolicitudesTable(handleModalHistorial);

  // Notificaciones
  const { notificaciones, resetNotificaciones, mensaje } =
    useNotificacionesPage(
      ["rechazo-solicitud", "solicitud-revertida", "solicitud-emitida"],
      refreshSolicitudesEmision
    );

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (params) {
      let filterArr = [];
      if (params.includes("&")) {
        const filters = params.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        filterArr.push(filtersDos);
        const decodeFilters = filtersDos.map(item => {
          return [item[0], decodeURI(item[1])]
        })
        setFiltersApplied(decodeFilters);
      } else {
        const filters = params.split("=");
        const arr = [filters[0], decodeURI(filters[1])]
        filterArr.push(arr);
        setFiltersApplied([arr]);
      }
    }
  }, [params]);

  const removeFilter = (e, filterName) => {
    handleChangeExternal(e, filterName, '')
  }

  return (
    <>
      {/* <Sidebar active="historialSolicitudes" /> */}

      <ModalHistorial
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModal}
        historial={historial}
        solicitudData={solicitudData}
      />

      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <NewTitlePageSection
                    title="Historial solicitudes"
                    description="Lista de tus solicitudes de emisión creadas"
                  >
                    <NewButton
                      backgroundColor={false}
                      textColor={true}
                      borderColor={true}
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      <FiFilter /> Filtros
                      {params?.split("=").length > 1 ? (
                        <span className="filtros-aplicados">
                          {params?.split("=").length - 1}
                        </span>
                      ) : null}
                    </NewButton>
                    <Link to={{ pathname: `/CargarSolicitudEmision` }}>
                      <NewButton
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        icon={false}
                      >
                        <span>
                          <FiPlus />
                        </span>
                        Crear solicitud
                      </NewButton>
                    </Link>
                  </NewTitlePageSection>
                </Col>

                {showFilters ? (
                  <>
                    <Col desktop={12}>
                      <NewFiltersSection
                        section="solicitudes"
                        inputs={filters}
                        values={inputValues}
                        params={params}
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        clearSearch={clearSearch}
                      />
                    </Col>

                    <Col desktop={12}>
                      {notificaciones.length > 0 && (
                        <NotificacionPagina
                          mensaje={mensaje}
                          resetNotificaciones={resetNotificaciones}
                        />
                      )}
                    </Col>
                  </>
                ) : null}
              </Grid>

              <Divider />

              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={2}>
                  <CardStats title={"Nuevas solicitudes"} value={data.estadisticas?.nuevas} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 1)} filterText="nuevas"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Anulaciones"} value={data.estadisticas?.anuladas} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 2)} filterText="anuladas" />
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Endoso"} value={data.estadisticas?.endosos} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 3)} filterText="endosos"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Pendientes"} value={data.estadisticas?.pendientes} filter={(e) => handleChangeExternal(e, "estado", "Pendiente inspección")} filterText="pendientes"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Rechazadas"} value={data.estadisticas?.rechazadas} filter={(e) => handleChangeExternal(e, "estado", "Rechazado")} filterText="rechazadas"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Prioritarias"} value={data.estadisticas?.prioritarias} filter={(e) => handleChangeExternal(e, "prioridad", 1)} filterText="prioritarias"/>
                </Col>
              </Grid>

              {filtersApplied.length > 0 && params && data.solicitudes
                ? 
                <div className="filtros-aplicados-container">
                  <span>{pagination.dataLength} resultados encontrados con los siguientes filtros: </span>
                  <div>
                    {filtersApplied.map((item) => {
                        const tiposSolicitud = { 1: "Nueva solicitud", 2: "Anulación por reemplazo", 3: "Endoso" }
                        const nombreFiltro = item[0] 
                        const valorFiltro = item[0] === "tipo_solicitud" ? tiposSolicitud[item[1]] : item[1]
                        return (
                          <span className="filtro-aplicado" onClick={(e) => removeFilter(e, item[0])}>
                            {nombreFiltro.charAt(0).toUpperCase() + nombreFiltro.slice(1).replace("_", " ")}: {valorFiltro} <FiX/>
                          </span>
                        );
                    })}
                  </div>
                </div>
                : null}

              <NewTable
                columns={tableData}
                data={data.solicitudes}
                loading={loading}
                order={handleTableOrder}
                orderParams={order}
                pagination={pagination}
              />
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </>
  );
};

export default HistorialSolicitudesRefactor;
