import styled from "styled-components";
import { colores, leerColor } from "../../constants";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  border-radius: 12px !important;
  //box-shadow: 0px 5px 30px #eaeaea;
  border: 1px solid ${({ theme }) => theme.border};
  filter: ${({ active }) => (active ? "grayscale(0)" : "grayscale(1)")};
  background-color: ${({ theme }) => theme.background};
  font-family: 'DM Sans', sans-serif;
  transition: all 0.5s;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    height: 115px;
    margin: 0px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    //pointer-events: none;
    background-color: ${({ theme }) => theme.background};
    border: 0;
    border-radius: 12px !important;
    transition: all 0.5s;
  }

  // Mejor Precio y Recomendado Container
  .mejor-precio,
  .recomendado {
    width: auto;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #eaeaea;
    border-radius: 6px 6px 0px 0px;
    position: absolute;
    top: -20px;
    left: 25px;
  }
  
  // Apto Competencia Container Background Animation
  @keyframes shimmer {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0px 0px;
    }
  }

  // Mejor Precio y Recomendado Texto
  .mejor-precio p,
  .recomendado p {
    margin: 0px 0px 0px 0px;
    padding: 5px 15px 4px 15px;
    font-size: 11px;
    text-align: center;
    color: var(--blanco);
    letter-spacing: 0.5px;
    font-family: 'DM Sans', sans-serif;
  }

  // Mejor Precio y Recomendado Icon
  .mejor-precio p span,
  .recomendado p span {
    margin: 0px 5px 0px 0px;
    font-size: 10px;
    font-family: 'DM Sans', sans-serif;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid ${({ theme }) => theme.border};
    transition: all 0.5s;
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    height: ${(props) =>
      props.seguro == "ATM"
        ? "42px"
        : props.seguro == "Rio Uruguay Seguros"
        ? "40px"
        : props.seguro == "Colón Seguros"
        ? "38px"
        : props.seguro == "Federación Patronal Seguros"
        ? "40px"
        : "40px"};
    object-fit: contain;
    padding: 4px;
    border-radius: 6px;
    background-color: #fff;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 12px 0px 0px 0px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: ${({ theme }) => theme.subtitle};
    line-height: 16px;
    font-family: 'DM Sans', sans-serif;
    transition: all 0.5s;
  }

  // Checks Container
  .checks-container {
    width: 694px;
    //margin: 0px 24px 0px 24px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
  }

  // Checks Container Icono
  .checks-container span {
    width: 139px;
    font-size: 26px;
    text-align: center;
    color: var(--verde50);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
  }

  .checks-container span span {
    height: 26px !important;
  }

  // Checks Container Text
  .checks-container span p {
    height: 28px;
    margin: 0px 0px 8px 0px;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.title};
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 0.5s;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }
  
  .check {
    display: flex;
    flex-direction: column;
  }

  // X Icono
  #check-no {
    font-size: 24px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 135px;
    height: 115px;
    padding: 0px 24px 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-left: 1px solid ${({ theme }) => theme.border};
    transition: all 0.5s;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--gris);

    .cuotas-naranja {
      color: var(--naranja100) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    margin: 0px 0px 0px 3px;
    text-decoration: line-through !important;
  }

  // Precio y oferta
  .precio-container div:nth-child(2) p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--lila90);
    line-height: normal;
  }

  // Precion Vacio
  #precio-vacio {
    font-weight: 200;
    font-size: 14px;
    text-align: center;
    color: var(--gris);
  }

  // Botones Container
  .botones-container {
    width: 115px;
    height: 100%;
    padding: 0px 8px 0px 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-left: 1px solid ${({ theme }) => theme.border};
    transition: all 0.5s;

  }

  a {
    text-decoration: none;
  }

  #contrata-boton {
    width: 100%;
    height: auto;
    padding: 8px 24px 8px 24px;
    background-color: var(--naranja100);
    color: ${({ theme }) => theme.subtitle};
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s all;
  }

  #contrata-boton:hover {
    background-color: var(--naranja40);
  }

  #ver-mas-boton {
    border: 0px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    background-color: transparent;
    font-size: 11px;
    text-align: center;
    color: ${({ theme }) => theme.subtitle};
    pointer-events: all;
    transition: 0.2s all;
    font-weight: 400;
  }

  #ver-mas-boton:hover {
    color: ${({ theme }) => theme.subtitle};
  }

  // Buscando Container
  #buscando-container {
    width: calc(100% - 164px);
    height: 115px;
    margin: 0px 0px 0px 24px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Animación Loading
  #animacion-loading {
    width: 80px !important;
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Text Container
  .children-container {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.border};
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

   // Datos Container
   .datos-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Plan Container
  .plan-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // Plan Container Text
  .plan-container p {
    margin: 32px 4px 0px 4px;
    text-align: center;
    font-weight: 700;
    color: ${({ theme }) => theme.subtitle};
  }

  // Detalles Beneficios Container
  .detalles-beneficios-container {
    width: 100%;
    padding: 32px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Detalles Beneficios Container Titulo
  .detalles-beneficios-container h4 {
    width: 100%;
    margin: 0px 0px 32px 0px;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: ${({ theme }) => theme.title};
  }

  // Detalles Beneficios Container Titulo Span
  .detalles-beneficios-container h4 span {
    margin: 4px 10px 0px 0px;
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
  }

  // Detalles Container y Beneficios Container
  .detalles-container,
  .beneficios-container {
    width: 40%;
    margin: 0% 3% 0% 3%;
    max-width: 430px;
  }

  // None Benfeicios
  #none-beneficios {
    display: none !important;
  }

  // Detalles Container Titulo y Beneficios Container Titulo
  .detalles-container h4,
  .beneficios-container h4 {
    margin: 0px 0px 24px 0px;
    text-align: left;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Detalles Container Titulo Icono y Beneficios Container Titulo Icono
  .detalles-container h4 span,
  .beneficios-container h4 span {
    margin: 5px 8px 0px 0px;
    color: ${({ theme }) => theme.subtitle};
    font-size: 21px;
  }

  // Detalle y Beneficio
  .detalle,
  .beneficio {
    width: 100%;
    margin: 0px 0px 10px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 6px;
  }

  // Detalle Texto y Beneficio Text
  .detalle p,
  .beneficio p {
    margin: 0px 0px 0px 0px;
    text-align: left !important;
    line-height: 1%.6;
    color: ${({ theme }) => theme.subtitle};
    font-weight: 400;
    font-size: 13px;
    white-space: pre-line;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Detalle Icono y Beneficio Icono
  .detalle p span,
  .beneficio p span {
    width: 13px;
    height: 100%;
    display: flex;
    margin: 0px 8px 0px 0px;
    color: ${({ theme }) => theme.subtitle};
    font-size: 12px;
  }

  .detalle span {
    display: flex;
  }

  // Vigencia Container
  .vigencia-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-top: 1px solid ${({ theme }) => theme.border};
  }

  // Vigencia Container Text
  .vigencia-container p {
    width: 100%;
    margin: 32px 0px 0px 0px;
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    font-weight: 600;
    color: ${({ theme }) => theme.subtitle};
  }

  // Vigencia Container Botones
  .vigencia-container a {
    margin: 24px 0px 32px 0px;
    padding: 8px 24px 8px 24px;
    background-color: ${leerColor(colores.azulRebranding)};
    color: ${leerColor(colores.blanco)};
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s all;
    text-decoration: none;
  }

  // Vigencia Container Botones Hover
  .vigencia-container a:hover {
    opacity: 0.7;
  }


  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 550ms;
  }
`;

export default Container;
