import { FiGrid } from "react-icons/fi";
import { Col, Grid } from "../Grid";
import Subtitle from "../Subtitle";
import NewInputSelect from "../NewInputSelect";
import { anios } from "../../constants/data";
import BarChart from "../BarChart";
import { useTheme } from "../../context/ThemeContext";

const CumplimientoAnualFichajes = ({ data }) => {
  const { usuarios, usuario, setAnio, anio, estadisticasAnuales } = data
  const { theme } = useTheme()

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={10}>
        <Subtitle
          title={`Cumplimiento anual de ${
            usuarios.find((user) => user.value == usuario)?.label
          }`}
          image={true}
          description="Estadísticas sobre tus fichajes a lo largo del año"
          icon={<FiGrid />}
          disableDivider={true}
        />
      </Col>
      <Col desktop={2}>
        <NewInputSelect
          name="anio"
          placeholder="2024"
          options={anios}
          onChange={(e) => setAnio(e.target.value)}
          value={anio}
        />
      </Col>

      {Object.keys(estadisticasAnuales).length > 0 ? (
        <Col desktop={12}>
          <div className="section-data">
            <BarChart
              data={estadisticasAnuales}
              optionsData={{
                responsive: true,
                scales: {
                  x: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : "#666666",
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                      tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                      color: theme === "dark" ? "#243158" : "#e5e5e5",
                    },
                  },
                  y: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : "#666666",
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                      tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                      color: theme === "dark" ? "#243158" : "#e5e5e5",
                    },
                  },
                },
                plugins: {
                  title: {
                    display: false,
                    text: "Visualización de primas realizadas a lo largo del año",
                  },
                },
              }}
            />
          </div>
        </Col>
      ) : null}
    </Grid>
  );
};

export default CumplimientoAnualFichajes;
