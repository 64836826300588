import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const RechazoContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 700px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border};
  transition: all 0.5s;

  .day-container {
    background-color: #f7f9ff;
    border-radius: 100px;
    color: ${leerColor(colores.grisEstados)};
    //border: 1px solid ${leerColor(colores.grisEstados)};
    width: fit-content;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 6px 14px;
    font-size: 14px;
  }
  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: #202020;
      font-weight: 500;
    }
    .motivo {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        cursor: pointer;
        transition: 0.2s;
    }

    .motivo-selected {
        color: ${leerColor(colores.azulRebranding)};
        border: 1px solid ${leerColor(colores.azulRebranding)};
        font-weight: 500;
    }

    .motivo:hover {
        opacity: 0.8s;
    }
  }
  .mensajesContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 30px;
    gap: 20px;

    /* ScrollBar */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)}; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }

    .editar-solicitud-btn-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 12px 0;

    a {
      text-decoration: none;
    }
  }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
   }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    //width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
`;