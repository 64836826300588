// Componentes
import Sidebar from "../../../components/Sidebar";
import TitlePageSection from "../../../components/TitlePageSection";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import NewTable from "../../../components/NewTable";
// ** Modales **
import ModalCargarSiniestroCSV from "../../../components/Modals/Siniestros/ModalCargarSiniestroCSV";
import ModalSuccess from "../../../components/ModalSuccess";
import ModalError from "../../../components/ModalError";
// Custom Hooks
import useSiniestros from "../../../hooks/useSiniestros";
import useModal from "../../../hooks/useModal";
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable";
// Utils
import { siniestrosTable } from "../../../utils/tableData";
import { filtersSiniestros } from "../../../utils/filtersPages";
import { filterSiniestrosInitialValues } from "../../../utils/filtersPages";
// Iconos
import { HiPlus } from "react-icons/hi";
import { useNotificacionesPage } from "../../../hooks/useNotificaciones";
import NotificacionPagina from "../../../components/NotificacionPagina";
import NewButton from "../../../components/NewButton";
import { FiArrowRight, FiFilter } from "react-icons/fi";
import { useState } from "react";
import NewFiltersSection from "../../../components/NewFiltersSection";
// Estilos
import { Container } from "./styled";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Divider from "../../../components/Divider";
import { useSingleOpen } from "../../../hooks/useSingleOpen";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import CardStats from "../../../components/CardStats";

const SiniestrosRefactor = () => {
  // Modales
  const confirmacionModal = useModal();
  const errorModal = useModal();
  const location = useLocation()

  console.log(location)

  // Storage
  const user = JSON.parse(localStorage.getItem("userInfo"));

  // Custom Hooks
  const {
    inputValues,
    params,
    handleSearch,
    handleChange,
    handleChangeExternal,
    clearSearch,
  } = useFiltersNew(filterSiniestrosInitialValues);
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } = useSingleOpen()

  // Orden
  const { order, handleTableOrder } = useTable();
  const {
    data,
    loading,
    pagination,
    operaciones,
    borrarSiniestro,
    editarSiniestro,
    cargarSiniestroCSV,
    refreshSiniestros,
  } = useSiniestros(params, order, confirmacionModal);

  // Notificaciones
  const { notificaciones, resetNotificaciones, mensaje } =
    useNotificacionesPage(["siniestros"], refreshSiniestros);

  // Tabla
  const tableData = siniestrosTable(
    editarSiniestro.abrirModal,
    user,
    location
  );

  // Filtros
  const filters = filtersSiniestros(data);

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={confirmacionModal.isModalOpen}
        setIsSuccessPopUpOpen={confirmacionModal.handleModal}
        popUpMessage={confirmacionModal.modalMessage}
      />

      <ModalError isSuccessPopUpOpen={errorModal.isModalOpen} />

      <ModalCargarSiniestroCSV
        isModalOpen={cargarSiniestroCSV.isModalOpen}
        handleModal={cargarSiniestroCSV.handleModal}
        csvFile={cargarSiniestroCSV.csvFile}
        setCsvFile={cargarSiniestroCSV.setCsvFile}
        postSiniestrosLoading={cargarSiniestroCSV.postSiniestrosLoading}
        cargarCSV={cargarSiniestroCSV.cargarCsv}
      />

      {/* <Sidebar active="siniestros" /> */}

      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <NewTitlePageSection
                    title="Siniestros"
                    description={"Lista y carga de siniestros"}
                  >
                    <NewButton
                      backgroundColor={false}
                      textColor={true}
                      borderColor={true}
                      onClick={() => handleFiltersOpen(!isFiltersOpen)}
                    >
                      <FiFilter /> Filtros
                      {params?.split("=").length > 1 ? (
                        <span className="filtros-aplicados">
                          {params?.split("=").length - 1}
                        </span>
                      ) : null}
                    </NewButton>
                    <Link to="/Siniestros/CrearSiniestro">
                      <NewButton
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        icon={false}
                        disabled={user.id_rol === 8}
                      >
                        <span>
                          <HiPlus></HiPlus>
                        </span>
                        Carga Manual
                      </NewButton>
                    </Link>
                    <NewButton
                      backgroundColor={true}
                      borderColor={false}
                      textColor={false}
                      icon={false}
                      onClick={cargarSiniestroCSV.handleModal}
                      disabled={user.id_rol === 8}
                    >
                      <span>
                        <HiPlus></HiPlus>
                      </span>
                      Carga CSV
                    </NewButton>
                  </NewTitlePageSection>
                </Col>
              </Grid>
              {isFiltersOpen ? (
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <NewFiltersSection
                      section="solicitudes"
                      inputs={filters}
                      values={inputValues}
                      params={params}
                      handleChange={handleChange}
                      handleSearch={handleSearch}
                      clearSearch={clearSearch}
                    />
                  </Col>
                  {notificaciones.length > 0 && (
                    <Col desktop={12}>
                      <NotificacionPagina
                        mensaje={mensaje}
                        resetNotificaciones={resetNotificaciones}
                      />
                    </Col>
                  )}
                </Grid>
              ) : null}

              <Divider />

              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={2}>
                  <CardStats title={"Siniestros"} value={data.estadisticas?.siniestros} filter={(e) => handleChangeExternal(e, "siniestros", 1)} filterText="siniestros"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Conformes"} value={data.estadisticas?.cerrado_positivo_conforme} filter={(e) => handleChangeExternal(e, "siniestros", 1)} filterText="conformes"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Parciales"} value={data.estadisticas?.cerrado_positivo_conforme_parcial} filter={(e) => handleChangeExternal(e, "siniestros", 1)} filterText="parciales"/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Disconformes"} value={data.estadisticas?.cerrado_positivo_disconforme} filter={(e) => handleChangeExternal(e, "siniestros", 1)} filterText="disconform."/>
                </Col>
                <Col desktop={2}>
                  <CardStats title={"Negativos"} value={data.estadisticas?.cerrado_negativo} filter={(e) => handleChangeExternal(e, "siniestros", 1)} filterText="negativos."/>
                </Col>
              </Grid>

              <NewTable
                columns={tableData}
                data={data.siniestros}
                loading={loading}
                order={handleTableOrder}
                orderParams={order}
                pagination={pagination}
              />
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </div>
  );
};

export default SiniestrosRefactor;
