import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const SelectDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${({ theme }) => theme.background};
    transition: all 0.5s;

  i {
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    width: 7px;
    height: 7px;
    display: block;
    background-color: ${leerColor(colores.blanco)};
    border-left: 3px solid ${leerColor(colores.gris)};
    border-bottom: 3px solid ${leerColor(colores.gris)};
    transform: rotate(-45deg);
    z-index: 59;
    border-radius: 10%;
  }

  select {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    background-color: ${({ theme }) => theme.input_background};
    border: 1.7px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    padding: 0 20px;
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    transition: all 0.5s;

    ::placeholder {
      color: ${leerColor(colores.gris)};
    }
  }

  label {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    color: ${({ theme }) => theme.title};
  }

    // Inputs and Select Focus
  .select:focus {
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
  }

    // Label Down
    .label-down {
    z-index: 57 !important;
  }

  // Select default arrow
  select::-ms-expand {
    display: none;
  }

  // Select Option
  select * {
    color: ${({ theme }) => theme.title};
  }
`