import NewInputBasic from "../../NewInputBasic";
import { Grid, Col } from "../../Grid";
import ErrorInput from "../../ErrorInput";
import NewInputCalendar from "../../NewInputCalendar";
import Subtitle from "../../Subtitle";
import { FiArrowLeft, FiUser } from "react-icons/fi";
import NewInputSelect from "../../NewInputSelect";
import NewButton from "../../NewButton";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UsuarioForm = ({ form, data }) => {
  const { inputValues, handleChange, formErrors, handleValidation } = form;
  const { estados, roles, loading } = data;

  const location = useLocation()
  const pathname = location.pathname

  return (
    <>
          <Subtitle
        icon={<FiUser />}
        title={"Información de usuario"}
        description={"Datos para iniciar sesión"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"usuario_usuario"}
            labelName={"Cuenta"}
            id={"usuario_usuario"}
            placeholder={"ejemplo@seguroweb.com.ar"}
            onChange={handleChange}
            color={formErrors.usuario_usuario ? "error" : ""}
            value={inputValues.usuario_usuario}
          />
          {formErrors.usuario_usuario && (
            <ErrorInput>{formErrors.usuario_usuario}</ErrorInput>
          )}
        </Col>
        {!pathname.includes("Editar") ? 
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"clave_usuario"}
            labelName={"Contraseña"}
            placeholder={"Contraseña"}
            id={"clave_usuario"}
            onChange={handleChange}
            value={inputValues.clave_usuario}
            color={formErrors.clave_usuario ? "error" : ""}
            required
          />
          {formErrors.clave_usuario && (
            <ErrorInput>{formErrors.clave_usuario}</ErrorInput>
          )}
        </Col>
        : null}
        <Col desktop={3}>
          <NewInputSelect
            type={"text"}
            name={"id_rol"}
            labelName={"Rol"}
            id={"id_rol"}
            onChange={handleChange}
            options={roles}
            placeholder={"Roles"}
            value={inputValues.id_rol}
            color={formErrors.id_rol ? "error" : ""}
          />
          {formErrors.id_rol && <ErrorInput>{formErrors.id_rol}</ErrorInput>}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            type={"text"}
            name={"estado_usuario"}
            labelName={"Estado"}
            id={"estado_usuario"}
            onChange={handleChange}
            options={estados}
            placeholder={"Estado"}
            value={inputValues.estado_usuario}
            color={formErrors.estado_usuario ? "error" : ""}
          />
          {formErrors.estado_usuario && (
            <ErrorInput>{formErrors.estado_usuario}</ErrorInput>
          )}
        </Col>
      </Grid>
      <hr />
      <Subtitle
        icon={<FiUser />}
        title={"Información de operador"}
        description={"Datos sobre el nuevo usuario"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"nombre_operador"}
            labelName={"Nombre"}
            id={"nombre_operador"}
            placeholder={"Nombre"}
            onChange={handleChange}
            color={formErrors.nombre_operador ? "error" : ""}
            value={inputValues.nombre_operador}
            required
          />
          {formErrors.nombre_operador && (
            <ErrorInput>{formErrors.nombre_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"apellido_operador"}
            labelName={"Apellido"}
            id={"apellido_operador"}
            placeholder={"Apellido"}
            onChange={handleChange}
            value={inputValues.apellido_operador}
            required
          />
          {formErrors.apellido_operador && (
            <ErrorInput>{formErrors.apellido_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"email_operador"}
            labelName={"Email"}
            id={"email_operador"}
            placeholder={"Email"}
            onChange={handleChange}
            color={formErrors.email_operador ? "error" : ""}
            value={inputValues.email_operador}
            required
          />
          {formErrors.email_operador && (
            <ErrorInput>{formErrors.email_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputCalendar
            type={"date"}
            name={"nacimiento_operador"}
            labelName={"Fecha de Nacimiento"}
            id={"usuario_usuario"}
            placeholder={"Nombre"}
            onChange={handleChange}
            value={inputValues.nacimiento_operador}
            color={formErrors.nacimiento_operador ? "error" : ""}
            required
          />
          {formErrors.nacimiento_operador && (
            <ErrorInput>{formErrors.nacimiento_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"telefono_operador"}
            labelName={"Telefono"}
            id={"telefono_operador"}
            placeholder={"123456"}
            onChange={handleChange}
            value={inputValues.telefono_operador}
            color={formErrors.telefono_operador ? "error" : ""}
            required
          />
          {formErrors.telefono_operador && (
            <ErrorInput>{formErrors.telefono_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"email_secundario"}
            labelName={"Email secundario"}
            id={"email_secundario"}
            placeholder={"email.seguroweb@gmail.com"}
            onChange={handleChange}
            value={inputValues.email_secundario}
            required
          />
          {formErrors.email_secundario && (
            <ErrorInput>{formErrors.email_secundario}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"dni_operador"}
            labelName={"Documento"}
            id={"dni_operador"}
            placeholder={"123456"}
            onChange={handleChange}
            value={inputValues.dni_operador}
            color={formErrors.dni_operador ? "error" : ""}
            required
          />
          {formErrors.dni_operador && (
            <ErrorInput>{formErrors.dni_operador}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"cuit_operador"}
            labelName={"CUIT"}
            id={"cuit_operador"}
            placeholder={"CUIT"}
            onChange={handleChange}
            value={inputValues.cuit_operador}
            color={formErrors.cuit_operador ? "error" : ""}
            required
          />
          {formErrors.cuit_operador && (
            <ErrorInput>{formErrors.cuit_operador}</ErrorInput>
          )}
        </Col>
      </Grid>
    </>
  );
};

export default UsuarioForm;
