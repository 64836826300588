import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import Button from "../../../Button";
import Titulo from "../../../Titulo";
import InputFile from "../../../InputFile";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { polizaReglas } from "../../../../utils/formRules";
import { polizaForm } from "../../../../utils/formInitialValues";
import {
  //actualizarOperacion,
  actualizarSolicitud,
  postMensajeHistorial,
  postSolicitudCertificado,
  postSolicitudPoliza,
} from "../../../../services/Emision";
import InputBasic from "../../../InputBasic";
import dayjs from "dayjs";
import sendFiles from "../../../../utils/uploadFile";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { useSocket } from "../../../../context/SocketProvider";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useTabs } from "../../../../hooks/useTabs";
import NewTabs from "../../../NewTabs";
import Divider from "../../../Divider";

const ModalEmisionManual = ({ modal, solicitud, getSolicitud, asegurado, seguro, producto }) => {
  const { isModalOpen, handleModal } = modal;
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, polizaReglas, polizaForm);
  const { handleTab, tabSelected } = useTabs();

  console.log(solicitud)


  const socket = useSocket()
  const userRol = JSON.parse(localStorage.getItem("userInfo"));
  const { loading, setLoading, loadingText, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Emitiendo solicitud...");


    if(inputValues.tipo === "certificado"){
      try {
        const certificado = await sendFiles(inputValues.certificado);

      const solicitudCertificado = {
        id_solicitud: solicitud.id,
        id_operacion: solicitud.operacion_solicitud,
        id_asegurado: solicitud.id_asegurado,
        url_certificado: certificado,
        certificado_id_mail: null,
        estado_mail_certificado: null,
        estado: 1,
      };
      const idCertificado = await postSolicitudCertificado(solicitudCertificado);

            // La solicitud pasa a estado emitido
            let data = solicitud;
            let prevEstado = solicitud.id_estado;
            data.id_estado = 4;
            data.id_estado_previo = prevEstado;
            data.ingreso_solicitud = dayjs(
              new Date(solicitud.ingreso_solicitud)
            ).format("DD/MM/YYYY HH:mm:ss");
            data.rechazo_solicitud =
              solicitud.rechazo_solicitud !== null
                ? dayjs(new Date(solicitud.rechazo_solicitud)).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : null;
            data.emision_solicitud = dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss");

                  // Postear mensaje en de solicitud emitida en 'sw_solicitudes_historial'
      const solicitudMessage = {
        id_solicitud: solicitud.id,
        id_area: 1,
        id_operador: userRol.id_operador,
        id_accion_historial: 3,
        fecha_solicitud_historial: dayjs(new Date()).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        descripcion_solicitud_historial:
          "Solicitud emitida con certificado de cobertura.",
        eprevio_solicitud_historial: prevEstado,
        estado_solicitud_historial: 1,
        email_solicitudes_historial: 0,
        id_historial_email: null,
      };
      postMensajeHistorial(solicitudMessage);

            // Postear mensaje de solicitud emitida en 'sw_solicitudes_historial'
            const certificadoMessage = {
              ...solicitudMessage,
              descripcion_solicitud_historial: `Certificado de cobertura: ${certificado}`,
            };
            postMensajeHistorial(certificadoMessage);
      
            await actualizarSolicitud(solicitud.id, data);
      
            const operadores = await axiosRequest.get('operadores')
            const usuario = operadores.data.find(operador => operador.id_operador === solicitud.vendedor_solicitud)

            const ID_ENDOSO = 3

            if(solicitud.id_tipo_solicitud !== ID_ENDOSO){
              
              const ID_BICICLETAS = 1
              const ID_BICICLETA_ELECTRICA = 44
              const ID_MONOPATIN = 45
              const ID_BICI_USO_COMERCIAL = 46
              const linkEmail = (producto === ID_BICICLETAS || producto === ID_BICICLETA_ELECTRICA || producto === ID_MONOPATIN || producto === ID_BICI_USO_COMERCIAL) ? "https://g.page/r/CZ_gbjLV51-NEAE/review" : "https://g.page/r/CVZiZ5ySX2V9EAE/review"
              const bodyEmail = `
                <html>
                  <body>
                    <p>¡Buenos días ${asegurado.nombre_asegurado}!</p>

                    <p>Nos comunicamos desde el Sector de Emisión, para brindarle adjunto el certificado provisorio, recuerde que este tiene vigencia durante 72 horas, en cuanto se emita la póliza definitiva le va a llegar por este mismo medio.</p>

                    <p>¡Ante cualquier consulta estamos a disposición!</p>

                    <p>Puede comunicarse con nosotros a los siguientes canales de Comunicación:</p>

                    <ul style="list-style: none; padding: 0px;">
                      <li style="margin-left: 0px;">Email: emisión@seguroweb.com.ar</li>
                      <li style="margin-left: 0px;">Teléfono: 0810-220-7323 opción 1.</li>
                      <li style="margin-left: 0px;">Whatsapp 1128061581</li>
                    </ul>

                    <div>
                      <p style="margin-bottom: 0px;">Atentamente,</p>
                      <p>Equipo de Emisión</p>
                    </div>
                  </body>
                </html>
              `
              const dataEmail = {
                mail: process.env.REACT_APP_ENV !== "production" ? ["rodriandrade1498@gmail.com"] : [asegurado.email_asegurado],
                htmlContent: bodyEmail,
                subject: `Certificado provisorio - ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - ${seguro.cia_cobertura_asegurada}`,
                adjuntos: [{ path: certificado, name: inputValues.certificado.name }]
              }
              const mailEmision = await axiosRequest.post('/send_email', dataEmail)
              if(mailEmision.status === 200){
                const solicitudMessage = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userRol.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial:
                    `Email de certificado de cobertura emitido enviado a ${asegurado.email_asegurado}.`,
                  eprevio_solicitud_historial: prevEstado,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(solicitudMessage);

                const updateCertificado = {
                  id_solicitud_certificado: idCertificado.id,
                  certificado_id_mail: mailEmision.data.id.slice(1, mailEmision.data.id.length - 1)
                }
                await axiosRequest.put(`/solicitudes_certificados/mailgun_id`, updateCertificado)
            }

                const notificacion = {
                  url_pagina: `/Emision/${solicitud.id}`,
                  nombre_icono: "emision",
                  nombre_pagina: "Emisiones",
                  notificacion: `Se emitió el certificado de la cobertura para la solicitud ${solicitud.id} - Asegurado: ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - Compañia: ${seguro.cia_cobertura_asegurada}`,
                  time: dayjs(new Date()).format("HH:mm"),
                  id_usuario: usuario.id_usuario,
                }
                socket.emit('notificacion-crm-emision-solicitud-emitida', notificacion)
                await axiosRequest.post('/notificacion/crear', notificacion)
          
                //await actualizarOperacion(solicitud.operacion_solicitud);
          
                handleModal();
                getSolicitud();          
                setLoading(false);
              }              
          
          } catch (error){
            console.log(error)
            setLoading(false);
          }
    } else {
      try {
        const poliza = await sendFiles(inputValues.poliza);
        const solicitudPoliza = {
          id_solicitud: solicitud.id,
          id_operacion: solicitud.operacion_solicitud,
          id_asegurado: solicitud.id_asegurado,
          numero_poliza: inputValues.numero_poliza,
          url_poliza: poliza,
          url_poliza_original: poliza,
          poliza_abierta_mail: 0,
          poliza_enviada_mail: 0,
          poliza_id_mail: null,
          estado_mail_poliza: null,
          estado: 1,
        };
        const idPoliza = await postSolicitudPoliza(solicitudPoliza);
        console.log("✅✅✅✅✅✅✅ POLIZA DATA ✅✅✅✅✅✅✅")
        console.log(idPoliza)
        console.log("✅✅✅✅✅✅✅ POLIZA DATA ✅✅✅✅✅✅✅")
  
        // La solicitud pasa a estado emitido
        let data = solicitud;
        let prevEstado = solicitud.id_estado;
        data.id_estado = 2;
        data.id_estado_previo = prevEstado;
        data.premio = inputValues.premio;
        data.ingreso_solicitud = dayjs(
          new Date(solicitud.ingreso_solicitud)
        ).format("DD/MM/YYYY HH:mm:ss");
        data.rechazo_solicitud =
          solicitud.rechazo_solicitud !== null
            ? dayjs(new Date(solicitud.rechazo_solicitud)).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : null;
        data.emision_solicitud = dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss");
  
        // Postear mensaje en de solicitud emitida en 'sw_solicitudes_historial'
        const solicitudMessage = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 3,
          fecha_solicitud_historial: dayjs(new Date()).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          descripcion_solicitud_historial:
            "¡Solicitud emitida!",
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null,
        };
        postMensajeHistorial(solicitudMessage);
  
        // Postear mensaje de solicitud emitida en 'sw_solicitudes_historial'
        const polizaMessage = {
          ...solicitudMessage,
          descripcion_solicitud_historial: `Póliza número ${inputValues.numero_poliza} emitida: ${poliza}`,
        };
        postMensajeHistorial(polizaMessage);
  
        await actualizarSolicitud(solicitud.id, data);
  
        const operadores = await axiosRequest.get('operadores')
        const usuario = operadores.data.find(operador => operador.id_operador === solicitud.vendedor_solicitud)
        
        const ID_ENDOSO = 3
  
        if(solicitud.id_tipo_solicitud !== ID_ENDOSO){
          const ID_BICICLETAS = 1
          const ID_BICICLETA_ELECTRICA = 44
          const ID_MONOPATIN = 45
          const ID_BICI_USO_COMERCIAL = 46
          const linkEmail = (producto === ID_BICICLETAS || producto === ID_BICICLETA_ELECTRICA || producto === ID_MONOPATIN || producto === ID_BICI_USO_COMERCIAL) ? "https://g.page/r/CZ_gbjLV51-NEAE/review" : "https://g.page/r/CVZiZ5ySX2V9EAE/review"
          const bodyEmail = `
            <html>
              <body>
                <a href="${linkEmail}" target="_blank"><img src="https://devfiles.gyssrl.ar/api/v1/storage/image/371cf13f-cc0b-4f95-8065-64b00f412cb7" alt="Se adjunta la póliza correspondiente" /></a>
              </body>
            </html>
          `
          //[asegurado.email_asegurado, usuario.usuario_usuario]
          const dataEmail = {
            mail: process.env.REACT_APP_ENV !== "production" ? ["rodriandrade1498@gmail.com"] : [asegurado.email_asegurado],
            htmlContent: bodyEmail,
            subject: `ENVÍO DE PÓLIZA - ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - ${seguro.cia_cobertura_asegurada}`,
            adjuntos: [{ path: poliza, name: inputValues.poliza.name }]
          }
          const mailEmision = await axiosRequest.post('/send_email', dataEmail)
          
          if(mailEmision.status === 200){
            console.log("✅✅✅✅✅✅✅ MAIL DATA ✅✅✅✅✅✅✅")
            console.log(mailEmision)
            console.log("✅✅✅✅✅✅✅ MAIL DATA ✅✅✅✅✅✅✅")
            const solicitudMessage = {
              id_solicitud: solicitud.id,
              id_area: 1,
              id_operador: userRol.id_operador,
              id_accion_historial: 3,
              fecha_solicitud_historial: dayjs(new Date()).format(
                "DD/MM/YYYY HH:mm:ss"
              ),
              descripcion_solicitud_historial:
                `Email de solicitud emitida enviado a ${asegurado.email_asegurado}.`,
              eprevio_solicitud_historial: prevEstado,
              estado_solicitud_historial: 1,
              email_solicitudes_historial: 0,
              id_historial_email: null,
            };
            postMensajeHistorial(solicitudMessage);

            const updatePoliza = {
              id_solicitud_poliza: idPoliza.id,
              poliza_id_mail: mailEmision.data.id.slice(1, mailEmision.data.id.length - 1)
            }
            await axiosRequest.put(`/solicitudes_polizas/mailgun_id`, updatePoliza)
            // y ${usuario.usuario_usuario}
          }
          
        }
  
        const notificacion = {
          url_pagina: `/Emision/${solicitud.id}`,
          nombre_icono: "emision",
          nombre_pagina: "Emisiones",
          notificacion: `¡Se emitió la solicitud ${solicitud.id}! - Asegurado: ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - Compañia: ${seguro.cia_cobertura_asegurada}`,
          time: dayjs(new Date()).format("HH:mm"),
          id_usuario: usuario.id_usuario,
        }
        socket.emit('notificacion-crm-emision-solicitud-emitida', notificacion)
        await axiosRequest.post('/notificacion/crear', notificacion)
  
        //await actualizarOperacion(solicitud.operacion_solicitud);
  
        handleModal();
        //exitoModal.setIsModalOpen(true);
        getSolicitud();
        setTimeout(() => {
          //exitoModal.setIsModalOpen(false);
        }, 1200);
  
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  const handleTipoEmision = (index) => {
    const tipo = index === 0 ? "poliza" : "certificado"
    handleChangeExternal("tipo", tipo)
    handleTab(index)
  }

  const tabs = [
    {
      name: "Póliza",
    },
    {
      name: "Certificado de cobertura",
    },
  ];

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={"¡Solicitud emitida correctamente!"}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
            
              <div className="header-container">
                <NewTitle>Emitir solicitud</NewTitle>
                <p>
                  Cargá la póliza de la solicitud o un certificado de cobertura
                </p>
              </div>
              <Divider />
              <div className="data-container">
                {parseInt(solicitud.id_estado) !== 4 ?
                <NewTabs
                  tabs={tabs}
                  tabSelected={tabSelected}
                  handleTab={handleTipoEmision}
                /> : null}
                {tabSelected === 0 ?
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Número de Póliza"
                      name="numero_poliza"
                      placeholder="40095821"
                      onChange={handleChange}
                      value={inputValues.numero_poliza}
                    />
                    {formErrors.numero_poliza && (
                      <ErrorInput>{formErrors.numero_poliza}</ErrorInput>
                    )}
                  </Col>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Premio"
                      name="premio"
                      placeholder="5000"
                      onChange={handleChange}
                      value={inputValues.premio}
                    />
                    {formErrors.premio && (
                      <ErrorInput>{formErrors.premio}</ErrorInput>
                    )}
                  </Col>
                  <Col desktop={12}>
                    <InputFile
                      name="poliza"
                      text="Adjuntar póliza"
                      labelName="Póliza"
                      accept=".pdf"
                      onChange={handleChangeExternal}
                      file={inputValues.poliza}
                    />
                    {formErrors.poliza && (
                      <ErrorInput>{formErrors.poliza}</ErrorInput>
                    )}
                  </Col>
                </Grid>
                : null}
                {tabSelected === 1 ?
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={12}>
                    <InputFile
                      name="certificado"
                      text="Adjuntar certificado de cobertura"
                      labelName="Certificado de cobertura"
                      accept=".pdf"
                      onChange={handleChangeExternal}
                      file={inputValues.certificado}
                    />
                    {formErrors.certificado && (
                      <ErrorInput>{formErrors.certificado}</ErrorInput>
                    )}
                  </Col>
                </Grid>
                : null}
              </div>
              <Divider />
              <div className={"actionButtonsCont"}>
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={handleModal}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton backgroundColor={true} onClick={handleValidation} disabled={loading}>
                    {loading ? "Emitiendo..." : `Emitir ${tabSelected === 0 ? "póliza" : "certificado"}`}
                  </NewButton>
                </div>
              </div>

        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalEmisionManual;
