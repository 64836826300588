/**
 * Archivo: AnulacionCalidad.js
 * Sistema: CMR
 * Responsables: NATALIA MOSQUERA - JOSE LUIS CONDORI
 * Programador: Rodrigo Andrade
 * Última modificación: 12 de marzo del 2025
 * Descripción: Componente para visualizar los detalles de la anulación de calidad.
 */

import { Container, ContainerFile } from "./styled";
import { FiAlertCircle, FiBox, FiPenTool, FiUser } from "react-icons/fi";
import dayjs from "dayjs";
import BackButton from "../../../components/BackButton";
import Divider from "../../../components/Divider";
import SpinnerLoading from "../../../components/SpinnerLoading";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import EmisionSeccion from "../../../components/EmisionSeccion";
import {
  INFORMACION_ASEGURADO_ANULACION_CALIDAD,
  INFORMACION_BIEN_ASEGURADO_CALIDAD,
  MOTIVO_ANULACION_CALIDAD,
} from "../../../constants/labels";
import HeaderTitleSection from "../../../components/HeaderTitleSection";
import Subtitle from "../../../components/Subtitle";
import CardNumber from "../../../components/CardNumber";
import { Col, Grid } from "../../../components/Grid";
import ImgGys from "../../../components/ImgGys/Index";
import useAnulacionCalidad from "./hooks/useAnulacionCalidad";

const AnulacionCalidad = () => {
  const { data } = useAnulacionCalidad();
  const {
    anulacion,
    producto,
    productosConArchivo,
    productosSinArchivo,
    loadingSiniestro,
    previousUrl,
  } = data;
  
  return (
    <Layout>
      <NewWrapper>
        <NewInner>
          <Container>
            {!loadingSiniestro.loading ? (
              <>
                <div>
                  <BackButton
                    url={previousUrl ?? "/ControlCalidad"}
                    text="Control Calidad"
                  />
                  {/* ✅ MEJORA: Uso de `?.` para evitar errores si `anulacion` no tiene valores */}
                  <NewTitlePageSection
                    title={`Anulación - ${anulacion?.nombre_asegurado ?? ""} ${
                      anulacion?.apellido_asegurado ?? ""
                    } - ${anulacion?.nombre_compania ?? ""}`}
                    description={`Anulación ingresada el ${
                      anulacion?.fecha_ingreso
                        ? dayjs(anulacion.fecha_ingreso).format("DD/MM/YYYY")
                        : ""
                    }\nOperacion - ${anulacion?.id_operacion_novedades}`}
                  />
                </div>

                <HeaderTitleSection
                  compania={anulacion?.nombre_compania}
                  estado={anulacion?.estado}
                  operadorData={anulacion}
                />
                <Divider />

                {anulacion?.motivo_anulacion_otros ? (
                  <>
                    <Subtitle
                      icon={<FiAlertCircle />}
                      title={"Motivo de Anulación"}
                      description={
                        "Razón por la que el asegurado quiere anular su póliza"
                      }
                      disableDivider={true}
                    />
                    <CardNumber
                      title="Motivo"
                      value={anulacion.motivo_anulacion_otros}
                    />
                  </>
                ) : (
                  <EmisionSeccion
                    title="Motivo de Anulación"
                    description={
                      "Razón por la que el asegurado quiere anular su póliza"
                    }
                    data={anulacion}
                    label={MOTIVO_ANULACION_CALIDAD}
                    icon={<FiAlertCircle />}
                  />
                )}

                <Divider />
                <EmisionSeccion
                  title="Asegurado"
                  description={
                    "Información del asegurado que solicitó la anulación de su póliza"
                  }
                  data={anulacion}
                  label={INFORMACION_ASEGURADO_ANULACION_CALIDAD}
                  icon={<FiUser />}
                />
                <Divider />
                <EmisionSeccion
                  title="Póliza"
                  description={"Información de la póliza que se busca anular"}
                  data={anulacion}
                  label={INFORMACION_BIEN_ASEGURADO_CALIDAD}
                  icon={<FiBox />}
                />
                {producto.length > 0 ? (
                  <>
                    <Divider />
                    <Subtitle icon={<FiBox />} title="Producto" description="Información del producto asociado a la póliza" disableDivider />
                    <Grid colGap={21} rowGap={21} narrow={false}>
                      {/* ✅ MEJORA: Separación de productos con y sin archivos */}
                      {productosConArchivo.map((item, index) => (
                        <Col desktop={3} key={index}>
                          <ContainerFile>
                            <label>
                              {item.label_producto_asegurado.replaceAll(
                                "_",
                                " "
                              )}
                            </label>
                            <ImgGys
                              url={item.descripcion_producto_asegurado}
                              index={index}
                              descarga={false}
                            />
                          </ContainerFile>
                        </Col>
                      ))}

                      {productosSinArchivo.map((item) => (
                        <Col
                          desktop={3}
                          key={item.id_anulacion_calidad_producto}
                        >
                          <div className="seccion-container">
                            <span>{item.label_producto_asegurado}</span>
                            <h2>{item.descripcion_producto_asegurado}</h2>
                          </div>
                        </Col>
                      ))}
                    </Grid>
                  </>
                ) : null}

                {anulacion?.firma_digital ? (
                  <>
                    <Divider />
                    <Subtitle
                      icon={<FiPenTool />}
                      title="Firma Digital"
                      description="Firma del asegurado brindada en el formulario de baja de Seguro Web"
                      disableDivider
                    />
                    <Grid colGap={21} rowGap={21} narrow={false}>
                      <Col desktop={3}>
                        <ContainerFile>
                          <label>Firma Digital</label>
                          <ImgGys
                            url={anulacion.firma_digital}
                            descarga={false}
                          />
                        </ContainerFile>
                      </Col>
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <SpinnerLoading />
            )}
          </Container>
        </NewInner>
      </NewWrapper>
    </Layout>
  );
};

export default AnulacionCalidad;
