import { Col, Grid } from "../../../../../components/Grid";
import Subtitle from "../../../../../components/Subtitle";
import { FiCalendar, FiSun, FiThumbsUp, FiUser } from "react-icons/fi";
import ErrorInput from "../../../../../components/ErrorInput";
import NewInputBasic from "../../../../../components/NewInputBasic";
import InputCheckbox from "../../../../../components/InputCheckbox";
import NewInputSelect from "../../../../../components/NewInputSelect";

const TipoExcepcion = ({ data, form }) => {
  const { tiposGoceSueldo } = data
  const { inputValues, handleChange, handleChangeExternal, handleChangeCheckbox, formErrors } = form   
  const ID_OTROS_TIPO_GOCE_SUELDO = 7;

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <Subtitle
          title="Tipo de excepción"
          description="Seleccioná cuál es el tipo de excepción para el usuario"
          icon={<FiUser />}
          disableDivider={true}
        />
      </Col>
      <Col desktop={3}>
        <div
          className={`excepcion-container ${
            inputValues.tipo === "Vacaciones" && "selected"
          }`}
          onClick={() => handleChangeExternal("tipo", "Vacaciones")}
        >
          <span className="icon">
            <FiSun />
          </span>
          <span>Vacaciones</span>
        </div>
      </Col>
      <Col desktop={3}>
        <div
          className={`excepcion-container ${
            inputValues.tipo === "Acuerdo" && "selected"
          }`}
          onClick={() => handleChangeExternal("tipo", "Acuerdo")}
        >
          <span className="icon">
            <FiThumbsUp />
          </span>
          <span>Acuerdo</span>
        </div>
      </Col>
      <Col desktop={3}>
        <div
          className={`excepcion-container ${
            inputValues.tipo === "Licencia" && "selected"
          }`}
          onClick={() => handleChangeExternal("tipo", "Licencia")}
        >
          <span className="icon">
            <FiCalendar />
          </span>
          <span>Licencia</span>
        </div>
      </Col>
      {formErrors.tipo && (
        <Col desktop={12}>
          <ErrorInput className="error">{formErrors.tipo}</ErrorInput>
        </Col>
      )}

      {inputValues.tipo === "Acuerdo" ? (
        <Col desktop={12}>
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={3}>
              <NewInputBasic
                labelName={"Motivo del acuerdo"}
                name={"motivo_acuerdo"}
                placeholder={"Turno médico"}
                onChange={handleChange}
                value={inputValues.motivo_acuerdo}
              />
              {formErrors.motivo_acuerdo && (
                <ErrorInput className="error">
                  {formErrors.motivo_acuerdo}
                </ErrorInput>
              )}
            </Col>
          </Grid>
        </Col>
      ) : null}

      {inputValues.tipo === "Licencia" ? (
        <Col desktop={12}>
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
              <InputCheckbox
                className={"inputCheckbox"}
                type={"checkbox"}
                name={"check_goce_sueldo"}
                labelName={"Con goce de sueldo"}
                onChange={handleChangeCheckbox}
                checked={inputValues.check_goce_sueldo}
              />
            </Col>
            <Col desktop={3}>
              <NewInputSelect
                name={"tipo_goce_sueldo"}
                labelName={"Tipo goce sueldo"}
                onChange={handleChange}
                placeholder={"Tipo goce sueldo"}
                options={tiposGoceSueldo}
                value={inputValues.tipo_goce_sueldo}
              />
              {formErrors.tipo_goce_sueldo && (
                <ErrorInput className="error">
                  {formErrors.tipo_goce_sueldo}
                </ErrorInput>
              )}
            </Col>

            {parseInt(inputValues.tipo_goce_sueldo) ===
            ID_OTROS_TIPO_GOCE_SUELDO ? (
              <Col desktop={3}>
                <NewInputBasic
                  labelName={"Observación"}
                  name={"tipo_goce_sueldo_otros"}
                  placeholder={"Observación"}
                  onChange={handleChange}
                  value={inputValues.tipo_goce_sueldo_otros}
                />
                {formErrors.tipo_goce_sueldo_otros && (
                  <ErrorInput className="error">
                    {formErrors.tipo_goce_sueldo_otros}
                  </ErrorInput>
                )}
              </Col>
            ) : null}
            <Col desktop={3}>
              <NewInputBasic
                labelName={"Motivo sin goce (opcional)"}
                name={"motivo_sin_goce_sueldo"}
                placeholder={"Motivo sin goce de sueldo"}
                onChange={handleChange}
                value={inputValues.motivo_sin_goce_sueldo}
              />
              {formErrors.motivo_sin_goce_sueldo && (
                <ErrorInput className="error">
                  {formErrors.motivo_sin_goce_sueldo}
                </ErrorInput>
              )}
            </Col>

          </Grid>
        </Col>
      ) : null}
    </Grid>
  );
};

export default TipoExcepcion;
