import React, { useEffect, useState } from 'react'
import axiosRequest from '../../../../utils/axiosConfig'
import { Col, Grid } from '../../../Grid'
import { Container, Premio } from './styled'
import NewButton from '../../../NewButton'
import { FiEdit } from 'react-icons/fi'
import ModalEditarPremio from '../../../Modals/Premios/ModalEditarPremio'
import useModal from '../../../../hooks/useModal'
import SpinnerLoading from '../../../SpinnerLoading'

const PremiosTab = () => {

  const [premios, setPremios] = useState([])
  const [premioSelected, setPremioSelected] = useState(null)
  const modal = useModal()

  const openModal = (premio) => {
    setPremioSelected(premio)
    modal.setIsModalOpen(true)
  }

  const getPremios = async () => {
    try {
        const premios = await axiosRequest.get("/premios")
        setPremios(premios.data)
    } catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    getPremios()
  }, [])  

  const PREMIOS_COMERCIAL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 25, 26, 27, 28]
  const PREMIOS_CALIDAD = [5, 8, 12, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

  return (
    <Container>
        {modal.isModalOpen && premioSelected ? <ModalEditarPremio modal={modal} premio={premioSelected} refreshPremios={getPremios}/> : null}
        <Grid>
        {premios.length > 0 ? premios.map(premio => {
            return (
                <Col desktop={6}>
                    <Premio>
                        <div className='titulo'>
                            <div className='titulo-data'>
                                <h4>{premio.id_vendedor_premio} - {premio.nombre}</h4>
                                <div className='tipos-container'>
                                {PREMIOS_COMERCIAL.includes(premio.id_vendedor_premio) ? <><span className='tipo-premio-tag'>Interno</span><span className='tipo-premio-tag'>Externo</span></> : null}
                                {PREMIOS_CALIDAD.includes(premio.id_vendedor_premio) ?<span className='tipo-premio-tag'>Calidad</span> : null}
                                </div>
                            </div>
                            <NewButton textColor={true} borderColor={false} backgroundColor={false} onClick={() => openModal(premio)}><FiEdit /> Editar</NewButton>
                        </div>
                        <p>{premio.descripcion}</p>
                    </Premio>
                </Col>
            )
        })
        : 
        <Col desktop={12}><SpinnerLoading text={"Cargando premios..."}/></Col>
        }
        </Grid>
    </Container>
  )
}

export default PremiosTab