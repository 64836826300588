import Modal from "../../../Modal"
import { ModalHistorialContainer } from "../../../InfoBoard/styled"
import Titulo from "../../../Titulo"
import { Grid, Col } from "../../../Grid"
import Button from "../../../Button"
import InputBasic from "../../../InputBasic"
import { ContainerBotones, Usuario, Icon, RechazoContainer } from "./styled"
import ErrorInput from "../../../ErrorInput"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { VscLock } from 'react-icons/vsc'
import { FaUserCircle } from 'react-icons/fa'
// React
import { useState } from "react"
import Subtitulo from "../../../Subtitulo"
import NewTitle from "../../../NewTitle"
import NewButton from "../../../NewButton"
import { useLoading } from "../../../../hooks/useLoading"
import NewInputBasic from "../../../NewInputBasic"
import Divider from "../../../Divider"

const ModalNuevaClave = ({ modal, form, usuario }) => {
    console.log(modal)
    const { isModalOpen, handleModal } = modal
    const { inputValues, handleChange, formErrors, handleValidation } = form 

    const [inputTypeClave, setInputTypeClave] = useState('password')
    const [inputTypeClaveRepetida, setInputTypeRepetida] = useState('password')

    const { loading } = useLoading()

    return (
      <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
          <NewTitle>
            Generar contraseña
          </NewTitle>
          <p>Modificar contraseña del usuario</p>
          </div>
          <Divider />
          <div className="data-container">
          <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                <NewInputBasic
                  type={inputTypeClave}
                  name={"clave_usuario"}
                  labelName={"Nueva Contraseña"}
                  id={"clave_usuario"}
                  onChange={handleChange}
                  placeholder={"Nueva contraseña"}
                  value={inputValues.clave_usuario}
                  color={formErrors.clave_usuario ? "error" : ""}
                  iconImage={inputTypeClave === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeClave(inputTypeClave === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario && <ErrorInput>{formErrors.clave_usuario}</ErrorInput>}
              </Col>
              <Col desktop={12}>
                <NewInputBasic
                  type={inputTypeClaveRepetida}
                  name={"clave_usuario_repetida"}
                  labelName={"Repetir Contraseña"}
                  id={"clave_usuario_repetida"}
                  placeholder={"Repetir contraseña ingresada"}
                  onChange={handleChange}
                  value={inputValues.clave_usuario_repetida}
                  color={formErrors.clave_usuario_repetida ? "error" : ""}
                  iconImage={inputTypeClaveRepetida === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeRepetida(inputTypeClaveRepetida === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario_repetida && <ErrorInput>{formErrors.clave_usuario_repetida}</ErrorInput>}
              </Col>
            </Grid>
          </div>
          <Divider />
          <div className={"actionButtonsCont"}>
            <div className={"buttonsCont"}>
              <NewButton
                onClick={handleModal}
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
              >
                Cancelar
              </NewButton>
              <NewButton
                onClick={handleValidation}
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                disabled={loading}
              >
                {loading ? "Generando contraseña..." : "Generar contraseña"}
              </NewButton>
            </div>
          </div>
        </RechazoContainer>
      </Modal>
    </>
    );
}

export default ModalNuevaClave

/*

      <div>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
          <ModalHistorialContainer>
            <Titulo>Generar contraseña</Titulo>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                {usuario && <Usuario><Icon><FaUserCircle /></Icon><strong>{usuario.nombre_operador} {usuario.apellido_operador}</strong> ・ {usuario.usuario_usuario} ・ {usuario.titulo_rol}</Usuario>}
                <InputBasic
                  type={inputTypeClave}
                  name={"clave_usuario"}
                  labelName={"Nueva Contraseña"}
                  id={"clave_usuario"}
                  onChange={handleChange}
                  value={inputValues.clave_usuario}
                  color={formErrors.clave_usuario ? "error" : ""}
                  iconImage={inputTypeClave === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeClave(inputTypeClave === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario && <ErrorInput>{formErrors.clave_usuario}</ErrorInput>}
              </Col>
              <Col desktop={12}>
                <InputBasic
                  type={inputTypeClaveRepetida}
                  name={"clave_usuario_repetida"}
                  labelName={"Repetir Contraseña"}
                  id={"clave_usuario_repetida"}
                  onChange={handleChange}
                  value={inputValues.clave_usuario_repetida}
                  color={formErrors.clave_usuario_repetida ? "error" : ""}
                  iconImage={inputTypeClaveRepetida === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeRepetida(inputTypeClaveRepetida === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario_repetida && <ErrorInput>{formErrors.clave_usuario_repetida}</ErrorInput>}
              </Col>
              <Col desktop={12}>
                <ContainerBotones>
                    <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        onClick={setIsModalOpen}
                        target={"_blank"}
                    >
                        Cancelar
                    </Button>
                    <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        onClick={handleValidation}
                        target={"_blank"}
                    >
                        <span><VscLock /></span>Generar contraseña
                    </Button>
                </ContainerBotones>
              </Col>
            </Grid>
          </ModalHistorialContainer>
        </Modal>
      </div>

*/