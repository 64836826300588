import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  p {
    margin: 0 0 0px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)};
  }

  h4 {
    margin: 10px 0;
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .data-icon {
    font-size: 24px;
  }

  .buttons-container {
    display: flex;
    width: 100%;
    gap: 10px;

    a {
      text-decoration: none;
    }
  }

  .premio-container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;

    span {
      text-transform: capitalize;
    }

    a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}


    span {
        font-size: 14px;
        color: ${({ theme }) => theme.subtitle};
        font-weight: 400;
    }

    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 24px;
        color: ${({ theme }) => theme.title};
      }

    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }

    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

  .total {
    border: 1px solid ${({ theme }) => theme.total_card_border};
    color: ${leerColor(colores.blanco)};
    background-color: ${({ theme }) => theme.total_card_background};

    span, h2{
      color: ${leerColor(colores.blanco)};
    }
  }

`;

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)};
  }
`;

export const Icon = styled.span`
  font-size: 60px;
  display: flex;
  color: ${leerColor(colores.celeste)};
`;

export const Breadcrumb = styled.span`
  display: flex;
  font-size: 14px;
  color: ${leerColor(colores.grisEstados)};
  gap: 10px;
  a {
    align-items: center;
      gap: 6px;
      font-weight: 400;
      margin-bottom: 20px;
      text-decoration: none;
      color: ${leerColor(colores.grisEstados)};

  }

  .vendedor-nombre {
    cursor: pointer;
  }



  a:hover, .vendedor-nombre:hover{
    opacity: 0.6;
  }

  .active {
    color: ${leerColor(colores.azulRebranding)}
  }
`;
