import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.background};
  transition: all 0.5s;

  .profile {
    width: 30px;
    border-radius: 100%;
    border: 1px solid #eaeaea;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 0;
  }

  .first-of-type {
    margin-top: 60px;
  }

  .sectionTitle {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .sectionTitle h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${({ theme }) => theme.title};
  }

  p {
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
    margin-bottom: 0;
  }
  .icon {
    display: flex;
    color: #1a56ff;
  }
`;
