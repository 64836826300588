import NewInputBasic from "../../../NewInputBasic";
import { Grid, Col } from "../../../Grid";
import ErrorInput from "../../../ErrorInput";
import NewInputCalendar from "../../../NewInputCalendar";
import Subtitle from "../../../Subtitle";
import {
  FiAlertOctagon,
  FiArrowLeft,
  FiFile,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import NewInputSelect from "../../../NewInputSelect";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Divider from "../../../Divider";
import NewInputTextarea from "../../../NewInputTextarea";
import { OperadorContainer } from "./styled";
import { useState } from "react";
import { useEffect } from "react";
import InputFile from "../../../InputFile";

const NuevaAnulacionForm = ({ form, formProducto, data }) => {
  const { inputValues, handleChange, formErrors } = form;
  const {
    motivos,
    companias,
    productos,
    operadoresIds,
    operadorSeleccionado,
    setOperadorSeleccionado,
    camposProducto,
  } = data;

  console.log(inputValues);

  return (
    <>
      <Subtitle
        icon={<FiUser />}
        title={"Datos personales"}
        description={
          "Información del asegurado que solicitá la anulación de su póliza"
        }
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"nombre_asegurado"}
            labelName={"Nombre"}
            placeholder={"Nombre"}
            onChange={handleChange}
            color={formErrors.nombre_asegurado ? "error" : ""}
            value={inputValues.nombre_asegurado}
          />
          {formErrors.nombre_asegurado && (
            <ErrorInput>{formErrors.nombre_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"apellido_asegurado"}
            labelName={"Apellido"}
            placeholder={"Apellido"}
            onChange={handleChange}
            color={formErrors.apellido_asegurado ? "error" : ""}
            value={inputValues.apellido_asegurado}
          />
          {formErrors.apellido_asegurado && (
            <ErrorInput>{formErrors.apellido_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"dni_asegurado"}
            labelName={"DNI"}
            placeholder={"DNI"}
            onChange={handleChange}
            color={formErrors.dni_asegurado ? "error" : ""}
            value={inputValues.dni_asegurado}
          />
          {formErrors.dni_asegurado && (
            <ErrorInput>{formErrors.dni_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"cuil_asegurado"}
            labelName={"CUIL"}
            placeholder={"CUIL"}
            onChange={handleChange}
            color={formErrors.cuil_asegurado ? "error" : ""}
            value={inputValues.cuil_asegurado}
          />
          {formErrors.cuil_asegurado && (
            <ErrorInput>{formErrors.cuil_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"email"}
            name={"email_asegurado"}
            labelName={"Correo Electrónico"}
            placeholder={"hola@seguroweb.com.ar"}
            onChange={handleChange}
            color={formErrors.email_asegurado ? "error" : ""}
            value={inputValues.email_asegurado}
          />
          {formErrors.email_asegurado && (
            <ErrorInput>{formErrors.email_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"telefono_asegurado"}
            labelName={"Teléfono"}
            placeholder={"1545948492"}
            onChange={handleChange}
            color={formErrors.telefono_asegurado ? "error" : ""}
            value={inputValues.telefono_asegurado}
          />
          {formErrors.telefono_asegurado && (
            <ErrorInput>{formErrors.telefono_asegurado}</ErrorInput>
          )}
        </Col>
      </Grid>

      <Divider />

      <Subtitle
        icon={<FiFile />}
       title={"Póliza"}
        description={"Información de la póliza que se solicita anular"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputSelect
            type={"text"}
            name={"producto_asegurado"}
            labelName={"Producto"}
            onChange={handleChange}
            options={productos}
            placeholder={"Producto"}
            value={inputValues.producto_asegurado}
            color={formErrors.producto_asegurado ? "error" : ""}
          />
          {formErrors.producto_asegurado && (
            <ErrorInput>{formErrors.producto_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"poliza_asegurado"}
            labelName={"Póliza"}
            placeholder={"Póliza"}
            onChange={handleChange}
            color={formErrors.poliza_asegurado ? "error" : ""}
            value={inputValues.poliza_asegurado}
            required
          />
          {formErrors.poliza_asegurado && (
            <ErrorInput>{formErrors.poliza_asegurado}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            type={"text"}
            name={"compania_asegurado"}
            labelName={"Compañia"}
            onChange={handleChange}
            options={companias}
            placeholder={"Compañia"}
            value={inputValues.compania_asegurado}
            color={formErrors.compania_asegurado ? "error" : ""}
          />
          {formErrors.compania_asegurado && (
            <ErrorInput>{formErrors.compania_asegurado}</ErrorInput>
          )}
        </Col>
        {/* <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"patente"}
            labelName={"Patente"}
            placeholder={"Patente"}
            onChange={handleChange}
            color={formErrors.patente ? "error" : ""}
            value={inputValues.patente}
            required
          />
          {formErrors.patente && <ErrorInput>{formErrors.patente}</ErrorInput>}
        </Col> */}
      </Grid>

      {camposProducto.length > 0 ?
      <>
      <Divider />

      <Subtitle
        icon={<FiFile />}
        title={"Producto"}
        description={"Información del producto asociado a la póliza"}
        disableDivider={true}
      />

      <Grid colGap={21} rowGap={21} narrow={false}>
        {camposProducto.length > 0
          ? camposProducto.map((campo) => {
              if (
                campo.tipo_campo === "text" ||
                campo.tipo_campo === "number"
              ) {
                return (
                  <Col desktop={3}>
                    <NewInputBasic
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={
                        formProducto.formErrors[campo.name_campo]
                          ? "error"
                          : "normal"
                      }
                      placeholder={campo.placeholder_campo}
                      onChange={formProducto.handleChange}
                      value={formProducto.inputValues[campo.name_campo]}
                      disabled={false}
                    />
                    {formProducto.formErrors[campo.name_campo] && (
                      <ErrorInput>
                        {formProducto.formErrors[campo.name_campo]}
                      </ErrorInput>
                    )}
                  </Col>
                );
              }
              if (campo.tipo_campo === "file") {
                return (
                  <Col desktop={4}>
                    <InputFile
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      required={false}
                      onChange={formProducto.handleChangeExternal}
                      file={formProducto.inputValues[campo.name_campo]}
                      text={"Adjuntar" + " " + campo.label_campo}
                      accept={campo.extensiones_campo}
                    />
                    {formProducto.formErrors[campo.name_campo] && (
                      <ErrorInput>
                        {formProducto.formErrors[campo.name_campo]}
                      </ErrorInput>
                    )}
                  </Col>
                );
              }
            })
          : null}
      </Grid>
      </>
      : null}

      <Divider />

      <Subtitle
        icon={<FiAlertOctagon />}
        title={"Motivo de Anulación"}
        description={"Motivo por el cuál el asegurado quiere anular su póliza"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={3}>
          <NewInputSelect
            type={"text"}
            name={"motivo_anulacion"}
            labelName={"Motivo de Anulación"}
            onChange={handleChange}
            options={motivos}
            placeholder={"Motivo"}
            value={inputValues.motivo_anulacion}
            color={formErrors.motivo_anulacion ? "error" : ""}
          />
          {formErrors.motivo_anulacion && (
            <ErrorInput>{formErrors.motivo_anulacion}</ErrorInput>
          )}
        </Col>
        {inputValues.motivo_anulacion == 5 ?
        <Col desktop={12}>
          <NewInputTextarea
            name={"motivo_anulacion_otros"}
            placeholder={"Breve descripción del motivo de la anulación"}
            onChange={handleChange}
            labelName={"Describí el motivo de anulación"}
          ></NewInputTextarea>
        </Col>
        : null}
      </Grid>

      <Divider />

      <Subtitle
        icon={<FiUsers />}
        title={"Operador de anulación"}
        description={"Seleccioná un operador para asignar la anulación"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={false}>
        {operadoresIds.length > 0
          ? operadoresIds.map((op) => {
              return (
                <Col desktop={3}>
                  <OperadorContainer>
                    <div
                      className={
                        operadorSeleccionado?.value === op.value
                          ? "operador operador-seleccionado"
                          : "operador"
                      }
                      onClick={() => setOperadorSeleccionado(op)}
                    >
                      <span className="icon">
                        <FiUser />
                      </span>
                      <h4>{op.label}</h4>
                    </div>
                  </OperadorContainer>
                </Col>
              );
            })
          : null}
      </Grid>
    </>
  );
};

export default NuevaAnulacionForm;
