import React from 'react'
import { Col, Grid } from '../../../../../components/Grid'
import Subtitle from '../../../../../components/Subtitle'
import { FiUser } from 'react-icons/fi'
import NewInputSelect from '../../../../../components/NewInputSelect'
import ErrorInput from '../../../../../components/ErrorInput'

const UsuarioExcepcion = ({ form, data  }) => {
  const { usuarios } = data
  const { inputValues, handleChange, formErrors } = form   

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
    <Col desktop={9}>
      <Subtitle
        title="Usuario"
        description="Elegí el usuario para el cuál crear la excepción"
        icon={<FiUser />}
        disableDivider={true}
      />
    </Col>
    <Col desktop={3}>
      <NewInputSelect
        name={"usuario"}
        onChange={handleChange}
        placeholder={"Usuario"}
        options={usuarios}
        value={inputValues.usuario}
      />
      {formErrors.usuario && (
        <ErrorInput className="error">
          {formErrors.usuario}
        </ErrorInput>
      )}
    </Col>
  </Grid>
  )
}

export default UsuarioExcepcion