import { useState } from 'react'
import NewTitlePageSection from '../../../components/NewTitlePageSection'
import { Col, Grid } from '../../../components/Grid'
import NewButton from '../../../components/NewButton'
import NewWrapper from '../../../components/NewWrapper'
import NewInner from '../../../components/NewInner'
import Divider from '../../../components/Divider'
import { Container } from './styled'
import { FiCalendar } from 'react-icons/fi'
import dayjs from "dayjs"
import Layout from "../../../components/Layout"
import ModalFichajes from '../../../components/Modals/Fichajes/ModalFichajes'
import useModal from '../../../hooks/useModal'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import isBetween from "dayjs/plugin/isBetween"
import { useFichajes } from './hooks/useFichajes'
import EstadisticasMesFichajes from '../../../components/EstadisticasMesFichajes'
import CumplimientoAnualFichajes from '../../../components/CumplimientoAnualFichajes'
import ExcepcionesFichajes from '../../../components/ExcepcionesFichajes'
import CalendarioFichajes from '../../../components/CalendarioFichajes'
import UsuarioFichajes from '../../../components/UsuarioFichajes'
dayjs.extend(isBetween);

const FichajesRefactor = () => {

  const [mes, setMes] = useState(new Date().getMonth())
  const [anio, setAnio] = useState(new Date().getFullYear())

  const id_rol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol
  const rolesPermitidosExcepciones = [1, 16]

  const { data, methods } = useFichajes(mes, anio)
  const { usuario, usuarios, usuariosData, fichajesDiaActual, fichajes, calendario, excepciones, estadisticasAnuales, estadisticasMensuales, mesEstadisticasMensuales, dia, loadingFichajes } = data
  const { refreshFichajes, handleDiaFichaje, handleChangeUsuario, handleChangeMesEstadisticas } = methods

  const fichajesModal = useModal()

  return (
    <>
        <ModalFichajes modal={fichajesModal} fichajes={fichajesDiaActual} refreshFichajes={refreshFichajes} usuarios={usuarios} usuariosData={usuariosData} />
        <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <NewTitlePageSection title="Fichajes" description="Lista y estadísticas de tu actividad">
                    {rolesPermitidosExcepciones.find(id => id === id_rol) ? <Link to="/Excepcion"><NewButton backgroundColor={false} textColor={true} borderColor={true} ><FiCalendar /> Crear Excepción</NewButton></Link> : null}
                    <NewButton backgroundColor={true} onClick={fichajesModal.handleModal}><FiCalendar /> Fichar</NewButton>
                  </NewTitlePageSection>
                </Col>
              </Grid>
              {rolesPermitidosExcepciones.find(id => id === id_rol) ?
              <UsuarioFichajes data={{ usuario, usuarios, handleChangeUsuario }}/>
              : null}
              <Divider />
              <CalendarioFichajes data={{ dia, mes, anio, calendario, handleDiaFichaje,fichajes, loadingFichajes, id_rol, usuarios, usuario, setMes, setAnio }} />
              <Divider />
              <EstadisticasMesFichajes data={{ usuarios, usuario, mesEstadisticasMensuales, handleChangeMesEstadisticas, anio, setAnio, estadisticasMensuales }} />
              <Divider />
              <CumplimientoAnualFichajes data={{ usuarios, usuario, setAnio, anio, estadisticasAnuales }} />
              <Divider />
              <ExcepcionesFichajes data={{ anio, setAnio, excepciones }} />
            </Container>
          </NewInner>
        </NewWrapper>
        </Layout>
    </>
  )
}

export default FichajesRefactor