import React from "react";
import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import Success from "../../../Success";

const ModalExito = ({ modal }) => {
  const { isModalOpen, handleModal } = modal;

  const handleCloseModal = () => {
    handleModal();
  };

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
            <Success
              title="¡Comisiones guardadas!"
              description="Los datos de comisiones fueron guardados correctamente"
              handleModal={handleCloseModal}
            />
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalExito;
