import { FiUser } from "react-icons/fi";
import { Col, Grid } from "../../../../../components/Grid";
import Subtitle from "../../../../../components/Subtitle";
import NewInputBasic from "../../../../../components/NewInputBasic";
import ErrorInput from "../../../../../components/ErrorInput";

const HorarioExcepcion = ({ form }) => {
  const { inputValues, handleChange, formErrors } = form   

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <Subtitle
          title="Horario"
          description="Establecé el horario para la excepción"
          icon={<FiUser />}
          disableDivider={true}
        />
      </Col>
      <Col desktop={3}>
        <NewInputBasic
          labelName={"Hora Desde"}
          name={"hora_desde"}
          placeholder={"09:00"}
          onChange={handleChange}
          value={inputValues.hora_desde}
        />
        {formErrors.hora_desde && (
          <ErrorInput className="error">{formErrors.hora_desde}</ErrorInput>
        )}
      </Col>
      <Col desktop={3}>
        <NewInputBasic
          labelName={"Hora Hasta"}
          name={"hora_hasta"}
          placeholder={"18:00"}
          onChange={handleChange}
          value={inputValues.hora_hasta}
        />
        {formErrors.hora_hasta && (
          <ErrorInput className="error">{formErrors.hora_hasta}</ErrorInput>
        )}
      </Col>
    </Grid>
  );
};

export default HorarioExcepcion;
