import Layout from "../../../components/Layout";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import { Container, PrioridadContainer } from "./styled";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import { controlCalidadAnulacionesTable } from "../../../utils/tableData";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import NewButton from "../../../components/NewButton";
import { useSingleOpen } from "../../../hooks/useSingleOpen";
import { FiBarChart, FiFilter, FiPlus, FiUserCheck } from "react-icons/fi";
import { Col, Grid } from "../../../components/Grid";
import NewFiltersSection from "../../../components/NewFiltersSection";
import useFiltersNew from "../../../hooks/useFiltersNew";
import {
  filterControlCalidadAnulaciones,
  filterControlCalidadAnulacionesInitialValues,
} from "../../../utils/filtersPages";
import { useAsignacionControlCalidad } from "../../../hooks/useAsignacionControlCalidad";
import ModalAsignacionCalidad from "../../../components/Modals/ControlCalidad/ModalAsignacionCalidad";
import useControlCalidad from "./hooks/useControlCalidad";
import Subtitle from "../../../components/Subtitle";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import BarChart from "../../../components/BarChart";
import { useTheme } from "../../../context/ThemeContext";
import Divider from "../../../components/Divider";
import PieChart from "../../../components/PieChart";
import NewInputSelect from "../../../components/NewInputSelect";
import { anios, meses } from "../../../constants/data";
import { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";

const ControlCalidad = () => {
  const location = useLocation();
  const [mes, setMes] = useState(
    location.state?.mes ? location.state.mes : new Date().getMonth()
  );
  const [anio, setAnio] = useState(
    location.state?.anio ? location.state.anio : new Date().getFullYear()
  );
  const { order, handleTableOrder } = useTable();
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } =
    useSingleOpen();
  const { inputValues, params, handleSearch, handleChange, clearSearch } =
    useFiltersNew(filterControlCalidadAnulacionesInitialValues);
  const { data, estadisticas, refreshAnulaciones, loading, pagination } =
    useControlCalidad(params, order, mes, anio);
  const { authState } = useAuthContext();
  const { userInfo } = authState;
  const id_rol = userInfo.id_rol;

  const filters = filterControlCalidadAnulaciones(data);
  const asignacionModal = useAsignacionControlCalidad(refreshAnulaciones);
  const tableData = controlCalidadAnulacionesTable(
    asignacionModal.seleccionarAnulacion,
    asignacionModal.anulacionesSeleccionadas,
    location
  );
  const { handleTab, tabSelected } = useTabs();
  const { theme } = useTheme();

  const CONTROL_CALIDAD_TABS = [
    {
      name: "Anulaciones",
    },
    {
      name: "Estadísticas",
    },
  ];
  
  return (
    <div>
      <ModalAsignacionCalidad
        isModalOpen={asignacionModal.modal.isModalOpen}
        setIsModalOpen={asignacionModal.modal.handleModal}
        anulaciones={asignacionModal.anulacionesSeleccionadas}
        removerAnulacion={asignacionModal.removerAnulacion}
        asignarAnulacion={asignacionModal.asignarAnulacion}
        operadores={data.operadoresIds}
        handleChangeOperador={asignacionModal.handleChangeOperador}
        loading={asignacionModal.loading}
        anulacionesAsignadas={asignacionModal.anulacionesAsignadas}
        operador={asignacionModal.operadorId}
      />
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <NewTitlePageSection
                title="Control Calidad"
                description="Lista de anulaciones cargadas desde el formulario de baja de Seguro Web"
              >
                <NewButton
                  backgroundColor={false}
                  textColor={true}
                  borderColor={true}
                  onClick={() => handleFiltersOpen(!isFiltersOpen)}
                >
                  <FiFilter /> Filtros
                </NewButton>

                {id_rol !== 18 ? (
                  <>
                    <Link to="/ControlCalidad/NuevaAnulacion">
                      <NewButton
                        backgroundColor={true}
                        textColor={false}
                        borderColor={false}
                      >
                        <span>
                          <FiPlus />
                        </span>
                        Nueva Anulación
                      </NewButton>
                    </Link>
                    <NewButton
                      backgroundColor={true}
                      textColor={false}
                      borderColor={false}
                      onClick={asignacionModal.modal.handleModal}
                    >
                      <span>
                        <FiUserCheck />
                      </span>
                      Asignar Anulaciones
                    </NewButton>
                  </>
                ) : null}

              </NewTitlePageSection>
              {isFiltersOpen ? (
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <NewFiltersSection
                      section="solicitudes"
                      inputs={filters}
                      values={inputValues}
                      params={params}
                      handleChange={handleChange}
                      handleSearch={handleSearch}
                      clearSearch={clearSearch}
                    />
                  </Col>
                </Grid>
              ) : null}

              <NewTabs
                tabs={CONTROL_CALIDAD_TABS}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {tabSelected === 0 ? (
                <>
                  <Subtitle
                    icon={<FiBarChart />}
                    title={`Niveles de prioridad`}
                    description={
                      "Prioridad de las anulaciones dependiendo de hace cuanto tiempo fueron cargadas"
                    }
                    disableDivider={true}
                  />

                  <PrioridadContainer>
                    <div className="prioridad">
                      <div className="subido_hace baja" />
                      Menos de 12 horas
                    </div>
                    <div className="prioridad">
                      <div className="subido_hace media" />
                      Entre 12 horas y 24 horas
                    </div>
                    <div className="prioridad">
                      <div className="subido_hace alta" />
                      Más de 24 horas
                    </div>
                  </PrioridadContainer>
                  
                  <NewTable
                    columns={tableData}
                    data={data.anulaciones}
                    loading={loading}
                    order={handleTableOrder}
                    orderParams={order}
                    pagination={pagination}
                  />
                </>
              ) : null}

              {tabSelected === 1 ? (
                <>
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={2}>
                      <NewInputSelect
                        name={"mes"}
                        labelName={"Mes"}
                        onChange={(e) => setMes(e.target.value)}
                        placeholder={"Mes"}
                        options={meses}
                        value={mes}
                      />
                    </Col>
                    <Col desktop={2}>
                      <NewInputSelect
                        name={"anio"}
                        labelName={"Año"}
                        onChange={(e) => setAnio(e.target.value)}
                        placeholder={"Año"}
                        options={anios}
                        value={anio}
                      />
                    </Col>
                    <Col desktop={12}>
                      <Divider />
                    </Col>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={`Compañias`}
                        description={
                          "Visualización de cantidad de anulaciones registradas por compañia"
                        }
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={12}>
                      <BarChart
                        data={estadisticas.companias}
                        optionsData={{
                          responsive: true,
                          scales: {
                            x: {
                              stacked: false,
                              ticks: {
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                              grid: {
                                borderColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                tickColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                color: theme === "dark" ? "#243158" : "#e5e5e5",
                              },
                            },
                            y: {
                              stacked: false,
                              ticks: {
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                              grid: {
                                borderColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                tickColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                color: theme === "dark" ? "#243158" : "#e5e5e5",
                              },
                            },
                          },
                          plugins: {
                            title: {
                              display: true,
                              color: theme === "dark" ? "#ffffff" : "#666666",
                              text: `Anulaciones por compañia`,
                            },
                            legend: {
                              display: true,
                              labels: {
                                boxWidth: 10,
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                            },
                          },
                        }}
                      />
                    </Col>
                    <Col desktop={12}>
                      <Divider />
                    </Col>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={`Productos`}
                        description={
                          "Visualización de cantidad de anulaciones registradas por producto"
                        }
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={12}>
                      <BarChart
                        data={estadisticas.productos}
                        optionsData={{
                          responsive: true,
                          scales: {
                            x: {
                              stacked: false,
                              ticks: {
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                              grid: {
                                borderColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                tickColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                color: theme === "dark" ? "#243158" : "#e5e5e5",
                              },
                            },
                            y: {
                              stacked: false,
                              ticks: {
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                              grid: {
                                borderColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                tickColor:
                                  theme === "dark" ? "#243158" : "#e5e5e5",
                                color: theme === "dark" ? "#243158" : "#e5e5e5",
                              },
                            },
                          },
                          plugins: {
                            title: {
                              display: true,
                              color: theme === "dark" ? "#ffffff" : "#666666",
                              text: `Anulaciones por producto`,
                            },
                            legend: {
                              display: true,
                              labels: {
                                boxWidth: 10,
                                color: theme === "dark" ? "#ffffff" : "#666666",
                              },
                            },
                          },
                        }}
                      />
                    </Col>
                    <Col desktop={12}>
                      <Divider />
                    </Col>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={`Motivos`}
                        description={"Cantidad de anulaciones por motivos"}
                        disableDivider={true}
                      />
                    </Col>

                    <Col desktop={4}>
                      <div className="pie">
                        <PieChart
                          data={estadisticas.motivos}
                          optionsData={{
                            plugins: {
                              title: {
                                display: true,
                                color: theme === "dark" ? "#ffffff" : "#666666",
                                text: `Motivos de anulaciones`,
                              },
                              legend: {
                                display: true,
                                labels: {
                                  boxWidth: 10,
                                  color:
                                    theme === "dark" ? "#ffffff" : "#666666",
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Grid>
                </>
              ) : null}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </div>
  );
};

export default ControlCalidad;
