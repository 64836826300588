import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.span`
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 0px 0 30px 0;
  }
`;

export const Error = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
  font-size: 14px;
`;

export const BuscarCliente = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  //padding-bottom: 20px;
  //margin-bottom: 40px;

  .container-botones {
    display: flex;
    gap: 10px;
  }
  /*
  div{
    width: 85%;
  }
  */
`;

export const Icon = styled.img`
  width: 16px;
  margin-right:10px;
`;

export const RemoveContainer = styled.div`
  display:flex;
  padding: 10px 20px;
  margin-bottom:20px;
  color: hsl(0,0%,32.2%);
  background-color: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.rojo)};
  border-radius:5px;
  //width: 350px;
  cursor: pointer;
  justify-content: center;

  span{
    font-weight: 600;
    color: ${leerColor(colores.rojo)};
    font-size: 14px;
  }

  :hover{
    opacity: 0.8;
  }
`;

export const ModalClienteEncontrado = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 600px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: ${({ theme }) => theme.title};
      font-weight: 500;
    }
    .motivo {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        cursor: pointer;
        //transition: 0.2s;
    }

    .motivo-selected {
        color: ${leerColor(colores.azulRebranding)};
        border: 1px solid ${leerColor(colores.azulRebranding)};
        font-weight: 500;
    }

    .motivo:hover {
        opacity: 0.8s;
    }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.subtitle};

    strong {
      color: ${leerColor(colores.azulRebranding)};
    }
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
`;