import React from "react";
import { Col, Grid } from "../../../Grid";
import EditarPremioForm from "../../../PremiosForm/EditarPremioForm";
import SpinnerLoading from "../../../SpinnerLoading";
import Divider from "../../../Divider";
import { FiStar } from "react-icons/fi";
import CardSinResultados from "../../../CardSinResultados";

const VendedorEdicionPremiosTab = ({ data }) => {
  const { premios, vendedor, vendedorNombre, mes, anio, loading } = data;
  
  return (
    <>
      <Divider />
      {loading ? <SpinnerLoading text={"Cargando información de premios..."} /> : null}
      {premios.length === 0 && !loading ? (
        <CardSinResultados
          icon={<FiStar />}
          title="No hay premios"
          description="No se encontraron premios disponible para el mes y año seleccionados"
        />
      ) : null}
      {premios.length > 0 && !loading ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {premios.map((prem, index) => {
            return (
              <Col desktop={12}>
                <EditarPremioForm
                  prem={prem}
                  vendedorId={vendedor ? vendedor.id : ''}
                  vendedor={vendedorNombre ?? ''}
                  mes={mes}
                  anio={anio}
                />
              </Col>
            );
          })}
        </Grid>
      ) : null}
    </>
  );
};

export default VendedorEdicionPremiosTab;

/*
          <SpinnerLoading text={"Cargando información de premios..."} />

*/
