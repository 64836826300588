import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewTabs from "../../../NewTabs";
import Divider from "../../../Divider";
import Success from "../../../Success";
import useModalFichajes from "./hooks/useModalFichajes";
import FichajesRecurrentesTab from "./components/FichajesRecurrentesTab"
import FichajesEspecialesTab from "./components/FichajesEspecialesTab"
import FichajesPorUsuarioTab from "./components/FichajesPorUsuarioTab";

const ModalFichajes = ({ modal, fichajes, refreshFichajes, usuarios, usuariosData }) => {
  const { isModalOpen, handleModal } = modal;

  const { fichajes_usuario_actual, fichajes_por_usuario, acciones, data, fichajeForm, fichajeUsuarioForm, tabs_modal } = useModalFichajes({ modal, fichajes, refreshFichajes, usuarios, usuariosData })

  // Fichajes del usuario logueado actualmente
  const { ingreso, inicio_almuerzo, fin_almuerzo, salida, salida_especial, reingreso_especial} = fichajes_usuario_actual
  // Fichajes del usuario seleccionado en la tab "Fichar por usuario"
  const { ingreso_fichaje_usuario, inicio_almuerzo_fichaje_usuario, fin_almuerzo_fichaje_usuario, salida_fichaje_usuario,salida_especial_fichaje_usuario, reingreso_especial_fichaje_usuario } = fichajes_por_usuario

  // Funciones utilizadas en el modal de Fichajes
  const { resetModal, seleccionarFichaje, handleTab } = acciones

  // Estados 
  const { tabs, tabSelected, success, fichajeSeleccionado, rolesComerciales, usuarioComercial, id_rol } = data

  // Formulario de fichajes para el usuario logueado 
  const { handleValidation } = fichajeUsuarioForm
  
  // Index de cada tab del modal
  const { FICHAJES_RECURRENTES_TAB, FICHAJES_ESPECIALES_TAB, FICHAJES_POR_USUARIO_TAB} = tabs_modal

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
              <div className="header-container">
                <NewTitle>Fichar</NewTitle>
                <p>
                  Cargá tu actividad en el CRM
                </p>
              </div>
              <Divider />
              {!success ?
              <>
                <div className="data-container">
                    <NewTabs
                        tabs={tabs}
                        tabSelected={tabSelected}
                        handleTab={handleTab}
                    /> 
                    {tabSelected === FICHAJES_RECURRENTES_TAB ?
                        <FichajesRecurrentesTab data={{ fichajeSeleccionado, rolesComerciales, id_rol, usuarioComercial }} fichajes={{ ingreso, inicio_almuerzo, fin_almuerzo, salida }} acciones={{ seleccionarFichaje }} formError={fichajeForm.formErrors.fichaje} />
                    : null}
                    {tabSelected === FICHAJES_ESPECIALES_TAB ?
                        <FichajesEspecialesTab data={{ fichajeSeleccionado }} fichajes={{ ingreso, salida_especial, reingreso_especial }} acciones={{ seleccionarFichaje }} />
                    : null}
                    {tabSelected === FICHAJES_POR_USUARIO_TAB ?
                        <FichajesPorUsuarioTab data={{ fichajeSeleccionado, rolesComerciales, usuarios, usuarioComercial }} fichajes={{ ingreso_fichaje_usuario, inicio_almuerzo_fichaje_usuario, fin_almuerzo_fichaje_usuario, salida_fichaje_usuario,salida_especial_fichaje_usuario, reingreso_especial_fichaje_usuario }} acciones={{ seleccionarFichaje }} form={fichajeUsuarioForm} />
                    : null}
                </div>
                <Divider />
                <div className={"actionButtonsCont"}>
                    <div className="buttonsCont">
                    <NewButton
                        backgroundColor={false}
                        textColor={true}
                        borderColor={true}
                        onClick={resetModal}
                    >
                        Cancelar
                    </NewButton>
                    <NewButton backgroundColor={true} onClick={tabSelected === 2 ? handleValidation : fichajeForm.handleValidation} >
                        Fichar {fichajeSeleccionado}
                    </NewButton>
                    </div>
                </div>
              </>
              :
                <Success title="¡Fichaje creado!" description={`Se cargó el nuevo fichaje correctamente`} handleModal={resetModal} />
              }
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalFichajes;
