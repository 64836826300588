import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 20px 0 20px 0;
  }

  p {
    margin: 0;
    color: ${leerColor(colores.grisEstados)};
  }

  .landings-container {
    display: flex;
    gap: 40px;
  }

  img {
    width: auto;
    max-width: 206px;
    height: 29px;
  }

  .agregar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-agregar-container {
    div {
        width: 100%;
    }
    display: flex;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
  }

  .input-agregado {
    display: flex;
    align-items: center;
  }
`;
