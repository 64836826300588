import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const OperadorContainer = styled.div`
  .operador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;
    min-height: 116px;

    .icon {
      font-size: 24px;
      color: ${({ theme }) => theme.subtitle};
    }

    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 400;
      color: ${({ theme }) => theme.subtitle};
    }

    .asignadas {
      font-size: 12px;
      color: ${leerColor(colores.grisEstados)};
    }

    :hover {
      opacity: 0.5;
    }
  }

  .operador-seleccionado {
    border: 1px solid ${leerColor(colores.azulRebranding)};

    h4 {
      font-weight: 600;
      color: ${leerColor(colores.azulRebranding)};
    }

    .icon {
      color: ${leerColor(colores.azulRebranding)};
    }
  }
`;

