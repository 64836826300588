import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "./styled";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FiTarget, FiFile, FiBarChart2, FiUsers, FiXSquare, FiStar, FiFolder, FiCalendar, FiLink, FiLogOut, FiDollarSign, FiAlertTriangle, FiClipboard, FiInfo, FiShield } from 'react-icons/fi'
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import axiosRequest from "../../utils/axiosConfig";
import { rolesCampañas, rolesCapacitaciones, rolesLandings, rolesEstadisticasProductoCanal, rolesObjetivos, rolesFedPat, rolesFichajes, rolesSiniestros, rolesConsultas, rolesStorageApi, rolesEstadisticas, rolesClientes, rolesEmisiones, rolesUsuarios, rolesRechazos, rolesHistorialSolicitudes, rolesSolicitudEmision, rolesSynergys, rolesColaboradores, rolesPremios, rolesControlCalidad } from "../../constants/roles";
import GySLightLogo from "../../assets/images/logos/gys_light.svg"
import GySDarkLogo from "../../assets/images/logos/gys_dark.svg"
import { useTheme } from "../../context/ThemeContext";

const NewSidebar = () => {

    const location = useLocation()
    console.log(location)
    const node = useRef();
    //const [open, setOpen] = useState(false);
    // History - React Router
    const history = useHistory(); 
    const [username, setUsername] = useState('')
    const authContext = useContext(AuthContext);
    const userRole = JSON.parse(localStorage.getItem('userInfo'))
    console.log(userRole.id_vendedor_novedades)
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const { theme } = useTheme()
    const GYS_LOGO = theme === "dark" ? GySLightLogo : GySDarkLogo

    console.log()
    // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
    useEffect(() => {
      if(!authContext.isAuthenticated()){
        history.push("/");
      }
    }, [])

    // Obtener nombre de usuario
    useEffect(() => {
      if(userRole){
        const getUsername = async () => {
          const username = await axiosRequest.get(`/usuario_operador/${userRole.id_usuario}`)
          setUsername(username.data.nombre_operador)
        }
        getUsername()
      }
    }, [userRole])

    // Remueve el token del local storage
    const logout = () =>{
      localStorage.removeItem("userInfo");
      localStorage.removeItem("expiresIn");
      removeCookie('token')
    }

    const menuLinks = [
      {
        'path': '/Fichajes',
        'class': 'fichajes',
        'active': 'fichajes',
        'icon': <FiCalendar />,
        'name': 'Fichajes',
        'allowedRoles': rolesFichajes,
        'allowedUsuarios': [],
        'show': true
      }, 
      {
        'path': '/SolicitudEmision',
        'class': 'solicitudEmision',
        'active': 'solicitudEmision',
        'icon': <FiFile />,
        'name': 'Solicitudes de Emisión',
        'allowedRoles': rolesSolicitudEmision,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Emisiones',
        'class': 'emisiones',
        'active': 'emisiones',
        'icon': <FiFile />,
        'name': 'Emisiones',
        'allowedRoles': rolesEmisiones,
        'allowedUsuarios': [],
        'show': true
      }, 
      {
        'path': '/HistorialSolicitudes',
        'class': 'historialSolicitudes',
        'active': 'historialSolicitudes',
        'icon': <FiFile />,
        'name': 'Historial Solicitudes',
        'allowedRoles': rolesHistorialSolicitudes,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/EstadisticasProductoCanal',
        'class': 'estadisticas-producto-canal',
        'active': 'estadisticas-producto-canal',
        'icon': <FiBarChart2 />,
        'name': 'Est. Producto - Canal',
        'allowedRoles': rolesEstadisticasProductoCanal,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/EstadisticasCobranzas',
        'class': 'estadisticas-cobranzas',
        'active': 'estadisticas-cobranzas',
        'icon': <FiBarChart2 />,
        'name': 'Estadísticas Cobranzas',
        'allowedRoles': [1, 8, 4, 23],
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/ObjetivosLeads',
        'class': 'objetivos',
        'active': 'objetivos',
        'icon': <FiTarget />,
        'name': 'Objetivos Leads',
        'allowedRoles': rolesObjetivos,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/ObjetivosCobranzas',
        'class': 'objetivos-cobranzas',
        'active': 'objetivos-cobranzas',
        'icon': <FiTarget />,
        'name': 'Objetivos de Cobranzas',
        'allowedRoles': [1, 8],
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/ObjetivosPorVendedor',
        'class': 'objetivos-por-vendedor',
        'active': 'objetivos-por-vendedor',
        'icon': <FiTarget />,
        'name': 'Objetivos Por Vendedor',
        'allowedRoles': [1, 4, 13, 14],
        'allowedUsuarios': [1, 4, 13, 14],
        'show': false
      },
      {
        'path': '/Rechazos2',
        'class': 'rechazos',
        'active': 'rechazos',
        'icon': <FiXSquare />,
        'name': 'Cobranzas',
        'allowedRoles': rolesRechazos,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Colaboradores',
        'class': 'colaboradores',
        'active': 'colaboradores',
        'icon': <FiXSquare />,
        'name': 'Colaboradores',
        'allowedRoles': rolesColaboradores,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Usuarios',
        'class': 'usuarios',
        'active': 'usuarios',
        'icon': <FiUsers />,
        'name': 'Usuarios',
        'allowedRoles': rolesUsuarios,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/StorageAPi',
        'class': 'storageapi',
        'active': 'storageapi',
        'icon': <FiFolder />,
        'name': 'StorageApi',
        'allowedRoles': rolesStorageApi,
        'allowedUsuarios': [],
        'show': true
      }, 
      {
        'path': '/Siniestros',
        'class': 'siniestros',
        'active': 'siniestros',
        'icon': <FiAlertTriangle />,
        'name': 'Siniestros',
        'allowedRoles': rolesSiniestros,
        'allowedUsuarios': userRole?.siniestros_habilitado_usuario ? userRole.id_usuario : '',
        'show': true
      },
      {
        'path': '/FedPat',
        'class': 'fedpat',
        'active': 'fedpat',
        'icon': <FiShield />,
        'name': 'FedPat',
        'allowedRoles': rolesFedPat,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/Campanias',
        'class': 'campanias',
        'active': 'campanias',
        'icon': <FiClipboard />,
        'name': 'Campañas',
        'allowedRoles': rolesCampañas,
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/Capacitaciones',
        'class': 'capacitaciones',
        'active': 'capacitaciones',
        'icon': <FiInfo />,
        'name': 'Capacitaciones',
        'allowedRoles': rolesCapacitaciones,
        'allowedUsuarios': [],
        'show': false
      },
 
      {
        'path': '/Landings',
        'class': 'landings',
        'active': 'landings',
        'icon': <FiLink />,
        'name': 'Landings',
        'allowedRoles': rolesLandings,
        'allowedUsuarios': [],
        'show': true
      },
      {
        'path': '/Autogestion',
        'class': 'autogestion',
        'active': 'autogestion',
        'icon': <FiDollarSign />,
        'name': 'Autogestión',
        'allowedRoles': [1, 4, 13, 14],
        'allowedUsuarios': [],
        'show': false
      },
      {
        'path': '/PremiosVendedores',
        'class': 'premios-vendedores',
        'active': 'premios-vendedores',
        'icon': <FiStar />,
        'name': 'Premios Vendedores',
        'allowedUsuarios': [],
        'allowedRoles': [1, 4, 13, 17, 16],
        'show': false
      },
      {
        'path': '/Premios',
        'class': 'premios',
        'active': 'premios',
        'icon': <FiStar />,
        'name': 'Premios',
        'allowedUsuarios': [],
        'allowedRoles': [1, 4, 7, 13, 17, 16],
        'show': true
      },
      {
        'path': '/Comisiones',
        'class': 'comisiones',
        'active': 'comisiones',
        'icon': <FiDollarSign />,
        'name': 'Comisiones',
        'allowedUsuarios': [],
        'allowedRoles': [1, 4, 7, 17, 16],
        'show': true
      },
      {
        'path': `/Premio/Vendedor/${userRole.id_vendedor_novedades}`,
        'class': 'premios',
        'active': 'premios',
        'icon': <FiStar />,
        'name': 'Premios',
        'allowedUsuarios': [],
        'allowedRoles': [10, 18, 22, 24],
        'show': true
      },
      {
        'path': `/Comisiones/${userRole.id_vendedor_novedades}`,
        'class': 'comisiones',
        'active': 'comisiones',
        'icon': <FiStar />,
        'name': 'Comisiones',
        'allowedUsuarios': [],
        'allowedRoles': [10, 18, 22, 24],
        'show': true
      },
       
      {
        'path': '/ControlCalidad',
        'class': 'landings',
        'active': 'controlcalidad',
        'icon': <FiLink />,
        'name': 'Control Calidad',
        'allowedRoles': rolesControlCalidad,
        'allowedUsuarios': [],
        'show': true
      },
    ]

  return (
    <Container>
      <nav>
        <div className="logoContainer">
          <img
            src={GYS_LOGO}
            alt="Logo de Gestión y Servicios"
            className="logo"
          />
        </div>
        <ul className="list">
        {userRole && menuLinks.map((item, index) => {
              if((item.allowedRoles.includes(userRole.id_rol) || item?.allowedUsuarios === userRole.id_usuario) && item.show){
                return (
                  <li key={index}>
                    <Link to={{ pathname: item.path }} className={`${item.path === location.pathname ? 'selected' : ''}`} active={item.path === location.pathname}>
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link> 
                  </li>
                )
              }
            })}
            <li>
              <Link to="/" onClick={logout}>
                  {/*<img src={CerrarSesion} alt={"Cerrar Sesion"} />*/}
                <span className="icon"><FiLogOut /></span>
                <span>Cerrar Sesión</span>
              </Link>
            </li>
        </ul>
      </nav>
    </Container>
  );
};

export default NewSidebar;
