// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable";
// Filters
import { filtersUsuarios } from "../../../utils/filtersPages";
import { filterUsuariosInitialValues } from "../../../utils/filtersPages";
// Table
import { usuariosTable } from "../../../utils/tableData";
// Icons
import { HiPlus } from "react-icons/hi";
import { useUsuarios } from "../../../hooks/useUsuarios";
import useModal from "../../../hooks/useModal";
import { Container } from "./styled";
import NewButton from "../../../components/NewButton";
import { FiFilter } from "react-icons/fi";
import { useState } from "react";
import NewFiltersSection from "../../../components/NewFiltersSection";
import NewTable from "../../../components/NewTable";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Divider from "../../../components/Divider";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import LimitesForm from "../../../components/LandingsForms/LimitesForm";
import { usePlanes } from "../LandingsRefactor/hooks/usePlanes";
import { landingsTabs } from "../../../constants/tabs";
import BackButton from "../../../components/BackButton";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";

const LandingsLimites = () => {
  // const landing = {
  //   name: "Seguro Bici",
  //   id_landing: 1,
  //   initialProduct: 1
  //   }
  const landing = landingsTabs[0];
  const { tabSelected, handleTab } = useTabs()

  const {
    productos,
    limites,
    pruductoSeleccionadoLimites,
    productosTabs
  } = usePlanes(landing);

  // console.log(productosTabs)

  return (
    <>
      {/* <Sidebar active="usuarios" /> */}
      <Layout>
      <NewWrapper>
        <NewInner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
              <BackButton url={"/Landings"} text={"Landings"}/>

                <NewTitlePageSection
                  title="Límites"
                  description={"Lista de límites establecidos según planes"}
                >
                </NewTitlePageSection>
              </Col>
            </Grid>

{/* 
            {productosTabs.length > 0 ?
              <NewTabs tabs={productosTabs} tabSelected={tabSelected} handleTab={handleTab} />
            : null} */}

            <LimitesForm
              limites={limites}
              productoSeleccionado={pruductoSeleccionadoLimites}
              productos={productos}
            />
          </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </>
  );
};

export default LandingsLimites;
