import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Col,
} from "../../../components/Index";
import axiosRequest from "../../../utils/axiosConfig";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewInputSelect from "../../../components/NewInputSelect";
import NewButton from "../../../components/NewButton";
import CardNumber from "../../../components/CardNumber";
import Subtitle from "../../../components/Subtitle";
import { FiFile, FiStar } from "react-icons/fi";
import { Container } from "./styled";
import Divider from "../../../components/Divider";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import { nuevasPolizasTable } from "../../../utils/tableData";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import { anios } from "../../../constants/data";

const NewColaboradores = () => {
  // Context
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const allowedRoles = [1, 2, 4, 7, 8, 13, 14, 19]
  const [colaboradores, setColaboradores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contadorNue, setContadorNuevas] = useState(0)
  const [contadorReno, setContadorRenovadas] = useState(0);
  const { tabSelected, handleTab } = useTabs()
  const { order, handleTableOrder } = useTable()

  const meses = [
    {label: "Enero", value: "01"},
    {label: "Febrero", value: "02"},
    {label: "Marzo", value: "03"},
    {label: "Abril", value: "04"},
    {label: "Mayo", value: "05"},
    {label: "Junio", value: "06"},
    {label: "Julio", value: "07"},
    {label: "Agosto", value: "08"},
    {label: "Septiembre", value: "09"},
    {label: "Octubre", value: "10"},
    {label: "Noviembre", value: "11"},
    {label: "Diciembre", value: "12"},
  ]

  const history = useHistory();

  // Chequear el rol del usuario autenticado para saber si puede acceder a la página
  useEffect(() => {
    const mesActual = (new Date()).getMonth() +1 ;
    const mesesAux = meses.slice(0,mesActual)
    const mesesAnteriores = {label:`${mesActual} últimos meses`, value:"00"}
    mesesAux.push(mesesAnteriores)
    getColaboradores()
    setMesesDisponibles(mesesAux)
    if (user) {
      if (!allowedRoles.includes(user.id_rol)) {
        history.push("/");
      }
    }
  }, []);

  const getColaboradores = async () => {
    setLoading(true)
    try {
      let res = await axiosRequest.get("/colaboradores")
      let res_todos = [ {numero:'00',nombre:'Todos'}, ...res.data.data]
      const colaboradores = (res_todos).map( col => ({label:col.nombre, value:col.numero}))
      setColaboradores(colaboradores)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // State que abre y cierra el menú de navegación
  const [open, setOpen] = useState(false);
  // State que almacena la data que se muestra en la tabla
  const [data, setData] = useState([]);
  // State que almacena las polizas nuevas
  const [dataNuevas, setDataNuevas] = useState([]);
  // State que almacena las polizas nuevas
  const [dataRenovadas, setDataRenovadas] = useState([]);
  // State para meses disponibles
  const [mesesDisponibles, setMesesDisponibles] = useState([])
  // State que guarda los params que se van a utilizar para filtrar en el backend
  const [filtro, setFiltro] = useState({
    mes: "",
    anio: new Date().getFullYear(),
    colaborador: ""
  });
  // State para definir si existen resultados o no (si está en true muestra el loading)
  const [hayBusquedas, setHayBusquedas] = useState(false);
  // desactiva el boton
  const [, set] = useState(false);
  const [error, setError] = useState({});
  const [btnLimpiar, setBtnLimpiar] = useState(false);

  // Headers de la tabla
  const headers = [
    "Pólizas nuevas",
    "Pólizas renovadas",
    "Pólizas anuladas",
    "Pólizas no vigentes",
    "Primas nuevas",
    "Primas renovadas",
  ];

  const subHeaders = [
    "#",
    "Asoc",
    "Operación",
    "Póliza",
    "Prima",
    "Sección",
    "Tipo endoso",
    "Anulada",
    "Vigente",
    "Compañia"
  ]


  const addField = (e) => {
    const { name, value } = e.target;
    setFiltro({ ...filtro, [name]: value });
  };


  const buscar = async () => {
    setLoading(true);
    handleTab(0)
    try {
      const send = await axiosRequest.get(
        `/estadistica_colaborador?colaborador=${filtro.colaborador}&mes=${filtro.mes}&anio=${filtro.anio}`
      );
      const {nuevas, renovadas, primas_nuevas, primas_renovadas, polizas_nuevas, polizas_renovadas, anuladas, no_vigentes} = send.data.data;
      const tabla = [{
        'Pólizas nuevas': nuevas,
        'Pólizas renovadas': renovadas,
        'Pólizas anuladas': anuladas,
        'Pólizas no vigentes': no_vigentes,
        'Primas nuevas': formatearComoCurrency(primas_nuevas),
        'Primas renovadas': formatearComoCurrency(primas_renovadas)
      }]
      setData(tabla);
      setDataNuevas(polizas_nuevas.data)
      setDataRenovadas(polizas_renovadas.data)
      if (send.data.length === 0) {
        setError({ type: "warning", msg: "No se encontraron coincidencias" });
      } else {
        hayBusquedas(true)
      }
    } catch (error) {
      setError({ type: "error", msg: error });
    } finally {
      setLoading(false)
    }
  };

  const filtroInicial = {
    mes: "",
    colaborador: "",
  };


  const limpiarForm = () => {
    setFiltro(filtroInicial);
    setData([]);
    setBtnLimpiar(false);
    set(true);
  };

  function formatearComoCurrency(argentinaNumber) {
    // Formatea el número como moneda argentina
    const formatoCurrency = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    // Devuelve el número formateado
    return "AR" +formatoCurrency.format(argentinaNumber);
}

const renderTableRows = (data, tipo) => {
  const rows = [];
  let lastValue = null;
  let lastValueIndex = -1;
  let rowspanCount = 0;
  let countAsoc = 1;
  data.forEach((row, index) => {
    let rowFormat = {
      "#": countAsoc,
      "Asoc": row["Asoc"],
      "Operación": row["Operación"],
      "Póliza": row["Póliza"],
      "Prima": row["Prima"],
      "Sección": row["Sección"],
      "Tipo endoso": row["Tipo endoso"],
      "Anulada": row["Anulada"],
      "Vigente": row["Vigente"],
      "Compañia": row["Compañia"]
    }
    countAsoc++;
    if (row.Asoc === lastValue && row.Asoc !== 0) {
      rowspanCount++;
      rows[lastValueIndex].rowspan++;
    } else {
      if (lastValueIndex !== -1) {
        rows[lastValueIndex].rowspan = rowspanCount + 1;
      }
      lastValue = row.Asoc;
      lastValueIndex = rows.length;
      rowspanCount = 0;
      rows.push(rowFormat);
    }
    if (index !== lastValueIndex) {
      rowFormat.Asoc = null;
      countAsoc--;
      rows.push(rowFormat);
    }
  });

  // Actualiza el rowspan de la última agrupación
  if (lastValueIndex !== -1) {
    rows[lastValueIndex].rowspan = rowspanCount + 1;
  }

  return {rows, countAsoc};
};

  const {rows: renderedRowsNuevas, countAsoc: countNuevas} = renderTableRows(dataNuevas, 'nuevas');
  const {rows: renderedRowsRenovadas, countAsoc: countReno} = renderTableRows(dataRenovadas, 'renovadas');

  let tabs = []

  if(renderedRowsNuevas.length > 0){
    tabs.push({ name: "Pólizas nuevas" })
  }
  if(renderedRowsRenovadas.length > 0){
    tabs.push({ name: "Pólizas renovadas" })
  }

  const tableData = nuevasPolizasTable(renderedRowsNuevas)

  return (
    <>
      
      <Layout>
      <NewWrapper>
        {/* {
          loading && <Loader />
        } */}
        <NewInner>
          <Container>
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
            <NewTitlePageSection
              title={"Colaboradores"}
              description="Lista de colaboradores"
            />
            </Col>
            <Col desktop={3}>
              <NewInputSelect
                options={meses}
                name={"mes"}
                labelName={"Mes"}
                value={filtro.mes}
                onChange={addField}
                placeholder="Mes"
              />
            </Col>
            <Col desktop={3}>
              <NewInputSelect
                options={anios}
                name={"anio"}
                labelName={"Año"}
                value={filtro.anio}
                onChange={addField}
                placeholder="Año"
              />
            </Col>
            <Col desktop={3}>
              <NewInputSelect
                options={colaboradores}
                name={"colaborador"}
                labelName={"Colaborador"}
                value={filtro.colaborador}
                onChange={addField}
                placeholder="Colaboradores"
              />
            </Col>
            <Col desktop={12}>
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={buscar}
                disabled={filtro.colaborador === '' || filtro.mes === ''}
              >
                Buscar
              </NewButton>
              {btnLimpiar ? (
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={limpiarForm}
                >
                  Limpiar
                </NewButton>
              ) : null}
            </Col>
          </Grid>


        <Divider />

          { data.length > 0 &&
          <>
          <Subtitle
                icon={<FiStar />}
                title={`Resumen`}
                description={
                "Estadísticas sobre el colaborador."
                }
                disableDivider={true}
            />

          <Grid colGap={21} rowGap={21} narrow={false}>
          {data.length > 0 ? data.map((row, index) => {
            const values = Object.entries(row)
            const stats = values.map((item) => {
                return (
                    <Col desktop={3}><CardNumber title={item[0]} value={item[1]}/></Col>
                )
            })
            return stats
            }) : null}
            </Grid>
          </>
          
        }

        {(renderedRowsNuevas.length > 0 || renderedRowsRenovadas.length > 0) ?
        <>
        <Divider />

        <Subtitle
                icon={<FiFile />}
                title={`Lista de pólizas`}
                description={
                "Sección de pólizas nuevas y/o renovadas."
                }
                disableDivider={true}
            />


        <NewTabs tabs={tabs} tabSelected={tabSelected} handleTab={handleTab}/>

        
        {tabSelected === 0 ?
        <NewTable
              columns={tableData}
              data={renderedRowsNuevas}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
            />
        : null}

        {tabSelected === 1 ?
        <NewTable
              columns={tableData}
              data={renderedRowsRenovadas}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
            />
        : null}
        </>
        : null}
        </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </>
  );
};

export default NewColaboradores;
