import React, { useState, useCallback, useEffect } from 'react';
import { Loader } from '../../../Loader/Loader';
import { Styled } from './styled';
import Modal from "../../../Modal";
import Titulo from "../../../Titulo";
import Subtitulo from "../../../Subtitulo";
import IconButton from "../../../IconButton";
import DeleteIcon from "../../../../assets/iconos/delete.svg";
import InputSelect from "../../../InputSelect";
import Button from "../../../Button";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { FiFile, FiTrash2, FiUser } from "react-icons/fi";
import { Grid, Col } from "../../../Grid";
import { Container, OperadorItem, RechazoContainer, ButtonNotificarTodos } from "./styled";
import NewTitle from "../../../NewTitle";
import NewButton from "../../../NewButton";
import { LOGOS_COMPANIAS } from "../../../../constants/logos-companias";
import NewTabs from "../../../NewTabs"
import { useTabs } from "../../../../hooks/useTabs";
import Divider from "../../../Divider";
import Success from "../../../Success";
import styled from "styled-components";
import { getAllPolizasPendientes } from "../../../../services/Rechazos";
import { AiOutlineMail } from 'react-icons/ai';
import { FiMail } from "react-icons/fi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const mensajesProceso = [
  "Se están enviando los correos...",
  "Se están modificando los estados...",
  "Se está creando el historial...",
];

const ModalNotificacionMasivaRechazos = ({
  modal,
  asignacion
}) => {

  const { isModalOpen, setIsModalOpen, handleModal } = modal
  const { rechazosSeleccionados, seleccionarRechazo, removerRechazo, notificarRechazos, loading, success } = asignacion
  const [loadingPendientes, setLoadingPendientes] = useState(false);
  const [notificacionesResult, setNotificacionesResult] = useState({
    success: [],
    error: []
  });
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [polizasEnviadas, setPolizasEnviadas] = useState([]);
  const [mensajeIndex, setMensajeIndex] = useState(0);
  const [noPolizasPendientes, setNoPolizasPendientes] = useState(false);
  const [showSuccessPolicies, setShowSuccessPolicies] = useState(false);

  useEffect(() => {
    let intervalId;

    if (showSpinner) {
      intervalId = setInterval(() => {
        setMensajeIndex((prevIndex) => (prevIndex + 1) % mensajesProceso.length);
      }, 20000);
    }

    return () => clearInterval(intervalId);
  }, [showSpinner]);

  const handleNotificarTodosPendientes = async () => {
    setPolizasEnviadas([]);
    setShowSpinner(true);
    setLoadingPendientes(true);
    setNoPolizasPendientes(false);

    try {
      const result = await getAllPolizasPendientes();
      if (result.type === "success" && (!result.data || !result.data.notificados_correctamente || result.data.notificados_correctamente.length === 0)) {
        setNoPolizasPendientes(true);
      }
      if (result.type === "success") {
        setNotificacionesResult({
          success: result.data.notificados_correctamente || [],
          error: result.data.error_al_notificar || []
        });
        setPolizasEnviadas(result.polizas);
      } else {
        setNotificacionesResult({
          success: [],
          error: [result.msg]
        });
      }
    } catch (error) {
      setNotificacionesResult({
        success: [],
        error: ["Error inesperado al notificar"]
      });
    } finally {
      setLoadingPendientes(false);
      setShowSpinner(false);
    }
  };

  const toggleSuccessPolicies = () => {
    setShowSuccessPolicies(!showSuccessPolicies);
  };

  return (
    <>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>
                Notificaciones masivas
            </NewTitle>
            <p>Notificá múltiples rechazos al mismo tiempo</p>
          </div>
          <hr />
          {showSpinner ? (
            <div className="spinner-container" style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px",
              width: "100%"
            }}>
              <SpinnerLoading />
              <div className="mensaje-proceso" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(173, 216, 230, 0.4)",
                borderRadius: "20px",
                padding: "8px 12px",
                marginTop: "10px",
                color: "#1e3a8a",
                width: "80%",
                textAlign: "center",
              }}>
                <FiMail className="icono-mail" />
                {mensajesProceso[mensajeIndex]}
              </div>
              {Array.isArray(polizasEnviadas) && polizasEnviadas.length > 0 && (
                polizasEnviadas.map((poliza, index) => (
                  <div key={index} className="notificacion-poliza">
                    <AiOutlineMail className="icono-mail" />
                    {poliza.mensaje}
                  </div>
                ))
              )}
            </div>
          ) : (
            <>
            {
              success ?
              <Success title="¡Gestión realizada!" description="Rechazos notificados correctamente" handleModal={handleModal} />
              :
              <>
              {rechazosSeleccionados.length === 0 && (
              <>
                <div className="sin-solicitudes-container">
                  <span className="icon-sin-solicitudes">
                    <FiFile />
                  </span>
                  <Subtitulo>No hay rechazos seleccionados</Subtitulo>
                  <span className="description">
                    Seleccioná un rechazo haciendo click en el{" "}
                    <strong>recuadro antes del ID</strong>.
                  </span>
                  <ButtonContainer>
                    <NewButton
                      backgroundColor={true}
                      borderColor={false}
                      textColor={false}
                      onClick={handleNotificarTodosPendientes}
                      disabled={loadingPendientes}
                    >
                      {loadingPendientes ? "Cargando..." : "Notificar todos los pendientes"}
                    </NewButton>
                  </ButtonContainer>
                  {noPolizasPendientes && (
                    <div className="mensaje-proceso" style={{
                      backgroundColor: "rgba(144, 238, 144, 0.4)",
                      color: "#2e7d32",
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      No hay pólizas pendientes por notificar.
                    </div>
                  )}
                  {notificacionesResult.success.length > 0 && (
                    <div className="notification-result success" style={{
                      backgroundColor: "rgba(144, 238, 144, 0.4)",
                      color: "#2e7d32",
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 12px",
                      borderRadius: "20px",
                      cursor: "pointer"
                    }} onClick={toggleSuccessPolicies}>
                      Se notificaron correctamente {notificacionesResult.success.length} pólizas
                      {showSuccessPolicies ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  )}
                  {showSuccessPolicies && Array.isArray(notificacionesResult.success) && notificacionesResult.success.map((poliza, index) => (
                    <div key={index} style={{
                      backgroundColor: "rgba(144, 238, 144, 0.2)",
                      color: "#2e7d32",
                      padding: "8px 12px",
                      borderRadius: "20px",
                      marginTop: "5px",
                      marginLeft: "20px"
                    }}>
                      Póliza ID: {poliza.poliza}
                    </div>
                  ))}
                  {notificacionesResult.error.length > 0 && (
                    <div className="notification-result error" style={{
                      backgroundColor: "rgba(255, 0, 0, 0.2)",
                      color: "#cf1322",
                      marginTop: "10px",
                      padding: "8px 12px",
                      borderRadius: "20px"
                    }}>
                      Error al notificar {notificacionesResult.error.length} pólizas.
                      {notificacionesResult.error.map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}

            {rechazosSeleccionados.length > 0 && (
              <>
                <div className="data-container">
                  <div className="solicitudes-container">
                  {rechazosSeleccionados.map((item, index) => {
                    const logo = LOGOS_COMPANIAS.find(logo => logo.nombre.toLocaleLowerCase() === item.compania.toLowerCase())?.url
                    return (
                      <>
                      <OperadorItem>
                        <>
                          <div className="solicitud">
                            <div className="logo-container">
                              <img src={logo} alt="Logo de compañia" />
                            </div>
                            <div className="solicitud-data-container">
                              <div className="header-solicitud-data">
                                <span>
                                  ID: {item.id} - Póliza: {item.poliza}
                                </span>
                                <span className="asegurado">
                                  {item.nombre} - {item.compania}
                                </span>
                              </div>  
                              <div className="operador-solicitud-data">
                                <span>
                                  Veces notificado: {item.veces_notificado}
                                </span>
                              </div>
                            </div>
                          </div>
                          <IconButton
                            action={() => removerRechazo(item.id)}
                            icon={<FiTrash2 />}
                            tooltipText="Quitar de la lista"
                            size="small"
                          />
                        </>
                      </OperadorItem>
                      {index + 1 !== rechazosSeleccionados.length ? <Divider /> : null}
                      </>
                    );
                  })}
                  </div>
                </div>

                <hr />
                
                <div className="actionButtonsCont">
                  <div className="buttonsCont">
                    <NewButton
                      backgroundColor={false}
                      borderColor={true}
                      textColor={true}
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancelar
                    </NewButton>
                    <NewButton
                      backgroundColor={true}
                      borderColor={false}
                      textColor={false}
                      onClick={notificarRechazos}
                      disabled={loading}
                    >
                      Notificar masivamente
                    </NewButton>
                  </div>
                </div>
              </>
            )}
              </>
            }
            </>
          )}
        </RechazoContainer>
      </Modal>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default ModalNotificacionMasivaRechazos;
