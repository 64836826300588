import React from 'react'
import { Col, Grid } from '../Grid'
import Subtitle from '../Subtitle'
import { FiBarChart, FiCalendar, FiCheckCircle, FiClock, FiTrendingUp } from 'react-icons/fi'
import NewInputSelect from '../NewInputSelect'
import CardNumber from '../CardNumber'
import { anios, meses } from '../../constants/data'
import SpinnerLoading from '../SpinnerLoading'

const EstadisticasMesFichajes = ({ data }) => {

  const { usuarios, usuario, mesEstadisticasMensuales, handleChangeMesEstadisticas, anio, setAnio, estadisticasMensuales } = data

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
    <Col desktop={8}>
      <Subtitle
        title={`Estadísticas del mes de ${usuarios.find(user => user.value == usuario)?.label}`}
        image={true}
        description="Estadísticas sobre tus fichajes a lo largo del mes"
        icon={<FiBarChart />}
        disableDivider={true}
      />
    </Col>
    <Col desktop={2}>
      <NewInputSelect
        name={"mes"}
        onChange={(e) => handleChangeMesEstadisticas(e.target.value)}
        placeholder={"Mes"}
        options={meses}
        value={mesEstadisticasMensuales}
      />
    </Col>
    <Col desktop={2}>
      <NewInputSelect
        name="anio"
        placeholder="2024"
        options={anios}
        onChange={(e) => setAnio(e.target.value)}
        value={anio}
      />
    </Col>

    {Object.keys(estadisticasMensuales).length > 0 ?
      <>
        <Col desktop={3}>
          <CardNumber title="Mes" value={meses.find(item => mesEstadisticasMensuales == item.value)?.label} icon={<FiCalendar />}/>
        </Col>
        <Col desktop={3}>
          <CardNumber title="Horas esperadas" value={`${estadisticasMensuales?.horas_esperadas}hs`} icon={<FiClock />}/>
        </Col>
        <Col desktop={3}>
          <CardNumber title="Horas cumplidas" value={`${estadisticasMensuales?.horas_cumplidas}hs`} icon={<FiCheckCircle /> } />
        </Col>
        <Col desktop={3}>
          <CardNumber title="Porcentaje" value={`${estadisticasMensuales?.porcentaje}%`} icon={<FiTrendingUp /> }/>
        </Col>
      </>
    : <Col desktop={12}><SpinnerLoading text={"Cargando estadísticas mensuales..."}/></Col>}
    </Grid>
  )
}

export default EstadisticasMesFichajes