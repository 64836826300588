import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputDiv, Input } from "./styled";
import CancelReminder from "../../assets/iconos/cancelReminder.svg";
import CalendarPicker from "../../assets/iconos/calendarPicker.svg";
import { FiCalendar } from "react-icons/fi";

function InputCalendar({ labelName, type, name, onChange, readOnly, color, value, disabled }) {
  return (
    <InputDiv>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        color={color}
        onChange={onChange}
        readOnly={readOnly}
        calendarPicker={<FiCalendar />}
        cancelReminder={CancelReminder}
        disabled={disabled}
      />
      <label htmlFor={name} color={color} className="label-top">{labelName}</label>
    </InputDiv>
  )
}

export default InputCalendar;
