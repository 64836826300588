import React from "react";
import { Col, Grid } from "../Grid";
import Subtitle from "../Subtitle";
import { FiSun } from "react-icons/fi";
import NewInputSelect from "../NewInputSelect";
import { anios } from "../../constants/data";
import CardExcepcion from "../CardExcepcion";
import CardSinResultados from "../CardSinResultados";

const ExcepcionesFichajes = ({ data }) => {
  const { anio, setAnio, excepciones } = data

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={10}>
          <Subtitle
            title="Excepciones"
            description="Lista de tus excepciones cargadas por RRHH"
            icon={<FiSun />}
            disableDivider={true}
          />
        </Col>
        <Col desktop={2}>
          <NewInputSelect
            name="anio"
            placeholder="2024"
            options={anios}
            onChange={(e) => setAnio(e.target.value)}
            value={anio}
          />
        </Col>
      </Grid>
      <Grid colGap={21} rowGap={21} narrow={false}>
        {excepciones.length > 0 ? (
          excepciones.map((excepcion) => {
            return (
              <Col desktop={4}>
                <CardExcepcion excepcion={excepcion} />
              </Col>
            );
          })
        ) : (
          <Col desktop={12}>
            <CardSinResultados
              icon={<FiSun />}
              title={"No hay excepciones"}
              description={
                "No se encontraron excepciones cargadas hasta el momento"
              }
            />
          </Col>
        )}
      </Grid>
    </>
  );
};

export default ExcepcionesFichajes;
