import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  .conversor-prima-container {
    display: flex;
    flex-direction: column;
  }

  .conversor-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 10px 0;
    gap: 10px;

    div {
      width: 50%;
      align-items: center;
      display: flex;
      label {
        display: none;
      }
    }
  }

  .igual-conversor {
    font-size: 20px;
    color: ${({ theme }) => theme.title};
  }

  .prima-convertida {
    font-size: 16px;
    width: 50%;
    background-color: #f3f6ff;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: ${leerColor(colores.azulRebranding)};
    font-weight: 500;
  }

  .aclaracion-conversor {
    font-size: 12px;
    color: ${({ theme }) => theme.subtitle};
  }
`;