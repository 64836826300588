import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { useLoading } from "../../../../hooks/useLoading";

export const usePremios = (mes, anio) => {
  const [vendedores, setVendedores] = useState([]);
  const [grupos, setGrupos] = useState([])
  const [totalPremios, setTotalPremios] = useState([]);
  const [limites, setLimites] = useState(null)
  const { loading, setLoading } = useLoading();
  const [porcentajeObjetivoOperaciones, setPorcentajeObjetivoOperaciones] = useState(null)
  const [premiosEdicion, setPremiosEdicion] = useState([])

  useEffect(() => {
    const getVendedor = async () => {
        try {
          setPremiosEdicion([]);
          const premios = await axiosRequest.get(
            `/premios/vendedor/293/${mes}/${anio}`
          );
          setPremiosEdicion(premios.data);
        } catch (error) {
          console.log(error);
        }
  
    };
    getVendedor();
  }, []);

  const getLimites = async () => {
    try {
      const limites = await axiosRequest.get(
        `/premios/limites`
      );
      const porcentaje_objetivo_operaciones = await axiosRequest.get(
        `/premios/porcentaje_objetivo_operaciones`
      );
      setLimites(limites.data)
      setPorcentajeObjetivoOperaciones(porcentaje_objetivo_operaciones.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLimites()
  }, [])

  const actualizarCalculoMes = async () => {
    setLoading(true);
    try {
      const calculo = await axiosRequest.get(
        `/premios/actualizar_calculo/${parseInt(mes) + 1}/${anio}`
      );      
      const totalPremios = calculo.data.reduce((total, item) => {
        return (total += item.total);
      }, 0);
      setTotalPremios(totalPremios);
      setVendedores(calculo.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshLimites = async () => getLimites()

  // VENDEDORES Y TOTAL PREMIOS
  useEffect(() => {
    const getVendedores = async () => {
      setLoading(true);
      if (mes !== null && anio) {
        try {
          const calculo = await axiosRequest.get(
            `/premios/calculo/${parseInt(mes) + 1}/${anio}`
          );
          const totalPremios = calculo.data.reduce((total, item) => {
            return (total += item.total);
          }, 0);
          setVendedores(calculo.data);
          setTotalPremios(totalPremios);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getVendedores();
  }, [mes, anio]);

  // GRUPOS
  useEffect(() => {
    const getGrupos = async () => {
      if (mes !== null && anio) {
        try {
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}/${anio}`
          );
          setGrupos(grupos.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getGrupos();
  }, [mes, anio]);

  return {
    vendedoresData: {
      vendedores,
      totalPremios,
      setVendedores,
    },
    refreshLimites,
    porcentajeObjetivoOperaciones,
    actualizarCalculoMes,
    limites,
    loading,
    setLoading,
    grupos,
    premiosEdicion
  };
};