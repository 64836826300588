import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: 'Operaciones emitidas con medios de pago',
      },
    },
  };

const PieChart = ({ data, optionsData }) => {
  return (
    <Doughnut data={data} options={optionsData ? optionsData : options} />
  )
}

export default PieChart