import React from "react";
import { FiArrowUpRight, FiEdit, FiUser } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from "../../../IconButton";
import { Col, Grid } from "../../../Grid";

const PremiosGruposTab = ({ data }) => {
  const formatter = new Intl.NumberFormat("es-ES", {});
  const { mes, vendedores, grupos } = data;
  const location = useLocation()
  
  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={false}>
        {grupos.length > 0 &&
          grupos.map((grupo) => {
            return (
              <Col desktop={4}>
                <div className="grupo-container">
                  <div className="grupo-titulo-container">
                    <h3>{grupo.nombre}</h3>
                    <div className="grupo-titulo-botones">
                      <Link
                        to={{
                          pathname: `/Premios/Vendedores/Grupos/${grupo.id_vendedores_grupo}`,
                          state: grupo,
                          previousUrl: `${location.pathname}${location.search}`
                        }}
                      >
                        <IconButton
                          className="icon"
                          icon={<FiArrowUpRight />}
                          tooltipText={"Ver grupo"}
                        />
                      </Link>
                      <Link
                        to={{
                          pathname: `/Premios/EditarGrupo/${grupo.id_vendedores_grupo}`,
                          state: grupo,
                          previousUrl: `${location.pathname}${location.search}`
                        }}
                      >
                        <IconButton
                          className="icon"
                          icon={<FiEdit />}
                          tooltipText={"Editar grupo"}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="integrantes-container">
                    {grupo.integrantes.map((integrante, index) => {
                      const vendedorData = vendedores.find(
                        (item) => item.id_vendedor === integrante.id_vendedor
                      );
                      if(vendedorData){
                        return (
                          <Link
                            to={{
                              pathname: `/Premio/Vendedor/${integrante.id_vendedor}`,
                              state: {
                                nombre: vendedorData.vendedor,
                                data: vendedorData,
                                mes: mes,
                                previousUrl: `${location.pathname}${location.search}`
                              },
                            }}
                          >
                            <div className="integrante">
                              <div className="integrante-data-container">
                                <span className="userIcon">
                                  <FiUser />
                                </span>
                                <div className="integrante-data">
                                  <span className="nombre">
                                    {integrante.nombre}
                                  </span>
                                  <div>
                                    <span className="integrante-prima-actual">
                                      Prima actual: $
                                      {formatter.format(integrante.prima)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              </Col>
            );
          })}
      </Grid>
    </>
  );
};

export default PremiosGruposTab;
