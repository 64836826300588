import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  div {
    
    height: 1px;
    width: 100%;
    //opacity: 0.2;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    transition: all 0.5s;
    //margin: 20px 0 20px 0;
    /*
    border:1px solid ${({ theme }) => theme.border};
    height: 1px;
    background-color: ${({ theme }) => theme.border};
    opacity: 1;
    transition: all 0.5s;
    */
  }
`;


