import { useState } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./styled";
// Components
import NewButton from "../../../components/NewButton";
import NewTabs from "../../../components/NewTabs";
import ModalCargarObjetivosPremios from "../../../components/Modals/Premios/ModalCargarObjetivos";
import PremiosVendedoresTab from "../../../components/Tabs/Premios/Vendedores";
import PremiosGruposTab from "../../../components/Tabs/Premios/Grupos";
import PremiosEstadisticasTab from "../../../components/Tabs/Premios/Estadisticas";
import PremiosHistorialTab from "../../../components/Tabs/Premios/Historial";
import PremiosObjetivosTab from "../../../components/Tabs/Premios/Objetivos";
// Custom Hooks
import { useTabs } from "../../../hooks/useTabs";
import { usePremios } from "./hooks/usePremios";
import { useObjetivos } from "./hooks/useObjetivos";
import useModal from "../../../hooks/useModal";
// Constants
import { PREMIOS_TABS } from "../../../constants/tabs";
import ModalActualizarLimites from "../../../components/Modals/Premios/ModalActualizarLimites";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import PremiosEdicionTab from "../../../components/Tabs/Premios/EdicionPremios";
import ModalTotalPorcentajes from "../../../components/Modals/Premios/ModalTotalPorcentajes";
import PremiosTab from "../../../components/Tabs/Premios/Premios";
import NewActionButton from "../../../components/NewActionButton";
import { FiActivity, FiPercent, FiTarget, FiUser } from "react-icons/fi";

const Premios = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const mesURL = searchParams.get("mes");
  const anioURL = searchParams.get("anio");
  const [mes, setMes] = useState(mesURL ? mesURL : new Date().getMonth());
  const [anio, setAnio] = useState(anioURL ? anioURL : new Date().getFullYear());

  const {
    objetivos,
    loading: loadingObjetivos,
    refreshObjetivos,
  } = useObjetivos(mes, anio);
  const { vendedoresData, premiosEdicion, grupos, limites, porcentajeObjetivoOperaciones, refreshLimites, loading, setLoading, actualizarCalculoMes } = usePremios(mes, anio);
  const { vendedores, totalPremios, setVendedores } = vendedoresData;
  const { handleTab, tabSelected } = useTabs();
  const objetivosModal = useModal();
  const limitesModal = useModal();
  const totalPorcentajesModal = useModal();

  const TABS_TO_RENDER = [
    <PremiosVendedoresTab
      data={{
        mes,
        anio,
        setAnio,
        setMes,
        loading,
        totalPremios,
        vendedores,
        grupos,
        setVendedores,
        setLoading,
        actualizarCalculoMes
      }}
    />,
    <PremiosGruposTab data={{ mes, anio, vendedores, grupos }} />,
    <PremiosEstadisticasTab data={{ mes, setMes, anio, setAnio }} />,
    <PremiosObjetivosTab data={{ mes, setMes, anio, setAnio, objetivos, loadingObjetivos: loadingObjetivos }} />,
    <PremiosHistorialTab data={{ vendedores }} />,
    <PremiosTab />
    // <PremiosEdicionTab data={{ premios: premiosEdicion, mes, anio }} />
  ];

  const ACCIONES_ACTION_BUTTON = [
    {
      name: "Actualizar Porcentajes",
      action: () => totalPorcentajesModal.setIsModalOpen(true),
      link: null,
      type: "button",
      icon: <FiPercent />
    },
    {
      name: "Actualizar Condiciones",
      action: () => limitesModal.setIsModalOpen(true),
      link: null,
      type: "button",
      icon: <FiActivity />
    },
    {
      name: "Cargar Objetivos",
      action: () => objetivosModal.setIsModalOpen(true),
      link: null,
      type: "button",
      icon: <FiTarget />
    },
    {
      name: "Crear grupo",
      action: null,
      link: '/CrearGrupo',
      type: "link",
      icon: <FiUser />
    }
  ]

  return (
    <div>
      {objetivosModal.isModalOpen ? (
        <ModalCargarObjetivosPremios
          modal={objetivosModal}
          refreshData={refreshObjetivos}
        />
      ) : null}
      {limitesModal.isModalOpen ? (
        <ModalActualizarLimites
          modal={limitesModal}
          limites={limites}
          refreshLimites={refreshLimites}
          actualizarCalculoMes={actualizarCalculoMes}
          porcentajeObjetivoOperaciones={porcentajeObjetivoOperaciones}
        />
      ) : null}
      {totalPorcentajesModal.isModalOpen ? (
        <ModalTotalPorcentajes
          modal={totalPorcentajesModal}
          mes={mes}
          anio={anio}
          refreshVendedores={actualizarCalculoMes}
        />
      ) : null}
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <NewTitlePageSection
                title="Premios"
                description="Lista de todos los vendedores junto a sus premios"
              >
                <NewActionButton actions={ACCIONES_ACTION_BUTTON} />
              </NewTitlePageSection>

              <NewTabs
                tabs={PREMIOS_TABS}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {TABS_TO_RENDER[tabSelected]}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </div>
  );
};

export default Premios;