import React, { useState } from 'react'
import NewButton from '../NewButton'
import { Container } from './styled'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const NewActionButton = ({ actions }) => {
  const [open, setOpen] = useState(false)

  const handleAction = (action) => {
    action()
    setOpen(false)
  }

  return (
    <Container>
        <NewButton backgroundColor={true} onClick={() => setOpen(!open)}>Gestionar</NewButton>
        {open ?
            <div className='acciones-container'>
                {actions.map(action => {
                    if(action.link){
                        return (
                            <Link to={action.link}>
                                <div className='accion'>
                                    <span>{action.icon} {action.name}</span>
                                </div>
                            </Link>
                        )
                    } else {
                        return (
                            <div className='accion' onClick={() => handleAction(action.action)}>
                                <span>{action.icon} {action.name}</span>
                            </div>
                        )
                    }
                })}
            </div>
        : null}
    </Container>
  )
}

export default NewActionButton