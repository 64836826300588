import Modal from "../../../Modal";
import Subtitulo from "../../../Subtitulo";
import IconButton from "../../../IconButton";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { FiFile, FiTrash2, FiUser } from "react-icons/fi";
import { Grid, Col } from "../../../Grid";
import { Container, OperadorItem, RechazoContainer } from "./styled";
import NewTitle from "../../../NewTitle";
import NewButton from "../../../NewButton";
import { LOGOS_COMPANIAS } from "../../../../constants/logos-companias";
import NewTabs from "../../../NewTabs"
import { useTabs } from "../../../../hooks/useTabs";
import Divider from "../../../Divider";

const ModalAsignacionCalidad = ({
  isModalOpen,
  setIsModalOpen,
  anulaciones,
  removerAnulacion,
  asignarAnulacion,
  operadores,
  handleChangeOperador,
  loading,
  anulacionesAsignadas,
  operador,
}) => {

  const exitoModal = useModal();

  const { tabSelected, handleTab } = useTabs();
  const tabs = [
    {
      name: "Operadores",
    },
    {
      name: "Anulaciones",
    }
  ];

  return (
    <>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={exitoModal.modalMessage}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <RechazoContainer>
          {loading && <SpinnerLoading text="Asignando solicitudes..." />}

          {anulaciones.length === 0 && (
            <>
              <div className="header-container">
              <NewTitle>
                  Asignación de anulaciones
              </NewTitle>
              <p>Elegí un operador de calidad para asignar las anulaciones</p>
            </div>
            <Divider />
            <div className="sin-solicitudes-container">
              <span className="icon-sin-solicitudes">
                <FiFile />
              </span>
              <Subtitulo>No hay anulaciones seleccionadas</Subtitulo>
              <span className="description">
                Seleccioná una anulación haciendo click en el{" "}
                <strong>recuadro antes del ID</strong>.
              </span>
            </div>
            </>
          )}

          {anulaciones.length > 0 && !loading && !anulacionesAsignadas && (
            <>
              <div className="header-container">
                <NewTitle>
                    Asignación de solicitudes
                </NewTitle>
                <p>Elegí un operador para llevar a cabo la emisión de las solicitudes seleccionadas</p>
              </div>
              <hr />

              <div className="header-container">
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
              </div>


              {tabSelected === 1 ?
              <div className="data-container">
                <div className="solicitudes-container">
                {anulaciones.map((item) => {
                  const logo = LOGOS_COMPANIAS.find(logo => logo.nombre.toLocaleLowerCase() === item.anulacion.nombre_compania.toLowerCase())?.url
                  return (
                    <OperadorItem>
                      <>
                        <div className="solicitud">
                          <div className="logo-container">
                            <img src={logo} alt="Logo de compañia" />
                            <span>{item.nombre_producto}</span>
                          </div>
                          <div className="solicitud-data-container">
                            <div className="header-solicitud-data">
                              <span>
                                ID: {item.id_anulacion_calidad} - 
                              </span>
                              <span className="asegurado">
                                {item.nombre_asegurado} {item.apellido_asegurado} - {item.nombre_compania}
                              </span>
                            </div>  
                            <div className="operador-solicitud-data">
                              <span>
                                {item.operador === "Sin Asignar"
                                  ? "Solicitud sin operador asignado"
                                  : `Solicitud asignada actualmente a ${item.operador}`}
                              </span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          action={() => removerAnulacion(item.id_anulacion_calidad)}
                          icon={<FiTrash2 />}
                          tooltipText="Quitar de la lista"
                          size="small"
                        />
                      </>
                    </OperadorItem>
                  );
                })}
                </div>
              </div>
              : null }

              {tabSelected === 0 ?
              <div className="data-container">
                <Grid colGap={10} rowGap={10}>
                  {operadores.map((op) => {
                    console.log(op)
                    return (
                      <Col desktop={3}>
                        <div
                          className={
                            operador?.value === op.value
                              ? "operador operador-seleccionado"
                              : "operador"
                          }
                          onClick={() => handleChangeOperador(op)}
                        >
                          <span className="icon">
                            <FiUser />
                          </span>
                          <h4>{op.label}</h4>
                        </div>
                      </Col>
                    );
                  })}
                </Grid>
              </div>
              : null}

              <Divider />
              
              <div className="actionButtonsCont">
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    borderColor={true}
                    textColor={true}
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    onClick={() => asignarAnulacion(exitoModal)}
                    disabled={!operador}
                  >
                    Asignar {operador && `a ${operador.label}`}
                  </NewButton>
                </div>
              </div>
            </>
          )}
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalAsignacionCalidad;
