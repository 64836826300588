import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  
    padding: 50px!important;
    text-align: center!important;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 6px;

  > div >div {
    text-align: center;
  }
  h3{
    margin: 0;
    margin-top:20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: ${({ theme }) => theme.title};
  }
  h3:nth-child(3) {
    margin: 0;
  }
`;

