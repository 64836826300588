// Components
import Modal from "../../../Modal";
import { ModalRechazoContainer } from "../../../../pages/styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputCalendar from "../../../InputCalendar";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import InputFileButton from "../../../InputFileButton";
import NewInputSelect from "../../../NewInputSelect";
import NewInputTextarea from "../../../NewInputTextarea";
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import useFiles from "../../../../hooks/useFiles";
// Utils
import {
  modalEstadoEmisionReglas,
  modalRechazoReglas,
} from "../../../../utils/formRules";
import {
  modalEstadoEmisionForm,
  modalRechazoForm,
} from "../../../../utils/formInitialValues";
// Services
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../../services/Siniestros";
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import { useEffect, useState } from "react";
import {
  actualizarSolicitud,
  actualizarSolicitudEstado,
  getEstadosEmision,
  postMensajeHistorial,
} from "../../../../services/Emision";
import Estado from "../../../Estado";
import dayjs from "dayjs";
import Divider from "../../../Divider";

const ModalEstadoEmision = ({
  isModalOpen,
  handleModal,
  asegurado,
  seguro,
  solicitud,
  errorModal,
  getSolicitud,
}) => {
  const exitoModal = useModal();

  const {
    inputValues,
    formErrors,
    handleValidation,
    handleChange,
    handleChangeExternal,
  } = useForm(handleSubmit, modalEstadoEmisionReglas, modalEstadoEmisionForm);
  const { loading, setLoading } = useLoading();
  const [estados, setEstados] = useState([]);
  const userRol = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const getEstados = async () => {
      const estados = await getEstadosEmision();
      const filterEstados = solicitud.id_tipo_solicitud === 3 ? estados.filter(
        (item) =>
          item.label !== "Rechazado" &&
          item.label !== "Pendiente Emisión" &&
          item.label !== "Emitido certificado"
      ) : estados.filter(
        (item) =>
          item.label !== "Rechazado" &&
          item.label !== "Pendiente Emisión" &&
          item.label !== "Emitido certificado" &&
          item.label !== "Endoso enviado a compañia"
      )
      setEstados(filterEstados);
    };
    getEstados();
  }, []);

  async function handleSubmit() {
    setLoading(true);
    try {
      // La solicitud pasa a estado emitido
      let data = solicitud;
      let prevEstado = solicitud.id_estado;
      data.id_estado = inputValues.estado;
      data.id_estado_previo = prevEstado;
      await actualizarSolicitudEstado(solicitud.id, data);

      // Postear mensaje en de solicitud emitida en 'sw_solicitudes_historial'
      const estadoSolicitudMensaje = {
        id_solicitud: solicitud.id,
        id_area: 1,
        id_operador: userRol.id_operador,
        id_accion_historial: 3,
        fecha_solicitud_historial: dayjs(new Date()).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        descripcion_solicitud_historial: `Estado de solicitud: ${
          estados.find((item) => item.key === inputValues.estado).label
        }`,
        eprevio_solicitud_historial: prevEstado,
        estado_solicitud_historial: 1,
        email_solicitudes_historial: 0,
        id_historial_email: null,
      };
      postMensajeHistorial(estadoSolicitudMensaje);

      const solicitudMessage = {
        id_solicitud: solicitud.id,
        id_area: 1,
        id_operador: userRol.id_operador,
        id_accion_historial: 3,
        fecha_solicitud_historial: dayjs(new Date()).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        descripcion_solicitud_historial: inputValues.mensaje,
        eprevio_solicitud_historial: prevEstado,
        estado_solicitud_historial: 1,
        email_solicitudes_historial: 0,
        id_historial_email: null,
      };
      postMensajeHistorial(solicitudMessage);
      handleModal();
      getSolicitud();
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorModal.setIsModalOpen(true);
      errorModal.handleModalMessage("Error al rechazar la solicitud");
      handleModal();
      setLoading(false);
    }
  }

  return (
    <>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Estado Emisión</NewTitle>
            <p>Modificá manualmente el estado de la solicitud</p>
          </div>
          <Divider />
          <div className="data-container">
            <div className="motivos-container">
              <label>Seleccioná un estado (*)</label>
              {estados.length > 0 ? (
                <>
                  <div className="estados-container">
                    {estados.map((item) => {
                      return (
                        <span
                          className={`${
                            item.key === inputValues.estado
                              ? "estado-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleChangeExternal("estado", item.key)
                          }
                        >
                          {item.label}
                        </span>
                      );
                    })}
                  </div>
                  {formErrors.estado ? (
                    <ErrorInput>{formErrors.estado}</ErrorInput>
                  ) : null}
                </>
              ) : (
                <SpinnerLoading />
              )}
            </div>
            <NewInputTextarea
              name={"mensaje"}
              placeholder={
                "Dejá un mensaje al vendedor en el historial de la solicitud"
              }
              onChange={handleChange}
              labelName={"Comentario para vendedor"}
            ></NewInputTextarea>
            {formErrors.mensaje ? (
              <ErrorInput>{formErrors.mensaje}</ErrorInput>
            ) : null}
          </div>
          <Divider />
          <div className={"actionButtonsCont"}>
            <div className={"buttonsCont"}>
              <NewButton
                onClick={handleModal}
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
              >
                Cancelar
              </NewButton>
              <NewButton
                onClick={handleValidation}
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                disabled={loading}
              >
                {loading ? "Modificando estado..." : "Modificar estado"}
              </NewButton>
            </div>
          </div>
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalEstadoEmision;
