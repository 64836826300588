import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const LabelStep = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.subtitle};
  font-weight: 500;
`;

export const StepContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  // #daf2ff
  :not(:last-of-type) {
    ::after {
      content: "";
      width: 200px;
      height: 2px;
      background-color: ${({ selected, index }) =>
        selected - index > 1 ? leerColor(colores.azulRebranding) : "#eaeaea"};
      position: absolute;
      top: 30%;
      left: 26px;
      z-index: -1;
    }
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px 0;
`;

const textColor = (selected, index, error) => {
  if (error && selected === index + 1) {
    return leerColor(colores.blanco);
  } else if (selected === index + 1) {
    return leerColor(colores.azulRebranding);
  } else if (selected - index > 1) {
    return leerColor(colores.blanco);
  } else {
    return leerColor(colores.gris);
  }
};

const backgroundColor = (selected, index, error) => {
  if (error && selected === index + 1) {
    return leerColor(colores.rojo);
  } else if (selected === index + 1) {
    return ({ theme }) => theme.background;
  } else if (selected - index > 1) {
    return leerColor(colores.azulRebranding);
  } else {
    return ({ theme }) => theme.background;
  }
};

const borderColor = (selected, index, error) => {
  if (error && selected === index + 1) {
    return `1px solid ${leerColor(colores.rojo)}`;
  } else if (selected === index + 1) {
    return `1px solid ${leerColor(colores.azulRebranding)}`;
  } else if (selected - index > 1) {
    return `1px solid ${leerColor(colores.azulRebranding)}`;
  } else {
    return `1px solid ${leerColor(colores.gris)}`;
  }
};

export const Step = styled.div`
  font-size: 18px;
  font-weight: 500;
  border-radius: 100%;
  color: ${({ selected, index, error }) => textColor(selected, index, error)};
  border: ${({ selected, index, error }) =>
    borderColor(selected, index, error)};
  background: ${({ selected, index, error }) =>
    backgroundColor(selected, index, error)};
  padding: 10px 18px;
  margin: 0 0 10px 0;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
`;

export const StepContent = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ stepSelected }) =>
    stepSelected === 1 ? "flex-end" : "space-between"};
  align-items: center;
`;

export const StepTitle = styled.span`
  color: ${({ selected }) => (selected ? "#fff" : "rgb(100, 100, 100)")};
  transition: 0.3s;
`;

export const ButtonsCont = styled.div`
  width: 100%;
  display: flex;
  place-content: space-between;
  padding-top: 40px;

  a {
    text-decoration: none;
  }
`;
