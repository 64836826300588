import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import { FiArrowLeft } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import { useForm } from "../../../hooks/useForm";
import { cargarSiniestrosReglas } from "../../../utils/formRules";
import NewButton from "../../../components/NewButton";
import { cargarSiniestrosForm } from "../../../utils/formInitialValues";
import useSiniestros from "../../../hooks/useSiniestros"
import { getPolizaInformation, getProductoSiniestroId } from "../../../services/Siniestros";
import { useLoading } from "../../../hooks/useLoading";
import BackButton from "../../../components/BackButton";
import CrearSiniestroForm from "../../../components/SiniestrosForms/CrearSiniestro";
import Divider from "../../../components/Divider";
import Success from "../../../components/Success"
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";

const CrearSiniestro = () => {

  const { data, operaciones, success } =  useSiniestros()
  const { productos, tipos } = data
  const { loading, setLoading } = useLoading()
  // Custom Hooks
  const { inputValues, handleChange, handleChangeExternal, handleValidationAsync, formErrors,
  } = useForm(handleSubmit, cargarSiniestrosReglas, cargarSiniestrosForm);

  const validateData = (e) => {
    setLoading(true)
    handleValidationAsync(e)
  }
  async function handleSubmit() {
    try {
      const polizaInfo = await getPolizaInformation(
        inputValues.poliza,
        inputValues.dni
      );
      const productoId = await getProductoSiniestroId(inputValues.producto);
      operaciones.postearSiniestro(inputValues, polizaInfo.data[0], productoId.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div>
      <div>
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                <div>
                  <BackButton url={"/Siniestros"} text="Siniestros"/>
                  <NewTitlePageSection
                    title={`Ingresar siniestro`}
                    description={`Ingresá un nuevo siniestro al sistema utilizando póliza y DNI del asegurado`}
                  />
                </div>
                <Divider />
                {!success ?
                  <>
                    <CrearSiniestroForm form={{ inputValues, handleChange, handleChangeExternal, formErrors }} data={{ productos, tipos }} />
                    <Divider />
                    <div className="buttons-container">
                      <NewButton backgroundColor={true} onClick={(e) => validateData(e)} disabled={loading}>
                        {loading ? "Ingresando siniestro..." : "Ingresar siniestro"}
                      </NewButton>
                      <Link to="/Siniestros">
                        <NewButton borderColor={true} textColor={true}>
                          <FiArrowLeft /> Volver a Siniestros
                        </NewButton>
                      </Link>
                    </div>
                  </>
                :
                  <Success title="¡Siniestro ingresado!" description={`El nuevo siniestro fue cargado correctamente`} link={"/Siniestros"} page="Siniestros" />
                }
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default CrearSiniestro;
