import { useState, useEffect } from "react"
import { useForm } from "../../../../hooks/useForm";
import { excepcionReglas } from "../../../../utils/formRules";
import { excepcionForm } from "../../../../utils/formInitialValues";
import axiosRequest from "../../../../utils/axiosConfig";
import formatSelectData from "../../../../utils/formatSelectData";
import dayjs from "dayjs"
import orderAlphabetically from "../../../../utils/alphabeticalOrder";

export const useExcepcion = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [tiposGoceSueldo, setTiposGoceSueldo] = useState([]);
    const [success, setSuccess] = useState(false);
    const {
      inputValues,
      handleChange,
      handleChangeExternal,
      handleChangeCheckbox,
      formErrors,
      handleValidation,
    } = useForm(handleSubmit, excepcionReglas, excepcionForm);
  
    useEffect(() => {
      const getTiposGoceSueldo = async () => {
        try {
          const tipos = await axiosRequest.get(`/excepciones/tipos_goce_sueldo`);
          const tiposFormatted = formatSelectData(
            tipos.data,
            "id_tipo_goce_sueldo",
            "tipo",
            "id_tipo_goce_sueldo"
          );
          setTiposGoceSueldo(tiposFormatted);
        } catch (error) {
          console.log(error);
        }
      };
      getTiposGoceSueldo();
    }, []);
  
    async function handleSubmit() {
      const day = dayjs(new Date()).format("YYYY-MM-DD");
  
      const dataExcepcion = {
        id_usuario_excepcion: inputValues.usuario,
        tipo_excepcion: inputValues.tipo,
        periodo_excepcion: inputValues.periodo,
        periodo_dia_excepcion: inputValues.dia ? inputValues.dia : null,
        periodo_fecha_excepcion: inputValues.fecha ? inputValues.fecha : null,
        periodo_fecha_desde_excepcion: inputValues.fecha_desde
          ? inputValues.fecha_desde
          : null,
        periodo_fecha_hasta_excepcion: inputValues.fecha_hasta
          ? inputValues.fecha_hasta
          : null,
        hora_excepcion: inputValues.hora,
        hora_desde_excepcion: inputValues.hora_desde
          ? dayjs(new Date(`${day} ${inputValues.hora_desde}`)).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null,
        hora_hasta_excepcion: inputValues.hora_hasta
          ? dayjs(new Date(`${day} ${inputValues.hora_hasta}`)).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null,
        goce_sueldo: inputValues.check_goce_sueldo ? 1 : 0,
        motivo_sin_goce_sueldo: inputValues.motivo_sin_goce_sueldo
          ? inputValues.motivo_sin_goce_sueldo
          : null,
        motivo_acuerdo: inputValues.motivo_acuerdo
          ? inputValues.motivo_acuerdo
          : null,
        tipo_goce_sueldo: inputValues.tipo_goce_sueldo
          ? inputValues.tipo_goce_sueldo
          : null,
        tipo_goce_sueldo_otros: inputValues.tipo_goce_sueldo_otros
          ? inputValues.tipo_goce_sueldo_otros
          : null,
        estado_excepcion: 1,
      };
      const excepcion = await axiosRequest.post("/excepcion", dataExcepcion);
      setSuccess(true);
    }
  
    useEffect(() => {
      const getUsuarios = async () => {
        try {
          const usuarios = await axiosRequest.get("/operadores");
          const filterUsuarios = usuarios.data.filter(
            (usuario) => !usuario.nombre_operador.toLowerCase().includes("prueba")
          );
          const formatUsuarios = formatSelectData(
            filterUsuarios,
            "id_usuario",
            ["nombre_operador", "apellido_operador"],
            "id_usuario"
          );
          setUsuarios(formatUsuarios.sort(orderAlphabetically));
        } catch (error) {
          console.log(error);
        }
      };
      getUsuarios();
    }, []);

    return {
        form: { inputValues, handleChange, handleChangeExternal, handleChangeCheckbox, formErrors, handleValidation, handleSubmit },
        data: { usuarios, tiposGoceSueldo, success }
    }
}