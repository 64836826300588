import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const EmisionData = styled.div`
  position: relative;
  .seccion-container {
      display: flex;
      flex-direction: column;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 8px;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 100%;
      justify-content: space-between;
      span {
        text-transform: capitalize;
      }
      a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  
    span {
        font-size: 14px;
        color: ${({ theme }) => theme.title};
        font-weight: 500;
    }
    h2 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        color: ${({ theme }) => theme.subtitle};
        line-height: 1.5;
    }
    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }
    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }
  h6 {
    //font-family: "Raleway";
    /*
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #bab8b8;
    margin: 0;
    */
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    //font-family: "Raleway";
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.subtitle};
    margin: 0px 0;
    margin-bottom: 6px;
    //text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .whatsapp-telefono {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: ${leerColor(colores.verde)};
    font-weight: 500;
    font-size: 16px;

    p {
      margin: 0 0 6px 0;
    }
  }
  .whatsapp-telefono:visited {
  color:${leerColor(colores.verde)};
  }
  .clipboard-icono {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: ${leerColor(colores.azulRebranding)};
    font-weight: 500;
    margin: 0px 0 0 10px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    transition: 0.3s;
  }
  .clipboard-icono::before {
    content: "¡Email copiado!";
    position: absolute;
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 12px;
    top: -35px;
    left: -55px;
    display:  ${(props) => (props.copied ? "flex" : "none")};
    white-space: nowrap;
  }
  /* .linkML {
    //font-family: "Raleway";
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    margin: 10px 0;
    margin-bottom: 6px;
    display: inline-block;
  }
  svg {
    margin: 10px 0;
    margin-bottom: 6px;
  }
  .extraCont {
    display: flex;

    svg {
      width: 20px;
      margin-right: 10px;
    }
  }
  .buttonsCont {
    display: flex;
    place-content: flex-end;
  }
  div .buttonsCont a:not(:first-child) {
    margin-left: 25px;
  }
  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;
  }

  a:visited {
    color: ${leerColor(colores.azulRebranding)};
  }

  .icon {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 10px;
  }

  .icon span {
    display: flex;
    align-items: center
  }

  .icon:hover {
    opacity: 0.7;
  } */
`;

