import React, { useEffect, useState } from "react";
import Subtitle from "../../../Subtitle";
import { FiBarChart } from "react-icons/fi";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import LineChart from "../../../LineChart";
import dayjs from "dayjs";
import axiosRequest from "../../../../utils/axiosConfig";
import axios from "axios";
import formatSelectData from "../../../../utils/formatSelectData";
import { useTheme } from "../../../../context/ThemeContext";
import Divider from "../../../Divider";
import SpinnerLoading from "../../../SpinnerLoading";

const VendedorRendimientoDiarioTab = ({ data }) => {
  const { vendedor, mes, anio, productos, productoSeleccionado, userRol } = data;
  const [rendimientoDiario, setRendimientoDiario] = useState({});
  const { theme } = useTheme();

  useEffect(() => {
    const getVendedor = async () => {
      if (mes !== null && productos && productoSeleccionado) {
        setRendimientoDiario({});
        try {
          const rendimientoDiario = await axiosRequest.get(
            `/premios/vendedor_rendimiento_diario/${vendedor.id}/${mes}/${productoSeleccionado}/${anio}`
          );

          const month =
            parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
          const feriados = await axios.get(
            `https://api.argentinadatos.com/v1/feriados/${anio}`
          );
          const feriadosMes = feriados.data.filter((item) =>
            item.fecha.split("-")[1].includes(month)
          );

          const diasMes = new Date(anio, parseInt(mes) + 1, 0).getDate();
          const labels = Array.from({ length: diasMes }).map((_, index) => {
            const day = index + 1 < 10 ? `0${index + 1}` : index + 1;
            const month =
              parseInt(mes) + 1 < 10 ? `0${parseInt(mes) + 1}` : parseInt(mes) + 1;
            return `${day}/${month}`;
          });

          const days = labels.map((label, index) => {
            const find = rendimientoDiario.data.operacionesPrimaPorDia.find(
              (item) => {
                return (
                  parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                );
              }
            );
            if (find) {
              return find.operaciones;
            } else {
              return 0;
            }
          });
          const productosEmitidosPrima = labels.map((label, index) => {
            if (rendimientoDiario.data.productoPorDiaEmitido.length > 0) {
              const find = rendimientoDiario.data.productoPorDiaEmitido.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const productosEmitidosOperaciones = labels.map((label, index) => {
            if (rendimientoDiario.data.productoPorDiaEmitido.length > 0) {
              const find = rendimientoDiario.data.productoPorDiaEmitido.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const prima = labels.map((label, index) => {
            if (rendimientoDiario.data.operacionesPrimaPorDia.length > 0) {
              const find = rendimientoDiario.data.operacionesPrimaPorDia.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const productosContratadosPrima = labels.map((label, index) => {
            if (rendimientoDiario.data.productoPorDiaContratado.length > 0) {
              const find = rendimientoDiario.data.productoPorDiaContratado.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechauc).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const productosContratadosOperaciones = labels.map((label, index) => {
            if (rendimientoDiario.data.productoPorDiaContratado.length > 0) {
              const find = rendimientoDiario.data.productoPorDiaContratado.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechauc).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const operacionesContratado = labels.map((label, index) => {
            if (
              rendimientoDiario.data.operacionesPrimaPorDiaContratado.length > 0
            ) {
              const find =
                rendimientoDiario.data.operacionesPrimaPorDiaContratado.find(
                  (item) => {
                    return (
                      parseInt(dayjs(item.fechauc).format("D")) === index + 1
                    );
                  }
                );
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const primaContratado = labels.map((label, index) => {
            if (
              rendimientoDiario.data.operacionesPrimaPorDiaContratado.length > 0
            ) {
              const find =
                rendimientoDiario.data.operacionesPrimaPorDiaContratado.find(
                  (item) => {
                    return (
                      parseInt(dayjs(item.fechauc).format("D")) === index + 1
                    );
                  }
                );
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const objetivoOperacionesPorDia = labels.map((label, index) => {
            const dia = label.split("/")[0];
            let day = new Date(anio, parseInt(mes), dia).getDay();
            if (day != 0 && day != 6) {
              return rendimientoDiario.data.objetivoOperacionesPorDia;
            } else {
              return 0;
            }
          });

          const objetivoPrimaPorDia = labels.map((label, index) => {
            const dia = label.split("/")[0];
            let day = new Date(anio, parseInt(mes), dia).getDay();
            if (day != 0 && day != 6) {
              return rendimientoDiario.data.objetivoPrimaPorDia;
            } else {
              return 0;
            }
          });

          // SITUACION ACTUAL PRIMA ESPERADA
          const primaEsperadaSituacionActual = labels.map((label, index) => {
            if (rendimientoDiario.data.primaEsperadaDiaria.length > 0) {
              const find = rendimientoDiario.data.primaEsperadaDiaria.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.esperada;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          // SITUACION ACTUAL OPERACIONES ESPERADAS
          const operacionesEsperadasSituacionActual = labels.map((label, index) => {
            if (rendimientoDiario.data.operacionesEsperadasDiarias.length > 0) {
              const find = rendimientoDiario.data.operacionesEsperadasDiarias.find(
                (item) => {
                  return (
                    parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                  );
                }
              );
              if (find) {
                return find.esperada;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          const options = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Operaciones emitidas por día",
              },
            },
          };

          const options2 = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Prima emitida por día",
              },
            },
          };

          const optionsOperacionesContratadas = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Operaciones contratadas por día",
              },
            },
          };

          const optionsPrimaContratada = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Prima contratada por día",
              },
            },
          };

          const productoLabel = productos.find(
            (item) => item.value == productoSeleccionado
          )?.label;

          const dataPrimaContratado = {
            labels: labels,
            datasets: [
              {
                label: "Prima contratada",
                data: primaContratado,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosContratadosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima esperada",
                data: objetivoPrimaPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              }
            ],
          };

          const dataOperacionesContratado = {
            labels: labels,
            datasets: [
              {
                label: "Operaciones contratadas",
                data: operacionesContratado,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosContratadosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones esperadas",
                data: objetivoOperacionesPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              }
            ],
          };

          const data = {
            labels: labels,
            datasets: [
              {
                label: "Operaciones por día",
                data: days,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosEmitidosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones esperadas",
                data: objetivoOperacionesPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              }
            ],
          };

          const data2 = {
            labels: labels,
            datasets: [
              {
                label: "Prima por día",
                data: prima,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosEmitidosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima esperada",
                data: objetivoPrimaPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              }
            ],
          };

          if(userRol !== 10 && userRol !== 18 && userRol !== 22 && userRol !== 24){
            dataPrimaContratado.datasets.push({
              label: "Prima esperada - Situación actual",
              data: primaEsperadaSituacionActual,
              fill: false,
              borderColor: "rgb(255, 145, 255)",
              tension: 0.001,
            })
            dataOperacionesContratado.datasets.push({
              label: "Operaciones esperadas - Situación actual",
              data: operacionesEsperadasSituacionActual,
              fill: false,
              borderColor: "rgb(255, 145, 255)",
              tension: 0.001,
            })
            data.datasets.push({
              label: "Operaciones esperadas - Situación actual",
              data: operacionesEsperadasSituacionActual,
              fill: false,
              borderColor: "rgb(255, 145, 255)",
              tension: 0.001,
            },)
            data2.datasets.push({
              label: "Prima esperada - Situación actual",
              data: primaEsperadaSituacionActual,
              fill: false,
              borderColor: "rgb(255, 145, 255)",
              tension: 0.001,
            })
          }

          setRendimientoDiario({
            data,
            data2,
            dataPrimaContratado,
            dataOperacionesContratado,
            options,
            options2,
            optionsOperacionesContratadas,
            optionsPrimaContratada,
          });
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [mes, productoSeleccionado, productos, anio]);

  return (
    <>
      <>
        <Divider />
        <Subtitle
          icon={<FiBarChart />}
          title={`Prima`}
          description={`Evolución de prima contratadas y emitidas por día`}
          disableDivider={true}
        />
        {Object.keys(rendimientoDiario).length > 0 ? (
          <Grid colGap={21} rowGap={60} narrow={false}>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.data2}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                    y: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                  },
                  plugins: {
                    title: {
                      display: true,
                      color: theme === "dark" ? "#ffffff" : "#666666",
                      text: `Visualización de prima emitida por día para ${productos.find(producto => producto.value == productoSeleccionado)?.label?.toLowerCase()}`,
                    },
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 10,
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                    },
                  },
                }}
              />
            </Col>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.dataPrimaContratado}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                    y: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                  },
                  plugins: {
                    title: {
                      display: true,
                      color: theme === "dark" ? "#ffffff" : "#666666",
                      text: `Visualización de prima contratada por día para el producto ${productos.find(producto => producto.value == productoSeleccionado)?.label?.toLowerCase()}`,
                    },
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 10,
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                    },
                  },
                }}
              />
            </Col>
          </Grid>
        ) : (
          <SpinnerLoading text="Cargando rendimiento de prima" />
        )}

        <hr />
        <Subtitle
          icon={<FiBarChart />}
          title={`Operaciones`}
          description={`Evolución de operaciones contratadas y emitidas por día`}
          disableDivider={true}
        />
        {Object.keys(rendimientoDiario).length > 0 ? (
          <Grid colGap={21} rowGap={60} narrow={false}>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.data}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                    y: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                  },
                  plugins: {
                    title: {
                      display: true,
                      color: theme === "dark" ? "#ffffff" : "#666666",
                      text: `Visualización de operaciones emitidas por día para el producto ${productos.find(producto => producto.value == productoSeleccionado)?.label?.toLowerCase()}`,
                    },
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 10,
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                    },
                  },
                }}
              />
            </Col>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.dataOperacionesContratado}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                    y: {
                      stacked: false,
                      ticks: {
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                      grid: {
                        borderColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        tickColor: theme === "dark" ? "#243158" : "#e5e5e5",
                        color: theme === "dark" ? "#243158" : "#e5e5e5",
                      },
                    },
                  },
                  plugins: {
                    title: {
                      display: true,
                      color: theme === "dark" ? "#ffffff" : "#666666",
                      text: `Visualización de operaciones contratadas por día para el producto ${productos.find(producto => producto.value == productoSeleccionado)?.label?.toLowerCase()}`,
                    },
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 10,
                        color: theme === "dark" ? "#ffffff" : "#666666",
                      },
                    },
                  },
                }}
              />
            </Col>
          </Grid>
        ) : (
          <SpinnerLoading text="Cargando rendimiento de operaciones" />
        )}
      </>
    </>
  );
};

export default VendedorRendimientoDiarioTab;
