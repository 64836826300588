import { Grid, Col } from "../Grid";
import { EmisionData } from "./styled";
import Subtitle from "../Subtitle";
import { FiFileText } from "react-icons/fi";
import { RiWhatsappLine } from "react-icons/ri";

const EmisionSeccion = ({ data, title, description, label, icon }) => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12} spaced={true}>
        <Subtitle title={title} description={description ? description : null} icon={icon} disableDivider={true}/>
      </Col>
      {Object.keys(data).length > 0 &&
        Object.entries(data).map((item, index) => {
          if (label[item[0]] && item[1]) {
            const isString = typeof(item[1]) === "string"
            const isLink = isString ? item[1].includes("http") : null
            const linebreak = isString ? item[1].includes("\n") : null
            const hasLineBreak = isString ? item[1].split("\n") : null
            const importe = item[0] === "importe" ? formatter.format(item[1]) : null
            let column = linebreak ? 12 : 3
            column = isString && item[1].includes("@") ? 6 : column
            return (
              <Col desktop={column} key={index}>
                <EmisionData>
                  <div className="seccion-container">
                    <span>{label[item[0]]}</span>
                    {hasLineBreak && linebreak ? hasLineBreak.map(item => <h2>{item}</h2>) : null}
                    {isLink ? <a href={item[1]} target="_blank" rel="noreferrer"><div className="icon"><span><FiFileText /></span></div></a> : null}
                    {importe ? <h2>${importe}</h2> : null}
                    {!isLink && !linebreak && !importe ? <h2>{item[1]}</h2> : null}
                  </div>
                </EmisionData>
              </Col>
            );
          }
        })}
    </Grid>
  );
};

export default EmisionSeccion;
