import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  width: 900px;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 700px;
  background: ${({ theme }) => theme.background};
  font-family: "DM Sans", sans-serif;
  border-radius: 6px;
  gap: 10px;
  box-sizing: border-box;

  .asignacion-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h2 {
      color: ${leerColor(colores.negro)};
      font-size: 24px;
      margin: 0;
    }

    p {
      color: ${leerColor(colores.grisEstados)};
      margin: 0;
      font-size: 14px;
    }
  }

  .icon-sin-solicitudes {
    font-size: 60px;
    color: ${leerColor(colores.azulRebranding)};
    display: flex;
  }

  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;

    h2 {
      color: ${({ theme }) => theme.title};
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${({ theme }) => theme.subtitle};
      font-size: 14px;
    }
  }

  h3 {
    font-weight: 500;
  }

  .operador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;

    .icon {
      font-size: 24px;
    }

    h3 {
      font-size: 14px;
      margin: 0;
      font-weight: 500;
      color: ${leerColor(colores.grisEstados)};
    }

    .asignadas {
      font-size: 12px;
      color: ${leerColor(colores.grisEstados)};
    }

    :hover {
      border: 1px solid ${leerColor(colores.celeste)};
    }
  }

  .operador-seleccionado {
    border: 1px solid ${leerColor(colores.celeste)};

    h3 {
      font-weight: 600;
      color: ${leerColor(colores.celeste)};
    }

    .icon {
      color: ${leerColor(colores.celeste)};
    }
  }

  .botones-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
`;

export const RechazoContainer = styled.div`
  background: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 700px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border};

  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 40px 0;

    h2 {
      color: ${({ theme }) => theme.title};
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${({ theme }) => theme.subtitle};
      font-size: 14px;
    }

    .icon-sin-solicitudes {
      font-size: 60px;
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
    }
  }

  .operador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;
    min-height: 116px;

    .icon {
      font-size: 24px;
    }

    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 400;
      color: ${leerColor(colores.grisEstados)};
    }

    .asignadas {
      font-size: 12px;
      color: ${leerColor(colores.grisEstados)};
    }

    :hover {
      opacity: 0.5;
    }
  }

  .operador-seleccionado {
    border: 1px solid ${leerColor(colores.azulRebranding)};

    h4 {
      font-weight: 600;
      color: ${leerColor(colores.azulRebranding)};
    }

    .icon {
      color: ${leerColor(colores.azulRebranding)};
    }
  }

  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: #202020;
      font-weight: 500;
    }
    .motivo {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)};
      border: 1px solid #eaeaea;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      cursor: pointer;
      transition: 0.2s;
    }

    .motivo-selected {
      color: ${leerColor(colores.azulRebranding)};
      border: 1px solid ${leerColor(colores.azulRebranding)};
      font-weight: 500;
    }

    .motivo:hover {
      opacity: 0.8s;
    }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    //gap: 10px;
    margin-top: 10px;
    box-sizing: border-box;

    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .solicitudes-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 380px;
    overflow-y: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
`;

export const OperadorItem = styled.div`
  display: flex;
  width: 100%;
  //border-bottom:1px solid #eeeeee;
  align-items: center;
  justify-content: space-between;
  font-family: "DM Sans", sans-serif;
  //padding: 30px 0;

  :first-of-type {
    padding-top: 0;
  }

  .asegurado {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.title};
  }

  .solicitud-data-container {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    box-sizing: border-box;
    height: 90px;
    justify-content: space-between;
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle};
  }

  .solicitud {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    .logo-container {
      width: 90px;
      height: 90px;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.border};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      span {
        font-size: 12px;
      }
    }

    img {
      width: 75px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
`;

export const ButtonNotificarTodos = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Styled = styled.div`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 800px;
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .result-container {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  .success-section {
    margin-bottom: 20px;
  }

  .error-section {
    margin-top: 20px;
  }

  .notificacion-success {
    color: #389e0d;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .notificacion-error {
    color: #cf1322;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .result-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }

  .result-table th {
    background-color: #e6e6e6;
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .result-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }

  .result-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .notification-result.success {
    background-color: rgba(144, 238, 144, 0.4);
    color: #2e7d32;
    margin-top: 10px;
    padding: 8px 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .notification-result.error {
    background-color: rgba(255, 0, 0, 0.2);
    color: #cf1322;
    margin-top: 10px;
    padding: 8px 12px;
    border-radius: 20px;
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;
  }

  .notificacion-poliza {
    display: flex;
    align-items: center;
    background-color: rgba(144, 238, 144, 0.4);
    border-radius: 20px;
    padding: 8px 12px;
    margin-bottom: 8px;
    color: #2e7d32;
  }

  .icono-mail {
    margin-right: 8px;
    font-size: 1.2em;
  }
`;
