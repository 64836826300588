import { Grid, Col } from "../../Grid";
import { InputSelect, InputBasic, ErrorInput } from "../../../components/Index";
import NewInputSelect from "../../NewInputSelect";
import NewInputBasic from "../../NewInputBasic";

const PlanForm = ({ form, data }) => {

  const { inputValues, handleChange, formErrors } = form;
  const { productos, companias, setCompañiaSelecciona, setProductoSeleccionado } = data;
  
  console.log(companias)
  console.log(productos)

  return (
    <Grid colGap={21} rowGap={10} narrow={false}>
      <Col desktop={3}>
        <NewInputSelect
          labelName={"Compañia"}
          name="compania"
          options={companias}
          onChange={handleChange}
          value={inputValues.compania}
          setCompañiaSelecciona={setCompañiaSelecciona}
          placeholder={"Compañias"}
        />
        {formErrors.compania && <ErrorInput>{formErrors.compania}</ErrorInput>}
      </Col>
      {inputValues.landing === "Seguro Bici" ||
      inputValues.landing === "Seguro Motos" ? (
        <Col desktop={3}>
          <NewInputSelect
            labelName={"Producto"}
            name="producto"
            options={productos}
            onChange={handleChange}
            value={inputValues.producto}
            setProductoSeleccionado={setProductoSeleccionado}
            placeholder={"Productos"}
          />
          {formErrors.producto && (
            <ErrorInput>{formErrors.producto}</ErrorInput>
          )}
        </Col>
      ) : null}
      <Col desktop={3}>
        <NewInputBasic
          type="text"
          value={inputValues.plan}
          labelName="Nombre del plan"
          placeholder="Plan Standard"
          name="plan"
          onChange={handleChange}
        />
        {formErrors.plan && <ErrorInput>{formErrors.plan}</ErrorInput>}
      </Col>
    </Grid>
  );
};

export default PlanForm;
