import styled from "styled-components";
import { breakpoints, colores, leerColor } from "../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //margin-top: 30px;
  box-sizing: border-box;
  //padding: 20px;
  //border: 1px solid #eaeaea;
  //border-radius: 8px;
  //background-color: ${leerColor(colores.blanco)};
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  gap: 30px;

  .descripcion {
  white-space: pre-line;
  }

  .container-section {
      display: flex;
    justify-content: space-between;
  }

  .container-acciones {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .notificaciones-icono {
      display: flex;
      border-radius: 500px;
      //border: 1px solid #eaeaea;
      font-size: 18px;
      box-sizing: border-box;
      padding: 6px;
      cursor: pointer;
      position: relative;

      :hover {
        opacity: 0.8;
      }
    }

    .notificaciones-contador {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 200px;
      background-color: ${leerColor(colores.rojo)};
      color: white;
      position: absolute;
      top: 18px;
      right: -8px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    .notificaciones-container {
      width: 400px;
      max-height: 400px;
      position: absolute;
      top: 40px;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border-radius: 6px;
      z-index: 199;
      box-sizing: border-box;
      font-family: "DM Sans", sans-serif;
      // border: 1px solid #eaeaea;
      background-color: ${({ theme }) => theme.background};
      display: flex;
      box-shadow: 0px 5px 30px #eaeaea;

      .notificaciones-titulo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;

        span {
          font-size: 13px;
          color: ${leerColor(colores.grisEstados)};
          padding-right: 20px;
          cursor: pointer;

          :hover {
            opacity: 0.8;
          }
        }
      }

      .empty-state-notificaciones {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        box-sizing: border-box;
        padding: 80px 0;
        gap: 6px;

        h4 {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
      }

      .tabs-container {
        padding: 20px;
      }

      .notif-container {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }

      .notif-item {
        font-weight: 500;
      }

      .notif {
        line-height: 1.5;
      }

      .empty-state-notificaciones-icono {
        font-size: 26px;
        color: ${leerColor(colores.azulRebranding)};
      }

      .empty-descripcion {
        color: ${leerColor(colores.grisEstados)};
        font-size: 14px;
        width: 80%;
        text-align: center;
      }

      h3 {
        margin: 0;
        padding: 20px;
        font-size: 16px;
        color: ${leerColor(colores.negro)};
      }

      .fecha-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;

        span {
          background-color: red;
          box-sizing: border-box;
          padding: 10px 20px;
          background-color: #f8f8f8;
          font-weight: 500;
          color: ${leerColor(colores.grisEstados)};
          border-radius: 100px;
          font-size: 14px;
        }
      }

      .notificacion-item {
        display: flex;
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #eaeaea;
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        flex-direction: column;
        gap: 10px;
        cursor: pointer;

        .notificacion-contenido-container {
          display: flex;
          gap: 14px;
          align-items: flex-start;
        }

        .notificacion-titulo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0;
            font-size: 14px;
            color: ${leerColor(colores.negro)};
          }
        }

        .notificacion-contenido {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        }

        .notificacion-hora {
          text-align: right;
        }

        .pagina-icono {
          font-size: 18px;
          box-sizing: border-box;
          border: 1px solid #eaeaea;
          padding: 10px;
          display: flex;
          border-radius: 100%;
        }

        a {
          color: ${leerColor(colores.azulRebranding)};
          display: flex;
          align-items: center;
          gap: 4px;

          .notificacion-icono {
            display: flex;
          }
        }

        :last-of-type {
          border: none;
        }

        :hover {
          background-color: #f7f7f7;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .descripcion {
    margin: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle};
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
  }
`;
