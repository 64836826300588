// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable";
// Filters
import { filtersUsuarios } from "../../../utils/filtersPages";
import { filterUsuariosInitialValues } from "../../../utils/filtersPages";
// Table
import { usuariosTable } from "../../../utils/tableData";
// Icons
import { HiPlus } from "react-icons/hi";
import { useUsuarios } from "../../../hooks/useUsuarios";
import useModal from "../../../hooks/useModal";
import { Container, LimiteCard } from "./styled";
import NewButton from "../../../components/NewButton";
import { FiFile, FiFilter } from "react-icons/fi";
import { useEffect, useState } from "react";
import NewFiltersSection from "../../../components/NewFiltersSection";
import Subtitle from "../../../components/Subtitle";
import NewTable from "../../../components/NewTable";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Divider from "../../../components/Divider";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import NewInputBasic from "../../../components/NewInputBasic";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import BackButton from "../../../components/BackButton";
import axiosRequest from "../../../utils/axiosConfig";
import CotizadorForm from "../../../components/LandingsForms/CotizadorForm";

const LandingsCotizador = () => {
  const [limites, setLimites] = useState([])
  const formatter = new Intl.NumberFormat("es-ES", {});

  useEffect(() => {
    const getLimites = async () => {
      try {
        const limites = await axiosRequest.get(`/productos_landing/Seguro Bici`);
        console.log(limites.data)
        setLimites(limites.data)
      } catch (error) {
        console.log(error)
      }
    }
    getLimites()
  }, [])

  return (
    <>
      <Layout>
      <NewWrapper>
        <NewInner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
              <BackButton url={"/Landings"} text={"Landings"}/>

                <NewTitlePageSection
                  title="Cotizador"
                  description={"Lista de valores límite para el cotizador de la landing"}
                >
                </NewTitlePageSection>
              </Col>
            </Grid>

            <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
              <Subtitle
                icon={<FiFile />}
                title={`Cotizador`}
                description={
                  "Valores obtenidos para todos los premios. Hacé click en cada uno para conocer más detalles."
                }
                disableDivider={true}
              />
              </Col>
              <Col desktop={12}>
                <CotizadorForm productos={limites} />
              </Col>
              {/* {limites.length > 0 ? limites.map(item => {
                return (
                  <Col desktop={3}>
                  <LimiteCard>
                    <div>
                    <span>{item.descripcion}</span>
                    <h3>$ {formatter.format(item.limite_cotizador)}</h3>
                    </div>
                    <Divider />
                    <NewInputBasic
                      type={"number"}
                      name={"telefono_siniestro"}
                      placeholder={"Teléfono"}
                      // onChange={handleChange}
                      // value={inputValues.telefono_siniestro}
                    />
                  </LimiteCard>
                  </Col>
                )
              }) : null} */}
            </Grid>
          </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </>
  );
};

export default LandingsCotizador;
