import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .formatos {
    gap: 10px;

    li {
      list-style: none;
      font-size: 14px;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 6px;
      line-height: 1.7;
      color: ${leerColor(colores.grisEstados)};
      display: flex;
      flex-direction: column;

      span {
      font-size: 14px;
      font-weight: 500;
      color: ${leerColor(colores.negro)};
    }
    }
  }
  .header-section {
    display: flex;
    gap: 10px;
  }
  .header-info {
    border: 1px solid #eaeaea;
    //box-sizing: border-box;
    padding: 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    gap: 6px;
  }
  .logo-compania {
    width: 100px;
    border: 1px solid #eaeaea;
    //box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
  }
  
  .back-button {
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      margin-bottom: 20px;
      text-decoration: none;
    }
    
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  p {
    margin: 0 0 0px 0;
    color: ${({ theme }) => theme.subtitle};
  }
  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)};
  }
  h4 {
    margin: 10px 0;
  }
  .container-botones {
    display: flex;
    gap: 10px;
  }
  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .data-icon {
    font-size: 24px;
  }
  .buttons-container {
    display: flex;
    width: 100%;
    gap: 10px;
    a {
      text-decoration: none;
    }
  }
  .premio-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
    span {
      text-transform: capitalize;
    }
    a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
    span {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        font-weight: 400;
    }
    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: ${leerColor(colores.negro)};
        text-transform: capitalize;
    }
    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }
    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }
  .total {
    border: 1px solid ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    background-color: ${leerColor(colores.negro)};
    span, h2{
      color: ${leerColor(colores.blanco)};
    }
  }

  .errores-container {
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid rgb(234, 234, 234);
    box-sizing: border-box;
    padding: 10px 16px;
    display: flex;
    gap: 6px;
    margin: 0px;
    margin-top: 20px;
    width: fit-content;

    .errores-titulo {
      color: ${leerColor(colores.negro)};
      display: flex;
      gap: 6px;
    }

    p {
      display: flex;
      gap: 6px;
      color: ${({ theme }) => theme.subtitle};
    }

    strong {
      color: ${leerColor(colores.rojo)};
      font-weight: 500;
    }

    .icon {
      display: flex;
      align-items: center;
      color: ${leerColor(colores.rojo)};
    }
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;
  p {
    margin: 0;
  }
  strong {
    color: ${leerColor(colores.azul)};
  }
`;

export const Icon = styled.span`
  font-size: 60px;
  display: flex;
  color: ${leerColor(colores.celeste)};
`;

export const Breadcrumb = styled.span`
  display: flex;
  font-size: 14px;
  color: ${leerColor(colores.grisEstados)};
  gap: 10px;
  a {
    align-items: center;
      gap: 6px;
      font-weight: 400;
      margin-bottom: 20px;
      text-decoration: none;
      color: ${leerColor(colores.grisEstados)};
  }
  .vendedor-nombre {
    cursor: pointer;
  }
  a:hover, .vendedor-nombre:hover{
    opacity: 0.6;
  }
  .active {
    color: ${leerColor(colores.azulRebranding)}
  }
`;