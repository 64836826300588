import {
  FiCalendar,
  FiCheck,
  FiCoffee,
  FiLogIn,
  FiLogOut,
  FiThumbsUp,
} from "react-icons/fi";
import { Col, Grid } from "../../../../../Grid";
import dayjs from "dayjs";
import ErrorInput from "../../../../../ErrorInput";
import NewInputSelect from "../../../../../NewInputSelect";
import NewInputCalendar from "../../../../../NewInputCalendar";
import NewInputBasic from "../../../../../NewInputBasic";
import Divider from "../../../../../Divider";
import CardSinResultados from "../../../../../CardSinResultados";

const FichajesPorUsuarioTab = ({ data, fichajes, acciones, form }) => {
  const { fichajeSeleccionado, rolesComerciales, usuarios, usuarioComercial } =
    data;
  const {
    ingreso_fichaje_usuario,
    inicio_almuerzo_fichaje_usuario,
    fin_almuerzo_fichaje_usuario,
    salida_fichaje_usuario,
    salida_especial_fichaje_usuario,
    reingreso_especial_fichaje_usuario,
  } = fichajes;
  const { seleccionarFichaje } = acciones;
  const { inputValues, handleChange, formErrors } = form;

  return (
    <div>
      <>
        <Grid colGap={21} rowGap={21} narrow={true}>
          <Col desktop={5}>
            <NewInputSelect
              labelName="Usuario"
              name={"usuario"}
              onChange={handleChange}
              placeholder={"Usuario"}
              options={usuarios}
              value={inputValues.usuario}
            />
            {formErrors.usuario ? (
              <ErrorInput>{formErrors.usuario}</ErrorInput>
            ) : null}
          </Col>
          <Col desktop={4}>
            <NewInputCalendar
              labelName={"Fecha"}
              name={"fecha"}
              onChange={handleChange}
              value={inputValues.fecha}
            />
            {formErrors.fecha ? (
              <ErrorInput>{formErrors.fecha}</ErrorInput>
            ) : null}
          </Col>
          <Col desktop={3}>
            <NewInputBasic
              type="hora"
              labelName="Hora"
              name="hora"
              placeholder="14:20"
              onChange={handleChange}
              value={inputValues.hora}
            />
            {formErrors.hora ? (
              <ErrorInput>{formErrors.hora}</ErrorInput>
            ) : null}
          </Col>
        </Grid>
        <Divider />
        <Grid colGap={21} rowGap={21} narrow={true}>
          {inputValues.usuario && inputValues.fecha ? (
            <>
              <Col desktop={12}>
                <div
                  className={`fichaje ${
                    fichajeSeleccionado === "Ingreso" &&
                    !ingreso_fichaje_usuario
                      ? "selected"
                      : ""
                  }  ${ingreso_fichaje_usuario ? "fichado" : ""}`}
                  onClick={() => seleccionarFichaje("Ingreso")}
                >
                  <div className="fichaje-data">
                    <span className="icon">
                      <FiLogIn />
                    </span>
                    <div className="fichaje-info">
                      <h4>Ingreso</h4>
                      {ingreso_fichaje_usuario ? (
                        <span>
                          Realizado a las{" "}
                          {ingreso_fichaje_usuario.dia_fichaje_esperado
                            ? dayjs(
                                ingreso_fichaje_usuario.dia_fichaje_esperado
                              ).format("HH:mm")
                            : dayjs(ingreso_fichaje_usuario.dia_fichaje).format(
                                "HH:mm"
                              )}
                          hs
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {ingreso_fichaje_usuario ? (
                    <div className="fichaje-realizado">
                      <span className="icon-fichaje-realizado">
                        <FiCheck />
                      </span>
                      <span>Fichaje realizado</span>
                    </div>
                  ) : (
                    <div className="fichaje-realizado">
                      <span className="icon-fichaje-disponible">
                        <FiThumbsUp />
                      </span>
                      <span className="fichaje-disponible">
                        Fichaje disponible
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              {!rolesComerciales.includes(usuarioComercial) ? (
                <>
                  <Col desktop={12}>
                    <div
                      className={`fichaje ${
                        !ingreso_fichaje_usuario ? "disabled" : ""
                      } ${
                        fichajeSeleccionado === "Inicio almuerzo" &&
                        ingreso_fichaje_usuario
                          ? "selected"
                          : ""
                      } ${inicio_almuerzo_fichaje_usuario ? "fichado" : ""}`}
                      onClick={() => seleccionarFichaje("Inicio almuerzo")}
                    >
                      <div className="fichaje-data">
                        <span className="icon">
                          <FiCoffee />
                        </span>
                        <div className="fichaje-info">
                          <h4>Inicio de almuerzo</h4>
                          {inicio_almuerzo_fichaje_usuario ? (
                            <span>
                              Realizado a las{" "}
                              {dayjs(
                                inicio_almuerzo_fichaje_usuario.dia_fichaje
                              ).format("HH:mm")}
                              hs
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {inicio_almuerzo_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-realizado">
                            <FiCheck />
                          </span>
                          <span>Fichaje realizado</span>
                        </div>
                      ) : null}
                      {ingreso_fichaje_usuario &&
                      !inicio_almuerzo_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-disponible">
                            <FiThumbsUp />
                          </span>
                          <span className="fichaje-disponible">
                            Fichaje disponible
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col desktop={12}>
                    <div
                      className={`fichaje ${
                        !inicio_almuerzo_fichaje_usuario ? "disabled" : ""
                      } ${
                        fichajeSeleccionado === "Fin almuerzo" &&
                        inicio_almuerzo_fichaje_usuario
                          ? "selected"
                          : ""
                      }  ${fin_almuerzo_fichaje_usuario ? "fichado" : ""}`}
                      onClick={() => seleccionarFichaje("Fin almuerzo")}
                    >
                      <div className="fichaje-data">
                        <span className="icon">
                          <FiCoffee />
                        </span>
                        <div className="fichaje-info">
                          <h4>Fin de almuerzo</h4>
                          {fin_almuerzo_fichaje_usuario ? (
                            <span>
                              Realizado a las{" "}
                              {dayjs(
                                fin_almuerzo_fichaje_usuario.dia_fichaje
                              ).format("HH:mm")}
                              hs
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {fin_almuerzo_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-realizado">
                            <FiCheck />
                          </span>
                          <span>Fichaje realizado</span>
                        </div>
                      ) : null}
                      {inicio_almuerzo_fichaje_usuario &&
                      !fin_almuerzo_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-disponible">
                            <FiThumbsUp />
                          </span>
                          <span className="fichaje-disponible">
                            Fichaje disponible
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </>
              ) : null}
              <Col desktop={12}>
                <div
                  className={`fichaje ${
                    (!fin_almuerzo_fichaje_usuario &&
                      !rolesComerciales.includes(usuarioComercial)) ||
                    (usuarioComercial && !ingreso_fichaje_usuario)
                      ? "disabled"
                      : ""
                  } ${
                    (fichajeSeleccionado === "Fin jornada" &&
                      fin_almuerzo_fichaje_usuario &&
                      !usuarioComercial) ||
                    (fichajeSeleccionado === "Fin jornada" &&
                      ingreso_fichaje_usuario &&
                      usuarioComercial)
                      ? "selected"
                      : ""
                  }  ${salida_fichaje_usuario ? "fichado" : ""}`}
                  onClick={() => seleccionarFichaje("Fin jornada")}
                >
                  <div className="fichaje-data">
                    <span className="icon">
                      <FiLogOut />
                    </span>
                    <div className="fichaje-info">
                      <h4>Salida</h4>
                      {salida_fichaje_usuario ? (
                        <span>
                          Realizado a las{" "}
                          {salida_fichaje_usuario.dia_fichaje_esperado
                            ? dayjs(
                                salida_fichaje_usuario.dia_fichaje_esperado
                              ).format("HH:mm")
                            : dayjs(salida_fichaje_usuario.dia_fichaje).format(
                                "HH:mm"
                              )}
                          hs
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {salida_fichaje_usuario ? (
                    <div className="fichaje-realizado">
                      <span className="icon-fichaje-realizado">
                        <FiCheck />
                      </span>
                      <span>Fichaje realizado</span>
                    </div>
                  ) : null}

                  {fin_almuerzo_fichaje_usuario && !salida_fichaje_usuario ? (
                    <div className="fichaje-realizado">
                      <span className="icon-fichaje-disponible">
                        <FiThumbsUp />
                      </span>
                      <span className="fichaje-disponible">
                        Fichaje disponible
                      </span>
                    </div>
                  ) : null}
                </div>
              </Col>
              {!rolesComerciales.includes(usuarioComercial) ? (
                <>
                  <Col desktop={12}>
                    <div
                      className={`fichaje ${
                        !ingreso_fichaje_usuario ? "disabled" : ""
                      } ${
                        fichajeSeleccionado === "Salida especial" &&
                        !salida_especial_fichaje_usuario
                          ? "selected"
                          : ""
                      }  ${salida_especial_fichaje_usuario ? "fichado" : ""}`}
                      onClick={() => seleccionarFichaje("Salida especial")}
                    >
                      <div className="fichaje-data">
                        <span className="icon">
                          <FiLogOut />
                        </span>
                        <div className="fichaje-info">
                          <h4>Salida especial</h4>
                          {salida_especial_fichaje_usuario ? (
                            <span>
                              Realizado a las{" "}
                              {dayjs(
                                salida_especial_fichaje_usuario.dia_fichaje
                              ).format("HH:mm")}
                              hs
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {salida_especial_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-realizado">
                            <FiCheck />
                          </span>
                          <span>Fichaje realizado</span>
                        </div>
                      ) : null}
                      {!salida_especial_fichaje_usuario &&
                      ingreso_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-disponible">
                            <FiThumbsUp />
                          </span>
                          <span className="fichaje-disponible">
                            Fichaje disponible
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col desktop={12}>
                    <div
                      className={`fichaje ${
                        !salida_especial_fichaje_usuario ? "disabled" : ""
                      } ${
                        fichajeSeleccionado === "Reingreso especial" &&
                        salida_especial_fichaje_usuario
                          ? "selected"
                          : ""
                      } ${reingreso_especial_fichaje_usuario ? "fichado" : ""}`}
                      onClick={() => seleccionarFichaje("Reingreso especial")}
                    >
                      <div className="fichaje-data">
                        <span className="icon">
                          <FiLogIn />
                        </span>
                        <div className="fichaje-info">
                          <h4>Reingreso especial</h4>
                          {reingreso_especial_fichaje_usuario ? (
                            <span>
                              Realizado a las{" "}
                              {dayjs(
                                reingreso_especial_fichaje_usuario.dia_fichaje
                              ).format("HH:mm")}
                              hs
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {reingreso_especial_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-realizado">
                            <FiCheck />
                          </span>
                          <span>Fichaje realizado</span>
                        </div>
                      ) : null}
                      {!reingreso_especial_fichaje_usuario &&
                      salida_especial_fichaje_usuario ? (
                        <div className="fichaje-realizado">
                          <span className="icon-fichaje-disponible">
                            <FiThumbsUp />
                          </span>
                          <span className="fichaje-disponible">
                            Fichaje disponible
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </>
              ) : null}
              <Col desktop={12}>
                {formErrors.fichaje ? (
                  <ErrorInput>{formErrors.fichaje}</ErrorInput>
                ) : null}
              </Col>
            </>
          ) : (
            <Col desktop={12}>
              <CardSinResultados
                icon={<FiCalendar />}
                title="No hay fichajes"
                description={
                  "Seleccioná un usuario y una fecha para ver la lista de fichajes disponibles"
                }
              />
            </Col>
          )}
        </Grid>
      </>
    </div>
  );
};

export default FichajesPorUsuarioTab;
