import axiosRequest from "./axiosConfig";
import dayjs from "dayjs";
import valid from "card-validator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// *** SINIESTROS *** //

// Cargar Siniestro
export const cargarSiniestrosReglas = async (state) => {
  let checkPoliza = false;
  let checkDNI = false;
  let checkPolizaDni = false;

  try {
    await axiosRequest.get(`/poliza_siniestro/${state.poliza}`);
    checkPoliza = true;
  } catch (error) {
    if (error.response) {
      checkPoliza = false;
    }
  }

  try {
    await axiosRequest.get(`/dni_siniestro/${state.dni}`);
    checkDNI = true;
  } catch (error) {
    if (error.response) {
      checkDNI = false;
    }
  }

  try {
    const getPolizaInfo = await axiosRequest.get(
      `/siniestros/${state.poliza}/${state.dni}`
    );
    console.log(getPolizaInfo);
    //polizaInfo = getPolizaInfo.data[0]
    checkPolizaDni = true;
  } catch (error) {
    if (error.response) {
      checkPolizaDni = false;
    }
  }

  // Fecha
  const currentDate = dayjs(new Date());
  const siniestroLoadedDate = dayjs(state.fecha_siniestro);
  const result = currentDate.diff(siniestroLoadedDate, "days", true);

  return {
    tipo_siniestro: [
      {
        regla: state.tipo_siniestro === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    fecha_siniestro: [
      {
        regla: state.fecha_siniestro === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: result < 0,
        mensaje: "La fecha es inválida.",
      },
    ],
    poliza: [
      {
        regla: state.poliza === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !checkPoliza,
        mensaje: "La poliza ingresada no existe.",
      },
      {
        regla: !checkPolizaDni,
        mensaje: "La poliza no está vinculada al DNI ingresado.",
      },
    ],
    dni: [
      {
        regla: state.dni === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.dni.length !== 8,
        mensaje: "El DNI no es válido.",
      },
      {
        regla: !checkDNI,
        mensaje: "El DNI ingresado no existe.",
      },
      {
        regla: !checkPolizaDni,
        mensaje: "El DNI no está vinculado a la poliza ingresada.",
      },
    ],
    informe: [
      {
        regla: state.informe === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// Editar Siniestro
export const editarSiniestrosReglas = (state) => {
  return {
    estado: [
      {
        regla: state.estado === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    informe_siniestro: [
      {
        regla:
          state.informe_siniestro === "" || state.informe_siniestro === null,
        mensaje: "Este campo es requerido.",
      },
    ],
    suma_pagada_siniestro: [
      {
        regla:
          state.estado === "Cerrado positivo" && !state.suma_pagada_siniestro,
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// *** SOLICITUD EMISION *** //

// Anulación
export const datosAnulaciónReglas = (state) => {
  return {
    /*
        poliza: [
            {
                regla: state.poliza === "" && (state.numero_poliza === "" && state.id_compania === ""),
                mensaje: 'El archivo de la póliza es requerido en caso de no seleccionar compañia ni cargar el número de póliza.'
            },
        ],
        */
    numero_poliza: [
      {
        regla: state.numero_poliza === "",
        mensaje: "El número de póliza es requerido en caso de no adjuntarla.",
      },
    ],
    id_compania: [
      {
        regla: state.id_compania === "",
        mensaje: "La compañia es requerida en caso de no adjuntar la póliza.",
      },
    ],
  };
};

// Compania
export const datosAseguradoReglas = (state, extraData) => {
  const { id_tipo_solicitud } = extraData;
  const validCUIT = [20, 23, 24, 27, 30];

  const currentDate = dayjs(new Date()).format("DD/MM/YYYY");
  const date1 = dayjs(state.nacimiento_asegurado, "DD-MM-YYYY");
  const date2 = dayjs(currentDate, "DD-MM-YYYY");
  let yearDifference = date2.diff(date1, "year");
  let year = state?.nacimiento_asegurado?.slice(6, 10);
  let currentYear = new Date().getFullYear();

  const data = {
    nombre_asegurado: [
      {
        regla: state.nombre_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.nombre_asegurado.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre_asegurado
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido_asegurado: [
      {
        regla: state.apellido_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.apellido_asegurado.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido_asegurado
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    dni_asegurado: [
      {
        regla: state.dni_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.dni_asegurado.length !== 8,
        mensaje: "El DNI es inválido.",
      },
    ],
    email_asegurado: [
      {
        regla: state.email_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email_asegurado),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    codigo_area_asegurado: [
      {
        regla: state.codigo_area_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          state.codigo_area_asegurado.length < 2 ||
          state.codigo_area_asegurado.length > 5,
        mensaje: "El código de área debe tener más de 2 digitos y menos de 5.",
      },
    ],
    telefono_asegurado: [
      {
        regla: state.telefono_asegurado === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.telefono_asegurado.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
  };

  if (id_tipo_solicitud != 3) {
    return {
      nombre_asegurado: [
        {
          regla: state.nombre_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.nombre_asegurado.length < 2,
          mensaje: "El campo nombre debe contener al menos 2 caracteres.",
        },
        {
          regla:
            !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
              state.nombre_asegurado
            ),
          mensaje: "El nombre ingresado no es válido.",
        },
      ],
      apellido_asegurado: [
        {
          regla: state.apellido_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.apellido_asegurado.length < 2,
          mensaje: "El campo apellido debe contener al menos 2 caracteres.",
        },
        {
          regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.apellido_asegurado
          ),
          mensaje: "El apellido ingresado no es válido.",
        },
      ],
      nacimiento_asegurado: [
        {
          regla: state.nacimiento_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: year < 1920 || year > currentYear,
          mensaje: "El año ingresado no es válido.",
        },
        {
          regla: yearDifference >= 18 && isNaN(yearDifference),
          mensaje: "La fecha ingresada no es válida.",
        },
        {
          regla: yearDifference >= 18 && yearDifference <= 0,
          mensaje: "El año ingresado no es válido.",
        },
        {
          regla:
            yearDifference < 18 &&
            state.tipo_persona_asegurada !== "Persona Jurídica",
          mensaje: "Es menor de 18 años.",
        },
      ],
      dni_asegurado: [
        {
          regla: state.dni_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.dni_asegurado.length !== 8,
          mensaje: "El DNI es inválido.",
        },
      ],
      genero_asegurado: [
        {
          regla: state.genero_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      cuit_asegurado: [
        {
          regla: state.cuit_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.cuit_asegurado?.length !== 11,
          mensaje: "El campo CUIL solo permite 11 digitos numericos.",
        },
        // {
        //     regla: !validCUIT.some(cuitNumber => state.cuit_asegurado.startsWith(cuitNumber)),
        //     mensaje: `El campo CUIL debe empezar con algunos de estos dígitos: ${validCUIT.join(", ")}.`
        // },
        {
          regla:
            parseInt(state.cuit_asegurado.slice(0, 2)) < 20 ||
            parseInt(state.cuit_asegurado.slice(0, 2)) > 99,
          mensaje: "El CUIL es inválido.",
        },
      ],
      condicion_fiscal_asegurado: [
        {
          regla: state.condicion_fiscal_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      tipo_persona_asegurada: [
        {
          regla: state.tipo_persona_asegurada === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      email_asegurado: [
        {
          regla: state.email_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email_asegurado),
          mensaje: "El email ingresado no es válido.",
        },
      ],
      codigo_area_asegurado: [
        {
          regla: state.codigo_area_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla:
            state.codigo_area_asegurado.length < 2 ||
            state.codigo_area_asegurado.length > 5,
          mensaje:
            "El código de área debe tener más de 2 digitos y menos de 5.",
        },
      ],
      telefono_asegurado: [
        {
          regla: state.telefono_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.telefono_asegurado.length < 8,
          mensaje: "El campo telefono es menor a 8 digitos.",
        },
      ],
      direccion_calle_asegurado: [
        {
          regla: state.direccion_calle_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      nacionalidad_asegurado: [
        {
          regla: state.nacionalidad_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      direccion_numero_asegurado: [
        {
          regla: state.direccion_numero_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state?.direccion_numero_asegurado?.length > 5,
          mensaje: "El número no debe contener tener más de 5 números.",
        },
      ],
      direccion_piso_asegurado: [
        {
          regla: state.direccion_piso_asegurado > 100,
          mensaje: "El piso ingresado no es válido.",
        },
      ],
      direccion_dpto_asegurado: [
        {
          regla: state?.direccion_dpto_asegurado?.length > 10,
          mensaje: "El depto ingresado no puede tener más de 10 digitos.",
        },
      ],
      codigo_postal_asegurado: [
        {
          regla: state.codigo_postal_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
        {
          regla: state.codigo_postal_asegurado.toString().length !== 4,
          mensaje: "El código postal debe contener 4 digitos.",
        },
      ],
      provincia_asegurado: [
        {
          regla: state.provincia_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      localidad_asegurado: [
        {
          regla: state.localidad_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      profesion_asegurado: [
        {
          regla: state.profesion_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      estado_civil_asegurado: [
        {
          regla: state.estado_civil_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      archivo_estatuto_empresa_asegurado: [
        {
          regla:
            state.tipo_persona_asegurada === "Persona Jurídica" &&
            state.archivo_estatuto_empresa_asegurado === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    };
  } else {
    return data;
  }
};

// Compania
export const datosSeguroReglas = (state) => {
  return {
    cia_cobertura_asegurada: [
      {
        regla: state.cia_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    producto_cobertura_asegurada: [
      {
        regla: state.producto_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    cobertura_cobertura_asegurada: [
      {
        regla: state.cobertura_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    tipo_facturacion_cobertura_asegurada: [
      {
        regla: state.tipo_facturacion_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    vigencia_cobertura_asegurada: [
      {
        regla: state.vigencia_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    cuotas_cobertura_asegurada: [
      {
        regla: state.cuotas_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    valor_cobertura_asegurada: [
      {
        regla: state.valor_cobertura_asegurada === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// Pago
export const datosPagoReglas = (state) => {
  const notValidCBUs = ["0143", "0147", "0158", "0165", "0384", "000000"];

  if (state.medio === 4) {
    return {
      medio: [
        {
          regla: state.medio === "" || parseInt(state.medio) === 0,
          mensaje: "Este campo es requerido.",
        },
      ],
    };
  }

  return {
    medio: [
      {
        regla: state.medio === "" || parseInt(state.medio) === 0,
        mensaje: "Este campo es requerido.",
      },
    ],
    titular_dato_facturacion: [
      {
        regla:
          state.titular_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 ||
            parseInt(state.medio) === 2 ||
            parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !valid.cardholderName(state.titular_dato_facturacion).isValid &&
          parseInt(state.medio) === 1,
        mensaje: "El nombre ingresado no es válido.",
      },
      {
        regla:
          state.titular_dato_facturacion?.length < 2 &&
          (parseInt(state.medio) === 1 ||
            parseInt(state.medio) === 2 ||
            parseInt(state.medio) === 6),
        mensaje:
          "El campo Titular de la tarjeta debe contener al menos 2 caracteres",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.titular_dato_facturacion
          ) &&
          (parseInt(state.medio) === 1 ||
            parseInt(state.medio) === 2 ||
            parseInt(state.medio) === 6),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    marca_dato_facturacion: [
      {
        regla:
          state.marca_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
    ],
    num_dato_facturacion: [
      {
        regla:
          state.num_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
      /*
            {
                regla: (state.num_dato_facturacion.startsWith("4517") || state.num_dato_facturacion.startsWith("4815") || state.num_dato_facturacion.startsWith("5285")) && (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
                mensaje: 'El número de tarjeta ingresado corresponde a una tarjeta de debito.'
            },
            */
      {
        regla:
          !valid.number(state.num_dato_facturacion).isValid &&
          !state.num_dato_facturacion.startsWith("5895") &&
          !state.num_dato_facturacion.startsWith("6042") &&
          state.banco_dato_facturacion.toLowerCase() !== "naranja" &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "El número de tarjeta ingresado no es válido.",
      },
      {
        regla: state.num_dato_facturacion === "" && parseInt(state.medio) === 2,
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          state?.num_dato_facturacion?.length !== 22 &&
          parseInt(state.medio) === 2,
        mensaje: `El CBU ingresado debe contener 22 digitos. El valor ingresado tiene ${state?.num_dato_facturacion?.length} digitos.`,
      },
      {
        regla:
          notValidCBUs.some((cbuNumber) =>
            state.num_dato_facturacion.startsWith(cbuNumber)
          ) && parseInt(state.medio) === 2,
        mensaje: `CBU no bancarizado.`,
      },
    ],
    cuil_titular_dato_facturacion: [
      {
        regla:
          state.cuil_titular_dato_facturacion === "" &&
          parseInt(state.medio) === 2,
        mensaje: "Este campo es requerido.",
      },
    ],
    vencimiento_dato_facturacion: [
      {
        regla:
          state.vencimiento_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !valid.expirationDate(state.vencimiento_dato_facturacion).isValid &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "La fecha de vencimiento ingresada no es válida.",
      },
    ],
    dni_dato_facturacion: [
      {
        regla:
          state.dni_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          (state.dni_dato_facturacion?.length < 7) |
            (state.dni_dato_facturacion?.length > 8) &&
          (parseInt(state.medio) === 1 || parseInt(state.medio) === 6),
        mensaje: "El campo DNI solo permite entre 7 y 8 digitos.",
      },
    ],
    banco_dato_facturacion: [
      {
        regla:
          state.banco_dato_facturacion === "" &&
          (parseInt(state.medio) === 1 ||
            parseInt(state.medio) === 2 ||
            parseInt(state.medio) === 6),
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !/^[A-Za-z'\s\u00C0-\u017F]+$/.test(state.banco_dato_facturacion) &&
          (parseInt(state.medio) === 1 ||
            parseInt(state.medio) === 2 ||
            parseInt(state.medio) === 6),
        mensaje: "El Banco emisor ingresado no es válido.",
      },
    ],
  };
};

// Observacion
export const datosObservacionReglas = async (state) => {
  /*
    let checkOperacion = null
    if(state.operacion){
        try {
            const data = {
                operacionId: state.operacion
            }
            checkOperacion = await axiosRequest.post('/operaciones', data);
        } catch (error) {
            console.log(error)
        }
    }

    return {
        operacion: [
            {
                regla: state.operacion === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.operacion.toString().length !== 6,
                mensaje: 'El número de operacion tiene que tener 6 dígitos.'
            }, 
            {
                regla: !checkOperacion,
                mensaje: 'Número de operación inválido.'
            }, 
        ],
    }
    */
};

// Operación
export const datosOperacionSolicitudReglas = (state) => {
  /*
    console.log(state)

    let checkOperacion = null
    if(state.num_operacion){
        try {
            const data = {
                operacionId: state.num_operacion
            }
            checkOperacion = await axiosRequest.post('/operaciones', data);
            console.log(checkOperacion)
        } catch (error) {
            console.log(error)
        }
    }
    */

  return {
    num_operacion: [
      {
        regla: state.num_operacion === "",
        mensaje: "Este campo es requerido.",
      },
      /*
            {
                regla: state.num_operacion.toString().length !== 6,
                mensaje: 'El número de operacion tiene que tener 6 dígitos.'
            }, 

            {
                regla: !checkOperacion,
                mensaje: 'Número de operación inválido.'
            }, 
                        */
    ],
    tipo_solicitud: [
      {
        regla: state.tipo_solicitud === "",
        mensaje: "Es necesario seleccionar un tipo de solicitud.",
      },
      /*
            {
                regla: state.num_operacion.toString().length !== 6,
                mensaje: 'El número de operacion tiene que tener 6 dígitos.'
            }, 

            {
                regla: !checkOperacion,
                mensaje: 'Número de operación inválido.'
            }, 
                        */
    ],
  };
};

// Observacion
export const modalRechazoReglas = (state) => {
  return {
    motivo: [
      {
        regla: state.motivo === "",
        mensaje: "Seleccioná un motivo de rechazo.",
      },
    ],
  };
};

// Observacion
export const modalEstadoEmisionReglas = (state) => {
  return {
    estado: [
      {
        regla: state.estado === "",
        mensaje: "El estado es requerido.",
      },
    ],
    mensaje: [
      {
        regla: state.mensaje === "",
        mensaje: "El mensaje es requerido.",
      },
    ],
  };
};

const validacionesProducto = (state, campos, producto) => {
  const maxFileSizeInMB = 5;
  const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;

  const camposArchivos = campos.filter((campo) => campo.tipo_campo === "file");

  let validaciones = {};
  if (camposArchivos.length > 0) {
    camposArchivos.forEach((campo) => {
      const sizeValidation = {
        regla: state[campo.name_campo]?.image?.size > maxFileSizeInKB,
        mensaje: "El archivo cargado excede el límite de 5mb.",
      };
      validaciones[campo.name_campo] = [sizeValidation];
    });
  }

  // Validaciones auto
  if (parseInt(producto) === 3) {
    const camposSinArchivos = campos.filter(
      (campo) => campo.tipo_campo !== "file"
    );
    if (camposSinArchivos.length > 0) {
      camposSinArchivos.forEach((campo) => {
        if (
          campo.name_campo !== "num_oblea_enargas_gnc" &&
          campo.name_campo !== "gnc_suma_asegurada" &&
          campo.name_campo !== "accesorios" &&
          !campo.name_campo.startsWith("check") &&
          campo.name_campo !== "uso"
        ) {
          const validation = {
            regla: state[campo.name_campo] === "",
            mensaje: "Este campo es requerido.",
          };
          validaciones[campo.name_campo] = [validation];
        }
      });
    }
  }

  return validaciones;

  /*
    const validaciones = {
        1: {
        
            marca: [
                {
                    regla: state.marca === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            modelo: [
                {
                    regla: state.modelo === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            num_serie: [
                {
                    regla: state.num_serie === "" && !state.no_posee_num_serie,
                    mensaje: 'Este campo es requerido.'
                },
            ],
            num_marco: [
                {
                    regla: state?.num_marco === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            suma_asegurada: [
                {
                    regla: state.suma_asegurada === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],

            archivo_bicicleta: [
                {
                    regla: state?.archivo_bicicleta === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            archivo_electrica_mono: [
                {
                    regla: state?.archivo_electrica_mono === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            archivo_num_serie_bicicleta: [
                {
                    regla: state.archivo_num_serie_bicicleta === "",
                    mensaje: 'Este campo es requerido.'
                },
            ],
            archivo_factura_bicicleta: [
                {
                    regla: state.archivo_factura_bicicleta === "" && !state.archivo_presupuesto_bicicleta && !state.link_mercadolibre,
                    mensaje: 'Este campo es requerido.'
                },
            ],
            link_mercadolibre: [
                {
                    regla: state.link_mercadolibre === "" && !state.archivo_factura_bicicleta && !state.archivo_presupuesto_bicicleta,
                    mensaje: 'Este campo es requerido.'
                },
                {
                    regla: !state.link_mercadolibre === "" && !state.link_mercadolibre.startsWith("https://www.mercadolibre.com.ar"),
                    mensaje: 'El link no es válido.'
                },
            ],
            archivo_presupuesto_bicicleta: [
                {
                    regla: state.archivo_presupuesto_bicicleta === "" && !state.archivo_factura_bicicleta && !state.link_mercadolibre,
                    mensaje: 'Este campo es requerido.'
                },
            ],
            archivo_video: [
                {
                    regla: state.archivo_video === "" && state.suma_asegurada >= 350000,
                    mensaje: 'Este campo es requerido.'
                },
            ],
    
        }
    }
        */
  /*
    let validacionesProducto = {}
    for (const key in validaciones[producto]) {
        const checkCampo = campos.find(campo => key === campo.name_campo)
        if(checkCampo) validacionesProducto[key] = validaciones[producto][key]
    }

    console.log(validacionesProducto)

    return validacionesProducto
    */
};

export const validacionesBienAsegurado = (state, wizardData) => {
  const { campos, seguro } = wizardData;
  const validaciones = validacionesProducto(
    state,
    campos.campos,
    seguro.producto_cobertura_asegurada
  );
  return validaciones;
};

// *** USUARIOS *** //

export const datosUsuariosReglas = (state, pathname) => {
  console.log(pathname);

  const validaciones = {
    nombre_operador: [
      {
        regla: state.nombre_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    apellido_operador: [
      {
        regla: state.apellido_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    nacimiento_operador: [
      {
        regla: state.nacimiento_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    email_operador: [
      {
        regla: state.email_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    telefono_operador: [
      {
        regla: state.telefono_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    dni_operador: [
      {
        regla: state.dni_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    cuit_operador: [
      {
        regla: state.cuit_operador === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    usuario_usuario: [
      {
        regla: state.usuario_usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    id_rol: [
      {
        regla: state.id_rol === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    estado_usuario: [
      {
        regla: state.estado_usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };

  if (!pathname.includes("Editar")) {
    validaciones.clave_usuario = [
      {
        regla: state.clave_usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ];
  }

  return validaciones;
};

export const datosGenerarClaveReglas = (state) => {
  return {
    clave_usuario: [
      {
        regla: state.clave_usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    clave_usuario_repetida: [
      {
        regla: state.clave_usuario_repetida === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.clave_usuario_repetida !== state.clave_usuario,
        mensaje: "Las contraseñas no coinciden.",
      },
    ],
  };
};

// *** LOGIN *** //

export const datosLoginReglas = (state) => {
  return {
    usuario: [
      {
        regla: state.usuario === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.usuario),
        mensaje: "El usuario ingresado no es válido.",
      },
    ],
    password: [
      {
        regla: state.password === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// *** OBJETIVOS LEADS *** //

export const datosObjetivoLeadReglas = (state) => {
  return {
    producto: [
      {
        regla: state.producto === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    canal: [
      {
        regla: state.canal === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    operaciones: [
      {
        regla: state.operaciones === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    leads: [
      {
        regla: state.leads === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    mes: [
      {
        regla: state.mes === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    anio: [
      {
        regla: state.anio === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// *** VALORES COTIZACIÓN - LANDINGS *** //

export const valoresCotizacionReglas = (state) => {
  let validacionesDetalles = {};
  for (const detalle of state) {
    if (state[detalle].startsWith("detalle")) {
      validacionesDetalles[detalle] = [
        {
          regla: state[detalle] === "",
          mensaje: "El detalle no puede estar vacío.",
        },
      ];
    }
  }

  return {
    valor_uno: [
      {
        regla: state.valor_uno === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.valor_uno === "0",
        mensaje: "El valor no puede ser 0.",
      },
    ],
    valor_dos: [
      {
        regla: state.valor_dos !== null && state.valor_dos === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.valor_dos !== null && state.valor_dos === "0",
        mensaje: "El valor no puede ser 0.",
      },
    ],
    ...validacionesDetalles,
  };
};

// *** ACTUALIZAR PLAN - LANDINGS *** //

export const actualizarPlanLandingReglas = (state, data) => {
  const { tieneValorCuota } = data;

  let detalles = [];
  let beneficios = [];

  for (const item in state) {
    if (item.includes("detalle")) detalles.push(state[item]);
    if (item.includes("beneficio")) beneficios.push(state[item]);
  }

  const detallesCompletos =
    detalles.length > 0 ? detalles.every((detalle) => detalle !== "") : true;
  const beneficiosCompletos =
    beneficios.length > 0
      ? beneficios.every((beneficio) => beneficio !== "")
      : true;

  return {
    plan: [
      {
        regla: state.plan === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    coberturas: [
      {
        regla: !state.coberturas,
        mensaje: "Es necesario seleccionar al menos una cobertura.",
      },
    ],
    detalles: [
      {
        regla: detalles.length > 0 && !detallesCompletos,
        mensaje:
          "Es requerido que todos los detalles en la lista tengan contenido.",
      },
    ],
    beneficios: [
      {
        regla: beneficios.length > 0 && !beneficiosCompletos,
        mensaje:
          "Es requerido que todos los beneficios en la lista tengan contenido.",
      },
    ],
    operador_uno: [
      {
        regla:
          tieneValorCuota &&
          (state.operador_uno === "" || state.operador_uno === null),
        mensaje: "El primer operador es requerido.",
      },
    ],
    operador_dos: [
      {
        regla:
          tieneValorCuota &&
          state.valor_dos &&
          (state.operador_dos === "" || state.operador_dos === null),
        mensaje: "El segundo operador es requerido.",
      },
    ],
    valor_uno: [
      {
        regla:
          tieneValorCuota &&
          (state.valor_uno === "" || state.valor_uno === null),
        mensaje: "El primer valor es requerido.",
      },
      {
        regla: tieneValorCuota && state.valor_uno === "0",
        mensaje: "El primer valor no puede ser 0.",
      },
    ],
    valor_dos: [
      /*
            {
                regla: tieneValorCuota && state.operador_dos && (state.valor_dos === null || state.valor_dos === ""),
                mensaje: 'El segundo valor es requerido.'
            },
            */
      {
        regla: tieneValorCuota && state.valor_dos && state.valor_dos === "0",
        mensaje: "El segundo valor no puede ser 0.",
      },
    ],
  };
};

export const nuevoDetalleReglas = (state) => {
  return {
    detalle: [
      {
        regla: state.detalle === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const nuevoBeneficioReglas = (state) => {
  return {
    beneficio: [
      {
        regla: state.beneficio === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const detallesReglas = (state) => {
  const rules = Object.keys(state).map((detalle) => {
    let obj = {};
    obj[detalle] = [
      {
        regla: state[detalle] === "",
        mensaje: "Este campo es requerido.",
      },
    ];
  });

  return { rules };
};

export const crearPlanReglas = (state, data) => {
  const { tieneValorCuota, nuevaCompaniaProducto } = data;
  const detallesCompletos =
    state.detalles.length > 0
      ? state.detalles.every((detalle) => detalle !== "")
      : true;
  const beneficiosCompletos =
    state.beneficios.length > 0
      ? state.beneficios.every((beneficio) => beneficio !== "")
      : true;

  return {
    landing: [
      {
        regla: state.landing === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    plan: [
      {
        regla: state.plan === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    compania: [
      {
        regla: state.compania === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    producto: [
      {
        regla: state.producto === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    coberturas: [
      {
        regla: !state.coberturas,
        mensaje: "Es necesario seleccionar al menos una cobertura.",
      },
    ],
    detalles: [
      {
        regla: state.detalles.length > 0 && !detallesCompletos,
        mensaje:
          "Es requerido que todos los detalles en la lista tengan contenido.",
      },
    ],
    beneficios: [
      {
        regla: state.beneficios.length > 0 && !beneficiosCompletos,
        mensaje:
          "Es requerido que todos los beneficios en la lista tengan contenido.",
      },
    ],
    operador_uno: [
      {
        regla:
          tieneValorCuota &&
          (state.operador_uno === "" || state.operador_uno === null),
        mensaje: "El primer operador es requerido.",
      },
    ],
    operador_dos: [
      {
        regla:
          tieneValorCuota &&
          state.valor_dos &&
          (state.operador_dos === "" || state.operador_dos === null),
        mensaje: "El segundo operador es requerido.",
      },
    ],
    valor_uno: [
      {
        regla:
          tieneValorCuota &&
          (state.valor_uno === "" || state.valor_uno === null),
        mensaje: "El primer valor es requerido.",
      },
      {
        regla: tieneValorCuota && state.valor_uno === "0",
        mensaje: "El primer valor no puede ser 0.",
      },
    ],
    valor_dos: [
      /*
            {
                regla: tieneValorCuota && state.operador_dos && (state.valor_dos === null || state.valor_dos === ""),
                mensaje: 'El segundo valor es requerido.'
            },
            */
      {
        regla: tieneValorCuota && state.valor_dos && state.valor_dos === "0",
        mensaje: "El segundo valor no puede ser 0.",
      },
    ],
    limite: [
      {
        regla:
          nuevaCompaniaProducto &&
          (state.limite === "" || state.limite === "0"),
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const cotizadorLimitesReglas = (state) => {
  let obj = {};
  Object.keys(state).map((producto) => {
    console.log(producto);
    obj[producto] = [
      {
        regla: state[producto] === "",
        mensaje: "Este campo es requerido.",
      },
    ];
  });

  return { obj };
};

export const limitesReglas = (state) => {
  let obj = {};
  Object.keys(state).map((limite) => {
    console.log(limite);
    obj[limite] = [
      {
        regla: state[limite] === "",
        mensaje: "Este campo es requerido.",
      },
    ];
  });

  return { obj };
};

export const polizaReglas = (state) => {
  return {
    tipo: [
      {
        regla: state.tipo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    certificado: [
      {
        regla: state.certificado === "" && state.tipo === "certificado",
        mensaje: "Este campo es requerido.",
      },
    ],
    poliza: [
      {
        regla: state.poliza === "" && state.tipo === "poliza",
        mensaje: "Este campo es requerido.",
      },
    ],
    numero_poliza: [
      {
        regla: state.numero_poliza === "" && state.tipo === "poliza",
        mensaje: "Este campo es requerido.",
      },
    ],
    premio: [
      {
        regla: state.premio === "" && state.tipo === "poliza",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// Compania
export const endosoFormReglas = (state) => {
  return {
    numero_poliza: [
      {
        regla: state.numero_poliza === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    id_compania: [
      {
        regla: state.id_compania === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    cambios_poliza: [
      {
        regla: state.cambios_poliza === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    archivo_dni: [
      {
        regla: state.archivo_dni === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const crearGrupoReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El nombre es requerido",
      },
    ],
    integrantes: [
      {
        regla: state.integrantes.length === 0,
        mensaje: "El grupo debe tener al menos un integrante",
      },
    ],
  };
};

export const editarGrupoReglas = (state) => {
  return {
    integrantes: [
      {
        regla: state.integrantes.length === 0,
        mensaje: "El grupo debe tener al menos un integrante",
      },
    ],
  };
};

export const objetivosReglas = (state) => {
  return {
    operaciones: [
      {
        regla: state.operaciones !== "" && state.operaciones == 0,
        mensaje: "Operaciones no puede ser 0.",
      },
    ],
    prima: [
      {
        regla: state.prima !== "" && state.prima == 0,
        mensaje: "Prima no puede ser 0.",
      },
    ],
  };
};

export const objetivosPremiosReglas = (state) => {
  return {
    objetivos: [
      {
        regla: state.objetivos === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const excepcionReglas = (state) => {
  return {
    usuario: [
      {
        regla: state.usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    tipo: [
      {
        regla: state.tipo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    motivo_acuerdo: [
      {
        regla: state.tipo === "Acuerdo" && state.motivo_acuerdo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    tipo_goce_sueldo: [
      {
        regla: state.check_goce_sueldo && state.tipo_goce_sueldo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    tipo_goce_sueldo_otros: [
      {
        regla:
          state.tipo_goce_sueldo == 7 && state.tipo_goce_sueldo_otros === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    periodo: [
      {
        regla: state.periodo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    fecha_desde: [
      {
        regla:
          (state.periodo === "Desde / Hasta" || state.periodo === "Día") &&
          state.fecha_desde === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    fecha_hasta: [
      {
        regla:
          (state.periodo === "Desde / Hasta" || state.periodo === "Día") &&
          state.fecha_hasta === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    fecha: [
      {
        regla: state.periodo === "Fecha" && state.fecha === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    dia: [
      {
        regla: state.periodo === "Día" && state.dia === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    hora_desde: [
      {
        regla: state.tipo === "Acuerdo" && state.hora_desde === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    hora_hasta: [
      {
        regla: state.tipo === "Acuerdo" && state.hora_hasta === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const fichajePorUsuarioReglas = (state) => {
  return {
    usuario: [
      {
        regla: state.usuario === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    fecha: [
      {
        regla: state.fecha === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    hora: [
      {
        regla: state.hora === "",
        mensaje: "Requerido.",
      },
    ],
    fichaje: [
      {
        regla: state.fichaje === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const fichajePropioReglas = (state) => {
  return {
    fichaje: [
      {
        regla: state.fichaje === "",
        mensaje: "Es necesario seleccionar un tipo de fichaje.",
      },
    ],
  };
};

export const gestionarRechazoReglas = (state) => {
  return {
    estado: [
      {
        regla: state.estado === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    medio_contacto: [
      {
        regla: state.medio_contacto === "" && state.estado !== "5",
        mensaje: "Este campo es requerido.",
      },
    ],
    mensaje: [
      {
        regla: state.mensaje === "" && state.estado !== "5",
        mensaje: "Este campo es requerido.",
      },
    ],
    /*
        adjunto: [
            {
                regla: state.adjunto === "" && state.estado === "resuelto positivo", // VALIDAR SIZE
                mensaje: 'Este campo es requerido.'
            }
        ],
        */
  };
};

export const subirRechazoManualReglas = (state) => {
  return {
    importe: [
      {
        regla: state.importe === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.importe == 0,
        mensaje: "El importe no puede ser 0.",
      },
    ],
    poliza: [
      {
        regla: state.poliza === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    id_compania: [
      {
        regla: state.id_compania === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const subirRechazoManualUpdateReglas = (state) => {
  return {
    idNuevoEstado: [
      {
        regla: state.idNuevoEstado === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    medioContacto: [
      {
        regla: state.medioContacto === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const limitesPremiosReglas = (state) => {
  return {
    limite_general: [
      {
        regla: state.limite_general === "" || state.limite_general === 0,
        mensaje: "Este campo es requerido.",
      },
    ],
    limite_objetivos: [
      {
        regla: state.limite_objetivos === "" || state.limite_objetivos === 0,
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const porcentajeObjetivoOperacionesPremiosReglas = (state) => {
  return {
    operaciones_porcentaje_objetivo: [
      {
        regla:
          state.operaciones_porcentaje_objetivo === "" ||
          state.operaciones_porcentaje_objetivo === 0,
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

export const subirRechazoManualFullReglas = (state) => {
  return {
    poliza: [
      {
        regla: state.poliza === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    importe: [
      {
        regla: state.importe === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    id_compania: [
      {
        regla: state.id_compania === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    dni: [
      {
        regla: state.dni === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.dni.length !== 8,
        mensaje: "El DNI no es válido.",
      },
    ],
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    mail: [
      {
        regla: state.mail === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.mail),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    ramo: [
      {
        regla: state.ramo === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    medio_pago: [
      {
        regla: state.medio_pago === "",
        mensaje: "Este campo es requerido.",
      },
    ],
  };
};

// *** Baja Servicio *** //
export const nuevaAnulacionCalidadReglas = (state) => {
  return {
    nombre_asegurado: [
      {
        regla: state.nombre_asegurado === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre_asegurado.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre_asegurado
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido_asegurado: [
      {
        regla: state.apellido_asegurado === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: state.apellido_asegurado.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido_asegurado
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email_asegurado: [
      {
        regla: state.email_asegurado === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email_asegurado),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    // dni_asegurado: [
    //   {
    //     regla: state.dni_asegurado === "",
    //     mensaje: "El campo DNI es requerido.",
    //   },
    // ],
    // cuil_asegurado: [
    //   {
    //     regla: state.cuil_asegurado === "",
    //     mensaje: "El campo CUIL es requerido.",
    //   },
    // ],
    telefono_asegurado: [
      {
        regla: state.telefono_asegurado === "",
        mensaje: "El campo teléfono es requerido.",
      },
      {
        regla: state.telefono_asegurado.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    poliza_asegurado: [
      {
        regla: state.poliza_asegurado === "",
        mensaje: "El campo Póliza es requerido.",
      },
    ],
    compania_asegurado: [
      {
        regla: state.compania_asegurado === "",
        mensaje: "El campo Asegurado es requerido.",
      },
    ],
    producto_asegurado: [
      {
        regla: state.producto_asegurado === "",
        mensaje: "El campo Bien Asegurado es requerido.",
      },
    ],
    motivo_anulacion: [
      {
        regla: state.motivo_anulacion === "",
        mensaje: "El motivo de anulación es requerido.",
      },
    ],
    motivo_anulacion_otros: [
      {
        regla:
          state.motivo_anulacion_otros === "5" &&
          state.motivo_anulacion_otros === "",
        mensaje: "El motivo de la anulación es requerido.",
      },
    ],
  };
};

const validacionesProductoAnulacionCalidad = (state, campos, producto) => {
  const validaciones = {
    // BICICLETAS
    1: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // BICICLETAS ELECTRICAS
    44: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // MONOPATIN
    45: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // NOTEBOOKS
    10: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // TABLET
    11: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CELULARES
    4: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      imei: [
        {
          regla: state.imei === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // HOGAR
    2: {
      direccion: [
        {
          regla: state.direccion === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // AUTOS
    3: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // MOTOS
    5: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CAMIONES
    13: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CAMIONES
    14: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SCOOTER
    15: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CICLOMOTOR
    16: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // ACCIDENTES PERSONALES
    20: {
      archivo_frente_dni: [
        {
          regla: state.archivo_frente_dni === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SALUD
    53: {
      archivo_frente_dni: [
        {
          regla: state.archivo_frente_dni === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // HOGAR
    60: {
      direccion: [
        {
          regla: state.direccion === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SEGURO TECNICO
    31: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CASCOS
    63: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
  };
  let validacionesProducto = {};
  for (const key in validaciones[producto]) {
    const checkCampo = campos.find((campo) => key === campo.name_campo);
    if (checkCampo) validacionesProducto[key] = validaciones[producto][key];
  }
  return validacionesProducto;
};

export const anulacionCalidadProductoReglas = (state, wizardData) => {
  const { campos, producto } = wizardData;
  const validaciones = validacionesProductoAnulacionCalidad(
    state,
    campos,
    producto
  );
  return validaciones;
};
