import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ModalEmision = styled.div`
  padding: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .poliza-icon {
    font-size: 64px;
    color: ${leerColor(colores.celeste)};
    margin: 0px 0 0px 0;
    border: 3px solid ${leerColor(colores.celeste)};
    border-radius: 100%;
    padding: 40px;
    display: flex;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    span {
      color: ${leerColor(colores.grisEstados)};
    }
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 10px 0 10px 0;
  }

  .poliza-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h4 {
    margin: 0;
  }

  /*
  h1 {
    margin-bottom: 11px;
    padding-bottom: 0;
  }
  h2 {
    margin-top: 30px;
  }
  */
  h3 {
    margin: 0;
    font-weight: 400;
  }
  .buttonsCont {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }
`;

export const RechazoContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 600px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border};

  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 440px;
    overflow-y: scroll;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
  }
  .selectCont {
    width: 50%;
  }

  .conversor-prima-container {
    display: flex;
    flex-direction: column;
  }

  .conversor-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 10px 0;
    gap: 10px;

    div {
      width: 50%;
      align-items: center;
      display: flex;
      label {
        display: none;
      }
    }
  }

  .igual-conversor {
    font-size: 20px;
  }

  .prima-convertida {
    font-size: 16px;
    width: 50%;
    background-color: #f3f6ff;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: ${leerColor(colores.azulRebranding)};
    font-weight: 500;
  }

  .aclaracion-conversor {
    font-size: 12px;
    color: ${({ theme }) => theme.subtitle};
  }

  // OBJETIVOS PRODUCTOS
  .container-form-objetivos-productos {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    a {
      width: 20%;
    }
  }

  .container-objetivos-productos-agregados {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    h4 {
      color: ${({ theme }) => theme.subtitle};
      font-weight: 400;
      margin: 0;
      font-size: 14px;
    }
  }

  .objetivo-producto {
    color: ${({ theme }) => theme.subtitle};
    border: 1px solid ${({ theme }) => theme.border};
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 6px;
    font-size: 14px;
    
    span {
      display: flex;
      align-items: center;
    }
  }

  .objetivo-producto-informacion {
    display: flex;
    gap: 6px;
  }

  .objetivo-producto-botones {
    display: flex;
    gap: 10px;

    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }

  .objetivo-producto-crear {
    color: ${({ theme }) => theme.subtitle};
    border: 1px dashed ${({ theme }) => theme.border};
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 6px;
    font-size: 14px;
    gap: 6px;
    transition: 0.3s;
    cursor: pointer;
    
    span {
      display: flex;
      align-items: center;
    }

    :hover {
      opacity: 0.7;
    }
  }
`;