import React, { useEffect, useState } from "react";
import Subtitle from "../../../Subtitle";
import { FiFile } from "react-icons/fi";
import NewTable from "../../../NewTable";
import { useTable } from "../../../../hooks/useTable";
import { operacionesVendedorTable } from "../../../../utils/tableData";
import axiosRequest from "../../../../utils/axiosConfig";
import Divider from "../../../Divider";
import { useLoading } from "../../../../hooks/useLoading";
import CardSinResultados from "../../../CardSinResultados";

const VendedorOperacionesTab = ({ data }) => {
  const { vendedor, mes, anio } = data;
  const { order, handleTableOrder } = useTable();
  const tableData = operacionesVendedorTable();
  const [operacionesVendedor, setOperacionesVendedor] = useState([]);
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    const getOperaciones = async () => {
      setLoading(true);
      setOperacionesVendedor([]);
      try {
        const operaciones = await axiosRequest.get(
          `/premios/operaciones_vendedor/${vendedor.id}/${mes}/${anio}`
        );
        setOperacionesVendedor(operaciones.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOperaciones();
  }, [mes, anio]);

  console.log(operacionesVendedor);

  return (
    <>
      <Divider />
      <Subtitle
        icon={<FiFile />}
        title={"Operaciones realizadas"}
        description={`Lista de operaciones realizadas en el mes (${operacionesVendedor.length} operaciones)`}
        disableDivider={true}
      />
      {operacionesVendedor.length === 0 && !loading ? (
        <CardSinResultados
          icon={<FiFile />}
          title="No hay operaciones"
          description="No se encontraron operaciones para el mes y año seleccionados"
        />
      ) : (
        <NewTable
          columns={tableData}
          data={operacionesVendedor}
          loading={loading}
          order={handleTableOrder}
          orderParams={order}
        />
      )}
    </>
  );
};

export default VendedorOperacionesTab;
