import {
  Container,
  StepsContainer,
  StepContainer,
  Step,
  StepTitle,
  StepContent,
  ButtonsContainer,
  ButtonsCont,
  LabelStep,
} from "./styled";
import Button from "../Button";
import useWizard from "../../hooks/useWizard";
import { useSolicitudContext } from "../../context/SolicitudContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import NewButton from "../NewButton";
const Wizard = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const {
    stepSelected,
    nextStep,
    previousStep,
    selectStep,
    submitDisabled,
    setSubmitDisabled,
  } = useWizard();

  const solicitud = useSolicitudContext();

  console.log(solicitud)

  // {children[stepSelected - 1]}

  return (
    <>
      <StepsContainer>
        {children.map((item, index) => {
          return (
            <StepContainer selected={stepSelected} index={index}>
              <Step
                onClick={(e) =>
                  selectStep(
                    e,
                    index + 1,
                    children.length,
                    Object.values(solicitud)[stepSelected - 1].validator
                  )
                }
                selected={stepSelected}
                index={index}
                error={
                  stepSelected === index + 1 &&
                  stepSelected === children.length - 1
                    ? Object.keys(
                        Object.values(solicitud)[stepSelected - 1]?.formErrors
                      ).length > 0
                    : false
                }
              >
                {index + 1}
              </Step>
              <LabelStep>{Object.values(solicitud)[index]?.name}</LabelStep>
            </StepContainer>
          );
        })}
      </StepsContainer>

      {children[stepSelected - 1]}

      <ButtonsCont stepSelected={stepSelected}>
        {stepSelected === 1 && (
          <Link
            to={{
              pathname:
                user.id_rol === 10 || user.id_rol === 22
                  ? "/CargarSolicitudEmision"
                  : "/CargarSolicitudEmision",
            }}
          >
            <NewButton backgroundColor={false} textColor={true}>
              <span>
                <FiArrowLeft />
              </span>
              Volver
            </NewButton>
          </Link>
        )}
        {stepSelected !== 1 && stepSelected !== children.length && (
          <NewButton
            backgroundColor={false}
            textColor={true}
            borderColor={true}
            onClick={previousStep}
          >
            <span>
              <FiArrowLeft />
            </span>
            Atrás
          </NewButton>
        )}
        {stepSelected < children.length - 1 && (
          <NewButton
            backgroundColor={true}
            onClick={(e) =>
              nextStep(e, Object.values(solicitud)[stepSelected - 1].validator)
            }
          >
            Siguiente
            <span>
              <FiArrowRight />
            </span>
          </NewButton>
        )}
        {stepSelected === children.length - 1 && (
          <NewButton
            backgroundColor={true}
            onClick={(e) =>
              solicitud.solicitud.postearSolicitud(
                e,
                nextStep,
                Object.values(solicitud)[stepSelected - 1].validator,
                setSubmitDisabled
              )
            }
            disabled={submitDisabled}
          >
            Enviar Solicitud
          </NewButton>
        )}
      </ButtonsCont>
    </>
  );
};

export default Wizard;
