import React from 'react'
import { Container } from './styled'

const Divider = () => {
  return (
    <Container>
        <div />
    </Container>
  )
}

export default Divider