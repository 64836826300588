import React from "react";
import { Col, Grid } from "../../../../../Grid";
import { FiCheck, FiLogIn, FiLogOut, FiThumbsUp } from "react-icons/fi";
import dayjs from "dayjs";

const FichajesEspecialesTab = ({ data, fichajes, acciones }) => {
  const { fichajeSeleccionado } = data;
  const { ingreso, salida_especial, reingreso_especial } = fichajes;
  const { seleccionarFichaje } = acciones;
  
  return (
    <div>
      <Grid colGap={21} rowGap={21} narrow={true}>
        {/* Fichaje de salida especial disponible para todos los roles */}
        <Col desktop={12}>
          <div
            className={`fichaje ${
              fichajeSeleccionado === "Salida especial" && !salida_especial
                ? "selected"
                : ""
            }  ${salida_especial ? "fichado" : ""}`}
            onClick={() => seleccionarFichaje("Salida especial")}
          >
            <div className="fichaje-data">
              <span className="icon">
                <FiLogOut />
              </span>
              <div className="fichaje-info">
                <h4>Salida especial</h4>
                {salida_especial ? (
                  <span>
                    Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}
                    hs
                  </span>
                ) : null}
              </div>
            </div>
            {salida_especial ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-realizado">
                  <FiCheck />
                </span>
                <span>Fichaje realizado</span>
              </div>
            ) : null}
            {!salida_especial ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-disponible">
                  <FiThumbsUp />
                </span>
                <span className="fichaje-disponible">Fichaje disponible</span>
              </div>
            ) : null}
          </div>
        </Col>
        {/* Fichaje de reingreso especial disponible para todos los roles */}
        <Col desktop={12}>
          <div
            className={`fichaje ${!salida_especial ? "disabled" : ""} ${
              fichajeSeleccionado === "Inicio almuerzo" && salida_especial
                ? "selected"
                : ""
            } ${reingreso_especial ? "fichado" : ""}`}
            onClick={() => seleccionarFichaje("Reingreso especial")}
          >
            <div className="fichaje-data">
              <span className="icon">
                <FiLogIn />
              </span>
              <div className="fichaje-info">
                <h4>Reingreso especial</h4>
                {reingreso_especial ? (
                  <span>
                    Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}
                    hs
                  </span>
                ) : null}
              </div>
            </div>
            {reingreso_especial ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-realizado">
                  <FiCheck />
                </span>
                <span>Fichaje realizado</span>
              </div>
            ) : null}
            {!reingreso_especial && salida_especial ? (
              <div className="fichaje-realizado">
                <span className="icon-fichaje-disponible">
                  <FiThumbsUp />
                </span>
                <span className="fichaje-disponible">Fichaje disponible</span>
              </div>
            ) : null}
          </div>
        </Col>
      </Grid>
    </div>
  );
};

export default FichajesEspecialesTab;
