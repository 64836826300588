import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RowsContainer } from './styled'
import { useTheme } from '../../context/ThemeContext';

const SkeletonLoading = ({rows, columns}) => {

    const { theme } = useTheme()

    const generateSkeletons = () => {
        const arraySkeleton = [];
        const skeletons = []
        for (let i = 0; i < rows; i++){
          skeletons.push(<Skeleton width={columns ? columns[i].size : "80%"} />)
        }
        for (let index = 0; index < 5; index++) {
            arraySkeleton.push(
              <SkeletonTheme >
                <RowsContainer rows={rows}>
                  {skeletons}
                </RowsContainer>
              </SkeletonTheme>
            )
        }
        return arraySkeleton;
    }

    return(
      generateSkeletons()
    )
}

export default SkeletonLoading