import { useState, useEffect, useRef } from "react";
import formatSelectData from "../../../../utils/formatSelectData";
import { useLoading } from "../../../../hooks/useLoading";
import usePagination from "../../../../hooks/usePagination";
import { useAuthContext } from "../../../../context/AuthContext";
import axiosRequest from "../../../../utils/axiosConfig";
import {
  getCompanias,
  getOperadoresCalidad,
} from "../../../../services/Emision";
import {
  getAnulaciones,
  getMotivosAnulaciones,
  getProductosCalidad,
} from "../../../../services/ControlCalidad";

export default function useControlCalidad(filterParams, orderParams, mes, anio) {
  // User Data
  const { authState } = useAuthContext();
  const { userInfo } = authState;
  const userParams = userInfo.id_rol === 18 ? `user=${userInfo.id_operador}` : ``;

  const [anulaciones, setAnulaciones] = useState([]);
  const [anulacionesLength, setAnulacionesLength] = useState(0);
  const [operadoresIds, setOperadoresIds] = useState([]);
  const [companias, setCompanias] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [estadisticasCompanias, setEstadisticasCompanias] = useState(null)
  const [estadisticasProductos, setEstadisticasProductos] = useState(null)
  const [estadisticasMotivos, setEstadisticasMotivos] = useState(null)
  const [estadisticas, setEstadisticas] = useState({});

  // Paginación
  const pagination = usePagination(anulacionesLength);

  // Filter ref
  const filtrosRef = useRef(null);
  const { loading, setLoading } = useLoading(false);

  const refreshAnulaciones = async () => {
    setLoading(true);
    setAnulaciones([]);
    let paginacion =
      filtrosRef.current !== filterParams ? 1 : pagination.currentPage;
    if (filtrosRef.current !== filterParams) {
      filtrosRef.current = filterParams;
      pagination.selectPage(1);
    }
    try {
      const anulaciones = await getAnulaciones(
        filterParams,
        orderParams,
        paginacion,
        userParams
      );
      const anulacionesLength = await getAnulaciones(
        filterParams,
        orderParams,
        null,
        userParams
      );
      setAnulaciones(anulaciones);
      setAnulacionesLength(anulacionesLength.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshAnulaciones();
  }, [filterParams, orderParams, pagination.currentPage]);

  const obtenerEstadisticas = (solicitudes) => {
    const data = solicitudes.reduce((total, item) => {
      const keys = Object.keys(total).length;
      if (keys == 0) {
        const obj = {
          pendientes: 0,
          rechazadas: 0,
          prioritarias: 0,
          anuladas: 0,
          endosos: 0,
          nuevas: 0,
        };
        if (item.id_estado == 1) obj.pendientes += 1;
        if (item.id_estado == 3) obj.rechazadas += 1;
        if (item.prioridad == 1) obj.prioritarias += 1;
        if (item.id_tipo_solicitud == 1) obj.nuevas += 1;
        if (item.id_tipo_solicitud == 2) obj.anuladas += 1;
        if (item.id_tipo_solicitud == 3) obj.endosos += 1;

        return obj;
      } else {
        if (item.id_estado == 1) total.pendientes += 1;
        if (item.id_estado == 3) total.rechazadas += 1;
        if (item.prioridad == 1) total.prioritarias += 1;
        if (item.id_tipo_solicitud == 1) total.nuevas += 1;
        if (item.id_tipo_solicitud == 2) total.anuladas += 1;
        if (item.id_tipo_solicitud == 3) total.endosos += 1;

        return total;
      }
    }, {});
    return data;
  };

  useEffect(() => {
    const getInitialData = async () => {
      if (!filterParams) {
        try {
          const anulaciones = await getAnulaciones(null, null, 1, userParams);
          const anulacionesLength = await getAnulaciones(
            null,
            null,
            null,
            userParams
          );
          setAnulaciones(anulaciones);
          setAnulacionesLength(anulacionesLength.length);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getEstadisticas = async () => {
      try {
        const estadisticasAnulaciones = await axiosRequest.get(`/control_calidad/anulaciones/estadisticas/${mes}/${anio}`)

        // Compañias
        const labelsCompania = estadisticasAnulaciones.data.companias.map(item => item.nombre_compania)
        const valuesCompania = estadisticasAnulaciones.data.companias.map((item) => item.cantidad)
        const estadisticasCompanias = {
          labels: labelsCompania,
          datasets: [
            {
              label: "Compañia",
              data: valuesCompania,
              backgroundColor: "#1A56FF",
            }
          ],
        };

        // Productos
        const labelsProductos = estadisticasAnulaciones.data.productos.map(item => item.nombre_producto)
        const valuesProductos = estadisticasAnulaciones.data.productos.map((item) => item.cantidad)
        const estadisticasProductos = {
          labels: labelsProductos,
          datasets: [
            {
              label: "Producto",
              data: valuesProductos,
              backgroundColor: "#1A56FF",
            }
          ],
        };

        // Productos
        const labelsMotivos = estadisticasAnulaciones.data.motivos.map(item => item.nombre_motivo_anulacion)
        const valuesMotivos = estadisticasAnulaciones.data.motivos.map((item) => item.cantidad)
        const estadisticasMotivos = {
          labels: labelsMotivos,
          datasets: [
            {
              label: "Motivos de anulaciones",
              data: valuesMotivos,
              backgroundColor: [
                "rgb(26, 86, 255)",
                "rgb(116, 255, 227)",
                "rgb(125, 188, 255)",
                "rgb(255, 65, 204)",
              ],
              hoverOffset: 4,
            },
          ],
        };
        setEstadisticasMotivos(estadisticasMotivos)
        setEstadisticasProductos(estadisticasProductos)
        setEstadisticasCompanias(estadisticasCompanias)
      } catch (error) {
        console.log(error);
      }
    }
    getEstadisticas()
  }, [mes, anio])

  useEffect(() => {
    const getInitialData = async () => {
      try {
        const companias = await getCompanias();
        const operadores = await getOperadoresCalidad();
        const motivos = await getMotivosAnulaciones();
        const productos = await getProductosCalidad()
        const estadisticasAnulaciones = await axiosRequest.get(`/control_calidad/anulaciones/estadisticas/${mes}/${anio}`)
        console.log(estadisticasAnulaciones)

        // Compañias
        const labelsCompania = estadisticasAnulaciones.data.companias.map(item => item.nombre_compania)
        const valuesCompania = estadisticasAnulaciones.data.companias.map((item) => item.cantidad)
        const estadisticasCompanias = {
          labels: labelsCompania,
          datasets: [
            {
              label: "Compañia",
              data: valuesCompania,
              backgroundColor: "#1A56FF",
            }
          ],
        };

        // Productos
        const labelsProductos = estadisticasAnulaciones.data.productos.map(item => item.nombre_producto)
        const valuesProductos = estadisticasAnulaciones.data.productos.map((item) => item.cantidad)
        const estadisticasProductos = {
          labels: labelsProductos,
          datasets: [
            {
              label: "Producto",
              data: valuesProductos,
              backgroundColor: "#1A56FF",
            }
          ],
        };

        // Productos
        const labelsMotivos = estadisticasAnulaciones.data.motivos.map(item => item.nombre_motivo_anulacion)
        const valuesMotivos = estadisticasAnulaciones.data.motivos.map((item) => item.cantidad)
        const estadisticasMotivos = {
          labels: labelsMotivos,
          datasets: [
            {
              label: "Motivos de anulaciones",
              data: valuesMotivos,
              backgroundColor: [
                "rgb(26, 86, 255)",
                "rgb(116, 255, 227)",
                "rgb(125, 188, 255)",
                "rgb(255, 65, 204)",
              ],
              hoverOffset: 4,
            },
          ],
        };

        // const productosList = formatSelectData(
        //   productos.data,
        //   "numero",
        //   "descripcion",
        //   "numero"
        // );
        setEstadisticasMotivos(estadisticasMotivos)
        setEstadisticasProductos(estadisticasProductos)
        setEstadisticasCompanias(estadisticasCompanias)
        setProductos(productos)
        setMotivos(motivos);
        setOperadoresIds(operadores.operadoresIds);
        setCompanias(companias);
      } catch (error) {
        console.log(error);
      }
    };
    getInitialData();
  }, []);

  return {
    data: {
      anulaciones: anulaciones,
      companias: companias,
      productos: productos,
      motivos: motivos,
      operadoresIds: operadoresIds,
    },
    estadisticas: {
      companias: estadisticasCompanias,
      productos: estadisticasProductos,
      motivos: estadisticasMotivos
    },
    refreshAnulaciones,
    loading,
    pagination,
  };
}
