import React, { useState, useEffect } from "react";
import {
  InputTextarea,
  InputFileButton,
  ErrorInput,
  Subtitulo,
  Grid,
  Col,
  InputBasic,
  Modal,
  FileLoaded,
} from "../../Index";

import { useSolicitudContext } from "../../../context/SolicitudContext";
import Subtitle from "../../Subtitle";
import { FiEye } from "react-icons/fi";
import NewInputTextarea from "../../NewInputTextarea";
import InputFile from "../../InputFile";
import NewInputSelect from "../../NewInputSelect";
import NewInputBasic from "../../NewInputBasic";
import { Container } from "./styled";

const AnularPolizaForm = () => {
  const { anular } = useSolicitudContext();
  const { values, formErrors, handleChange, handleChangeExternal, data } =
    anular;
  const { companias } = data;

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={12}>
          <Subtitle
            title="Anulación"
            description="Adjuntar póliza que se debe anular o completar el número de póliza y compañia"
            icon={<FiEye />}
          />
        </Col>
      </Grid>
      <Container>
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={4}>
          <NewInputBasic
            type={"number"}
            name={"numero_poliza"}
            labelName={"Número de la póliza"}
            color={formErrors.numero_poliza ? "error" : "normal"}
            placeholder={"Número de la póliza"}
            onChange={handleChange}
            value={values.numero_poliza}
          />
          {formErrors.numero_poliza && (
            <ErrorInput>{formErrors.numero_poliza}</ErrorInput>
          )}
        </Col>
        <Col desktop={4}>
          <NewInputSelect
            name={"id_compania"}
            labelName={"Compañía"}
            color={formErrors.id_compania ? "error" : "normal"}
            placeholder="Compañia"
            onChange={handleChange}
            options={companias}
            value={values.id_compania}
          />
          {formErrors.id_compania && (
            <ErrorInput>{formErrors.id_compania}</ErrorInput>
          )}
        </Col>
      </Grid>
      </Container>
    </>
  );
};

export default AnularPolizaForm;
